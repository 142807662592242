import { useMutation } from '@apollo/client';
import { ActionIcon, Button, FileButton } from '@mantine/core';
import { UploadSimple } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { ClientImageDraft, Folder } from '@shared';
import { createUploadedImageDraftMutation } from '../../graphql/common';
import useImageUploader from '../../hooks/useImageUploader';
import { clearEntitiesFromCache } from '../../utils/cache';
import { handleUnexpectedError } from '../../utils/error';

type UploadImageButtonProps = {
	folder: Folder;
	sourceDraftId: string;
	handleImageUploaded: (image: ClientImageDraft) => void;
	text?: string;
};

export function UploadImageDraftButton({
	folder,
	sourceDraftId,
	handleImageUploaded,
	text = 'Upload',
}: UploadImageButtonProps) {
	const [file, setFile] = useState<File>();
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const { uploadImage } = useImageUploader();
	const [createUploadedImageDraft] = useMutation(createUploadedImageDraftMutation);

	async function handleUploadImage(file: File) {
		setIsUploading(true);
		try {
			const url = await uploadImage(file, folder);
			const imageDraftResponse = await createUploadedImageDraft({
				variables: {
					input: {
						uploadedImageUrl: url,
						sourceDraftId,
					},
				},
				update(cache) {
					clearEntitiesFromCache(cache, ['imageDrafts']);
				},
			});
			const imageDraft = imageDraftResponse.data!.createUploadedImageDraft;
			handleImageUploaded(imageDraft);
		} catch (error) {
			handleUnexpectedError(error);
		} finally {
			setIsUploading(false);
		}
	}

	useEffect(() => {
		if (!file) {
			return;
		}

		handleUploadImage(file);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file]);

	return (
		<FileButton onChange={(file) => setFile(file ?? undefined)} accept="image/png,image/jpeg">
			{(props) =>
				text === '' ? (
					<ActionIcon size="sm" loading={isUploading} {...props}>
						<UploadSimple />
					</ActionIcon>
				) : (
					<Button size="xs" leftSection={<UploadSimple />} loading={isUploading} {...props}>
						{text}
					</Button>
				)
			}
		</FileButton>
	);
}
