import { Shape, ShapeProportions } from '../types';

export function getProportions(shape: Shape): ShapeProportions {
	switch (shape) {
		case Shape.Circle:
			return {
				bodyY: 1,
				bodyPadding: 0.25,
			};
		case Shape.CircleWithTail:
			return {
				bodyY: 0.8,
				bodyPadding: 0.2,
			};
		case Shape.Cloud:
			return {
				bodyY: 1,
				bodyPadding: 0.4,
			};
		case Shape.Rectangle:
			return {
				bodyY: 1,
				bodyPadding: 0.1,
			};
		case Shape.RectangleWithTail:
			return {
				bodyY: 0.8,
				bodyPadding: 0.1,
			};
		case Shape.RectangleRounded:
			return {
				bodyY: 1,
				bodyPadding: 0.1,
			};
		case Shape.RectangleRoundedWithTail:
			return {
				bodyY: 0.8,
				bodyPadding: 0.1,
			};
	}
}

export function calculateShapeScaleFactors(shape1: Shape, shape2: Shape): ShapeProportions {
	const proportions1 = getProportions(shape1);
	const proportions2 = getProportions(shape2);

	return {
		bodyY: proportions2.bodyY / proportions1.bodyY,
		bodyPadding: 1 - (proportions2.bodyPadding - proportions1.bodyPadding),
	};
}
