import { useMutation } from '@apollo/client';
import { Button, Select, Stack, Text, Textarea } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import {
	ContactUsMessageType,
	SendContactUsEmailInput,
	SendContactUsEmailInputSchema,
	graphql,
	pascalCaseToSentence,
} from '@shared';
import PageHeader from '../components/ui/PageHeader';
import ViewContainer from '../components/ui/ViewContainer';

export default function ContactUs() {
	const [sendContactUsEmail, { loading: sendContactUsEmailLoading }] = useMutation(sendContactUsEmailMutation);

	const form = useForm<SendContactUsEmailInput>({
		validate: zodResolver(SendContactUsEmailInputSchema),
		validateInputOnBlur: true,
		initialValues: {
			type: ContactUsMessageType.Feedback,
			message: '',
		},
	});

	async function handleContactUs() {
		await sendContactUsEmail({
			variables: {
				input: form.values,
			},
			onCompleted: () => {
				form.reset();
				notifications.show({
					color: 'teal',
					title: 'Message sent',
					message: 'We will get back to you as soon as possible via email to follow up on your message.',
				});
			},
		});
	}

	return (
		<ViewContainer width="compact">
			<PageHeader text="Contact Us" />
			<Stack>
				<Select
					label="Type"
					data={Object.keys(ContactUsMessageType).map((key) => ({
						label: pascalCaseToSentence(key),
						value: key,
					}))}
					allowDeselect={false}
					{...form.getInputProps('type')}
				/>
				<Textarea label="Message" minRows={4} {...form.getInputProps('message')} />
				<Button
					loading={sendContactUsEmailLoading}
					disabled={!form.isValid()}
					onClick={async () => await handleContactUs()}
					mt="xs"
				>
					Submit
				</Button>
				<Text c="dimmed" size="xs" ta="center">
					We will get back to you as soon as possible via email to follow up on your message.
				</Text>
			</Stack>
		</ViewContainer>
	);
}

const sendContactUsEmailMutation = graphql(`
	mutation SendContactUsEmail($input: SendContactUsEmailInput!) {
		sendContactUsEmail(input: $input)
	}
`);
