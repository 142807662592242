import { Box, Button, Center, Grid, Group, Menu } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';
import { UserRole } from '@shared';
import LumiLogo from '../assets/LumiLogo';
import useSession from '../hooks/useSession';
import { headerHeight } from '../utils/ui';
import BurgerMenu from './BurgerMenu';
import Cart from './Cart';
import ThemeToggler from './ThemeToggler';
import TokenCount from './TokenCount';
import UserMenu from './UserMenu';
import ProfilePicture from './ui/ProfilePicture';
import ViewContainer from './ui/ViewContainer';

export default function Header() {
	const { pathname } = useLocation();
	const { isAuthenticated, user } = useSession();

	return (
		<ViewContainer h={headerHeight}>
			<Center h={headerHeight}>
				<Grid w="100%">
					<Grid.Col span={5}>
						<BurgerMenu />
					</Grid.Col>
					<Grid.Col span={2}>
						<Center>
							<LumiLogo link="/" />
						</Center>
					</Grid.Col>
					<Grid.Col span={5}>
						<Group justify="flex-end">
							{!isAuthenticated && <ThemeToggler />}
							{isAuthenticated ? (
								<>
									<Cart />
									{user.role !== UserRole.BetaTester && <TokenCount />}
									<Menu position="bottom-end">
										<Menu.Target>
											<Box>
												<ProfilePicture user={user} isLink={false} />
											</Box>
										</Menu.Target>
										<UserMenu />
									</Menu>
								</>
							) : pathname === '/sign-up' ? (
								// Show sign in button on the sign up page, otherwise always sign up
								<Button component={Link} to="/sign-in">
									Sign in
								</Button>
							) : (
								<Button component={Link} to="/sign-up">
									Sign up
								</Button>
							)}
						</Group>
					</Grid.Col>
				</Grid>
			</Center>
		</ViewContainer>
	);
}
