import { zod } from '../utils';

export const featureFlagsSchema = zod.object({
	// aws managed flags
	enabled: zod.boolean(),
	// custom flags
	isOn: zod.boolean(),
	isWaitlistEnabled: zod.boolean(),
});

export type FeatureFlags = zod.infer<typeof featureFlagsSchema>;
