import { useQuery } from '@apollo/client';
import { Box, Group, Stack, Switch, Title, useMantineColorScheme } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { Step } from 'intro.js-react';
import { useEffect, useState } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import {
	CompanionMessage,
	CompanionMessageSchema,
	Endpoints,
	ResponseBody,
	ResultOf,
	StoryDraft,
	TutorialType,
	graphql,
} from '@shared';
import CompanionChat, { GenericStream } from '../../components/CompanionChat';
import NewCharacterCard from '../../components/NewCharacterCard';
import Tutorial from '../../components/Tutorial';
import RoundedImage from '../../components/ui/RoundedImage';
import Sidebar from '../../components/ui/Sidebar';
import TextEditor from '../../components/ui/TextEditor';
import { fillHeight, fillHeightTarget } from '../../css/utils.module.css';
import { useStream } from '../../hooks/useStream';
import useTutorials from '../../hooks/useTutorials';
import { showCustomErrorNotification } from '../../utils/error';
import { sendRequest } from '../../utils/request';
import { NewStoryPageHeader } from './pageHeader';
import { StepComponentProps } from './stepComponent';

const endpoint = Endpoints.sendMessageToIdeaCompanion;

type IdeaProps = StepComponentProps;

export default function Idea({ storyDraft, handleUpdateStoryDraft, activeStep, setActiveStep }: IdeaProps) {
	const companionForm = useForm({
		validate: zodResolver(CompanionMessageSchema.pick({ text: true })),
		initialValues: { text: '' },
	});
	const { shouldShowTutorial, setShouldShowTutorial, acknowledgeTutorial } = useTutorials({
		tutorialType: TutorialType.NewStoryIdea,
	});
	const { colorScheme } = useMantineColorScheme();
	const [characters, setCharacters] = useState<CharactersForNewStory>([]);
	const [showDiff, setShowDiff] = useState(false);
	const [prevIdea, setPrevIdea] = useState(storyDraft.idea);
	const [idea, setIdea] = useState(storyDraft.idea);
	const stream = useStream({ endpoint, handleCompleteStream });
	const [isGeneratingScenes, setIsGeneratingScenes] = useState(false);
	const [companionHistory, setCompanionHistory] = useState<CompanionMessage[]>([
		{
			role: 'assistant',
			text: "Need help with your story? I'm here to help! Just type your idea below and I'll help you flesh it out.",
		},
	]);
	useQuery(getCharactersForNewStoryQuery, {
		onCompleted(data) {
			setCharacters(data.characters.map((c) => ({ ...c, isSelected: false })));
		},
	});

	// Since storyDraft.idea is async, we need to update the idea state when it changes
	useEffect(() => {
		setIdea(storyDraft.idea);
	}, [storyDraft.idea]);

	// Keep parent StoryDraft in sync with changes on this page
	useEffect(() => {
		handleUpdateStoryDraft({ idea });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [idea]);

	function handleSelectCharacter(character: StoryDraft['characters'][number], wasPreviouslySelected: boolean) {
		const newSelectedCharacters = wasPreviouslySelected
			? storyDraft.characters.filter((c) => c.id !== character.id)
			: [...storyDraft.characters, character];
		handleUpdateStoryDraft({ characters: newSelectedCharacters });
	}

	function handleCompleteStream(data: ResponseBody<typeof endpoint>) {
		setPrevIdea(idea);
		if (data.idea) {
			setIdea(data.idea);
		}
		const inboundMessage: CompanionMessage = {
			role: 'assistant',
			text: data.message,
			rawMessage: JSON.stringify(data),
		};
		setCompanionHistory((prev) => [...prev, inboundMessage]);
	}

	async function handleSendMessage() {
		if (storyDraft.characters.length === 0) {
			showCustomErrorNotification({
				message: 'You must select at least one character to generate an idea',
			});
			return;
		}
		const { text } = companionForm.values;
		const outboundMessage: CompanionMessage = {
			role: 'user',
			text,
		};
		companionForm.reset();
		setCompanionHistory((prev) => [...prev, outboundMessage]);
		stream.start({
			message: text,
			idea,
			characterIds: storyDraft.characters.map((c) => c.id),
			storyDraftId: storyDraft.id,
			history: companionHistory.slice(-10),
		});
	}

	function handlePreviousClicked() {
		setActiveStep(activeStep - 1);
	}

	async function handleNextClicked() {
		if (idea.length <= 0) {
			showCustomErrorNotification({
				message: 'You must finish your idea with the Companion',
			});
			return;
		}
		setIsGeneratingScenes(true);
		const response = await sendRequest(Endpoints.generateScript, {
			idea,
			characterIds: storyDraft.characters.map((c) => c.id),
			storyDraftId: storyDraft.id,
			settings: storyDraft.settings,
		});
		handleUpdateStoryDraft({
			idea,
			script: response.data.script,
		});
		setActiveStep(activeStep + 1);
		// TODO: Update token cache
	}

	return (
		<>
			<NewStoryPageHeader
				activeStep={activeStep}
				isLoading={stream.isActive || isGeneratingScenes}
				isNextButtonDisabled={false}
				handlePreviousClicked={handlePreviousClicked}
				handleNextClicked={handleNextClicked}
				setActiveStep={setActiveStep}
				handleShowTutorial={setShouldShowTutorial}
			/>
			<Tutorial
				steps={tutorialSteps}
				shouldShowTutorial={shouldShowTutorial}
				handleExitTutorial={acknowledgeTutorial}
			/>
			<Sidebar.Container pb="xs">
				<Sidebar.Side w="lg">
					<Stack className={fillHeight}>
						<Title order={6}>Companion</Title>
						<CompanionChat
							form={companionForm}
							stream={stream as GenericStream}
							companionHistory={companionHistory}
							handleSendMessage={handleSendMessage}
						/>
					</Stack>
				</Sidebar.Side>
				<Sidebar.Main>
					<Stack w="100%" className={fillHeight}>
						<Group justify="space-between">
							<Title order={6}>Idea</Title>
							<Switch
								id="see-changes-switch"
								checked={showDiff}
								onLabel="Hide changes"
								offLabel="See changes"
								size="lg"
								onChange={() => setShowDiff((prev) => !prev)}
							/>
						</Group>
						<TextEditor
							id="idea-input"
							placeholder={
								!idea && !stream.activeStreamData
									? 'In the deep, dark jungles of the Amazon, a group of explorers stumble upon a hidden temple. Inside, they find a mysterious artifact that ...'
									: undefined
							}
							text={stream.activeStreamData?.idea ?? idea}
							handleChange={setIdea}
							className={fillHeightTarget}
						/>
						{showDiff && prevIdea !== idea && (
							<Box
								style={{
									border: `1px solid ${colorScheme === 'dark' ? '#424242' : '#ced4da'}`,
									borderRadius: 4,
								}}
							>
								<ReactDiffViewer
									oldValue={prevIdea}
									newValue={idea}
									splitView={false}
									hideLineNumbers
									hideMarkers
									compareMethod={DiffMethod.WORDS}
									useDarkTheme={colorScheme === 'dark'}
									styles={{
										contentText: {
											fontSize: 14,
											fontFamily: 'inherit',
											padding: '2px 8px',
										},
									}}
								/>
							</Box>
						)}
					</Stack>
				</Sidebar.Main>
				<Sidebar.Side w="sm">
					<Stack>
						<Title order={6}>Characters</Title>
						<Stack className={fillHeight}>
							<NewCharacterCard callbackUrl={`/new-story/${storyDraft.id}`} />
							{characters.map((character, i) => {
								const isSelected = !!storyDraft.characters.find(({ id }) => character.id === id);
								return (
									<RoundedImage
										id={`character-${i}`}
										key={character.id}
										label={character.name}
										url={character.imageUrl}
										isSelected={isSelected}
										onClick={() => handleSelectCharacter(character, isSelected)}
									/>
								);
							})}
						</Stack>
					</Stack>
				</Sidebar.Side>
			</Sidebar.Container>
		</>
	);
}

export type GetCharactersForNewStoryReturn = ResultOf<typeof getCharactersForNewStoryQuery>['characters'];
type CharactersForNewStory = (GetCharactersForNewStoryReturn[number] & { isSelected: boolean })[];
const getCharactersForNewStoryQuery = graphql(`
	query GetCharactersForNewStory {
		characters {
			id
			name
			description
			imageUrl
			createdAt
		}
	}
`);

const tutorialSteps: Step[] = [
	{
		element: '#companion-message-form',
		title: 'Companion',
		intro: 'Your companion is here to help you create and edit your idea. Start typing or use the mic to chat with it.',
	},
	{
		element: '#idea-input',
		title: 'Idea',
		intro: 'You can also make edits directly in the text box.',
	},
	{
		element: '#see-changes-switch',
		title: 'Changes',
		intro: '"See Changes" allows you to see any edits the companion has made to your idea, if there are any.',
	},
	{
		element: '#character-0',
		title: 'Characters',
		intro: 'You can also add or remove characters from your story as your idea evolves.',
	},
	{
		element: '#new-story-header-next-button',
		title: 'Next',
		intro: 'Once you are satisfied with your idea, click Next to continue.',
	},
];
