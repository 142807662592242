import { Button, Stack, Text } from '@mantine/core';
import PageHeader from '../components/ui/PageHeader';
import ViewContainer from '../components/ui/ViewContainer';

export default function ErrorPage() {
	return (
		<ViewContainer width="compact">
			<Stack>
				<PageHeader text="Whoops!" />
				<Text>
					We seem to have run into some errors. Don't worry, our development team has already notified. Try refreshing
					the page below, or come back later.
				</Text>
				<Button onClick={() => window.location.reload()}>Refresh</Button>
			</Stack>
		</ViewContainer>
	);
}
