import { useQuery } from '@apollo/client';
import { Stack } from '@mantine/core';
import { useState } from 'react';
import { ResultOf, graphql } from '@shared';
import FeaturedCarousel from '../components/FeaturedCarousel';
import { Footer } from '../components/Footer';
import Hero from '../components/Hero';
import StoryCard from '../components/StoryCard';
import Loading from '../components/ui/Loading';
import ViewContainer from '../components/ui/ViewContainer';
import { fillHeight, fillHeightTarget } from '../css/utils.module.css';
import useFavoriteStory from '../hooks/useFavoriteStory';
import useSession from '../hooks/useSession';
import { homepageFeaturedStoriesIds, upcomingStoriesIds } from '../static/stories';
import { handleUnexpectedError } from '../utils/error';

const MaxMyCharactersToDisplay = 5;

export default function Home() {
	const { isAuthenticated } = useSession();
	const [myCharacters, setMyCharacters] = useState<GetMyCharactersReturn>();
	const [featuredStories, setFeaturedStories] = useState<GetStoriesReturn>([]);
	const [upcomingStories, setUpcomingStories] = useState<GetUpcomingStoriesReturn>([]);
	const { favoriteStory } = useFavoriteStory();
	const { loading: getMyCharactersLoading } = useQuery(getMyCharactersQuery, {
		variables: {
			pagination: { offset: 0, limit: MaxMyCharactersToDisplay },
		},
		skip: !isAuthenticated,
		onCompleted: (data) => {
			setMyCharacters(data.characters);
		},
		onError: handleUnexpectedError,
	});
	const { loading: getFeaturedStoriesLoading } = useQuery(getStoriesQuery, {
		variables: {
			filter: {
				storyIds: homepageFeaturedStoriesIds,
			},
		},
		onCompleted(data) {
			setFeaturedStories(data.stories);
		},
		onError: handleUnexpectedError,
	});
	const { loading: getUpcomingStoriesLoading } = useQuery(getUpcomingStoriesQuery, {
		variables: {
			ids: upcomingStoriesIds,
		},
		onCompleted(data) {
			setUpcomingStories(data.upcomingStories);
		},
		onError: handleUnexpectedError,
	});

	const handleFavoriteStory = async (storyId: string, isFavorited: boolean) =>
		await favoriteStory(storyId, isFavorited, (data) => {
			setFeaturedStories((prev) =>
				prev?.map((s) =>
					s.id === data.favoriteStory.story.id ? { ...s, isFavorited: data.favoriteStory.story.isFavorited } : s
				)
			);
		});

	return (
		<>
			<Stack className={fillHeight}>
				{(getMyCharactersLoading || getFeaturedStoriesLoading || getUpcomingStoriesLoading) && <Loading pt="lg" />}
				<ViewContainer className={fillHeightTarget}>
					<Hero characters={myCharacters} />
				</ViewContainer>
				{!!featuredStories.length && (
					<ViewContainer>
						<FeaturedCarousel
							title="Featured Stories"
							slides={featuredStories.map((story) => (
								<StoryCard
									key={story.id}
									story={story}
									creator={story.creator}
									favoriting={{
										isFavorited: !!story.isFavorited,
										favoriteStory: handleFavoriteStory,
									}}
								/>
							))}
						/>
					</ViewContainer>
				)}
				{!!upcomingStories.length && (
					<ViewContainer>
						<FeaturedCarousel
							title="Stories Coming Soon"
							slides={upcomingStories.map((story) => (
								<StoryCard key={story.id} story={story} creator={story.creator} isUpcomingStory />
							))}
						/>
					</ViewContainer>
				)}
				<Footer />
			</Stack>
		</>
	);
}

export type GetMyCharactersReturn = ResultOf<typeof getMyCharactersQuery>['characters'];
const getMyCharactersQuery = graphql(`
	query GetMyCharactersForHomePage($sorting: [SortInput!], $pagination: PaginationInput) {
		characters(sorting: $sorting, pagination: $pagination) {
			id
			name
			imageUrl
		}
	}
`);

export type GetStoriesReturn = ResultOf<typeof getStoriesQuery>['stories'];
const getStoriesQuery = graphql(`
	query GetStoriesForHomePage($filter: StoriesFilterInput, $pagination: PaginationInput) {
		stories(filter: $filter, pagination: $pagination) {
			id
			title
			coverImageUrl
			isFavorited
			creator {
				id
				username
				displayName
				profilePictureImageUrl
			}
		}
	}
`);

type GetUpcomingStoriesReturn = ResultOf<typeof getUpcomingStoriesQuery>['upcomingStories'];
const getUpcomingStoriesQuery = graphql(`
	query GetUpcomingStoriesForHomePage($ids: [ID!]) {
		upcomingStories(ids: $ids) {
			id
			title
			coverImageUrl
			creator {
				id
				username
				displayName
				profilePictureImageUrl
			}
		}
	}
`);
