import { createContext } from 'react';
import { FeatureFlags } from '@shared';

export type FeatureFlagsContext = {
	isLoading: boolean;
	isError: boolean;
	data: FeatureFlags;
};

export const defaultFeatureFlagsContext: FeatureFlagsContext = {
	isLoading: false,
	isError: false,
	data: {
		enabled: false,
		isOn: false,
		isWaitlistEnabled: false,
	},
};

export const FeatureFlagsContext = createContext<FeatureFlagsContext>(defaultFeatureFlagsContext);
