import { ActionIcon, Group, Select } from '@mantine/core';
import { TextB, TextItalic, TextUnderline } from '@phosphor-icons/react';
import { DialogueShapes, Font, Shape, TextAlignment, TextStyle, TextWeight, textSizes } from '@shared';
import { DialogueObject, getTextBox } from '../canvas';

export type DialogueToolbarProps = {
	dialogueObject: DialogueObject;
	handleUpdateDialogueTextBox: (index: number, values: fabric.ITextboxOptions) => void;
	handleUpdateShape: (index: number, shape: Shape) => void;
};

export default function DialogueToolbar({
	dialogueObject,
	handleUpdateDialogueTextBox,
	handleUpdateShape,
}: DialogueToolbarProps) {
	const index = dialogueObject.index;
	const textBox = getTextBox(dialogueObject);

	return (
		<Group gap="xs">
			<Select
				title="Font"
				data={Object.values(Font)}
				value={textBox.fontFamily}
				onChange={(value) =>
					handleUpdateDialogueTextBox(index, {
						fontFamily: value as Font,
					})
				}
				allowDeselect={false}
				styles={{ wrapper: { width: 150 } }}
				size="xs"
			/>
			<Select
				title="Size"
				width="100"
				data={textSizes.map((s) => s.toString())}
				value={textBox.fontSize?.toString()}
				onChange={(value) =>
					handleUpdateDialogueTextBox(index, {
						fontSize: Number(value),
					})
				}
				allowDeselect={false}
				styles={{ wrapper: { width: 75 } }}
				size="xs"
			/>
			<ActionIcon
				variant={textBox.fontWeight === TextWeight.Bold ? 'light' : 'default'}
				onClick={() =>
					handleUpdateDialogueTextBox(index, {
						fontWeight: textBox.fontWeight === TextWeight.Bold ? TextWeight.Normal : TextWeight.Bold,
					})
				}
			>
				<TextB />
			</ActionIcon>
			<ActionIcon
				variant={textBox.fontStyle === TextStyle.Italic ? 'light' : 'default'}
				onClick={() =>
					handleUpdateDialogueTextBox(index, {
						fontStyle: textBox.fontStyle === TextStyle.Italic ? TextStyle.Normal : TextStyle.Italic,
					})
				}
			>
				<TextItalic />
			</ActionIcon>
			<ActionIcon
				variant={textBox.underline ? 'light' : 'default'}
				onClick={() =>
					handleUpdateDialogueTextBox(index, {
						underline: !textBox.underline,
					})
				}
			>
				<TextUnderline />
			</ActionIcon>
			<Select
				title="Alignment"
				data={Object.entries(TextAlignment).map(([key, value]) => ({
					value,
					label: key,
				}))}
				value={textBox.textAlign!}
				onChange={(value) =>
					handleUpdateDialogueTextBox(index, {
						textAlign: value!,
					})
				}
				allowDeselect={false}
				styles={{ wrapper: { width: 100 } }}
				size="xs"
			/>
			<Select
				title="Shape"
				data={DialogueShapes.map((label) => ({
					value: label,
					label,
				}))}
				placeholder={'Shape'}
				onChange={(value) => handleUpdateShape(index, value as Shape)}
				allowDeselect={false}
				styles={{ wrapper: { width: 100 } }}
				size="xs"
			/>
		</Group>
	);
}
