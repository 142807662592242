import { Card, Group, Image, SimpleGrid, Text, Title } from '@mantine/core';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { GetMyShopItemsReturn } from '../graphql/common';

type ItemCardProps = {
	item: GetMyShopItemsReturn[number];
	navigateToUrl: string;
};

export default function ItemCard({ item, navigateToUrl }: ItemCardProps) {
	const images = useMemo(
		() => [
			...new Set(item.itemVariants!.map((variant) => variant.itemVariantImages!.map((image) => image.imageUrl)).flat()),
		],
		[item]
	);

	return (
		<Card>
			<Card.Section>
				<Image src={item.itemVariants[0].itemVariantImages[0].imageUrl} component={Link} to={navigateToUrl} />
			</Card.Section>
			<Card.Section p="xs">
				<Group justify="space-between" align="start">
					<Title order={5} flex={1}>
						{item.name}
					</Title>
					<Text>${item.itemVariants[0].price.toFixed(2)}</Text>
				</Group>
			</Card.Section>
			<Card.Section p="xs">
				<SimpleGrid cols={3}>
					{images.map((image) => (
						<Image key={image} src={image} />
					))}
				</SimpleGrid>
			</Card.Section>
		</Card>
	);
}
