import { Stack, Text } from '@mantine/core';
import PageHeader from '../components/ui/PageHeader';
import ViewContainer from '../components/ui/ViewContainer';

export default function Waitlisted() {
	return (
		<ViewContainer>
			<Stack>
				<PageHeader text="Welcome" />
				<Text>
					Thank you for signing up for an account. You have been added to the waitlist, and we will notify you once you
					are off the waitlist.
				</Text>
			</Stack>
		</ViewContainer>
	);
}
