import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'intro.js/introjs.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import App from './App';
import './css/global.css';
import ErrorPage from './pages/ErrorPage';
import AcknowledgedTutorialsProvider from './providers/AcknowledgedTutorialsProvider';
import AuthenticatedApolloProvider from './providers/AuthenticatedApolloProvider';
import FeatureFlagsProvider from './providers/FeatureFlagsProvider';
import SessionProvider from './providers/SessionProvider';
import { theme } from './theme';
import { initializePosthog } from './utils/posthog';
import { initializeSentry } from './utils/sentry';

ReactDOM.createRoot(document.getElementById('root')!).render(
	<BrowserRouter>
		<MantineProvider theme={theme} defaultColorScheme="auto">
			<ErrorBoundary fallback={<ErrorPage />}>
				<ModalsProvider>
					<Notifications />
					<QueryClientProvider client={new QueryClient()}>
						<AuthenticatedApolloProvider>
							<FeatureFlagsProvider>
								<SessionProvider>
									<AcknowledgedTutorialsProvider>
										<App />
									</AcknowledgedTutorialsProvider>
								</SessionProvider>
							</FeatureFlagsProvider>
						</AuthenticatedApolloProvider>
					</QueryClientProvider>
				</ModalsProvider>
			</ErrorBoundary>
		</MantineProvider>
	</BrowserRouter>
);

initializePosthog();
initializeSentry();
