type ImageDimensions = {
	width: number;
	height: number;
	// Width / height
	ratio: number;
};

export const ImageDimensions = {
	character: { width: 512, height: 768, ratio: 512 / 768 } satisfies ImageDimensions,
	story: { width: 512, height: 768, ratio: 512 / 768 } satisfies ImageDimensions,
};
