import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArtStyle, StoryDraft, StoryImageType, StoryType, graphql } from '@shared';
import Loading from '../../components/ui/Loading';
import ViewContainer from '../../components/ui/ViewContainer';
import { fillHeight } from '../../css/utils.module.css';
import { useInterval } from '../../hooks/useInterval';
import { clearEntitiesFromCache } from '../../utils/cache';
import { handleUnexpectedError } from '../../utils/error';
import Idea from './Idea';
import Pages from './Pages';
import Plan from './Plan';
import Preview from './Preview';
import Script from './Script';
import { StepIndex } from './step';

const AutosaveFrequencyInMS = 2 * 1000;
const initialStoryDraft: StoryDraft = {
	id: '',
	details: {
		title: '',
		summary: '',
		genres: [],
	},
	characters: [],
	idea: '',
	script: '',
	pages: [],
	panels: [],
	cover: {
		id: '',
	},
	settings: {
		storyType: StoryType.Comic,
		imageType: StoryImageType.Uploaded,
		artStyle: ArtStyle.Anime,
		numberOfPages: 5,
	},
};

export default function NewStory() {
	const navigate = useNavigate();
	const storyDraftId = useParams().id;
	const [activeStep, setActiveStep] = useState(0);
	const [storyDraft, setStoryDraft] = useState<StoryDraft>(initialStoryDraft);
	const [lastSavedStoryDraft, setLastSavedStoryDraft] = useState<StoryDraft>(initialStoryDraft);
	const [createStoryDraft, { loading: createStoryDraftLoading }] = useMutation(createStoryDraftMutation);
	// TODO: Use the loader to show the user we are saving
	const [updateStoryDraft] = useMutation(updateStoryDraftMutation);
	const { loading: getStoryDraftLoading } = useQuery(getStoryDraftQuery, {
		variables: {
			id: storyDraftId!,
		},
		skip: !storyDraftId,
		onCompleted: (data) => {
			// Create a copy so we can safely mutate it
			const storyDraftCopy = structuredClone(data.storyDraft.data) as StoryDraft;
			setStoryDraft({
				...storyDraftCopy,
				id: storyDraftId!,
			});
			setLastSavedStoryDraft(storyDraftCopy);
			// If their last step was Preview, restore them to Pages instead
			// Reason: we need to regenerate the canvas image data from Pages —> Preview, as the data is too large to be saved
			const restoredActiveStep =
				data.storyDraft.lastActiveFormStep === StepIndex.Preview ? StepIndex.Pages : data.storyDraft.lastActiveFormStep;
			setActiveStep(restoredActiveStep);
		},
		onError() {
			handleUnexpectedError('useGetStoryDraftQuery returned no data');
			return;
		},
	});

	// Update the local state
	function handleUpdateStoryDraft(value: Partial<StoryDraft>) {
		setStoryDraft((prev) => ({ ...prev, ...value }));
	}

	// Create the initial draft when going from Plan —> Idea
	useEffect(() => {
		if (!storyDraftId && activeStep === StepIndex.Idea) {
			createStoryDraft({
				variables: {
					input: {
						data: storyDraft,
						lastActiveFormStep: activeStep,
					},
				},
				onCompleted: (data) => {
					navigate(`${data.createStoryDraft.id}`, {
						replace: true,
					});
				},
				update: (cache) => {
					clearEntitiesFromCache(cache, ['storyDraft', 'storyDrafts']);
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStep]);

	// Update the database
	async function autosave() {
		// If there's no draft, we're still creating the draft, or there are no changes, don't autosave
		if (!storyDraftId || createStoryDraftLoading || storyDraft === lastSavedStoryDraft) {
			return;
		}

		setLastSavedStoryDraft(storyDraft);
		// Remove `canvasImageData` from pages before saving, as it's too large to send over the network/save in the database
		const savableStoryDraft: StoryDraft = {
			...storyDraft,
			pages: storyDraft.pages.map((p) => ({
				...p,
				canvasImageData: undefined,
			})),
		};
		await updateStoryDraft({
			variables: {
				input: {
					id: storyDraftId,
					data: savableStoryDraft,
					lastActiveFormStep: activeStep,
				},
			},
			update: (cache) => {
				clearEntitiesFromCache(cache, ['storyDraft', 'storyDrafts']);
			},
		});
	}

	// Set up autosave interval
	useInterval(() => {
		autosave();
	}, AutosaveFrequencyInMS);

	// Before a user leaves the page, warn them if there are unsaved changes
	useEffect(() => {
		function handleBeforeUnload(e: BeforeUnloadEvent) {
			if (storyDraft === lastSavedStoryDraft) {
				return;
			}

			e.preventDefault();
			autosave();
		}

		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => window.removeEventListener('beforeunload', handleBeforeUnload);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storyDraft, lastSavedStoryDraft]);

	return (
		<ViewContainer className={fillHeight}>
			{(getStoryDraftLoading || createStoryDraftLoading) && <Loading />}
			{!(getStoryDraftLoading || createStoryDraftLoading) && (
				<>
					{activeStep === StepIndex.Plan && (
						<Plan
							storyDraft={storyDraft}
							handleUpdateStoryDraft={handleUpdateStoryDraft}
							activeStep={activeStep}
							setActiveStep={setActiveStep}
						/>
					)}
					{activeStep === StepIndex.Idea && (
						<Idea
							storyDraft={storyDraft}
							handleUpdateStoryDraft={handleUpdateStoryDraft}
							activeStep={activeStep}
							setActiveStep={setActiveStep}
						/>
					)}
					{activeStep === StepIndex.Pages && (
						<Pages
							storyDraft={storyDraft}
							handleUpdateStoryDraft={handleUpdateStoryDraft}
							activeStep={activeStep}
							setActiveStep={setActiveStep}
							setStoryDraft={setStoryDraft}
							triggerSaveStoryDraft={autosave}
						/>
					)}
					{activeStep === StepIndex.Script && (
						<Script
							storyDraft={storyDraft}
							handleUpdateStoryDraft={handleUpdateStoryDraft}
							activeStep={activeStep}
							setActiveStep={setActiveStep}
						/>
					)}
					{activeStep === StepIndex.Preview && (
						<Preview
							storyDraft={storyDraft}
							handleUpdateStoryDraft={handleUpdateStoryDraft}
							activeStep={activeStep}
							setActiveStep={setActiveStep}
						/>
					)}
				</>
			)}
		</ViewContainer>
	);
}

const getStoryDraftQuery = graphql(`
	query GetStoryDraft($id: ID!) {
		storyDraft(id: $id) {
			lastActiveFormStep
			data
		}
	}
`);

const createStoryDraftMutation = graphql(`
	mutation CreateStoryDraft($input: CreateStoryDraftInput!) {
		createStoryDraft(input: $input) {
			id
		}
	}
`);

const updateStoryDraftMutation = graphql(`
	mutation UpdateStoryDraft($input: UpdateStoryDraftInput!) {
		updateStoryDraft(input: $input) {
			id
		}
	}
`);
