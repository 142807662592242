import { Avatar, AvatarProps, Box, Image } from '@mantine/core';
import { Link } from 'react-router-dom';
import { User } from '@shared';
import useColors from '../../hooks/useColors';

type ProfilePictureProps = AvatarProps & {
	user: Pick<User, 'profilePictureImageUrl' | 'displayName' | 'username'>;
	isLink?: boolean;
};

/**
 * shared component to render a profile picture or the initials of a user
 */
export default function ProfilePicture({ user, isLink = true, ...props }: ProfilePictureProps) {
	const { backgroundColor } = useColors();

	return (
		<Avatar
			component={isLink ? Link : undefined}
			to={`/users/${user.username}`}
			bg={backgroundColor}
			style={{
				cursor: 'pointer',
			}}
			{...props}
		>
			{user.profilePictureImageUrl ? <Image src={user.profilePictureImageUrl} /> : <Box>{user.displayName[0]}</Box>}
		</Avatar>
	);
}
