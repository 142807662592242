import { ActionIcon } from '@mantine/core';
import { Question } from '@phosphor-icons/react';
import useColors from '../../hooks/useColors';

type ShowTutorialButtonProps = {
	setShouldShowTutorial: (value: true) => void;
};

export default function ShowTutorialButton({ setShouldShowTutorial }: ShowTutorialButtonProps) {
	const { elementColor } = useColors();

	return (
		<ActionIcon variant="transparent" onClick={() => setShouldShowTutorial(true)}>
			<Question size={18} color={elementColor} />
		</ActionIcon>
	);
}
