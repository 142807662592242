import { AspectRatio, Paper, PaperProps } from '@mantine/core';
import { ImageDimensions } from '@shared';
import Loading from './Loading';

type ImageLoadingPlaceholderProps = PaperProps & {
	aspectRatio?: number;
};

const defaultAspectRatio = ImageDimensions.story.ratio;

export default function ImageLoadingPlaceholder({
	aspectRatio = defaultAspectRatio,
	...paperProps
}: ImageLoadingPlaceholderProps) {
	return (
		<Paper {...paperProps} bg="softGray">
			<AspectRatio ratio={aspectRatio}>
				<Loading />
			</AspectRatio>
		</Paper>
	);
}
