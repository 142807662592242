import { ActionIcon } from '@mantine/core';
import { PaperPlaneRight } from '@phosphor-icons/react';
import useColors from '../../hooks/useColors';

type SendMessageButtonProps = {
	loading: boolean;
};

export default function SendMessageButton({ loading }: SendMessageButtonProps) {
	const { elementColor } = useColors();

	return (
		<ActionIcon
			type="submit"
			loading={loading}
			variant="transparent"
			style={{
				cursor: 'pointer',
			}}
		>
			<PaperPlaneRight size={20} color={elementColor} />
		</ActionIcon>
	);
}
