import { useMutation } from '@apollo/client';
import { Button, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { UpdatePasswordInput, UpdatePasswordInputSchema, graphql } from '@shared';
import Form from '../../components/Form';
import { handleUnexpectedError } from '../../utils/error';

export default function Password() {
	const [updatePassword, { loading }] = useMutation(updatePasswordMutation);

	const form = useForm<UpdatePasswordInput>({
		validate: zodResolver(UpdatePasswordInputSchema),
		validateInputOnBlur: true,
		initialValues: {
			oldPassword: '',
			newPassword: '',
		},
	});

	async function handleUpdatePassword() {
		await updatePassword({
			variables: {
				input: form.values,
			},
			onCompleted: () => {
				form.reset();
				notifications.show({
					title: 'Password updated',
					message: 'Your password has been updated',
					color: 'teal',
				});
			},
			onError: handleUnexpectedError,
		});
	}

	return (
		<Form onSubmit={handleUpdatePassword}>
			<Stack>
				<TextInput label="Old Password" type="password" {...form.getInputProps('oldPassword')} />
				<TextInput label="New Password" type="password" {...form.getInputProps('newPassword')} />
				<Button type="submit" disabled={!form.isValid() || !form.isDirty()} loading={loading} mt="xs">
					Save
				</Button>
			</Stack>
		</Form>
	);
}

const updatePasswordMutation = graphql(`
	mutation UpdatePassword($input: UpdatePasswordInput!) {
		updatePassword(input: $input)
	}
`);
