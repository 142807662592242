import { ActionIcon, ActionIconProps } from '@mantine/core';
import { Heart } from '@phosphor-icons/react';

type FavoriteButtonProps = ActionIconProps & {
	isFavorited: boolean;
};

export default function FavoriteButton({ isFavorited, ...props }: FavoriteButtonProps) {
	return (
		<ActionIcon variant="transparent" color="red" {...props}>
			<Heart weight={isFavorited ? 'fill' : undefined} style={{ width: '75%', height: '75%' }} />
		</ActionIcon>
	);
}
