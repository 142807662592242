import { useQuery } from '@apollo/client';
import { Button, SimpleGrid, Stack } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import ItemCard from '../../components/ItemCard';
import { getMyShopItemsQuery } from '../../graphql/common';
import useSession from '../../hooks/useSession';

export default function Items() {
	const navigate = useNavigate();
	const { user } = useSession();
	const { data } = useQuery(getMyShopItemsQuery, {
		variables: {
			shopId: user.shop?.id || '',
		},
		onCompleted: (data) => {
			if (data.items.length === 0) {
				navigate('/my-shop/create-item');
			}
		},
	});

	return (
		<Stack>
			<Button component={Link} to="/my-shop/create-item" ml="auto">
				Create Item
			</Button>
			<SimpleGrid cols={3}>
				{data?.items.map((item) => (
					<ItemCard key={item.id} item={item} navigateToUrl={`/users/${user.username}/shop/${item.id}`} />
				))}
			</SimpleGrid>
		</Stack>
	);
}
