import zod from '../utils/zod';

export type CompanionMessage = zod.infer<typeof CompanionMessageSchema>;
export const CompanionMessageSchema = zod.object({
	text: zod.string().min(1),
	role: zod.enum(['user', 'assistant', 'system']),
	rawMessage: zod.string().optional(),
});

export type CompanionHistory = zod.infer<typeof CompanionHistorySchema>;
export const CompanionHistorySchema = zod.array(CompanionMessageSchema);

export const companionCharacterSchema = zod.object({
	message: zod.string(),
	name: zod.string(),
	details: zod.string(),
});

export type CompanionIdea = zod.infer<typeof CompanionIdeaSchema>;
export const CompanionIdeaSchema = zod.object({
	message: zod.string(),
	idea: zod.string(),
});
