import { Navigate, Route, Routes } from 'react-router-dom';
import { AppContainer } from './components/AppContainer';
import Header from './components/Header';
import useFeatureFlags from './hooks/useFeatureFlags';
import useSession from './hooks/useSession';
import Character from './pages/Character';
import Checkout from './pages/Checkout';
import ContactUs from './pages/ContactUs';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import Item from './pages/Item';
import CharacterDrafts from './pages/MyCharacters/CharacterDrafts';
import Characters from './pages/MyCharacters/Characters';
import MyCharacters from './pages/MyCharacters/MyCharacters';
import CreateItem from './pages/MyShop/CreateItem';
import Dashboard from './pages/MyShop/Dashboard';
import Items from './pages/MyShop/Items';
import Management from './pages/MyShop/Management';
import MyShop from './pages/MyShop/MyShop';
import MyStories from './pages/MyStories';
import NewCharacter from './pages/NewCharacter';
import NewStory from './pages/NewStory';
import OrderConfirmation from './pages/OrderConfirmation';
import Pricing from './pages/Pricing';
import ResetPassword from './pages/ResetPassword';
import Billing from './pages/Settings/Billing';
import Password from './pages/Settings/Password';
import Profile from './pages/Settings/Profile';
import Settings from './pages/Settings/Settings';
import Tokens from './pages/Settings/Tokens';
import Shop from './pages/Shop';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Stories from './pages/Stories';
import Story from './pages/Story';
import User from './pages/User';
import VerifyEmail from './pages/VerifyEmail';
import Waitlisted from './pages/Waitlisted';
import { isDevelopment } from './utils/environment';

export default function App() {
	const { isAuthenticated, isLoading: isSessionLoading } = useSession();
	const { isLoading: isFeatureFlagsLoading, isError: isFeatureFlagsError } = useFeatureFlags();

	function ProtectedNoAuthRoute(Page: JSX.Element): JSX.Element {
		return isAuthenticated ? <Navigate to="/" /> : Page;
	}

	// wait for useSession and useFeatureFlags to finish loading before rendering the app
	if (isSessionLoading || isFeatureFlagsLoading) {
		return <></>;
	}

	if (isFeatureFlagsError) {
		throw Error('Failed to load feature flags');
	}

	return (
		<AppContainer>
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
				{/* authenticated views */}
				{isAuthenticated && (
					<>
						<Route path="settings" element={<Settings />}>
							<Route path="profile" element={<Profile />} />
							<Route path="password" element={<Password />} />
							<Route path="billing" element={<Billing />} />
							<Route path="tokens" element={<Tokens />} />
						</Route>
						<Route path="my-characters" element={<MyCharacters />}>
							<Route index element={<Characters />} />
							<Route path="drafts" element={<CharacterDrafts />} />
						</Route>
						<Route path="my-stories" element={<MyStories />} />
						<Route path="my-shop" element={<MyShop />}>
							<Route path="dashboard" element={<Dashboard />} />
							<Route path="items" element={<Items />} />
							<Route path="management" element={<Management />} />
							<Route path="create-item" element={<CreateItem />} />
						</Route>
						<Route path="new-character" element={<NewCharacter />} />
						<Route path="new-character/:id" element={<NewCharacter />} />
						<Route path="new-story" element={<NewStory />} />
						<Route path="new-story/:id" element={<NewStory />} />
						<Route path="checkout" element={<Checkout />} />
						<Route path="order-confirmation/:orderNumber" element={<OrderConfirmation />} />
						<Route path="contact-us" element={<ContactUs />} />
					</>
				)}
				{/* show this in development since it helps with debugging stories */}
				{isDevelopment() && <Route path="stories" element={<Stories />} />}
				<Route path="stories/:id" element={<Story />} />
				<Route path="characters/:id" element={<Character />} />
				<Route path="users/:username" element={<User />} />
				<Route path="pricing" element={<Pricing />} />
				<Route path="users/:username/shop" element={<Shop />} />
				<Route path="users/:username/shop/:id" element={<Item />} />
				{/* unauthenticated views */}
				<Route path="/sign-in" element={ProtectedNoAuthRoute(<SignIn />)} />
				<Route path="/sign-up" element={ProtectedNoAuthRoute(<SignUp />)} />
				<Route path="/verify-email" element={ProtectedNoAuthRoute(<VerifyEmail />)} />
				<Route path="/forgot-password" element={ProtectedNoAuthRoute(<ForgotPassword />)} />
				<Route path="/reset-password" element={ProtectedNoAuthRoute(<ResetPassword />)} />
				<Route path="/waitlisted" element={ProtectedNoAuthRoute(<Waitlisted />)} />
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</AppContainer>
	);
}
