import { useQuery } from '@apollo/client';
import posthog from 'posthog-js';
import { PropsWithChildren, useState } from 'react';
import { SessionContext, defaultSessionContext } from '../contexts/session';
import { getUserContextQuery } from '../graphql/common';

export default function SessionProvider({ children }: PropsWithChildren) {
	// Manually trigger rerenders simply by updating this state, since you cannot listen for cookie changes
	const [, setRefreshTrigger] = useState(false);

	const { data, loading, error, refetch } = useQuery(getUserContextQuery);

	// if the query is loading, return the default context, with loading true
	if (loading) {
		return <SessionContext.Provider value={defaultSessionContext}>{children}</SessionContext.Provider>;
	}

	// if there are no session cookies, or we ran into errors, return the default context, with loading false
	if (error) {
		return (
			<SessionContext.Provider value={{ ...defaultSessionContext, isLoading: false, refreshSession }}>
				{children}
			</SessionContext.Provider>
		);
	}

	if (!data?.authUser) {
		// TODO: Handle unexpected control flow; `getUserContext` should never return null
		return (
			<SessionContext.Provider value={{ ...defaultSessionContext, isLoading: false, refreshSession }}>
				{children}
			</SessionContext.Provider>
		);
	}

	posthog.identify(data.authUser.id, {
		email: data.authUser.email,
		name: data.authUser.displayName,
		username: data.authUser.username,
		role: data.authUser.role,
		subscription_plan_id: data.authUser.currentSubscription?.subscriptionPlanId,
	});

	function refreshSession() {
		setRefreshTrigger((prev) => !prev);
		refetch();
	}

	return (
		<SessionContext.Provider
			value={{
				isAuthenticated: true,
				isLoading: false,
				user: data!.authUser,
				refreshSession,
			}}
		>
			{children}
		</SessionContext.Provider>
	);
}
