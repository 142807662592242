export enum Folder {
	CharacterImages = 'character-images',
	CoverImages = 'cover-images',
	ImageDrafts = 'image-drafts',
	PageImages = 'page-images',
	PanelImages = 'panel-images',
	ProfilePictureImages = 'profile-picture-images',
	ShopItemGraphics = 'shop-item-graphics',
	ShopItemImages = 'shop-item-images',
}
