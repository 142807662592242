import { useLazyQuery } from '@apollo/client';
import { Group, Select, SimpleGrid, Stack, Tabs } from '@mantine/core';
import { SortAscending } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ResultOf, SortSelection, createSortInputs, graphql } from '@shared';
import NewCharacterCard from '../../components/NewCharacterCard';
import Loading from '../../components/ui/Loading';
import RoundedImage from '../../components/ui/RoundedImage';
import { handleUnexpectedError } from '../../utils/error';
import { OutletContext } from './MyCharacters';
import { TabValue } from './tabs';

// TOD: Implement Pagination. Start with a large page size for now
const PageSize = 50;
const sortSelections: SortSelection[] = ['Newest', 'Oldest', 'Name A-Z', 'Name Z-A'];

export default function CharacterDrafts() {
	const { handleSetActiveTab } = useOutletContext<OutletContext>();
	const [sort, setSort] = useState<SortSelection>('Newest');
	const [characterDrafts, setCharacterDrafts] = useState<GetMyCharacterDraftsReturn>([]);

	const [getCharacterDrafts, { loading: getCharacterDraftsLoading }] = useLazyQuery(getMyCharacterDraftsQuery, {
		variables: {
			sorting: createSortInputs(sort),
			pagination: { offset: 0, limit: PageSize },
		},
		onCompleted: (data) => {
			setCharacterDrafts(data.characterDrafts);
		},
		onError: handleUnexpectedError,
	});

	// Handle sorting
	useEffect(() => {
		getCharacterDrafts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort]);

	return (
		<Stack>
			<Group justify="space-between">
				<Tabs value={TabValue.Drafts} onChange={(value) => handleSetActiveTab(value as TabValue)}>
					<Tabs.List>
						<Tabs.Tab value={TabValue.Published}>{TabValue.Published}</Tabs.Tab>
						<Tabs.Tab value={TabValue.Drafts}>{TabValue.Drafts}</Tabs.Tab>
					</Tabs.List>
				</Tabs>
				<Group>
					{/* Sorting */}
					<Select
						data={sortSelections}
						disabled={getCharacterDraftsLoading}
						value={sort}
						onChange={(value) => setSort(value as SortSelection)}
						leftSection={<SortAscending />}
						size="xs"
					/>
				</Group>
			</Group>
			<SimpleGrid cols={3}>
				{getCharacterDraftsLoading ? (
					<Loading />
				) : (
					<>
						<NewCharacterCard />
						{characterDrafts.map((c) => (
							// TODO: Change when codegen has `maybeValue: 'T | undefined'`
							<RoundedImage
								key={c.id}
								label={c.name ?? 'Unnamed'}
								url={c.selectedImageDraft?.imageUrl ?? undefined}
								to={`/new-character/${c.id}`}
							/>
						))}
					</>
				)}
			</SimpleGrid>
		</Stack>
	);
}

type GetMyCharacterDraftsReturn = ResultOf<typeof getMyCharacterDraftsQuery>['characterDrafts'];
const getMyCharacterDraftsQuery = graphql(`
	query GetMyCharacterDrafts($sorting: [SortInput!], $pagination: PaginationInput) {
		characterDrafts(sorting: $sorting, pagination: $pagination) {
			id
			name
			selectedImageDraft {
				id
				imageUrl
			}
		}
	}
`);
