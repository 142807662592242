import { Carousel } from '@mantine/carousel';
import { Box, BoxProps, Stack, Title } from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';
import { ReactElement, useRef } from 'react';
import carouselClasses from '../css/Carousel.module.css';
import useIsMobileView from '../hooks/useIsMobileView';
import ControlIcon from './ui/ControlIcon';

type FeaturedCarouselProps = BoxProps & {
	title: string;
	slides?: ReactElement[];
};

export default function FeaturedCarousel({ title, slides, ...props }: FeaturedCarouselProps) {
	const isMobileView = useIsMobileView();
	const autoplay = useRef(Autoplay({ delay: 5000 }));

	return (
		<Box {...props} pb={{ base: '50', sm: '100' }}>
			<Stack>
				<Title order={3}>{title}</Title>
				<Carousel
					align="start"
					slideSize={{ base: '50%', sm: '33.3333%', md: '25%' }}
					slideGap="md"
					classNames={carouselClasses}
					withControls={slides && slides.length > (isMobileView ? 1 : 3) ? true : false}
					plugins={slides && slides.length > 5 ? [autoplay.current] : []}
					onMouseEnter={autoplay.current.stop}
					onMouseLeave={autoplay.current.reset}
					previousControlIcon={<ControlIcon direction="previous" />}
					nextControlIcon={<ControlIcon direction="next" />}
					loop
					dragFree
				>
					{slides?.map((slide) => <Carousel.Slide key={slide.key}>{slide}</Carousel.Slide>)}
				</Carousel>
			</Stack>
		</Box>
	);
}
