import { useQuery } from '@apollo/client';
import { Box, SimpleGrid } from '@mantine/core';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import StoryCard from '../components/StoryCard';
import { GetStoriesReturn, getStoriesQuery } from '../graphql/common';
import useFavoriteStory from '../hooks/useFavoriteStory';

const BATCH = 25;

export default function Stories() {
	const [stories, setStories] = useState<GetStoriesReturn>([]);
	const [hasMore, setHasMore] = useState(true);
	const { favoriteStory } = useFavoriteStory();
	const { fetchMore: getMore } = useQuery(getStoriesQuery, {
		variables: {
			pagination: {
				offset: 0,
				limit: BATCH,
			},
		},
		onCompleted: (data) => {
			if (data.stories.length > 0) {
				setStories((prevStories) => [...prevStories, ...data.stories]);
			}
			if (data.stories.length < BATCH) {
				setHasMore(false);
			}
		},
	});

	const handleGetMoreStories = async () => {
		await getMore({
			variables: {
				pagination: {
					offset: stories.length,
					limit: BATCH,
				},
			},
		});
	};

	const handleFavoriteStory = async (storyId: string, isFavorited: boolean) =>
		await favoriteStory(storyId, isFavorited, (data) =>
			setStories((prevStories) =>
				prevStories.map((s) =>
					s.id === data.favoriteStory.story.id ? { ...s, isFavorited: data.favoriteStory.story.isFavorited } : s
				)
			)
		);

	return (
		<InfiniteScroll
			dataLength={stories.length}
			next={handleGetMoreStories}
			hasMore={hasMore}
			loader={''}
			refreshFunction={handleGetMoreStories}
		>
			<SimpleGrid cols={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}>
				{stories.map((s) => (
					<Box key={s.id}>
						<StoryCard
							story={s}
							creator={s.creator}
							favoriting={{
								isFavorited: !!s.isFavorited,
								favoriteStory: handleFavoriteStory,
							}}
						/>
					</Box>
				))}
			</SimpleGrid>
		</InfiniteScroll>
	);
}
