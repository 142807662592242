import { createContext } from 'react';
import { TutorialType } from '@shared';

type AcknowledgedTutorialsContext = {
	isLoading: boolean;
	acknowledgedTutorials: TutorialType[];
};

export const defaultAcknowledgedTutorialsContext: AcknowledgedTutorialsContext = {
	isLoading: false,
	// assume all tutorials have been acknowledged by default, since its annoying to show users
	// tutorials they may have seen
	acknowledgedTutorials: Object.keys(TutorialType).map((key) => TutorialType[key as keyof typeof TutorialType]),
};

export const AcknowledgedTutorialsContext = createContext<AcknowledgedTutorialsContext>(
	defaultAcknowledgedTutorialsContext
);
