import { Flex, Stack } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import PageHeader from '../../components/ui/PageHeader';
import SidebarLink from '../../components/ui/SidebarLink';
import ViewContainer from '../../components/ui/ViewContainer';
import useIsMobileView from '../../hooks/useIsMobileView';

export default function Settings() {
	const isMobileView = useIsMobileView();

	return (
		<ViewContainer width="compact">
			<PageHeader text="Settings" />
			<Flex direction={isMobileView ? 'column' : 'row'} my="sm" gap="xl">
				<Stack w={150} gap={0}>
					<SidebarLink label="Profile" to="/settings/profile" />
					<SidebarLink label="Password" to="/settings/password" />
					<SidebarLink label="Billing" to="/settings/billing" />
					<SidebarLink label="Tokens" to="/settings/tokens" />
				</Stack>
				<Stack flex={1}>
					<Outlet />
				</Stack>
			</Flex>
		</ViewContainer>
	);
}
