import { Font, TextAlignment, TextSize } from './textStyle';

// Always create initial shapes in a 100x100 frame. This allows consistent scaling interoperability later
export const InitialBubbleWidth = 100;
export const InitialBubbleHeight = 100;
export const InitialTailWidth = 10;
export const InitialTailHeight = 20;
// How much it goes in from a corner on a rectangular dialogue bubble
export const InitialTailInset = 20;
export const InitialBorderRadius = 5;

export type CaptionBubble = TextBubble;

export type DialogueBubble = TextBubble & {
	index: number;
	characterId: string | undefined;
	characterName: string;
};

type TextBubble = {
	text: string;
	xCoordinate: number;
	yCoordinate: number;
	width: number;
	height: number;
	backgroundColor: string;
	font: Font;
	textSize: TextSize;
	textAlignment: TextAlignment;
	textColor: string;
	shape: Shape;
};

export enum Shape {
	'Circle' = 'Circle',
	'CircleWithTail' = 'Circle + Tail',
	'Cloud' = 'Cloud',
	'Rectangle' = 'Rectangle',
	'RectangleWithTail' = 'Rectangle + Tail',
	'RectangleRounded' = 'Rounded Rectangle',
	'RectangleRoundedWithTail' = 'Rounded Rectangle + Tail',
}

export const DialogueShapes = [
	// TODO: Known issue with circle where the textbox to move slightly out of alignment to the right. Need to fix this. Believe it's related to the padding proportion changing
	Shape.Circle,
	// TODO: fix these so they are options
	// Shape.CircleWithTail,
	// Shape.Cloud,
	Shape.Rectangle,
	Shape.RectangleWithTail,
	Shape.RectangleRounded,
	Shape.RectangleRoundedWithTail,
];
export const CaptionShapes = DialogueShapes.filter((s) => s.includes('WithTail'));

// A shape is comprised of a body and a tail. The body is the main part of the shape, and the tail is the part that points to the speaker.
// For now, the tail is always at the bottom of the shape, so we only need to specify those  proportions in y direction. It is assumed body in the X direction is always 1
export type ShapeProportions = {
	// Proportion of the shape that is the body versus the tail (e.g. if bodyY: 0.8, then the tail is 0.2)
	bodyY: number;
	// Extra space between the text and the edge of the body. It is a multiplier relative to the body size, not combined body and tail
	// This will be lower for shapes like rectangles that need minimal padding, but higher for shapes like circles, where the text box rectangle will quickly go outside of the circle if there is not substantial padding
	bodyPadding: number;
};
