export enum ItemType {
	Top = 'Top',
	Bottom = 'Bottom',
	Accessories = 'Accessories',
}

export enum OrderStatus {
	Pending = 'Pending',
	Shipped = 'Shipped',
}

export const MinimumWithdrawalAmount = 50;

export enum ShopBalanceEventType {
	Purchase = 'Purchase',
	Withdrawal = 'Withdrawal',
}
