import { UserAddress } from '../graphql';

export enum State {
	AL = 'Alabama',
	AK = 'Alaska',
	AZ = 'Arizona',
	AR = 'Arkansas',
	CA = 'California',
	CO = 'Colorado',
	CT = 'Connecticut',
	DC = 'District of Columbia',
	DE = 'Delaware',
	FL = 'Florida',
	GA = 'Georgia',
	HI = 'Hawaii',
	ID = 'Idaho',
	IL = 'Illinois',
	IN = 'Indiana',
	IA = 'Iowa',
	KS = 'Kansas',
	KY = 'Kentucky',
	LA = 'Louisiana',
	ME = 'Maine',
	MD = 'Maryland',
	MA = 'Massachusetts',
	MI = 'Michigan',
	MN = 'Minnesota',
	MS = 'Mississippi',
	MO = 'Missouri',
	MT = 'Montana',
	NE = 'Nebraska',
	NV = 'Nevada',
	NH = 'New Hampshire',
	NJ = 'New Jersey',
	NM = 'New Mexico',
	NY = 'New York',
	NC = 'North Carolina',
	ND = 'North Dakota',
	OH = 'Ohio',
	OK = 'Oklahoma',
	OR = 'Oregon',
	PA = 'Pennsylvania',
	RI = 'Rhode Island',
	SC = 'South Carolina',
	SD = 'South Dakota',
	TN = 'Tennessee',
	TX = 'Texas',
	UT = 'Utah',
	VT = 'Vermont',
	VA = 'Virginia',
	WA = 'Washington',
	WV = 'West Virginia',
	WI = 'Wisconsin',
	WY = 'Wyoming',
}

export function getStateCode(state: State): string {
	return Object.keys(State).find((key) => State[key as keyof typeof State] === state)!;
}

export enum Country {
	US = 'United States',
}

export function getCountryCode(country: Country): string {
	return Object.keys(Country).find((key) => Country[key as keyof typeof Country] === country)!;
}

export function getAddressString(
	address: Pick<UserAddress, 'addressLine1' | 'addressLine2' | 'city' | 'state' | 'country' | 'zip'>
) {
	return `${address.addressLine1}${address.addressLine2 ? `, ${address.addressLine2}, ` : ','} ${address.city}, ${address.state}, ${address.zip}`;
}
