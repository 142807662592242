import {
	AspectRatio,
	Box,
	ElementProps,
	Image,
	Paper,
	PaperProps,
	Text,
	TextProps,
	useMantineTheme,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { ImageDimensions } from '@shared';

const defaultAspectRatio = ImageDimensions.story.ratio;
const defaultBorderRadius = 3;

type RoundedImageProps = PaperProps &
	ElementProps<'div', keyof PaperProps> & {
		isSelected?: boolean;
		url?: string;
		aspectRatio?: number;
		borderRadius?: number;
		label?: string;
		textProps?: TextProps;
		to?: string;
	};

export default function RoundedImage({
	isSelected,
	url,
	aspectRatio = defaultAspectRatio,
	borderRadius = defaultBorderRadius,
	label,
	textProps,
	to,
	...paperProps
}: RoundedImageProps) {
	const theme = useMantineTheme();
	const isSelectable = isSelected !== undefined || paperProps.onClick !== undefined;
	return (
		<Box component={to ? Link : undefined} to={to || ''} style={{ textDecoration: 'none', color: 'inherit' }}>
			<Paper
				bg="softGray"
				radius="sm"
				// Creates an outline within the image bounds by using a negative `outlineOffset`. Otherwise the outline would be cut off by a parent div at the edge (overflow hidden)
				// Use `outline` so that the image isn't resized, unlike with `border` which causes the image to be inset by the `padding` width
				style={{
					cursor: isSelectable || to ? 'pointer' : 'default',
					outline: isSelected ? `${borderRadius}px solid ${theme.colors.lumiBurntOrange[6]}` : undefined,
					outlineOffset: `-${borderRadius}px`,
					borderRadius: isSelected ? `${theme.radius.sm}` : undefined,
				}}
				{...paperProps}
			>
				<AspectRatio ratio={aspectRatio}>
					{url ? (
						<Image
							style={{ borderRadius: `${theme.radius.sm}`, objectFit: 'cover', objectPosition: 'top' }}
							src={url}
						/>
					) : (
						<Box />
					)}
				</AspectRatio>
			</Paper>
			{label && (
				<Text ta="center" pt="4" lineClamp={1} {...textProps}>
					{label}
				</Text>
			)}
		</Box>
	);
}
