import { ActionIcon, ActionIconProps } from '@mantine/core';
import { Microphone, StopCircle } from '@phosphor-icons/react';
import { useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { showCustomErrorNotification } from '../../utils/error';

type SpeechToTextButtonProps = ActionIconProps & {
	disabled: boolean;
	onSpeechConverted: (transcript: string) => void;
};

export default function SpeechToTextButton({ disabled, onSpeechConverted, ...props }: SpeechToTextButtonProps) {
	const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition, isMicrophoneAvailable } =
		useSpeechRecognition();

	// Set the text in the parent component as user is speaking
	useEffect(() => {
		if (listening) {
			onSpeechConverted(transcript);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listening, transcript]);

	async function handleSpeechToTextClicked() {
		if (!browserSupportsSpeechRecognition) {
			showCustomErrorNotification({
				message: 'Your browser does not support speech recognition',
			});
			return;
		}
		if (!isMicrophoneAvailable) {
			showCustomErrorNotification({
				message: 'Your microphone is not available',
			});
			return;
		}
		if (listening) {
			SpeechRecognition.stopListening();
			onSpeechConverted(transcript);
			resetTranscript();
		} else {
			SpeechRecognition.startListening();
		}
	}

	return (
		<ActionIcon variant="transparent" disabled={disabled} color="red" onClick={handleSpeechToTextClicked} {...props}>
			{listening ? <StopCircle size={20} /> : <Microphone size={20} />}
		</ActionIcon>
	);
}
