import { useMutation } from '@apollo/client';
import { Anchor, Button, Divider, PasswordInput, Stack } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ResetPasswordInput, ResetPasswordInputSchema, UserFriendlyErrors, graphql } from '@shared';
import Form from '../components/Form';
import PageHeader from '../components/ui/PageHeader';
import ViewContainer from '../components/ui/ViewContainer';
import { handleUnexpectedError, showCustomErrorNotification } from '../utils/error';

export default function ResetPassword() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [token] = useState(searchParams.get('token') || '');
	const [resetPassword, { loading }] = useMutation(resetPasswordMutation);

	// Run on page load if the link is invalid, aka someone just navigated to /reset-password
	useEffect(() => {
		if (!token) {
			navigate('/forgot-password');
			showCustomErrorNotification({ message: UserFriendlyErrors.InvalidAuthLink });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const form = useForm<ResetPasswordInput>({
		validate: zodResolver(ResetPasswordInputSchema),
		validateInputOnBlur: true,
		initialValues: {
			token,
			password: '',
		},
	});

	const handleResetPassword = async () => {
		await resetPassword({
			variables: {
				input: form.values,
			},
			onCompleted: () => {
				navigate('/sign-in');
				notifications.show({
					title: 'Password reset',
					message: 'Your password has been reset. Sign in using your new password',
					color: 'green',
				});
			},
			onError: handleUnexpectedError,
		});
	};

	return (
		<ViewContainer width="compact">
			<Form onSubmit={async () => handleResetPassword()}>
				<Stack>
					<PageHeader text="Reset Password" />
					<PasswordInput label="Password" placeholder="************" {...form.getInputProps('password')} />
					<Button type="submit" disabled={!form.isValid() || !token} loading={loading} mt="xs">
						Reset password
					</Button>
				</Stack>
				<Divider my="lg" />
				<Stack>
					<Anchor component={Link} to="/sign-up" c="dimmed" size="sm">
						Don't have an account? Sign up
					</Anchor>
					<Anchor component={Link} to="/sign-in" c="dimmed" size="sm">
						Already know your password? Sign in
					</Anchor>
				</Stack>
			</Form>
		</ViewContainer>
	);
}

const resetPasswordMutation = graphql(`
	mutation ResetPassword($input: ResetPasswordInput!) {
		resetPassword(input: $input)
	}
`);
