import { Alert, AlertProps } from '@mantine/core';
import { ReactNode } from 'react';

/**
 * A generic component to be used when displaying a "note" alert to the user.
 * Notes are not success or error messages, but rather some piece of information
 * that the user should be aware of.
 *
 * e.g.
 * - "It may take a few minutes to generate your images"
 * - "Your email address cannot be changed after sign up"
 * - "You have 3 days left to complete your profile"
 */

type NoteProps = AlertProps & {
	icon: ReactNode;
	text: string;
};

export default function Note({ icon, text, ...props }: NoteProps) {
	return (
		<Alert variant="light" color="gray" title="Info" icon={icon} {...props}>
			{text}
		</Alert>
	);
}
