import { zod } from '../../utils';

export enum ArtStyle {
	'Anime' = 'Anime',
	'3D Animation' = '3D Animation',
	'CG' = 'CG',
	'Cinematic' = 'Cinematic',
	'Comic' = 'Comic',
	'Sketch' = 'Sketch',
	'Watercolor' = 'Watercolor',
}

export const artStyleImageUrls: Map<ArtStyle, string> = new Map([
	[
		ArtStyle['Anime'],
		'https://cdn.leonardo.ai/users/1ada3201-45fb-484d-b202-f03c65f265ee/generations/4bd4e754-0ed7-4ad5-8c88-92f8c9619a90/Default_Studio_Ghibli_anime_style_full_body_shot_of_a_fierce_a_0.jpg',
	],
	[
		ArtStyle['3D Animation'],
		'https://cdn.leonardo.ai/users/1ada3201-45fb-484d-b202-f03c65f265ee/generations/1ddb4fa7-4df5-4241-9725-cea6347e66e3/Default_Disney_Pixar_animation_style_full_body_shot_of_Tori_Bl_0.jpg',
	],
	[
		ArtStyle['CG'],
		'https://cdn.leonardo.ai/users/1ada3201-45fb-484d-b202-f03c65f265ee/generations/323c3b88-1f1e-4b4f-8e2f-7ab5929f6bf1/Default_RPG_CG_animation_full_body_shot_of_a_female_vigilante_1.jpg',
	],
	[
		ArtStyle['Comic'],
		'https://cdn.leonardo.ai/users/1ada3201-45fb-484d-b202-f03c65f265ee/generations/85e5b400-65ab-4d8a-937d-6b93149e1582/Default_Marvel_comic_book_illustration_of_a_full_body_shot_of_1.jpg',
	],
	[
		ArtStyle['Cinematic'],
		'https://cdn.leonardo.ai/users/1ada3201-45fb-484d-b202-f03c65f265ee/generations/d651dcc2-4d4a-424f-81ae-09455bb9bc0e/Default_Photorealistic_full_body_shot_of_a_27yearold_athletic_1.jpg',
	],
	[
		ArtStyle['Sketch'],
		'https://cdn.leonardo.ai/users/1ada3201-45fb-484d-b202-f03c65f265ee/generations/50d2fd36-8174-4645-9060-5cf3becc6da2/Default_Black_and_white_line_sketch_of_Tori_Blaze_a_27yearold_1.jpg',
	],
	[
		ArtStyle['Watercolor'],
		'https://cdn.leonardo.ai/users/1ada3201-45fb-484d-b202-f03c65f265ee/generations/51ac529c-2ccb-4aee-9aef-c41ea6864cc6/Default_Watercolor_abstract_sketch_of_an_athletic_female_vigil_0.jpg',
	],
]);

export const artStylePromptStrings: Map<ArtStyle, string> = new Map([
	[ArtStyle['Anime'], 'Studio Ghibli anime style'],
	[ArtStyle['3D Animation'], 'Disney Pixar animation style'],
	[ArtStyle['CG'], 'RPG CG animation astyle'],
	[ArtStyle['Comic'], 'Marvel comic book style'],
	[ArtStyle['Cinematic'], 'Photorealistic photo'],
	[ArtStyle['Sketch'], 'Black and white line sketch'],
	[ArtStyle['Watercolor'], 'Watercolor abstract sketch'],
]);

export const ArtStyleSchema = zod.nativeEnum(ArtStyle);
