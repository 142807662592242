import {
	ActionIcon,
	Autocomplete,
	Box,
	Button,
	Divider,
	Group,
	InputLabel,
	NavLink,
	ScrollArea,
	Stack,
	TagsInput,
	Text,
	TextInput,
	Textarea,
	Title,
	Tooltip,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { Plus, TrashSimple } from '@phosphor-icons/react';
import { LegacyRef, RefObject, createRef, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	ClientImageDraft,
	CompanionMessage,
	CompanionMessageSchema,
	DetailsSchema,
	Endpoints,
	Folder,
	GeneratedDialogue,
	Panel,
	PanelSchema,
	ResponseBody,
	Shape,
	StoryDraft,
	StoryDraftSchema,
	StoryImageType,
	zod,
} from '@shared';
import CompanionChat, { GenericStream } from '../../components/CompanionChat';
import RoundedImage from '../../components/ui/RoundedImage';
import Sidebar from '../../components/ui/Sidebar';
import { UploadImageDraftButton } from '../../components/ui/UploadImageDraftButton';
import { fillHeight } from '../../css/utils.module.css';
import { useStream } from '../../hooks/useStream';
import { isProduction } from '../../utils/environment';
import { CoverImageEditor } from './PanelEditing/CoverImageEditor';
import { PanelImageEditor } from './PanelEditing/PanelImageEditor';
import DialogueToolbar from './PanelEditing/Toolbar/DialogueToolbar';
import {
	CanvasData,
	CanvasObject,
	CaptionObject,
	DialogueObject,
	addDialogueObject,
	createImageData,
	getCanvasData,
	initializeCanvasData,
	isCaptionObject,
	isDialogueObject,
	loadCanvas,
	removeObject,
	setBackgroundImage,
	updateObjectText,
	updateShape,
} from './PanelEditing/canvas';
import { NewStoryPageHeader } from './pageHeader';
import { StepComponentProps } from './stepComponent';

const endpoint = Endpoints.sendMessageToPanelsCompanion;
const panelTooltipMaxCharacters = 125;
// For now hardcode cover index to -1
const coverPanelRefIndex = -1;
const shouldShowCompanion = false;

enum Tab {
	Text = 'Text',
	Images = 'Images',
	Pages = 'Pages',
}

type PagesProps = StepComponentProps & {
	// A case where using `handleUpdateStoryDraft` does not work, as it requires precise insertion into a specific panel, or specific panel dialogue/caption
	setStoryDraft: React.Dispatch<React.SetStateAction<StoryDraft>>;
	// Manually trigger a save ahead of autosave. Guarantees the database will have the latest copy before proceeding to Preview page
	triggerSaveStoryDraft: () => Promise<void>;
};

export default function Pages({
	storyDraft,
	activeStep,
	setActiveStep,
	setStoryDraft,
	triggerSaveStoryDraft,
}: PagesProps) {
	const [searchParams, setSearchParams] = useSearchParams();
	const pagesAndPanels = useMemo(
		() =>
			storyDraft.pages.map((page) => ({
				...page,
				panels: storyDraft.panels.filter((panel) => panel.pageIndex === page.index),
			})),
		[storyDraft.pages, storyDraft.panels]
	);
	const [pageIndicesInView, setPageIndicesInView] = useState<Set<number>>(new Set());
	const [activeTab, setActiveTab] = useState<Tab>(
		zod.nativeEnum(Tab).safeParse(searchParams.get('tab') as Tab).success ? (searchParams.get('tab') as Tab) : Tab.Text
	);
	const [activePanelIndex, setActivePanelIndex] = useState(
		searchParams.get('activePanelIndex') ? parseInt(searchParams.get('activePanelIndex')!) : undefined
	);
	const activePanel = activePanelIndex !== undefined ? storyDraft.panels[activePanelIndex] : undefined;
	const activePanelIndexOnPage = useMemo(() => {
		if (activePanel === undefined) {
			return undefined;
		}
		return pagesAndPanels[activePanel.pageIndex].panels.findIndex((p) => p.id === activePanel.id);
	}, [activePanel, pagesAndPanels]);

	const [detailsErrors, setDetailsErrors] = useState<zod.ZodIssue[]>([]);
	const [pagesError, setPagesError] = useState<string>();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const scrollToRef = (ref: RefObject<HTMLDivElement>, behavior: ScrollBehavior = 'instant') =>
		ref.current!.scrollIntoView({ behavior });
	const coverPanelRef = useRef<HTMLDivElement>(null);
	const coverPageRef = useRef<HTMLDivElement>(null);
	const panelRefs = useMemo(
		() => Array.from({ length: storyDraft.panels.length }, () => createRef<HTMLDivElement>()),
		[storyDraft.panels.length]
	);
	const pageRefs = useMemo(
		() => Array.from({ length: storyDraft.pages.length }, () => createRef<HTMLDivElement>()),
		[storyDraft.pages.length]
	);
	const companionForm = useForm({
		validate: zodResolver(CompanionMessageSchema.pick({ text: true })),
		initialValues: { text: '' },
	});
	const stream = useStream({ endpoint, handleCompleteStream });
	const [companionHistory, setCompanionHistory] = useState<CompanionMessage[]>([
		{
			role: 'assistant',
			text: 'Here are the panels for your story. Would you like help with any edits or ideas?',
		},
	]);

	const panelIdToIndexMap = useMemo(() => {
		const map: { [id: string]: number } = {};
		storyDraft.panels.forEach((panel, index) => {
			map[panel.id] = index;
		});
		return map;
	}, [storyDraft.panels]);

	function getAbsolutePanelIndex(panelId: string) {
		return panelIdToIndexMap[panelId];
	}

	// track which panel or cover is in view
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					// TODO: Tab.Images was causing the observer to reset the activePanelIndex to the cover every time
					if (activeTab !== Tab.Text) {
						return;
					}
					const target = entry.target as HTMLElement;
					const index = parseInt(target.dataset.index!);
					if (entry.isIntersecting) {
						setPageIndicesInView((prev) => new Set(prev).add(index));
					} else {
						setPageIndicesInView((prev) => {
							const newSet = new Set(prev);
							newSet.delete(index);
							return newSet;
						});
					}
				});
			},
			{ threshold: 0.1 } // Adjust threshold as needed
		);

		if (coverPanelRef.current) {
			const element = coverPanelRef.current as HTMLElement;
			element.dataset.index = coverPanelRefIndex.toString();
			observer.observe(element);
		}
		panelRefs.forEach((ref, index) => {
			if (ref.current) {
				const element = ref.current as HTMLElement;
				element.dataset.index = index.toString();
				observer.observe(element);
			}
		});

		return () => {
			observer.disconnect();
		};
	}, [coverPanelRef, panelRefs, activeTab]);

	// TODO: This was erroring out
	// // scroll to the last selected panel on page load
	// useEffect(() => {
	// 	// Try to scrollToRef. If the ref isn't ready yet, wait and try again
	// 	const interval = setInterval(() => {
	// 		if (refs[activePanelIndex].current) {
	// 			scrollToRef(refs[activePanelIndex]);
	// 			clearInterval(interval);
	// 		}
	// 	}, 250);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// keep track of the current panel in view, if any
	useEffect(() => {
		const firstPageInViewIndex = Array.from(pageIndicesInView.values()).sort()[0];
		if (firstPageInViewIndex === coverPanelRefIndex) {
			setActivePanelIndex(undefined);
		} else {
			setActivePanelIndex(firstPageInViewIndex);
		}
	}, [pageIndicesInView]);

	// Update the URL with the active panel
	useEffect(() => {
		if (activePanelIndex === undefined) {
			searchParams.delete('activePanelIndex');
			setSearchParams(searchParams);
		} else {
			searchParams.set('activePanelIndex', activePanelIndex.toString());
			setSearchParams(searchParams);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activePanelIndex]);

	// update the URL with the active tab
	useEffect(() => {
		searchParams.set('tab', activeTab);
		setSearchParams(searchParams);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab]);

	function clearSearchParams() {
		searchParams.delete('tab');
		searchParams.delete('activePanelIndex');
		setSearchParams(searchParams);
	}

	function handleCompleteStream(data: ResponseBody<typeof endpoint>) {
		if (data.panels && data.panels.length > 0) {
			const newPanels = storyDraft.panels.map((p) =>
				data.panels!.map((newPanel) => newPanel.index).includes(p.index)
					? {
							...p,
							...data.panels!.find((newPanel) => newPanel.index === p.index),
						}
					: {
							...p,
						}
			);
			setStoryDraft((prev) => ({
				...prev,
				panels: newPanels,
			}));
		}
		const inboundMessage: CompanionMessage = {
			role: 'assistant',
			text: data.message,
		};
		setCompanionHistory((prev) => [...prev, inboundMessage]);
	}

	async function handleSendMessage() {
		const { text } = companionForm.values;
		const outboundMessage: CompanionMessage = {
			role: 'user',
			text,
		};
		companionForm.reset();
		setCompanionHistory((prev) => [...prev, outboundMessage]);
		stream.start({
			message: text,
			panels: storyDraft.panels,
			characterIds: storyDraft.characters.map((c) => c.id),
			storyDraftId: storyDraft.id,
			history: companionHistory.slice(-10),
		});
	}

	function handleCoverImageUploaded(image: ClientImageDraft) {
		setStoryDraft((prev) => ({
			...prev,
			cover: {
				...prev.cover,
				image,
			},
		}));
	}

	function handlePageImageUploaded(image: ClientImageDraft, index: number) {
		setStoryDraft((prev) => ({
			...prev,
			pages: prev.pages.map((p, i) => (i === index ? { ...p, image } : p)),
		}));
	}

	function handlePreviousClicked() {
		setActiveStep(activeStep - 1);
		clearSearchParams();
	}

	async function handleNextClicked() {
		setIsSubmitting(true);
		setDetailsErrors([]);
		setPagesError(undefined);
		const detailsValidation = DetailsSchema.safeParse(storyDraft.details);
		setDetailsErrors(detailsValidation.error?.errors ?? []);
		let isValid = detailsValidation.success;
		if (storyDraft.settings.imageType === StoryImageType.Uploaded) {
			if (!storyDraft.cover.image || storyDraft.pages.some((p) => !p.image)) {
				setPagesError('Every page must have an image uploaded');
				isValid = false;
			}
		} else {
			// For now, every page has 1 panel, so iterate over all panels
			if (!storyDraft.cover.image || storyDraft.panels.some((p) => !p.selectedImageDraft)) {
				setPagesError('Every page must have an image created or uploaded');
				isValid = false;
			}
		}
		if (!isValid) {
			setIsSubmitting(false);
			return;
		}
		if (storyDraft.settings.imageType === StoryImageType.Generated) {
			// Ensure every panel has `canvasData`, which could be undefined if they generate an image but move away from the panel before it finishes
			const canvasDatas = await Promise.all(storyDraft.panels.map((p) => p.canvasData ?? initializeCanvasData(p)));
			// Prepare Preview by creating images from each panel's canvas
			const panelsCanvasImageData = await Promise.all(
				storyDraft.panels.map(async (_, i) => await createImageData(canvasDatas[i], i))
			);
			setStoryDraft((prev) => ({
				...prev,
				panels: prev.panels.map((p, i) => ({
					...p,
					canvasData: canvasDatas[i],
				})),
				pages: prev.pages.map((p, i) => ({
					...p,
					canvasImageData: panelsCanvasImageData[i],
				})),
				cover: {
					...prev.cover,
				},
			}));
		}
		triggerSaveStoryDraft();
		setActiveStep(activeStep + 1);
		setIsSubmitting(false);
		clearSearchParams();
	}

	return (
		<>
			<NewStoryPageHeader
				activeStep={activeStep}
				isLoading={isSubmitting}
				isNextButtonDisabled={false}
				handlePreviousClicked={handlePreviousClicked}
				handleNextClicked={handleNextClicked}
				setActiveStep={setActiveStep}
			/>
			{detailsErrors.length > 0 && (
				<Stack gap="0" mt="md">
					<Text c="red" fw="bold">
						Your cover details are incomplete.
					</Text>
					{!isProduction() &&
						detailsErrors.map((e, i) => (
							<Text key={i} c="red">
								{e.path}: {e.message}
							</Text>
						))}
				</Stack>
			)}
			{pagesError && (
				<Text c="red" fw="bold">
					{pagesError}
				</Text>
			)}
			<Sidebar.Container>
				{shouldShowCompanion && (
					<Sidebar.Side w="lg">
						<Stack className={fillHeight}>
							<Title order={6}>Companion</Title>
							<CompanionChat
								form={companionForm}
								stream={stream as GenericStream}
								companionHistory={companionHistory}
								handleSendMessage={handleSendMessage}
							/>
						</Stack>
					</Sidebar.Side>
				)}
				<Sidebar.Main>
					<Stack w="100%" className={fillHeight}>
						<Group gap={0}>
							{Object.keys(Tab).map((tab) => {
								let label = tab.toUpperCase();
								// Rename Images —> Inspiration in the uploaded images (illustrator workflow)
								if (tab === Tab.Images && storyDraft.settings.imageType === StoryImageType.Uploaded) {
									label = 'INSPIRATION';
								}
								return (
									<Button
										key={tab}
										variant={Tab[tab as keyof typeof Tab] === activeTab ? 'light' : 'subtle'}
										color="gray"
										size="xs"
										onClick={() => setActiveTab(Tab[tab as keyof typeof Tab])}
									>
										{label}
									</Button>
								);
							})}
						</Group>
						{activeTab === Tab.Text && (
							<Stack gap="xl">
								<Box key="cover" ref={coverPanelRef}>
									<Title order={6} c="dimmed" ta="center">
										COVER
									</Title>
									<DetailsForm storyDraft={storyDraft} setStoryDraft={setStoryDraft} />
								</Box>
								{pagesAndPanels.map((page) => (
									<Stack key={page.index}>
										<Divider />
										<Title order={6} ta="center" c="dimmed">
											PAGE {page.index + 1}
										</Title>
										<Stack gap="xl">
											{page.panels.map((panel, i) => (
												<TextForm
													key={panel.index}
													panel={panel}
													panelIndexOnPage={i}
													storyDraft={storyDraft}
													setStoryDraft={setStoryDraft}
													ref={panelRefs[getAbsolutePanelIndex(panel.id)]}
												/>
											))}
										</Stack>
									</Stack>
								))}
							</Stack>
						)}
						{activeTab === Tab.Pages && (
							<Stack maw="300">
								<Stack gap="xs" key="cover" ref={coverPageRef}>
									<Group justify="space-between" align="center">
										<Title order={6} c="dimmed">
											Cover
										</Title>
										<UploadImageDraftButton
											folder={Folder.CoverImages}
											sourceDraftId={storyDraft.id}
											handleImageUploaded={(image) => handleCoverImageUploaded(image)}
										/>
									</Group>
									{/* TODO: Change when codegen has `maybeValue: 'T | undefined'` */}
									<RoundedImage url={storyDraft.cover.image?.imageUrl ?? undefined} />
								</Stack>
								{storyDraft.settings.imageType === StoryImageType.Uploaded
									? storyDraft.pages.map((page, i) => (
											<Stack gap="xs" key={i} ref={pageRefs[i]}>
												<Group justify="space-between" align="center">
													<Title order={6} c="dimmed">
														Page {i + 1}
													</Title>
													<UploadImageDraftButton
														folder={Folder.PageImages}
														sourceDraftId={storyDraft.id}
														handleImageUploaded={(image) => handlePageImageUploaded(image, i)}
													/>
												</Group>
												{/* TODO: Change when codegen has `maybeValue: 'T | undefined'` */}
												<RoundedImage url={page.image?.imageUrl ?? undefined} />
											</Stack>
										))
									: pagesAndPanels.map((page, i) => (
											<PageCanvas
												key={i}
												pageIndex={i}
												// For now, every page has 1 panel, so use the page's "first panel"
												panel={page.panels[0]}
												storyDraft={storyDraft}
												setStoryDraft={setStoryDraft}
												ref={pageRefs[i]}
											/>
										))}
							</Stack>
						)}
						{activeTab === Tab.Images &&
							(activePanel === undefined ? (
								<>
									<Title order={6} c="dimmed">
										Cover
									</Title>
									<CoverImageEditor storyDraft={storyDraft} setStoryDraft={setStoryDraft} />
								</>
							) : (
								<Box className={fillHeight}>
									<Title order={6} c="dimmed">
										Page {activePanel.pageIndex + 1}
										{activePanelIndexOnPage !== undefined ? `, Panel ${activePanelIndexOnPage + 1}` : ''}
									</Title>
									<PanelImageEditor
										key={activePanelIndex}
										panel={activePanel}
										characters={storyDraft.characters.filter((c) => activePanel.characterIds?.includes(c.id))}
										storyDraft={storyDraft}
										setStoryDraft={setStoryDraft}
									/>
								</Box>
							))}
					</Stack>
				</Sidebar.Main>
				<Sidebar.Side w="md">
					<ScrollArea w="100%" offsetScrollbars={false}>
						<NavLink
							label="Cover"
							active={activePanelIndex === undefined}
							onClick={() => {
								setActivePanelIndex(undefined);
								if (activeTab === Tab.Text) {
									scrollToRef(coverPanelRef);
								} else if (activeTab === Tab.Pages) {
									scrollToRef(coverPageRef);
								}
							}}
							style={{
								borderRadius: 4,
							}}
						/>
						{pagesAndPanels.map((page) => (
							<NavLink
								key={page.index + 1}
								label={`Page ${page.index + 1}`}
								childrenOffset="md"
								defaultOpened
								style={{
									borderRadius: 4,
								}}
							>
								{page.panels.map((panel, panelIndexOnPage) => (
									<Tooltip
										key={panel.index}
										label={
											panel.description.slice(0, panelTooltipMaxCharacters) +
											(panel.description.length > panelTooltipMaxCharacters ? '...' : '')
										}
										multiline
										w={250}
										withArrow
										arrowSize={8}
										position="left"
									>
										<NavLink
											label={`Panel ${panelIndexOnPage + 1}`}
											active={activePanel?.id === panel.id}
											onClick={() => {
												setActivePanelIndex(storyDraft.panels.findIndex((p) => p.id === panel.id));
												if (activeTab === Tab.Text) {
													scrollToRef(panelRefs[getAbsolutePanelIndex(panel.id)]);
												} else if (activeTab === Tab.Pages) {
													scrollToRef(pageRefs[panel.pageIndex]);
												}
											}}
											style={{
												borderRadius: 4,
											}}
										/>
									</Tooltip>
								))}
							</NavLink>
						))}
					</ScrollArea>
				</Sidebar.Side>
			</Sidebar.Container>
		</>
	);
}

type DetailsFormProps = Pick<PagesProps, 'storyDraft' | 'setStoryDraft'>;

function DetailsForm({ storyDraft, setStoryDraft }: DetailsFormProps) {
	/**
	 * Use `uncontrolled` mode in the Mantine Form, but make each input controlled via `value={...}`
	 * Confusing, but the form's values, derived from `storyDraft`, may change elsewhere (i.e. PanelImageEditor)
	 * This setup allows us to:
	 * 1. Guarantee autosave by always keeping `storyDraft` up-to-date
	 * 2. Allow other forms to also change `storyDraft`, and this form will receive those changes
	 */
	const form = useForm<StoryDraft['details']>({
		mode: 'uncontrolled',
		// Always validate against `storyDraft` instead of `form.values`. This is because `initialValues` aren't set, so the first validation will always think the form is empty
		validate: () => {
			const validator = zodResolver(StoryDraftSchema.shape.details);
			return validator(storyDraft.details);
		},
		validateInputOnBlur: true,
		// Keep the parent storyDraft in sync with this child's form values
		onValuesChange: (values) => {
			setStoryDraft((prev) => ({
				...prev,
				details: {
					...prev.details,
					...values,
				},
			}));
		},
	});

	return (
		<Stack gap="xs">
			<TextInput
				label="Title"
				size="xs"
				variant="unstyled"
				{...form.getInputProps('title')}
				key={form.key('title')}
				value={storyDraft.details.title}
			/>
			<Textarea
				label="Summary"
				size="xs"
				variant="unstyled"
				{...form.getInputProps('summary')}
				key={form.key('summary')}
				value={storyDraft.details.summary}
			/>
			<TagsInput
				label="Genres"
				placeholder="Type your genre and hit enter"
				size="xs"
				variant="unstyled"
				{...form.getInputProps('genres')}
				key={form.key('genres')}
				value={storyDraft.details.genres}
			/>
		</Stack>
	);
}

const TextFormSchema = PanelSchema.pick({
	description: true,
	setting: true,
	caption: true,
	dialogues: true,
});

type TextFormProps = Pick<PagesProps, 'storyDraft' | 'setStoryDraft'> & {
	panel: Panel;
	panelIndexOnPage: number;
};

const TextForm = forwardRef(function (
	{ panel, panelIndexOnPage, storyDraft, setStoryDraft }: TextFormProps,
	ref: LegacyRef<HTMLDivElement>
) {
	const sortedCharacters = useMemo(() => {
		return storyDraft.characters.sort((a, b) => a.name.localeCompare(b.name));
	}, [storyDraft.characters]);

	/**
	 * Use `uncontrolled` mode in the Mantine Form, but make each input controlled via `value={...}`
	 * Confusing, but the form's values, derived from `storyDraft`, may change elsewhere (i.e. PanelImageEditor)
	 * This setup allows us to:
	 * 1. Guarantee autosave by always keeping `storyDraft` up-to-date
	 * 2. Allow other forms to also change `storyDraft`, and this form will receive those changes
	 */
	const form = useForm<zod.infer<typeof TextFormSchema>>({
		mode: 'uncontrolled',
		validate: () => {
			const validator = zodResolver(TextFormSchema);
			return validator(panel);
		},
		validateInputOnBlur: true,
		initialValues: {
			description: panel.description,
			setting: panel.setting,
			caption: panel.caption,
			dialogues: panel.dialogues,
		},
		// Keep the parent storyDraft in sync with this child's form values
		onValuesChange: (values) => {
			setStoryDraft((prev) => ({
				...prev,
				panels: prev.panels.map((p) => ({
					...p,
					...(p.id === panel.id ? values : {}),
				})),
			}));
		},
	});

	function handleSelectCharacter(characterId: string) {
		setStoryDraft((prev) => ({
			...prev,
			panels: prev.panels.map((p) => {
				if (p.id !== panel.id) {
					return p;
				}
				const prevCharacterIds = p.characterIds ?? [];
				return {
					...p,
					characterIds: p.characterIds?.includes(characterId)
						? p.characterIds.filter((id) => characterId !== id)
						: [...prevCharacterIds, characterId],
				};
			}),
		}));
	}

	// Form <> Canvas update handlers
	function handleAddDialogue() {
		// Update the form
		const newDialogue: Panel['dialogues'][number] = {
			index: form.getValues().dialogues.length,
			characterName: '',
			characterId: '',
			text: '',
		};
		form.setValues((prev) => ({
			...prev,
			dialogues: [...(prev.dialogues ?? []), newDialogue],
		}));
	}

	function handleRemoveDialogue(index: number) {
		// Filter out the dialogue with the given index and reindex the remaining dialogues
		// Update the form
		form.setValues((prev) => ({
			...prev,
			dialogues: prev.dialogues?.filter((d) => d.index !== index).map((d, i) => ({ ...d, index: i })),
		}));
	}

	return (
		<Stack gap="xs" ref={ref}>
			<Text size="sm" fw="bold" c="dimmed">
				Panel {panelIndexOnPage + 1}
			</Text>
			<Box>
				<InputLabel size="xs">Characters</InputLabel>
				<ScrollArea scrollbars="x">
					{/* Note, adding a width to this Group allows it to take up the remaining width. Unknown why this works, but it does */}
					<Group w="100" wrap="nowrap">
						{sortedCharacters.map((c) => (
							<RoundedImage
								key={c.id}
								url={c.imageUrl}
								borderRadius={2}
								label={c.name}
								textProps={{ size: 'xs', truncate: true, mt: 4 }}
								w="60"
								h="90"
								isSelected={panel.characterIds?.includes(c.id) ?? false}
								onClick={() => handleSelectCharacter(c.id)}
							/>
						))}
					</Group>
				</ScrollArea>
			</Box>
			<Textarea
				label="Setting"
				size="xs"
				variant="unstyled"
				{...form.getInputProps('setting')}
				key={form.key('setting')}
				value={panel.setting}
			/>
			<Textarea
				label="Description"
				size="xs"
				variant="unstyled"
				{...form.getInputProps('description')}
				key={form.key('description')}
				value={panel.description}
			/>
			<TextInput
				label="Caption"
				description="Optional"
				size="xs"
				variant="unstyled"
				{...form.getInputProps('caption')}
				key={form.key('caption')}
				value={panel.caption}
				onChange={(e) => {
					// Call Mantine's `onChange` function (which updates the form field value) since we are overriding it
					form.getInputProps('caption').onChange(e);
				}}
			/>
			<Group justify="space-between">
				<Box>
					<InputLabel size="xs">Dialogue</InputLabel>
					<Text c="dimmed" style={{ fontSize: 10 }}>
						Optional
					</Text>
				</Box>
				<ActionIcon size="sm" onClick={() => handleAddDialogue()}>
					<Plus />
				</ActionIcon>
			</Group>
			{form.getValues().dialogues.map((d, i) => (
				<Stack key={d.index} gap={0}>
					<Group justify="space-between" align="start">
						<Autocomplete
							placeholder="Character name"
							data={storyDraft.characters.map((c, j) => c.name + `${j}`)}
							size="xs"
							flex={1}
							variant="unstyled"
							{...form.getInputProps(`dialogues.${i}.characterName`)}
							key={form.key(`dialogues.${i}.characterName`)}
							value={panel.dialogues[i].characterName}
							onChange={(value) => {
								// Call Mantine's `onChange` function (which updates the form field value) since we are overriding it
								form.getInputProps(`dialogues.${i}.characterName`).onChange(value);
								// Update the characterId
								const newCharacterId = storyDraft.characters.find((c) => c.name === value)?.id;
								form.setValues((prev) => ({
									...prev,
									dialogues: prev.dialogues?.map((d) => (d.index == i ? { ...d, characterId: newCharacterId } : d)),
								}));
							}}
							onFocus={() => {
								form.getInputProps(`dialogues.${i}.characterName`).onFocus();
							}}
						/>
						<ActionIcon size="sm" variant="transparent" color="dark" onClick={() => handleRemoveDialogue(d.index)}>
							<TrashSimple />
						</ActionIcon>
					</Group>
					<Stack gap="xs">
						<TextInput
							placeholder="Text"
							size="xs"
							flex={1}
							variant="unstyled"
							{...form.getInputProps(`dialogues.${i}.text`)}
							key={form.key(`dialogues.${i}.text`)}
							value={panel.dialogues[i].text}
							onChange={(e) => {
								// Call Mantine's `onChange` function (which updates the form field value) since we are overriding it
								form.getInputProps(`dialogues.${i}.text`).onChange(e);
							}}
							onFocus={() => {
								form.getInputProps(`dialogues.${i}.text`).onFocus();
							}}
						/>
					</Stack>
				</Stack>
			))}
		</Stack>
	);
});

type PageCanvasProps = { pageIndex: number } & Omit<TextFormProps, 'panelIndexOnPage'>;

const PageCanvas = forwardRef(function (
	{ pageIndex, panel, storyDraft, setStoryDraft }: PageCanvasProps,
	ref: LegacyRef<HTMLDivElement>
) {
	// For now, every page has 1 panel, so use the page's "first panel"

	// Canvas declarations
	const canvasRef = useRef<fabric.Canvas>();
	const [selectedObject, setSelectedObject] = useState<CaptionObject | DialogueObject>();

	useEffect(() => {
		async function initializeAndLoadCanvas() {
			let canvasData = panel.canvasData;
			if (!canvasData) {
				canvasData = await initializeCanvasData(panel);
			}
			const canvas = await loadCanvas(canvasData, document, pageIndex);
			// Canvas event listeners
			canvas.on('object:added', updateCanvasData);
			canvas.on('object:removed', updateCanvasData);
			canvas.on('object:modified', updateCanvasData);
			canvas.on('selection:created', handleNewSelection);
			canvas.on('selection:updated', handleNewSelection);
			canvas.on('selection:cleared', () => {
				setSelectedObject(undefined);
			});
			if (!canvasRef.current) {
				canvasRef.current = canvas;
			}
		}
		initializeAndLoadCanvas().then(() => adjustDialogues());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Update the canvas if the selected image changes (uploaded or newly generated in PanelImageEditor)
	useEffect(() => {
		if (canvasRef.current && panel.selectedImageDraft?.imageUrl) {
			setBackgroundImage(canvasRef.current, panel.selectedImageDraft.imageUrl);
		}
	}, [panel.selectedImageDraft]);

	async function adjustDialogues() {
		const canvasData: CanvasData = panel.canvasData;
		if (!canvasData || !canvasRef.current) {
			return;
		}
		const dialogueBoxes = canvasData.objects.filter(isDialogueObject);
		const matchingDialogies: Record<number, [CanvasObject, GeneratedDialogue]> = {};
		panel.dialogues.forEach((dialogue) => {
			const matchingTextBox = dialogueBoxes.find((textBox) => textBox.index === dialogue.index);
			if (matchingTextBox) {
				matchingDialogies[matchingTextBox.index] = [matchingTextBox, dialogue];
			}
		});
		// remove any dialogueBoxes that are no longer in the panel
		dialogueBoxes
			.filter((textBox) => !matchingDialogies[textBox.index])
			.forEach((textBox) => removeObject(canvasRef.current!, 'Dialogue', textBox.index));
		// add new panel dialogues that aren't in the canvas yet
		panel.dialogues
			.filter((dialogue) => !matchingDialogies[dialogue.index])
			.forEach(({ characterName, text, index }) => addDialogueObject(canvasRef.current!, characterName, text, index));
		// update the text of any dialogueBoxes that have changed
		Object.values(matchingDialogies).forEach(([textBox, dialogue]) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if ((textBox as any).text !== dialogue.text) {
				updateObjectText(canvasRef.current!, 'Dialogue', textBox.index, { text: dialogue.text });
			}
		});
	}

	// For now, have to manually update both `storyDraft.dialogues` and `scene.canvasData.objects`
	// Ideally only `scene.canvasData.objects` is the source of truth, but it is too slow when typing
	function handleUpdateDialogueTextBox(dialogueIndex: number, values: fabric.ITextboxOptions) {
		updateObjectText(canvasRef.current!, 'Dialogue', dialogueIndex, values);
		updateCanvasData();
	}

	function handleNewSelection(e: fabric.IEvent<MouseEvent>) {
		if (e.selected?.length !== 1) {
			return;
		}
		const object = e.selected[0];
		if (isCaptionObject(object) || isDialogueObject(object)) {
			setSelectedObject(object);
		}
	}

	// const updateCanvasDataDebounced = debounce(updateCanvasData, 200);
	function updateCanvasData() {
		if (canvasRef.current) {
			const newCanvasData = getCanvasData(canvasRef.current!);
			setStoryDraft((prev) => ({
				...prev,
				panels: prev.panels.map((p) => ({
					...p,
					canvasData: p.id === panel.id ? newCanvasData : p.canvasData,
				})),
			}));
		}
	}

	// Toolbar actions
	function handleUpdateShape(index: number, shape: Shape) {
		updateShape(canvasRef.current!, index, shape);
		updateCanvasData();
	}

	function handleImageUploaded(image: ClientImageDraft) {
		setStoryDraft((prev) => ({
			...prev,
			panels: prev.panels.map((p) => (p.id === panel.id ? { ...p, selectedImageDraft: image } : p)),
		}));
	}

	useEffect(() => {
		updateCanvasData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storyDraft.panels]);

	return (
		<Stack ref={ref}>
			<Stack gap="xs">
				<Group justify="space-between">
					<Title order={5}>Page {pageIndex + 1}</Title>
					<UploadImageDraftButton
						folder={Folder.PageImages}
						sourceDraftId={storyDraft.id}
						handleImageUploaded={handleImageUploaded}
					/>
				</Group>
				{selectedObject && isDialogueObject(selectedObject) && (
					<DialogueToolbar
						dialogueObject={selectedObject}
						handleUpdateDialogueTextBox={handleUpdateDialogueTextBox}
						handleUpdateShape={handleUpdateShape}
					/>
				)}
				<Box w="300" h="450" id="page-canvas-parent" bg={panel.selectedImageDraft ? '' : 'softGray'}>
					<canvas id={`page-canvas-${pageIndex}`} />
				</Box>
			</Stack>
		</Stack>
	);
});
