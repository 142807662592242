import { useMutation } from '@apollo/client';
import { Menu } from '@mantine/core';
import { Link } from 'react-router-dom';
import { graphql } from '@shared';
import useSession from '../hooks/useSession';
import { apolloClient } from '../utils/apollo';

export default function UserMenu() {
	const { refreshSession } = useSession();
	const [signOut, { loading }] = useMutation(signOutMutation);

	return (
		<>
			<Menu.Dropdown>
				<Menu.Item component={Link} to="/settings/profile" disabled={loading}>
					Settings
				</Menu.Item>
				<Menu.Item component={Link} to="/contact-us" disabled={loading}>
					Contact us
				</Menu.Item>
				<Menu.Item
					disabled={loading}
					onClick={async () => {
						await signOut({
							onCompleted: async () => {
								refreshSession();
								// reset the Apollo cache to clear out any data that should not be available to the next user
								await apolloClient.resetStore();
							},
						});
					}}
				>
					Sign out
				</Menu.Item>
			</Menu.Dropdown>
		</>
	);
}

const signOutMutation = graphql(`
	mutation SignOut {
		signOut
	}
`);
