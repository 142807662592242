import { Container, ContainerProps, MantineSize } from '@mantine/core';

export const defaultPaddingX: MantineSize = 'md';

type ViewContainerProps = ContainerProps & {
	width?: 'compact' | 'standard' | 'full';
};

export default function ViewContainer({ width = 'standard', children, ...props }: ViewContainerProps) {
	const size = width === 'compact' ? 'sm' : width === 'standard' ? 'xl' : '100%';
	return (
		// `w="100%"` ensures the container may take up the full width of the parent (basically maxW), before `size` sets the actual width
		<Container px={defaultPaddingX} size={size} w="100%" {...props}>
			{children}
		</Container>
	);
}
