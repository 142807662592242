import { Button, Flex, Text } from '@mantine/core';
import useIsMobileView from '../../hooks/useIsMobileView';
import { steps } from './step';

type NewStoryStepperProps = {
	activeStep: number;
	setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};

export default function NewStoryStepper({ activeStep, setActiveStep }: NewStoryStepperProps) {
	const isMobileView = useIsMobileView();

	function handleStepClick(index: number) {
		setActiveStep(index);
	}

	// In mobile, only display the current step, as it takes up too much screen real estate	otherwise
	if (isMobileView) {
		return (
			<Text c="gray" fw="bold" size="xs">
				{steps[activeStep].toUpperCase()}
			</Text>
		);
	}

	return (
		<Flex align="flex-start">
			{steps.map((step, i) => {
				const stepLabel = step.toUpperCase();
				if (i < activeStep) {
					return (
						<Button variant="subtle" color="gray" size="xs" key={i} onClick={() => handleStepClick(i)}>
							{stepLabel}
						</Button>
					);
				} else if (i === activeStep) {
					return (
						<Button variant="light" color="gray" size="xs" key={i}>
							{stepLabel}
						</Button>
					);
				} else {
					return (
						<Button variant="subtle" color="gray" size="xs" key={i} style={{ pointerEvents: 'none' }}>
							{stepLabel}
						</Button>
					);
				}
			})}
		</Flex>
	);
}
