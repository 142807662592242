import { Group, GroupProps, Title } from '@mantine/core';
import { ReactElement } from 'react';

type PageHeader = GroupProps & {
	text: string;
	button?: ReactElement;
};

export default function PageHeader({ text, button, ...props }: PageHeader) {
	return (
		<Group my="lg" justify="space-between" {...props}>
			<Title order={2}>{text}</Title>
			{button && <>{button}</>}
		</Group>
	);
}
