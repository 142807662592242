import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
	AspectRatio,
	Box,
	Button,
	Divider,
	Group,
	Image,
	InputLabel,
	ScrollArea,
	SimpleGrid,
	Slider,
	Stack,
	Text,
	TextInput,
	Title,
	useMatches,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { Step } from 'intro.js-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	ArtStyle,
	ArtStyleSchema,
	ClientImageDraft,
	CompanionMessage,
	CompanionMessageSchema,
	Endpoints,
	Folder,
	ImageDimensions,
	ImageKind,
	ImageStatus,
	ResponseBody,
	TutorialType,
	defaultPagination,
	graphql,
	invariant,
} from '@shared';
import { ArtStyleSelector } from '../components/ArtStyleSelector';
import CompanionChat, { GenericStream } from '../components/CompanionChat';
import Tutorial from '../components/Tutorial';
import ImageLoadingPlaceholder from '../components/ui/ImageLoading';
import Loading from '../components/ui/Loading';
import PageHeader from '../components/ui/PageHeader';
import RoundedImage from '../components/ui/RoundedImage';
import ShowTutorialButton from '../components/ui/ShowTutorialButton';
import Sidebar from '../components/ui/Sidebar';
import TextEditor from '../components/ui/TextEditor';
import UploadImageButton from '../components/ui/UploadButton';
import ViewContainer from '../components/ui/ViewContainer';
import { fillHeight, fillHeightTarget } from '../css/utils.module.css';
import { createUploadedImageDraftMutation, getImageDraftsQuery } from '../graphql/common';
import useImageUploader from '../hooks/useImageUploader';
import { useInterval } from '../hooks/useInterval';
import { useStream } from '../hooks/useStream';
import useTutorials from '../hooks/useTutorials';
import { clearEntitiesFromCache, clearTokenBalanceFromCache } from '../utils/cache';
import { handleUnexpectedError, showCustomErrorNotification } from '../utils/error';

const AutosaveFrequencyInMS = 2 * 1000;
const ImageDraftUpdateFrequencyInMS = 2 * 1000;

const endpoint = Endpoints.sendMessageToCharacterCompanion;

export default function NewCharacter() {
	const rightSidebarWidth = useMatches({
		base: 'sm',
		xs: 'md',
		sm: 'lg',
	});

	const navigate = useNavigate();
	const characterDraftId = useParams().id;
	const [searchParams] = useSearchParams();
	const callbackUrl = searchParams.get('callbackUrl');
	const companionForm = useForm({
		validate: zodResolver(CompanionMessageSchema.pick({ text: true })),
		initialValues: { text: '' },
	});
	const { shouldShowTutorial, setShouldShowTutorial, acknowledgeTutorial } = useTutorials({
		tutorialType: TutorialType.NewCharacter,
	});
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const stream = useStream({ endpoint, handleCompleteStream });
	const [companionHistory, setCompanionHistory] = useState<CompanionMessage[]>([
		{
			role: 'assistant',
			text: "Let's create your character. What are they like? If you need help with ideas let me know.",
		},
	]);
	const [artStyle, setArtStyle] = useState(ArtStyle.Anime);
	// TODO: Allow specifying the number of images
	const [numberOfImages, setNumberOfImages] = useState(4);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [images, setImages] = useState<ClientImageDraft[]>([]);
	const pendingImages = useMemo(() => images.filter((i) => i.status === ImageStatus.Pending), [images]);
	const [imagesWithFeedback, setImagesWithFeedback] = useState<ClientImageDraft[]>([]);
	const [selectedImageDraftId, setSelectedImageDraftId] = useState<string>();
	// TODO: Support loading the selected image if it is beyond the initial load's pagination
	const selectedImage = useMemo(
		() => images.find((i) => i.id === selectedImageDraftId),
		[images, selectedImageDraftId]
	);
	const [selectedImageRating, setSelectedImageRating] = useState<number>(1);
	const [lastAutosave, setLastAutosave] = useState({
		name,
		description,
		artStyle,
		selectedImageDraftId,
	});
	const { uploadImage } = useImageUploader();
	const [getPendingImages] = useLazyQuery(getImageDraftsQuery);
	const [createUploadedImageDraft] = useMutation(createUploadedImageDraftMutation);
	const [generateImages, { loading: generateImagesLoading }] = useMutation(generateCharacterImagesMutation);
	const [generateHeadshotImages] = useMutation(generateCharacterHeadshotImagesMutation);
	const [createCharacterDraft, { loading: createCharacterDraftLoading }] = useMutation(createCharacterDraftMutation);
	const [updateCharacterDraft] = useMutation(updateCharacterDraftMutation);
	const [createCharacter, { loading: createCharacterLoading }] = useMutation(createCharacterMutation);
	const { loading: getInitialImageDraftsLoading } = useQuery(getImageDraftsQuery, {
		variables: {
			sourceDraftId: characterDraftId!,
		},
		skip: !characterDraftId,
		onCompleted: (data) => {
			if (data.imageDrafts.length > 0) {
				setImages(data.imageDrafts);
			}
		},
		onError: handleUnexpectedError,
	});

	const [getMoreImages] = useLazyQuery(getImageDraftsQuery, {
		variables: {
			sourceDraftId: characterDraftId!,
			pagination: { offset: images.length, limit: defaultPagination.limit },
		},
		onCompleted: (data) => {
			if (data.imageDrafts.length > 0) {
				const newImageDrafts = data.imageDrafts;
				setImages((prev) => [...prev, ...newImageDrafts]);
			}
		},
		onError: handleUnexpectedError,
	});

	async function handleGetMoreImages() {
		if (!characterDraftId || getInitialImageDraftsLoading) {
			return;
		}
		await getMoreImages();
	}

	const { loading: getCharacterDraftLoading } = useQuery(getCharacterDraftQuery, {
		variables: {
			id: characterDraftId!,
		},
		skip: !characterDraftId,
		onCompleted: (data) => {
			if (!data.characterDraft) {
				handleUnexpectedError('useGetCharacterDraftQuery returned no data');
				return;
			}
			const { name, description, selectedImageDraftId, artStyle } = data.characterDraft;
			if (selectedImageDraftId) {
				setSelectedImageDraftId(selectedImageDraftId);
				setLastAutosave((prev) => ({
					...prev,
					selectedImageDraftId,
				}));
			}
			if (ArtStyleSchema.safeParse(artStyle).success) {
				const artStyleParsed = ArtStyleSchema.parse(artStyle);
				setArtStyle(artStyleParsed);
				setLastAutosave((prev) => ({
					...prev,
					artStyle: artStyleParsed,
				}));
			}
			if (name) {
				setName(name);
				// TODO: Change when codegen has `maybeValue: 'T | undefined'`
				setLastAutosave((prev) => ({
					...prev,
					name,
				}));
			}
			if (description) {
				setDescription(description);
				// TODO: Change when codegen has `maybeValue: 'T | undefined'`
				setLastAutosave((prev) => ({
					...prev,
					description,
				}));
			}
		},
	});

	// Create the draft if it doesn't exist
	useEffect(() => {
		if (!characterDraftId) {
			createCharacterDraft({
				onCompleted: (data) => {
					const characterDraftId = data.createCharacterDraft.id;
					// Preserve search parameters
					const searchParams = new URLSearchParams(location.search);
					const searchParamsString = searchParams.size > 0 ? `?${searchParams.toString()}` : '';
					navigate(`/new-character/${characterDraftId}${searchParamsString}`, {
						replace: true,
					});
				},
				update: (cache) => {
					clearEntitiesFromCache(cache, ['characterDraft', 'characterDrafts']);
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Set up autosave interval
	useInterval(() => autosave(), AutosaveFrequencyInMS);

	// Pending —> Completed images polling
	useInterval(async () => {
		if (pendingImages.length) {
			await updatePendingImages();
		}
	}, ImageDraftUpdateFrequencyInMS);

	async function autosave() {
		const newAutosave = {
			name,
			description,
			artStyle,
			selectedImageDraftId,
		};
		// If there's no draft or there are no changes to save, don't autosave
		if (!characterDraftId) {
			return;
		} else if (
			newAutosave.name === lastAutosave.name &&
			newAutosave.artStyle === lastAutosave.artStyle &&
			newAutosave.description === lastAutosave.description &&
			newAutosave.selectedImageDraftId === lastAutosave.selectedImageDraftId
		) {
			return;
		}
		setLastAutosave(newAutosave);
		await updateCharacterDraft({
			variables: {
				input: { ...newAutosave, characterDraftId },
			},
			update: (cache) => {
				clearEntitiesFromCache(cache, ['characterDraft', 'characterDrafts']);
			},
		});
	}

	async function updatePendingImages() {
		if (!characterDraftId) {
			return;
		}
		await getPendingImages({
			fetchPolicy: 'network-only',
			variables: {
				sourceDraftId: characterDraftId,
			},
			onCompleted(data) {
				if (!data?.imageDrafts.length) {
					return;
				}
				const completedImages = data.imageDrafts
					.filter((image) => image.status === ImageStatus.Completed && image.imageUrl)
					.map((image) => ({
						...image,
						imageUrl: image.imageUrl,
						status: image.status,
					}));
				setImages((prev) => [
					...prev.map((image) => {
						const updatedImage = completedImages.find((i) => i.id === image.id);
						if (updatedImage) {
							return {
								...image,
								imageUrl: updatedImage.imageUrl,
								status: updatedImage.status,
							};
						}
						return { ...image };
					}),
				]);
			},
			onError: handleUnexpectedError,
		});
	}

	async function handleGenerateImages() {
		invariant(characterDraftId);
		if (!name) {
			showCustomErrorNotification({ message: 'You must provide a name for the character' });
			return;
		} else if (!description) {
			showCustomErrorNotification({ message: 'You must provide a description for the character' });
			return;
		}
		await generateImages({
			variables: {
				input: {
					name,
					description,
					artStyle,
					numberOfImages,
					characterDraftId,
				},
			},
			onCompleted: (data) => {
				const imageDrafts = data.generateCharacterImages;
				if (!selectedImageDraftId) {
					setSelectedImageDraftId(imageDrafts[0].id);
				}
				setImages((prev) => [...imageDrafts, ...prev]);
			},
			onError: handleUnexpectedError,
			update: (cache) => {
				clearTokenBalanceFromCache(cache);
			},
		});
	}

	function handleCompleteStream(data: ResponseBody<typeof endpoint>) {
		if (data.name) {
			setName(data.name);
		}
		if (data.description) {
			setDescription(data.description);
		}
		const inboundMessage: CompanionMessage = {
			role: 'assistant',
			text: data.message,
			rawMessage: JSON.stringify(data),
		};
		setCompanionHistory((prev) => [...prev, inboundMessage]);
	}

	async function handleSendMessage() {
		invariant(characterDraftId);
		const { text } = companionForm.getValues();
		const outboundMessage: CompanionMessage = {
			role: 'user',
			text,
		};
		companionForm.reset();
		setCompanionHistory((prev) => [...prev, outboundMessage]);
		stream.start({
			characterDraftId,
			message: text,
			name,
			description,
			history: companionHistory.slice(-10),
		});
	}

	const handleCreateCharacter = async () => {
		invariant(characterDraftId);
		if (!name) {
			showCustomErrorNotification({ message: 'You must provide a name for the character' });
			return;
		} else if (!description) {
			showCustomErrorNotification({ message: 'You must provide a description for the character' });
			return;
		} else if (!selectedImage?.imageUrl) {
			showCustomErrorNotification({ message: 'You must create or upload an image of the character' });
			return;
		}
		await createCharacter({
			variables: {
				input: {
					name,
					description,
					imageUrl: selectedImage.imageUrl,
					artStyle,
					characterDraftId,
				},
			},
			async onCompleted(data) {
				if (selectedImage.kind === ImageKind.GeneratedImage) {
					// Purposely not awaiting to avoid blocking the user
					generateHeadshotImages({
						variables: {
							input: {
								name,
								description,
								artStyle,
								numberOfImages: 1,
								characterDraftId,
								characterId: data.createCharacter.id,
								originalImageDraftId: selectedImage.id,
							},
						},
					});
				}
				navigate(callbackUrl || '/my-characters');
			},
			onError: handleUnexpectedError,
			update: (cache) => {
				clearEntitiesFromCache(cache, ['characters', 'character']);
			},
		});
	};

	async function handleProvideCharacterImageFeedback(image: ClientImageDraft) {
		if (image.kind !== ImageKind.GeneratedImage) {
			return;
		}
		setImages((prev) => [
			...prev.map((i) => (i.imageUrl === image.imageUrl ? { ...i, userRating: selectedImageRating } : i)),
		]);
		setImagesWithFeedback((prev) => [...prev, image]);
		setSelectedImageRating(1);
	}

	async function handleUploadImage(file: File) {
		try {
			setIsUploading(true);
			const uploadedImage = await uploadImage(file, Folder.CharacterImages);
			await createUploadedImageDraft({
				variables: {
					input: {
						sourceDraftId: characterDraftId!,
						uploadedImageUrl: uploadedImage,
					},
				},
				onCompleted: (data) => {
					setImages((prev) => [data.createUploadedImageDraft, ...prev]);
				},
			});
		} catch (error) {
			handleUnexpectedError(error);
		} finally {
			setIsUploading(false);
		}
	}

	return (
		<ViewContainer width="full" className={fillHeight}>
			<Tutorial
				steps={tutorialSteps}
				shouldShowTutorial={shouldShowTutorial}
				handleExitTutorial={acknowledgeTutorial}
			/>
			<PageHeader
				text="New Character"
				button={
					<Group>
						<ShowTutorialButton setShouldShowTutorial={setShouldShowTutorial} />
						<Button
							id="save-button"
							disabled={generateImagesLoading || createCharacterDraftLoading}
							loading={createCharacterLoading}
							onClick={async () => await handleCreateCharacter()}
						>
							Save
						</Button>
					</Group>
				}
			/>
			{createCharacterDraftLoading || getCharacterDraftLoading || getInitialImageDraftsLoading ? (
				<Loading />
			) : (
				<Sidebar.Container pb="xs">
					<Sidebar.Side visibleFrom="md" w="lg">
						<Stack>
							<Title order={3}>Companion</Title>
							<CompanionChat
								form={companionForm}
								stream={stream as GenericStream}
								companionHistory={companionHistory}
								handleSendMessage={handleSendMessage}
							/>
						</Stack>
					</Sidebar.Side>
					<Sidebar.Main>
						<Stack w="100%" className={fillHeight} align="flex-start">
							<TextInput
								label="Name"
								placeholder="John Doe"
								variant="unstyled"
								value={stream.activeStreamData?.name ?? name}
								onChange={(event) => setName(event.target.value)}
							/>
							<Box w="100%">
								<InputLabel mb="xs">Description</InputLabel>
								<TextEditor
									id="description-input"
									placeholder="A queen dressed in shiny, silver armor..."
									text={stream.activeStreamData?.description ?? description}
									handleChange={setDescription}
									shouldShowToolbar={false}
									// Initial height. Hardcoded to 4 lines, as it doesn't have a lineClamp prop
									h="84"
									style={{ overflow: 'scroll', resize: 'vertical' }}
								/>
							</Box>
							<AspectRatio ratio={ImageDimensions.character.ratio} className={fillHeight}>
								{selectedImage?.imageUrl ? (
									<Image
										style={{ objectFit: 'contain', objectPosition: 'left' }}
										src={selectedImage?.imageUrl}
										className={fillHeight}
									/>
								) : (
									<Box bg="softGray" />
								)}
							</AspectRatio>
							<Box>
								{selectedImage?.kind === ImageKind.GeneratedImage &&
									selectedImage.imageUrl &&
									!imagesWithFeedback.find((image) => image.imageUrl === selectedImage.imageUrl) && (
										<Group justify="space-between">
											<Stack gap={2}>
												<Text size="sm">How would you rate the quality of this image? (1 lowest, 5 highest)</Text>
												<Group gap="xs">
													<Text>{selectedImageRating}</Text>
													<Slider
														flex={1}
														min={1}
														max={5}
														value={selectedImageRating}
														onChange={setSelectedImageRating}
													/>
													<Button
														size="xs"
														variant="transparent"
														onClick={async () => await handleProvideCharacterImageFeedback(selectedImage)}
													>
														Save
													</Button>
												</Group>
											</Stack>
										</Group>
									)}
							</Box>
						</Stack>
					</Sidebar.Main>
					<Sidebar.Side w={rightSidebarWidth}>
						<Stack w="100%" gap="xs" className={fillHeight}>
							<UploadImageButton
								size="xs"
								variant="transparent"
								text="Upload image"
								acceptedFileTypes="image/png,image/jpeg"
								isLoading={isUploading}
								onLoaded={handleUploadImage}
							/>
							<Divider />
							<ArtStyleSelector selectedArtStyle={artStyle} handleSelectArtStyle={setArtStyle} />
							<Group grow preventGrowOverflow={false}>
								<InputLabel>Number of images</InputLabel>
								<Text w="sm" ta="right">
									{numberOfImages}
								</Text>
								<Slider w="100" min={1} max={8} value={numberOfImages} onChange={setNumberOfImages} />
							</Group>
							<Button
								id="generate-button"
								disabled={createCharacterLoading}
								loading={generateImagesLoading}
								onClick={() => handleGenerateImages()}
							>
								Generate images
							</Button>
							<Divider />
							<ScrollArea onScroll={handleGetMoreImages} className={fillHeightTarget}>
								<SimpleGrid cols={{ base: 1, xs: 2 }}>
									{images.map((image) =>
										image.imageUrl ? (
											<RoundedImage
												key={image.id}
												url={image.imageUrl}
												isSelected={image.id === selectedImage?.id}
												onClick={() => setSelectedImageDraftId(image.id)}
											/>
										) : (
											<ImageLoadingPlaceholder key={image.id} />
										)
									)}
								</SimpleGrid>
							</ScrollArea>
						</Stack>
					</Sidebar.Side>
				</Sidebar.Container>
			)}
		</ViewContainer>
	);
}

const generateCharacterImagesMutation = graphql(`
	mutation GenerateCharacterImages($input: GenerateCharacterImagesInput!) {
		generateCharacterImages(input: $input) {
			id
			kind
			status
			imageUrl
		}
	}
`);

const generateCharacterHeadshotImagesMutation = graphql(`
	mutation GenerateCharacterHeadshotImages($input: GenerateCharacterHeadshotImagesInput!) {
		generateCharacterHeadshotImages(input: $input) {
			id
			kind
			status
			imageUrl
		}
	}
`);

const getCharacterDraftQuery = graphql(`
	query GetCharacterDraft($id: ID!) {
		characterDraft(id: $id) {
			id
			name
			description
			artStyle
			selectedImageDraftId
		}
	}
`);

const createCharacterDraftMutation = graphql(`
	mutation CreateCharacterDraft {
		createCharacterDraft {
			id
		}
	}
`);

const updateCharacterDraftMutation = graphql(`
	mutation UpdateCharacterDraft($input: UpdateCharacterDraftInput!) {
		updateCharacterDraft(input: $input)
	}
`);

const createCharacterMutation = graphql(`
	mutation CreateCharacter($input: CreateCharacterInput!) {
		createCharacter(input: $input) {
			id
		}
	}
`);

const tutorialSteps: Step[] = [
	{
		element: '#companion-message-form',
		title: 'Companion',
		intro: 'Your companion is here to help you create your character. Start typing or use the mic to chat with it.',
	},
	{
		element: '#description-input',
		title: 'Description',
		intro: 'Great job! You can also make edits directly in the text box.',
	},
	{
		element: '#art-style-carousel',
		title: 'Art style',
		intro:
			'Now that you have your character description, select your art style and you can generate your character’s image.',
	},
	{
		element: '#action-buttons',
		title: 'Images',
		intro:
			'You can generate images based on your character description, or upload your artwork here if you are an illustrator.',
	},
	{
		element: '#save-button',
		title: 'Save',
		intro:
			'Once you are happy with your character, and have selected an image, click the save button to create your character.',
	},
];
