import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ActionIcon, Anchor, Box, Button, Group, Radio, Stack, Table, Text, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BuyTokenBuckets, UserRole, formatNumber, graphql, pascalCaseToSentence } from '@shared';
import NoAccessPage from '../../components/NoAccessPage';
import Loading from '../../components/ui/Loading';
import { getSubscriptionQuery } from '../../graphql/common';
import useSession from '../../hooks/useSession';
import { clearEntitiesFromCache, clearTokenBalanceFromCache } from '../../utils/cache';
import { formatDateAsMonthDayAndYear, formatDateTimeAsMonthDayAndYear } from '../../utils/time';

export default function Tokens() {
	const { user } = useSession();
	const [buyTokensSelection, setBuyTokensSelection] = useState<string>();
	const [tokenAutoPaySelection, setTokenAutoPaySelection] = useState<string>();
	const [isTokenBalanceEventsOpen, setIsTokenBalanceEventsOpen] = useState(false);
	const { data: subscriptionData } = useQuery(getSubscriptionQuery);
	const [getTokenBalanceEvents, { data: tokenBalanceEventsData, loading: tokenBalanceEventsLoading }] =
		useLazyQuery(getTokenBalanceEventsQuery);
	const [buyTokens, { loading: buyTokensLoading }] = useMutation(buyTokensMutation);
	const [updateTokenAutoPay, { loading: updateTokenAutoPayLoading }] = useMutation(updateTokenAutoPayMutation);
	const [deleteTokenAutoPay, { loading: deleteTokenAutoPayLoading }] = useMutation(deleteTokenAutoPayMutation);
	const { data: tokenAutoPayData, loading: tokenAutoPayLoading } = useQuery(getTokenAutoPayQuery, {
		onCompleted(data) {
			setTokenAutoPaySelection(data.tokenAutoPay?.amount.toString() || '');
		},
	});

	useEffect(() => {
		if (isTokenBalanceEventsOpen) {
			getTokenBalanceEvents();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTokenBalanceEventsOpen]);

	async function handleBuyTokens() {
		if (buyTokensSelection) {
			await buyTokens({
				variables: {
					input: {
						tokens: parseInt(buyTokensSelection),
					},
				},
				onCompleted: () => {
					setBuyTokensSelection('');
				},
				onError: (error) => {
					notifications.show({
						title: 'Error',
						message: error.message,
						color: 'red',
					});
				},
				update: (cache) => {
					clearTokenBalanceFromCache(cache);
				},
			});
		}
	}

	async function handleChangeTokenAutoPay() {
		if (tokenAutoPaySelection) {
			await updateTokenAutoPay({
				variables: {
					input: {
						amount: parseInt(tokenAutoPaySelection),
					},
				},
				update: (cache) => {
					clearEntitiesFromCache(cache, ['tokenAutoPay']);
				},
			});
		} else {
			await deleteTokenAutoPay({
				update: (cache) => {
					clearEntitiesFromCache(cache, ['tokenAutoPay']);
				},
			});
		}
	}

	if (user.role === UserRole.BetaTester) {
		return <NoAccessPage text="You do not currently have access to this page." />;
	}

	return (
		<Stack gap="xl">
			{subscriptionData?.subscription ? (
				<>
					<Stack gap="xs">
						<Group justify="space-between">
							<Title order={4}>Plan</Title>
							<Button component={Link} to="/pricing">
								Change
							</Button>
						</Group>
						<Stack gap="xs">
							<Text>{subscriptionData.subscription.subscriptionPlan.name}</Text>
							<Text>${subscriptionData.subscription.subscriptionPlan.monthlyPrice} / month</Text>
							{subscriptionData.subscription.nextPaymentDate ? (
								<Text>
									Next payment: {formatDateAsMonthDayAndYear(new Date(subscriptionData.subscription.nextPaymentDate))}
								</Text>
							) : (
								<Text>
									Expiring on: {formatDateAsMonthDayAndYear(new Date(subscriptionData.subscription.endDate!))}
								</Text>
							)}
						</Stack>
					</Stack>
					<Stack gap="xs">
						<Title order={4}>Balance</Title>
						<Text>{formatNumber(user.tokenBalance.amount || 0)} tokens</Text>
					</Stack>
					<Stack gap="xs">
						<Group justify="space-between">
							<Box>
								<Title order={4}>More tokens</Title>
								<Text c="dimmed" size="xs">
									Buy more tokens one time
								</Text>
							</Box>
							<Button loading={buyTokensLoading} disabled={!buyTokensSelection} onClick={handleBuyTokens}>
								Buy
							</Button>
						</Group>
						<Radio.Group value={buyTokensSelection} onChange={setBuyTokensSelection} mt="xs">
							<Stack gap="xs">
								<Radio value="" label={<Text>None</Text>} size="md" />
								{Object.entries(BuyTokenBuckets).map(([tokens, price]) => (
									<Radio
										key={tokens}
										value={tokens}
										label={
											<Group gap="xs">
												<Text>${price}</Text>
												<Text>{formatNumber(parseInt(tokens))} tokens</Text>
											</Group>
										}
										size="md"
									/>
								))}
							</Stack>
						</Radio.Group>
					</Stack>
					{!tokenAutoPayLoading && (
						<Stack gap="xs">
							<Group justify="space-between">
								<Box>
									<Title order={4}>Automatic tokens</Title>
									<Text c="dimmed" size="xs">
										Automatically add more tokens to your account when you run out
									</Text>
								</Box>
								<Button
									loading={updateTokenAutoPayLoading || deleteTokenAutoPayLoading}
									disabled={
										tokenAutoPaySelection === ''
											? !tokenAutoPayData?.tokenAutoPay
											: tokenAutoPaySelection === tokenAutoPayData?.tokenAutoPay?.amount
									}
									onClick={handleChangeTokenAutoPay}
								>
									Save
								</Button>
							</Group>
							<Radio.Group value={tokenAutoPaySelection} onChange={setTokenAutoPaySelection} mt="xs">
								<Stack gap="xs">
									<Radio value="" label="None" size="md" />
									{Object.entries(BuyTokenBuckets).map(([tokens, price]) => (
										<Radio
											key={tokens}
											value={tokens}
											label={
												<Group gap="xs">
													<Text>${price}</Text>
													<Text>{formatNumber(parseInt(tokens))} tokens</Text>
												</Group>
											}
											size="md"
										/>
									))}
								</Stack>
							</Radio.Group>
						</Stack>
					)}
					<Stack gap="xs">
						<Group
							justify="space-between"
							onClick={() => setIsTokenBalanceEventsOpen((prev) => !prev)}
							style={{ cursor: 'pointer' }}
						>
							<Title order={4}>Usage</Title>
							<ActionIcon variant="transparent" color="dark">
								{isTokenBalanceEventsOpen ? <CaretUp /> : <CaretDown />}
							</ActionIcon>
						</Group>
						{isTokenBalanceEventsOpen && (
							<Table.ScrollContainer minWidth={500}>
								<Table withRowBorders={false}>
									{tokenBalanceEventsLoading ? (
										<Loading size="md" />
									) : (
										<>
											<Table.Thead>
												<Table.Tr>
													<Table.Th>Date</Table.Th>
													<Table.Th>Amount</Table.Th>
													<Table.Th>Description</Table.Th>
												</Table.Tr>
											</Table.Thead>
											<Table.Tbody>
												{tokenBalanceEventsData?.tokenBalanceEvents.map((event) => (
													<Table.Tr key={event.id}>
														<Table.Td>{formatDateTimeAsMonthDayAndYear(new Date(event.createdAt))}</Table.Td>
														<Table.Td>{event.amount}</Table.Td>
														<Table.Td>{pascalCaseToSentence(event.type)}</Table.Td>
													</Table.Tr>
												))}
											</Table.Tbody>
										</>
									)}
								</Table>
							</Table.ScrollContainer>
						)}
					</Stack>
				</>
			) : (
				<Text>
					You do not have an active subscription at this moment. Go to our <Anchor href="/pricing">pricing</Anchor> page
					to subscribe now!
				</Text>
			)}
		</Stack>
	);
}

const buyTokensMutation = graphql(`
	mutation BuyTokens($input: BuyTokensInput!) {
		buyTokens(input: $input)
	}
`);

const getTokenAutoPayQuery = graphql(`
	query GetTokenAutoPay {
		tokenAutoPay {
			id
			amount
		}
	}
`);

const updateTokenAutoPayMutation = graphql(`
	mutation UpdateTokenAutoPay($input: UpdateTokenAutoPayInput!) {
		updateTokenAutoPay(input: $input) {
			id
		}
	}
`);

const deleteTokenAutoPayMutation = graphql(`
	mutation DeleteTokenAutoPay {
		deleteTokenAutoPay {
			id
		}
	}
`);

const getTokenBalanceEventsQuery = graphql(`
	query GetTokenBalanceEvents {
		tokenBalanceEvents {
			id
			type
			amount
			createdAt
		}
	}
`);
