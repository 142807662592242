import { MantineTheme } from '@mantine/core';
import { Appearance } from '@stripe/stripe-js';

export const headerHeight = '60px';

/**
 * TODO: Figure out how to pull some values from the Mantine CSS variables
 */
export const stripeAppearance = (theme: MantineTheme, colorScheme: string): Appearance => ({
	disableAnimations: true,
	theme: colorScheme == 'dark' ? 'night' : 'stripe',
	variables: {
		fontSizeBase: '0.9rem',
		colorPrimary: theme.colors.lumiBurntOrange[6],
		colorDanger: theme.colors.red[6],
		gridRowSpacing: '20px',
		...(colorScheme == 'dark'
			? { colorBackground: theme.colors.dark[6], tabIconColor: 'white', tabIconSelectedColor: 'whote' }
			: { colorBackground: 'white', tabIconColor: theme.colors.dark[6], tabIconSelectedColor: theme.colors.dark[6] }),
	},
	rules: {
		'.Label': {
			fontWeight: '500',
			color: colorScheme === 'light' ? 'black' : theme.colors.dark[0],
		},
		'.Input': {
			boxShadow: 'none',
			borderColor: colorScheme === 'light' ? theme.colors.gray[4] : theme.colors.dark[4],
			paddingTop: '8px',
			paddingBottom: '8px',
			borderRadius: '4px',
		},
		'.Input:focus': {
			boxShadow: 'none',
			borderColor: theme.colors.lumiBurntOrange[6],
		},
		'.Tab': {
			boxShadow: 'none',
			borderColor: colorScheme === 'light' ? theme.colors.gray[4] : theme.colors.dark[4],
			paddingTop: '8px',
			paddingBottom: '8px',
			borderRadius: '4px',
		},
		'.TabLabel': {
			fontWeight: '500',
			color: colorScheme === 'light' ? 'black' : theme.colors.dark[0],
		},
		'.Tab:focus': {
			// TODO: Not actually removing the boxShadow for some reasons
			boxShadow: 'none',
		},
		'.Tab--selected': {
			boxShadow: 'none',
		},
	},
});
