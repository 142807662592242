import { useLazyQuery } from '@apollo/client';
import { decode } from 'base64-arraybuffer';
import { Folder, graphql } from '@shared';

export default function useImageUploader() {
	const [getUploadUrls] = useLazyQuery(getUploadUrlsQuery);

	// Returns the cloudfront url after uploading
	async function uploadImage(file: File, folder: Folder): Promise<string>;
	async function uploadImage(httpUrl: string, folder: Folder): Promise<string>;
	async function uploadImage(dataUrl: string, folder: Folder): Promise<string>;
	async function uploadImage(input: File | string, folder: Folder): Promise<string> {
		let imageData: ArrayBuffer;
		if (input instanceof File) {
			imageData = await input.arrayBuffer();
		} else if (typeof input === 'string' && input.startsWith('data:image/')) {
			imageData = decode(input.replace(/^data:.+;base64,/, ''));
		} else if (typeof input === 'string' && input.startsWith('https')) {
			// If the input is already a URL, return it
			return input;
		} else {
			throw new Error('Invalid input type');
		}
		const uploadUrlsResponse = await getUploadUrls({
			variables: {
				input: {
					folder,
				},
			},
			fetchPolicy: 'no-cache',
		});
		if (!uploadUrlsResponse.data) {
			throw new Error('No file upload URL generated');
		}
		const { uploadUrl, cloudfrontUrl } = uploadUrlsResponse.data.fileUploadUrls;
		const uploadResponse = await fetch(uploadUrl, {
			method: 'PUT',
			body: imageData,
		});
		if (!uploadResponse.ok) {
			throw new Error('Failed to upload image');
		}
		return cloudfrontUrl;
	}

	return {
		uploadImage,
	};
}

const getUploadUrlsQuery = graphql(`
	query GetUploadUrls($input: FileUploadUrlsInput!) {
		fileUploadUrls(input: $input) {
			uploadUrl
			cloudfrontUrl
		}
	}
`);
