import { Box, Group, InputLabel, MantineStyleProps, ScrollArea } from '@mantine/core';
import { ArtStyle, artStyleImageUrls } from '@shared';
import RoundedImage from './ui/RoundedImage';

type ArtStyleSelectorProps = {
	selectedArtStyle: ArtStyle;
	handleSelectArtStyle: (artStyle: ArtStyle) => void;
	imageWidth?: MantineStyleProps['w'];
};

export function ArtStyleSelector({
	selectedArtStyle,
	handleSelectArtStyle,
	imageWidth = '100',
}: ArtStyleSelectorProps) {
	return (
		<Box>
			<InputLabel>Art Style</InputLabel>
			<ScrollArea scrollbars="x">
				{/* Note, adding a width to this Group allows it to take up the remaining width. Unknown why this works, but it does */}
				<Group wrap="nowrap">
					{Object.values(ArtStyle).map((artStyleKey) => (
						<RoundedImage
							w={imageWidth}
							aspectRatio={1}
							key={artStyleKey}
							label={artStyleKey}
							textProps={{ size: 'sm' }}
							url={artStyleImageUrls.get(artStyleKey)}
							isSelected={artStyleKey === selectedArtStyle}
							onClick={() => handleSelectArtStyle(artStyleKey)}
						/>
					))}
				</Group>
			</ScrollArea>
		</Box>
	);
}
