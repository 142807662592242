import { AspectRatio, Box, BoxProps, Image } from '@mantine/core';
import { ImageDimensions } from '@shared';

type PageCardProps = BoxProps & {
	// Remote or canvas image url
	imageUrl: string;
};
// In the future, we may have PanelCards which are arranged onto a single Page. For now 1 Panel = 1 Page, so use this component by default
export default function PageCard({ imageUrl, ...boxProps }: PageCardProps) {
	return (
		<Box {...boxProps}>
			<AspectRatio ratio={ImageDimensions.story.ratio}>
				<Image src={imageUrl} />
			</AspectRatio>
		</Box>
	);
}
