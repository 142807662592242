import { ResultOf, graphql } from '@shared';

export const createUploadedImageDraftMutation = graphql(`
	mutation createUploadedImageDraft($input: CreateUploadedImageDraftInput!) {
		createUploadedImageDraft(input: $input) {
			id
			kind
			status
			imageUrl
		}
	}
`);

export const getImageDraftsQuery = graphql(`
	query GetImageDrafts($sourceDraftId: ID!, $pagination: PaginationInput) {
		imageDrafts(sourceDraftId: $sourceDraftId, pagination: $pagination) {
			id
			status
			kind
			imageUrl
		}
	}
`);

export type GetUserContextReturn = ResultOf<typeof getUserContextQuery>['authUser'];
export const getUserContextQuery = graphql(`
	query GetUserContext {
		authUser {
			id
			email
			username
			firstName
			lastName
			displayName
			profilePictureImageUrl
			bio
			isBlocklisted
			role
			currentSubscription {
				id
				subscriptionPlanId
			}
			tokenBalance {
				amount
			}
			shop {
				id
			}
		}
	}
`);

export type GetStoriesReturn = ResultOf<typeof getStoriesQuery>['stories'];
export const getStoriesQuery = graphql(`
	query GetStories($filter: StoriesFilterInput, $pagination: PaginationInput) {
		stories(filter: $filter, pagination: $pagination) {
			id
			title
			coverImageUrl
			isFavorited
			createdAt
			creator {
				id
				username
				displayName
				profilePictureImageUrl
			}
		}
	}
`);

export type GetSubscriptionPlansReturn = ResultOf<typeof getSubscriptionPlansQuery>['subscriptionPlans'];
export const getSubscriptionPlansQuery = graphql(`
	query GetSubscriptionPlans {
		subscriptionPlans {
			id
			name
			description
			bullets
			monthlyPrice
			yearlyPrice
		}
	}
`);

export const getSubscriptionQuery = graphql(`
	query GetSubscription {
		subscription {
			id
			status
			period
			nextPaymentDate
			endDate
			createdAt
			paymentMethod {
				id
				brand
				lastFourNumbers
			}
			subscriptionPlan {
				id
				name
				monthlyPrice
				yearlyPrice
			}
		}
	}
`);

export const getPaymentMethodsQuery = graphql(`
	query GetPaymentMethods {
		paymentMethods {
			id
			brand
			lastFourNumbers
		}
	}
`);

export type GetMyShopItemsReturn = ResultOf<typeof getMyShopItemsQuery>['items'];
export const getMyShopItemsQuery = graphql(`
	query GetMyShopItems($shopId: ID!) {
		items(shopId: $shopId) {
			id
			name
			itemVariants {
				id
				price
				itemVariantImages {
					id
					imageUrl
				}
			}
		}
	}
`);
