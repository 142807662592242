import { AspectRatio, Box, Card, CardProps, Group, Image, Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { ImageDimensions, Story, User } from '@shared';
import useSession from '../hooks/useSession';
import FavoriteButton from './ui/FavoriteButton';
import ProfilePicture from './ui/ProfilePicture';

export type StoryCardProps = CardProps & {
	story: {
		id: Story['id'];
		title: Story['title'];
		// Can be an empty string
		summary?: Story['summary'];
		// Can be an empty string
		coverImageUrl?: Story['coverImageUrl'];
		// Can be an empty string
	};
	isDraft?: boolean;
	isUpcomingStory?: boolean;
	creator?: Pick<User, 'username' | 'displayName' | 'profilePictureImageUrl'>;
	// Combined field because you cannot have `favoriteStory` without `isFavorited`
	favoriting?: {
		isFavorited: boolean;
		favoriteStory: (storyId: string, isFavorited: boolean) => void;
	};
};

export default function StoryCard({
	story: { id, title, coverImageUrl, summary },
	isUpcomingStory,
	isDraft,
	creator,
	favoriting,
	...props
}: StoryCardProps) {
	const navigate = useNavigate();
	const { isAuthenticated } = useSession();
	const storyLink = isUpcomingStory ? undefined : isDraft ? `/new-story/${id}` : `/stories/${id}`;
	const isFavoritable = !!favoriting && !isDraft && !isUpcomingStory;

	return (
		<Card {...props} padding={0} withBorder={false} bg="transparent">
			<Stack gap="4">
				<Card.Section
					onClick={() => (storyLink ? navigate(storyLink) : undefined)}
					style={{
						cursor: storyLink ? 'pointer' : 'default',
					}}
				>
					<AspectRatio bg="softGray" ratio={ImageDimensions.story.ratio}>
						{coverImageUrl && <Image src={coverImageUrl} />}
						{!coverImageUrl && <Box />}
					</AspectRatio>
				</Card.Section>
				<Card.Section>
					<Group justify="space-between" align="center">
						<Text size="sm" fw="bold" flex={1} lineClamp={1}>
							{title || 'Untitled'}
						</Text>
						{isFavoritable && (
							<Box
								onClick={() =>
									isAuthenticated ? favoriting.favoriteStory(id, !!favoriting.isFavorited) : navigate('/sign-up')
								}
							>
								<FavoriteButton size="md" py="0" isFavorited={!!favoriting.isFavorited} />
							</Box>
						)}
					</Group>
				</Card.Section>
				{creator && (
					<Card.Section>
						<Group gap="xs">
							<ProfilePicture user={creator} size="sm" />
							<Text size="sm" flex={1} lineClamp={1}>
								{creator.displayName}
							</Text>
						</Group>
					</Card.Section>
				)}
				{summary && (
					<Card.Section>
						<Text size="sm" lineClamp={4}>
							{summary}
						</Text>
					</Card.Section>
				)}
			</Stack>
		</Card>
	);
}
