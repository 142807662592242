import { Button, Group, Title } from '@mantine/core';
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';
import ShowTutorialButton from '../../components/ui/ShowTutorialButton';
import { StepIndex } from './step';
import NewStoryStepper from './stepper';

type NewStoryPageHeaderProps = {
	activeStep: number;
	isLoading: boolean;
	isNextButtonDisabled: boolean;
	handlePreviousClicked: () => void;
	handleNextClicked: () => void;
	setActiveStep: React.Dispatch<React.SetStateAction<number>>;
	handleShowTutorial?: (value: true) => void;
};

export function NewStoryPageHeader({
	activeStep,
	isLoading,
	isNextButtonDisabled,
	handlePreviousClicked,
	handleNextClicked,
	setActiveStep,
	handleShowTutorial,
}: NewStoryPageHeaderProps) {
	const isPreviewStep = activeStep === StepIndex.Preview;

	return (
		<Group gap="0" my="md" justify="space-between">
			<Group gap="xs">
				<Title order={3}>New Story</Title>
				<NewStoryStepper activeStep={activeStep} setActiveStep={setActiveStep} />
			</Group>
			<Group gap="xs">
				{handleShowTutorial && <ShowTutorialButton setShouldShowTutorial={handleShowTutorial} />}
				<Button
					variant="default"
					leftSection={<ArrowLeft />}
					disabled={activeStep === 0 || isLoading}
					onClick={() => handlePreviousClicked()}
					size={'xs'}
				>
					Previous
				</Button>
				<Button
					id="new-story-header-next-button"
					rightSection={!isPreviewStep && <ArrowRight />}
					loading={isLoading}
					disabled={isNextButtonDisabled}
					onClick={() => handleNextClicked()}
					size={'xs'}
				>
					{isPreviewStep ? 'Publish' : 'Next'}
				</Button>
			</Group>
		</Group>
	);
}
