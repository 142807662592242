import { Button } from '@mantine/core';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import PageHeader from '../../components/ui/PageHeader';
import ViewContainer from '../../components/ui/ViewContainer';
import { TabValue } from './tabs';

export type OutletContext = {
	handleSetActiveTab: (tab: TabValue) => void;
};

export default function MyCharacters() {
	const navigate = useNavigate();
	function handleSetActiveTab(tab: TabValue) {
		switch (tab) {
			case TabValue.Drafts:
				navigate('/my-characters/drafts');
				break;
			case TabValue.Published:
				navigate('/my-characters');
				break;
		}
	}

	return (
		<ViewContainer width="compact">
			<PageHeader
				text="My Characters"
				button={
					<Button component={Link} to="/new-story">
						New Story
					</Button>
				}
			/>
			<Outlet context={{ handleSetActiveTab }} />
		</ViewContainer>
	);
}
