export type Step = 'Plan' | 'Idea' | 'Script' | 'Pages' | 'Preview';
export const steps: Step[] = ['Plan', 'Idea', 'Script', 'Pages', 'Preview'];

export enum StepIndex {
	Plan = 0,
	Idea = 1,
	Script = 2,
	Pages = 3,
	Preview = 4,
}
