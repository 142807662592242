import { Stack, Title } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { Step } from 'intro.js-react';
import { useEffect, useState } from 'react';
import { CompanionMessage, CompanionMessageSchema, Endpoints, ResponseBody, TutorialType } from '@shared';
import CompanionChat, { GenericStream } from '../../components/CompanionChat';
import Tutorial from '../../components/Tutorial';
import RoundedImage from '../../components/ui/RoundedImage';
import Sidebar from '../../components/ui/Sidebar';
import TextEditor from '../../components/ui/TextEditor';
import { fillHeight } from '../../css/utils.module.css';
import { useStream } from '../../hooks/useStream';
import useTutorials from '../../hooks/useTutorials';
import { showCustomErrorNotification } from '../../utils/error';
import { sendRequest } from '../../utils/request';
import { NewStoryPageHeader } from './pageHeader';
import { StepComponentProps } from './stepComponent';

const endpoint = Endpoints.sendMessageToScriptCompanion;

type ScriptProps = StepComponentProps;

export default function Script({ storyDraft, handleUpdateStoryDraft, activeStep, setActiveStep }: ScriptProps) {
	const companionForm = useForm({
		validate: zodResolver(CompanionMessageSchema.pick({ text: true })),
		initialValues: { text: '' },
	});
	const { shouldShowTutorial, setShouldShowTutorial, acknowledgeTutorial } = useTutorials({
		tutorialType: TutorialType.NewStoryScript,
	});
	const [script, setScript] = useState(storyDraft.script);
	const stream = useStream({ endpoint, handleCompleteStream });
	const [isGeneratingPanels, setIsGeneratingPanels] = useState(false);
	const [companionHistory, setCompanionHistory] = useState<CompanionMessage[]>([
		{
			role: 'assistant',
			text: 'Here are the script for your story. Would you like help with any edits or ideas?',
		},
	]);

	// Keep parent StoryDraft in sync with changes on this page
	useEffect(() => {
		handleUpdateStoryDraft({ script });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [script]);

	function handleCompleteStream(data: ResponseBody<typeof endpoint>) {
		if (data.script) {
			setScript(data.script);
		}
		const inboundMessage: CompanionMessage = {
			role: 'assistant',
			text: data.message,
			rawMessage: JSON.stringify(data),
		};
		setCompanionHistory((prev) => [...prev, inboundMessage]);
	}

	async function handleSendMessage() {
		const { text } = companionForm.values;
		const outboundMessage: CompanionMessage = {
			role: 'user',
			text,
		};
		companionForm.reset();
		setCompanionHistory((prev) => [...prev, outboundMessage]);
		stream.start({
			message: text,
			script,
			characterIds: storyDraft.characters.map((c) => c.id),
			storyDraftId: storyDraft.id,
			history: companionHistory.slice(-10),
			settings: storyDraft.settings,
		});
	}

	function handlePreviousClicked() {
		setActiveStep(activeStep - 1);
	}
	async function handleNextClicked() {
		// Regex to match each page along with its content
		// - Page \d+\n: Matches "Page" followed by one or more digits and a newline.
		// - [\s\S]*?: Matches any character (including newlines) non-greedily.
		// - (?=(Page \d+|$)): Positive lookahead to ensure the match is followed by either "Page" and digits or end of string.
		const pageRegex = /(Page \d+\n[\s\S]*?)(?=(Page \d+|$))/g;
		const scriptPages: string[] = Array.from(script.matchAll(pageRegex), (m) => m[1]);

		if (!scriptPages.length) {
			showCustomErrorNotification({
				message: 'No pages found. Every page must start with "Page #" on a new line',
			});
			return;
		}

		if (scriptPages.length !== storyDraft.settings.numberOfPages) {
			showCustomErrorNotification({
				message: `We found ${scriptPages.length} pages in your script, but you set your story Plan to ${storyDraft.settings.numberOfPages} pages. Update your story plan or the script so they match.`,
			});
			return;
		}

		const missingPages: number[] = [];
		for (let i = 1; i <= storyDraft.settings.numberOfPages; i++) {
			const regex = new RegExp(`Page ${i}`);
			if (!regex.test(script)) {
				missingPages.push(i);
			}
		}

		if (missingPages.length > 0) {
			showCustomErrorNotification({
				message: `You are missing the following page(s) in your script: ${missingPages.join(', ')}.`,
			});
			return;
		}

		setIsGeneratingPanels(true);
		const response = await sendRequest(Endpoints.generatePanels, {
			script,
			characterIds: storyDraft.characters.map((c) => c.id),
			storyDraftId: storyDraft.id,
			settings: storyDraft.settings,
		});
		handleUpdateStoryDraft(response.data);
		setActiveStep(activeStep + 1);
		// TODO: Update token cache
	}

	return (
		<>
			<NewStoryPageHeader
				activeStep={activeStep}
				isLoading={stream.isActive || isGeneratingPanels}
				isNextButtonDisabled={false}
				handlePreviousClicked={handlePreviousClicked}
				handleNextClicked={handleNextClicked}
				setActiveStep={setActiveStep}
				handleShowTutorial={setShouldShowTutorial}
			/>
			<Tutorial
				steps={tutorialSteps}
				shouldShowTutorial={shouldShowTutorial}
				handleExitTutorial={acknowledgeTutorial}
			/>
			<Sidebar.Container pb="xs">
				<Sidebar.Side w="lg">
					<Stack>
						<Title order={6}>Companion</Title>
						<CompanionChat
							form={companionForm}
							stream={stream as GenericStream}
							companionHistory={companionHistory}
							handleSendMessage={handleSendMessage}
						/>
					</Stack>
				</Sidebar.Side>
				<Sidebar.Main className={fillHeight}>
					<Stack>
						<Title order={6}>Script</Title>
						<TextEditor
							id="idea-input"
							placeholder="Your script"
							text={stream.activeStreamData?.script ?? script}
							handleChange={setScript}
						/>
					</Stack>
				</Sidebar.Main>
				<Sidebar.Side w="sm">
					<Stack id="characters-sidebar">
						<Title order={6}>Characters</Title>
						<Stack className={fillHeight}>
							{storyDraft.characters.map((character) => (
								<RoundedImage
									key={character.id}
									label={character.name}
									url={character.imageUrl}
									textProps={{
										size: 'sm',
										truncate: true,
									}}
								/>
							))}
						</Stack>
					</Stack>
				</Sidebar.Side>
			</Sidebar.Container>
		</>
	);
}

const tutorialSteps: Step[] = [
	{
		element: '#characters-sidebar',
		title: 'Characters',
		intro: 'The characters in this story are always here for your reference.',
	},
	{
		element: '#companion-message-form',
		title: 'Companion',
		intro: 'Your companion is here to help you create and edit your script. Start typing or use the mic to chat to it.',
	},
	{
		element: '#scene-0-text',
		title: 'Script',
		intro: 'You can also make edits directly in the text box.',
	},
	{
		element: '#scene-0-panels',
		title: 'Pages',
		intro: 'You can adjust the number of pages for each scene.',
	},
	{
		element: '#new-story-header-next-button',
		title: 'Next',
		intro: 'Once you are satisfied with your script, click Next to continue.',
	},
];
