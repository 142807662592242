import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useEffect } from 'react';
import { maskInSessionReplayClass } from '../utils/posthog';

type StripePaymentCollectionFormProps = {
	handleSetStripe: (stripe: Stripe) => void;
	handleSetElements: (elements: StripeElements) => void;
	handleSetIsFormValid: (isFormValid: boolean) => void;
};

export default function StripePaymentCollectionForm({
	handleSetStripe,
	handleSetElements,
	handleSetIsFormValid,
}: StripePaymentCollectionFormProps) {
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		if (stripe) {
			handleSetStripe(stripe);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stripe]);

	useEffect(() => {
		if (elements) {
			handleSetElements(elements);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [elements]);

	return <PaymentElement className={maskInSessionReplayClass} onChange={(e) => handleSetIsFormValid(e.complete)} />;
}
