import { Box, Group, Stack, Textarea } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useScrollIntoView } from '@mantine/hooks';
import { useEffect } from 'react';
import { CompanionMessage } from '@shared';
import { fillHeight, fillHeightTarget } from '../css/utils.module.css';
import { UseStreamReturn } from '../hooks/useStream';
import ChatMessage from './ChatMessage';
import Form from './Form';
import SendMessageButton from './ui/SendMessageButton';
import SpeechToTextButton from './ui/SpeechToTextButton';

export type GenericStream = UseStreamReturn<{ message: string } & object, { message: string }>;

type CompanionChatProps = {
	form: UseFormReturnType<{ text: string }, (values: { text: string }) => { text: string }>;
	stream: GenericStream;
	companionHistory: CompanionMessage[];
	handleSendMessage: () => void;
	h?: number | string;
};

export default function CompanionChat({ form, stream, companionHistory, handleSendMessage }: CompanionChatProps) {
	const {
		scrollIntoView,
		targetRef: mostRecentChatRef,
		scrollableRef: companionScrollRef,
	} = useScrollIntoView<HTMLDivElement>();

	// Scroll to bottom when a new message is streaming or added
	useEffect(() => {
		scrollIntoView();
	}, [stream.activeStreamData, scrollIntoView]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.metaKey && !event.altKey) {
			event.preventDefault();
			handleSend();
		}
	};

	const handleSend = () => {
		if (form.values.text.trim() !== '') {
			handleSendMessage();
		}
	};

	return (
		<Stack gap="xs" className={fillHeight}>
			<Stack gap="xs" className={fillHeight} ref={companionScrollRef}>
				{companionHistory.map((message, i) => (
					<ChatMessage key={i} message={message} />
				))}
				{stream.activeStreamData && (
					<ChatMessage key="active" message={{ role: 'assistant', text: stream.activeStreamData.message }} />
				)}
				<Box ref={mostRecentChatRef} />
			</Stack>
			<Form onSubmit={form.onSubmit(handleSend)} id="companion-message-form">
				<Group gap={2} preventGrowOverflow={false}>
					<Textarea
						placeholder="Message"
						autosize
						maxRows={4}
						size="xs"
						className={fillHeightTarget}
						{...form.getInputProps('text')}
						onKeyDown={handleKeyDown}
					/>
					<SendMessageButton loading={stream.isActive} />
					<SpeechToTextButton
						disabled={stream.isActive}
						onSpeechConverted={(transcript) => form.setFieldValue('text', transcript)}
					/>
				</Group>
			</Form>
		</Stack>
	);
}
