// key is the amount of tokens you get, value is the price
export const BuyTokenBuckets = {
	350: 10,
	750: 18,
	1750: 25,
} as const;

export const TokenCosts = {
	// For now, assume regeneration costs the same as generation
	GenerateCharacterImage: 10,
	GenerateCoverImage: 10,
	GenerateSceneImage: 10,
	UpscaleImage: 20,
	CharacterCompanion: 2,
	IdeaCompanion: 2,
	ScriptCompanion: 2,
	PanelCompanion: 2,
	GenerateScript: 5,
	GeneratePanels: 5,
} as const;
