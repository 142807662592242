import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Button, Group, Image, InputLabel, Slider, Stack, Switch, Text, Textarea } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
	ClientImageDraft,
	ImageKind,
	ImageStatus,
	Panel,
	PanelSchema,
	StoryDraft,
	UserFriendlyErrors,
	defaultPagination,
	graphql,
	zod,
} from '@shared';
import Form from '../../../components/Form';
import ImageLoadingPlaceholder from '../../../components/ui/ImageLoading';
import RoundedImage from '../../../components/ui/RoundedImage';
import Sidebar from '../../../components/ui/Sidebar';
import { fillHeight } from '../../../css/utils.module.css';
import { getImageDraftsQuery } from '../../../graphql/common';
import { useInterval } from '../../../hooks/useInterval';
import { clearEntitiesFromCache } from '../../../utils/cache';
import { handleUnexpectedError, showCustomErrorNotification } from '../../../utils/error';
import { CharactersGrid } from '../charactersGrid';

const ImageDraftUpdateFrequencyInMS = 2 * 1000;

const imageGenerationFormSchema = PanelSchema.pick({
	description: true,
	setting: true,
}).extend({
	// The instructions from the user for image generation guidance
	instructions: zod.string().optional(),
	numberOfImages: zod.number().int().min(1).max(4),
	shouldUseOriginalImage: zod.boolean(),
});
type ImageGenerationForm = zod.infer<typeof imageGenerationFormSchema>;

export type PanelImageEditorProps = {
	panel: Panel;
	characters?: StoryDraft['characters'];
	storyDraft: StoryDraft;
	setStoryDraft: React.Dispatch<React.SetStateAction<StoryDraft>>;
};

export function PanelImageEditor({ panel, characters, storyDraft, setStoryDraft }: PanelImageEditorProps) {
	const { selectedImageDraft: selectedImage } = panel;
	const imageGenerationForm = useForm<ImageGenerationForm>({
		validate: zodResolver(imageGenerationFormSchema),
		initialValues: {
			description: panel.description,
			setting: panel.setting,
			instructions: '',
			numberOfImages: 2,
			shouldUseOriginalImage: false,
		},
	});
	const [images, setImages] = useState<ClientImageDraft[]>([]);
	const [hasMoreImages, setHasMoreImages] = useState(true);
	const { loading: getInitialImageDraftsLoading } = useQuery(getImageDraftsQuery, {
		variables: {
			sourceDraftId: panel.id,
		},
		onCompleted: (data) => {
			if (data.imageDrafts.length > 0) {
				const imageDrafts = data.imageDrafts;
				setImages(imageDrafts);
			}
			if (data.imageDrafts.length < defaultPagination.limit) {
				setHasMoreImages(false);
			}
		},
		onError: handleUnexpectedError,
	});
	const [getMoreImages] = useLazyQuery(getImageDraftsQuery, {
		variables: {
			sourceDraftId: panel.id,
			pagination: { offset: images.length, limit: defaultPagination.limit },
		},
		onCompleted: (data) => {
			if (data.imageDrafts.length > 0) {
				const newImageDrafts = data.imageDrafts;
				setImages((prev) => [...prev, ...newImageDrafts]);
			}
			if (data.imageDrafts.length < defaultPagination.limit) {
				setHasMoreImages(false);
			}
		},
		onError: handleUnexpectedError,
	});

	async function handleGetMoreImages() {
		if (getInitialImageDraftsLoading) {
			return;
		}
		await getMoreImages();
	}

	// Select the first image once images begin generating or are loaded
	useEffect(() => {
		if (images.length > 0 && !selectedImage) {
			handleImageSelection(images[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [images, selectedImage]);

	// Keep the parent storyDraft in sync with this child's form values
	useEffect(() => {
		const newValues: Partial<Panel> = {
			description: imageGenerationForm.values.description,
			setting: imageGenerationForm.values.setting,
		};
		setStoryDraft((prev) => ({
			...prev,
			panels: prev.panels.map((p) => ({
				...p,
				...(p.index === panel.index && p.pageIndex === panel.pageIndex ? newValues : {}),
			})),
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageGenerationForm.values]);

	useInterval(async () => {
		if (pendingImages.length) {
			await updatePendingImages();
		}
	}, ImageDraftUpdateFrequencyInMS);

	const [generatePanelImages, { loading: generatePanelImagesLoading }] = useMutation(generateSceneImagesMutation);
	const [regeneratePanelImages, { loading: regeneratePanelImagesLoading }] = useMutation(regenerateSceneImagesMutation);
	// const [upscaleImage, { loading: upscaleImageLoading }] = useMutation(upscaleImageMutation);
	const pendingImages = useMemo(() => images.filter((i) => i.status === ImageStatus.Pending), [images]);
	const [getPendingImages] = useLazyQuery(getImageDraftsQuery);

	function updatePendingImages() {
		getPendingImages({
			fetchPolicy: 'network-only',
			variables: {
				sourceDraftId: panel.id,
			},
			onCompleted(data) {
				if (data.imageDrafts.length > 0) {
					const completedImages = data.imageDrafts
						.filter((image) => image.status === ImageStatus.Completed && image.imageUrl)
						.map((image) => ({
							...image,
							imageUrl: image.imageUrl,
							status: image.status,
						}));
					setImages((prev) => [
						...prev.map((image) => {
							const updatedImage = completedImages.find((i) => i.id === image.id);
							if (updatedImage) {
								return {
									...image,
									imageUrl: updatedImage.imageUrl,
									status: updatedImage.status,
								};
							}
							return { ...image };
						}),
					]);
				}
			},
			onError: handleUnexpectedError,
		});
	}

	async function handleGeneratePanelImages(values: ImageGenerationForm) {
		const { shouldUseOriginalImage, ...formValues } = values;
		const input = {
			...formValues,
			panelDraftId: panel.id,
			storyDraftId: storyDraft.id,
			characterIds: characters?.map((c) => c.id),
			artStyle: storyDraft.settings.artStyle,
		};
		if (shouldUseOriginalImage) {
			if (!selectedImage) {
				return showCustomErrorNotification({
					title: 'No Image To Modify',
					message: 'You must upload or generate an image first',
				});
			} else if (selectedImage.kind !== ImageKind.GeneratedImage) {
				return showCustomErrorNotification({
					message: UserFriendlyErrors.NoRegeneratingUploadedOrEditedImages,
				});
			}
			return await regeneratePanelImages({
				variables: {
					input: {
						...input,
						shouldUseOriginalImage,
						originalImageDraftId: selectedImage.id,
					},
				},
				onCompleted(data) {
					const newImages = data.regenerateSceneImages;
					setImages((prev) => [...newImages, ...prev]);
				},
				update(cache) {
					clearEntitiesFromCache(cache, ['imageDrafts']);
				},
				onError: handleUnexpectedError,
			});
		} else {
			return await generatePanelImages({
				variables: {
					input,
				},
				onCompleted(data) {
					const newImages = data.generateSceneImages;
					setImages((prev) => [...newImages, ...prev]);
				},
				update(cache) {
					clearEntitiesFromCache(cache, ['imageDrafts']);
				},
				onError: handleUnexpectedError,
			});
		}
	}

	// async function handleUpscaleClicked() {
	// 	if (!selectedImage) {
	// 		showCustomErrorNotification({
	// 			title: 'No Image Selected',
	// 			message: 'You must upload or generate an image first',
	// 		});
	// 		return;
	// 	}
	// 	// TODO: Allow upscaling uploaded images
	// 	if (selectedImage.kind === ImageKind.UploadedImage) {
	// 		showCustomErrorNotification({
	// 			title: 'Upscale Unavailable',
	// 			message: 'You may only upscale images that were generated',
	// 		});
	// 		return;
	// 	}

	// 	await upscaleImage({
	// 		variables: {
	// 			input: {
	// 				imageDraftId: selectedImage.id,
	// 				panelDraftId: panel.id,
	// 				// For when we implement upscaling of uploaded images
	// 				// generatedLeonardoId: selectedImage.kind !== ImageKind.UploadedImage ? selectedImage.leonardoId : undefined,
	// 				// uploadedLeonardoId: selectedImage.kind === ImageKind.UploadedImage ? selectedImage.leonardoId : undefined,
	// 			},
	// 		},
	// 		onCompleted(data) {
	// 			const newImage = data.upscaleImage;
	// 			setImages((prev) => [newImage, ...prev]);
	// 		},
	// 		update(cache) {
	// 			clearEntitiesFromCache(cache, ['imageDrafts']);
	// 		},
	// 		onError: handleUnexpectedError,
	// 	});
	// }

	function handleImageSelection(newSelectedImage: ClientImageDraft) {
		setStoryDraft((prev) => ({
			...prev,
			panels: prev.panels.map((p) => ({
				...p,
				selectedImageDraft:
					p.index === panel.index && p.pageIndex === panel.pageIndex ? newSelectedImage : p.selectedImageDraft,
			})),
		}));
	}

	return (
		<Sidebar.Container>
			<Sidebar.Side>
				<Form onSubmit={imageGenerationForm.onSubmit(handleGeneratePanelImages)}>
					<Stack gap="xs">
						<Box>
							<InputLabel size="xs">Characters</InputLabel>
							{characters ? (
								<CharactersGrid characters={characters} cols={4} />
							) : (
								<Text size="xs" fs="italic">
									No characters in this panel
								</Text>
							)}
						</Box>
						<Textarea
							label="Setting"
							variant="unstyled"
							size="xs"
							maxRows={4}
							{...imageGenerationForm.getInputProps('setting')}
						/>
						<Textarea
							label="Description"
							variant="unstyled"
							size="xs"
							maxRows={4}
							{...imageGenerationForm.getInputProps('description')}
						/>
						<Textarea
							label="Image instructions"
							size="xs"
							variant="unstyled"
							placeholder='e.g. "Add a sunset in the background"'
							description="Optional"
							maxRows={4}
							{...imageGenerationForm.getInputProps('instructions')}
						/>
						<Group preventGrowOverflow={false}>
							<InputLabel size="xs">Number of images</InputLabel>
							<Slider flex={1} min={1} max={4} {...imageGenerationForm.getInputProps('numberOfImages')} />
						</Group>
						<Group justify="space-between" preventGrowOverflow={false}>
							<InputLabel size="xs">Modify selected image</InputLabel>
							<Switch
								size="xs"
								disabled={!selectedImage}
								{...imageGenerationForm.getInputProps('shouldUseOriginalImage')}
							/>
						</Group>
						<Group grow>
							<Button
								type="submit"
								size="xs"
								mt="xs"
								loading={generatePanelImagesLoading || regeneratePanelImagesLoading}
							>
								Generate
							</Button>
							{/* <Button size="xs" mt="xs" loading={upscaleImageLoading} onClick={handleUpscaleClicked}>
									Upscale
								</Button> */}
						</Group>
					</Stack>
				</Form>
			</Sidebar.Side>
			<Sidebar.Main>
				<Box className={fillHeight} w="100%" mih="500">
					{selectedImage?.imageUrl && (
						<Image
							src={selectedImage!.imageUrl}
							className={fillHeight}
							style={{ objectFit: 'contain', objectPosition: 'top' }}
						/>
					)}
				</Box>
			</Sidebar.Main>
			<Sidebar.Side>
				<Box className={fillHeight}>
					{/* Generated images */}
					<InfiniteScroll
						dataLength={images.length}
						next={handleGetMoreImages}
						hasMore={hasMoreImages}
						loader={''}
						refreshFunction={handleGetMoreImages}
						className={fillHeight}
					>
						<Stack>
							{/* Add placeholders in while loading */}
							{(generatePanelImagesLoading || regeneratePanelImagesLoading) &&
								Array.from({ length: imageGenerationForm.values.numberOfImages }).map((_, i) => (
									<ImageLoadingPlaceholder key={`generate-${i}`} />
								))}
							{/* {upscaleImageLoading &&
												Array.from({ length: 1 }).map((_, i) => <ImageLoadingPlaceholder key={`upscale-${i}`} />)} */}
							{images.map((image) =>
								image.imageUrl ? (
									<RoundedImage
										key={image.id}
										// TODO: Change when codegen has `maybeValue: 'T | undefined'`
										url={image.imageUrl ?? undefined}
										isSelected={image.id === selectedImage?.id}
										onClick={() => handleImageSelection(image)}
									/>
								) : (
									<ImageLoadingPlaceholder key={image.id} />
								)
							)}
						</Stack>
					</InfiniteScroll>
				</Box>
			</Sidebar.Side>
		</Sidebar.Container>
	);
}

const generateSceneImagesMutation = graphql(`
	mutation generateSceneImages($input: GenerateSceneImagesInput!) {
		generateSceneImages(input: $input) {
			id
			status
			kind
			imageUrl
		}
	}
`);

const regenerateSceneImagesMutation = graphql(`
	mutation RegenerateSceneImages($input: RegenerateSceneImagesInput!) {
		regenerateSceneImages(input: $input) {
			id
			status
			kind
			imageUrl
		}
	}
`);

// const upscaleImageMutation = graphql(`
// 	mutation UpscaleImage($input: UpscaleImageInput!) {
// 		upscaleImage(input: $input) {
// 			id
// 			status
// 			kind
// 			imageUrl
// 		}
// 	}
// `);
