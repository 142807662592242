import { FormHTMLAttributes, PropsWithChildren } from 'react';

export default function Form({
	children,
	...props
}: PropsWithChildren<React.DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>>) {
	return (
		<form onSubmitCapture={(e) => e.preventDefault()} {...props}>
			{children}
		</form>
	);
}
