import * as Sentry from '@sentry/react';

export function initializeSentry() {
	if (import.meta.env.MODE === 'production') {
		Sentry.init({
			dsn: 'https://7020052ba98fd55d661d501c9b6f0b73@o4506657685045248.ingest.sentry.io/4506657691729920',
			integrations: [
				new Sentry.BrowserTracing({
					// Trace only requests going to our server
					tracePropagationTargets: [import.meta.env.VITE_GRAPHQL_API!],
				}),
				Sentry.replayIntegration(),
			],
			// Don't record sessions 0 since we do this in Posthog
			replaysSessionSampleRate: 0,
			// However, record sessions if there was an error for ease of debugging
			// Can turn off if we hit quota limits and Posthog is convenient enough
			replaysOnErrorSampleRate: 1,
			// Performance Monitoring
			// Capture 100% of transactions
			tracesSampleRate: 1,
			ignoreTransactions: ['app.posthog'],
		});
	}
}
