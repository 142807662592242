import { useMutation, useQuery } from '@apollo/client';
import { Button, Center, SegmentedControl, SimpleGrid, Stack, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubscriptionPeriod, UserRole, graphql } from '@shared';
import NoAccessPage from '../components/NoAccessPage';
import PaymentForm from '../components/PaymentForm';
import SubscriptionPlanPriceCard from '../components/SubscriptionPlanPriceCard';
import PageHeader from '../components/ui/PageHeader';
import ViewContainer from '../components/ui/ViewContainer';
import { GetSubscriptionPlansReturn, getSubscriptionPlansQuery } from '../graphql/common';
import useCreatePaymentMethod from '../hooks/useCreatePaymentMethod';
import useSession from '../hooks/useSession';
import { clearEntitiesFromCache, clearTokenBalanceFromCache } from '../utils/cache';
import { handleUnexpectedError } from '../utils/error';

export default function Pricing() {
	const navigate = useNavigate();
	const { user } = useSession();
	const [period, setPeriod] = useState<SubscriptionPeriod>(SubscriptionPeriod.Monthly);
	const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<GetSubscriptionPlansReturn[number]>();
	const { data: subscriptionPlansData } = useQuery(getSubscriptionPlansQuery);
	const { createPaymentMethod, loading: createPaymentMethodLoading } = useCreatePaymentMethod();
	const [createSubscription] = useMutation(createSubscriptionMutation);
	const [changeSubscriptionPlan, { loading: changeSubscriptionPlanLoading }] =
		useMutation(changeSubscriptionPlanMutation);

	async function handleCreateSubscription(stripe: Stripe, elements: StripeElements) {
		await createPaymentMethod({
			stripe,
			elements,
			async onCompleted(data) {
				await createSubscription({
					variables: {
						input: {
							subscriptionPlanId: selectedSubscriptionPlan!.id,
							paymentMethodId: data.createPaymentMethod.id,
							period,
						},
					},
					onError: handleUnexpectedError,
					update: (cache) => {
						clearEntitiesFromCache(cache, ['subscription', 'subscriptionEvents', 'paymentMethods']);
						clearTokenBalanceFromCache(cache);
					},
				});
			},
		});
	}

	async function handleChangeSubscriptionPlan(
		subscriptionPlan: GetSubscriptionPlansReturn[number],
		type: 'Upgrade' | 'Downgrade'
	) {
		return modals.openConfirmModal({
			title: `${type} Subscription`,
			children: (
				<Text>
					Are you sure you want to {type.toLowerCase()} your subscription?{' '}
					{type === 'Upgrade' &&
						`You will be charged $${period === SubscriptionPeriod.Monthly ? subscriptionPlan.monthlyPrice : subscriptionPlan.yearlyPrice} every ${period === SubscriptionPeriod.Monthly ? 'month' : 'year'} for your new subscription immediately`}
				</Text>
			),
			labels: { confirm: 'Yes', cancel: 'No' },
			confirmProps: {
				loading: changeSubscriptionPlanLoading,
			},
			cancelProps: {
				variant: 'default',
				disabled: changeSubscriptionPlanLoading,
			},
			centered: true,
			onConfirm: async () => {
				await changeSubscriptionPlan({
					variables: {
						input: {
							subscriptionPlanId: subscriptionPlan.id,
							period,
						},
					},
					onCompleted: () => {
						navigate('/settings/billing');
					},
					update: (cache) => {
						clearEntitiesFromCache(cache, ['subscription', 'subscriptionEvents']);
						clearTokenBalanceFromCache(cache);
					},
				});
			},
		});
	}

	if (user.role === UserRole.BetaTester) {
		return <NoAccessPage text="You currently have full access to the platform for free!" />;
	}

	return (
		<ViewContainer width="compact">
			<Stack>
				<PageHeader justify="center" text="A plan for every creator" />
				<Center>
					<SegmentedControl
						value={period}
						onChange={(e) => setPeriod(e as SubscriptionPeriod)}
						data={[SubscriptionPeriod.Monthly, SubscriptionPeriod.Yearly]}
					/>
				</Center>
				<SimpleGrid cols={{ base: 1, xs: 3 }} w="100%" mt="xs">
					{subscriptionPlansData?.subscriptionPlans.map((plan, i) => (
						<SubscriptionPlanPriceCard
							key={plan.id}
							plan={plan}
							selectedPeriod={period}
							isSelected={plan.id === selectedSubscriptionPlan?.id}
							button={(() => {
								const usersPlan = user.currentSubscription?.subscriptionPlanId
									? subscriptionPlansData.subscriptionPlans.find(
											(plan) => plan.id === user.currentSubscription?.subscriptionPlanId
										)
									: undefined;
								if (usersPlan) {
									const usersPlanIndex = subscriptionPlansData.subscriptionPlans.indexOf(usersPlan);
									const text = usersPlanIndex < i ? 'Upgrade' : 'Downgrade';
									if (usersPlanIndex !== i) {
										return (
											<Button
												loading={changeSubscriptionPlanLoading}
												onClick={async () => await handleChangeSubscriptionPlan(plan, text)}
											>
												{text}
											</Button>
										);
									} else {
										return <Button disabled>Current plan</Button>;
									}
								}
								if (!user.currentSubscription) {
									return <Button onClick={() => setSelectedSubscriptionPlan(plan)}>Select</Button>;
								}
							})()}
						/>
					))}
				</SimpleGrid>
				{!user.currentSubscription && (
					<PaymentForm
						shouldDisable={!selectedSubscriptionPlan}
						isLoading={createPaymentMethodLoading}
						handleSubmit={handleCreateSubscription}
					/>
				)}
			</Stack>
		</ViewContainer>
	);
}

const createSubscriptionMutation = graphql(`
	mutation CreateSubscription($input: CreateSubscriptionInput!) {
		createSubscription(input: $input) {
			id
		}
	}
`);

const changeSubscriptionPlanMutation = graphql(`
	mutation ChangeSubscriptionPlan($input: ChangeSubscriptionPlanInput!) {
		changeSubscriptionPlan(input: $input) {
			id
		}
	}
`);
