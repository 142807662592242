import { useLazyQuery, useMutation } from '@apollo/client';
import {
	Avatar,
	Box,
	Button,
	InputLabel,
	Stack,
	Switch,
	TextInput,
	Textarea,
	useMantineColorScheme,
} from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useEffect, useRef, useState } from 'react';
import { Folder, UpdateUserInput, UpdateUserInputSchema, graphql } from '@shared';
import Form from '../../components/Form';
import ProfilePicture from '../../components/ui/ProfilePicture';
import useImageUploader from '../../hooks/useImageUploader';
import useSession from '../../hooks/useSession';
import { clearEntitiesFromCache } from '../../utils/cache';
import { handleUnexpectedError } from '../../utils/error';

export default function Profile() {
	const openRef = useRef<() => void>(null);
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const { user } = useSession();
	const [isUsernameTaken, setIsUsernameTaken] = useState(false);
	const { uploadImage } = useImageUploader();
	const [checkIsUsernameTaken] = useLazyQuery(checkIsUsernameTakenQuery);
	const [updateUser, { loading: updateUserLoading }] = useMutation(updateUserMutation);

	const form = useForm<UpdateUserInput>({
		validate: zodResolver(UpdateUserInputSchema),
		validateInputOnBlur: true,
		initialValues: {
			username: user.username,
			firstName: user.firstName,
			lastName: user.lastName,
			displayName: user.displayName,
			profilePictureImageUrl: user.profilePictureImageUrl || undefined,
			bio: user.bio || undefined,
		},
	});

	// update form values when user changes
	useEffect(() => {
		form.setValues({
			username: user.username,
			firstName: user.firstName,
			lastName: user.lastName,
			displayName: user.displayName,
			profilePictureImageUrl: user.profilePictureImageUrl || undefined,
		});
		form.resetDirty();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	async function handleImageDrop(file: File) {
		const imageUrl = await uploadImage(file, Folder.ProfilePictureImages);
		form.setValues({
			profilePictureImageUrl: imageUrl,
		});
	}

	async function handleCheckUsername(username: string) {
		checkIsUsernameTaken({
			variables: {
				filter: {
					username,
				},
			},
			onCompleted: (data) => {
				setIsUsernameTaken(data.user.id !== user.id);
			},
		});
	}

	async function handleUpdateUser() {
		await updateUser({
			variables: {
				input: form.values,
			},
			onCompleted: () => {
				notifications.show({
					title: 'Profile updated',
					message: 'Your profile has been updated',
					color: 'teal',
				});
			},
			onError: (error) => {
				handleUnexpectedError(error);
			},
			update: (cache) => {
				clearEntitiesFromCache(cache, ['user']);
			},
		});
	}

	return (
		<Stack>
			<Form onSubmit={handleUpdateUser}>
				<Stack>
					<Avatar
						size={200}
						style={{
							cursor: 'pointer',
						}}
					>
						<Dropzone
							openRef={openRef}
							onDrop={async (files) => await handleImageDrop(files[0])}
							accept={['image/*']}
							maxFiles={1}
							maxSize={5 * 1024 * 1024}
						>
							<ProfilePicture
								user={{
									...user,
									profilePictureImageUrl: form.values.profilePictureImageUrl,
								}}
								size={200}
								isLink={false}
							/>
						</Dropzone>
					</Avatar>
					<TextInput label="Email" value={user.email} disabled />
					<TextInput
						label="Username"
						{...form.getInputProps('username')}
						error={isUsernameTaken ? 'Username is already taken' : undefined}
						onChange={async (e) => {
							form.setValues({
								username: e.target.value,
							});
							handleCheckUsername(e.target.value);
						}}
					/>
					<TextInput label="First Name" placeholder="Stanley" {...form.getInputProps('firstName')} />
					<TextInput label="Last Name" placeholder="Lieber" {...form.getInputProps('lastName')} />
					<TextInput label="Display Name" placeholder="Stan Lee" {...form.getInputProps('displayName')} />
					<Textarea
						label="Bio"
						placeholder="58 year old comic creator, born and raised in Manhattan "
						{...form.getInputProps('bio')}
					/>
					<Box>
						<InputLabel>Color scheme</InputLabel>
						<Switch
							checked={colorScheme === 'light' ? false : true}
							onLabel="Dark"
							offLabel="Light"
							size="lg"
							onChange={() => toggleColorScheme()}
						/>
					</Box>
					<Button
						type="submit"
						disabled={!form.isValid() || !form.isDirty() || isUsernameTaken}
						loading={updateUserLoading}
						mt="xs"
					>
						Save
					</Button>
				</Stack>
			</Form>
		</Stack>
	);
}

const checkIsUsernameTakenQuery = graphql(`
	query CheckIsUsernameTaken($filter: UserFilterInput!) {
		user(filter: $filter) {
			id
		}
	}
`);

const updateUserMutation = graphql(`
	mutation UpdateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			id
		}
	}
`);
