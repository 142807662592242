import { ActionIcon, Indicator } from '@mantine/core';
import { Coin } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { formatLargeNumberToBeReadable } from '@shared';
import useColors from '../hooks/useColors';
import useSession from '../hooks/useSession';

export default function TokenCount() {
	const { elementColor } = useColors();
	const { user } = useSession();

	return (
		<Indicator size={16} label={formatLargeNumberToBeReadable(user?.tokenBalance.amount || 0)}>
			<ActionIcon component={Link} to="/settings/tokens" variant="transparent">
				<Coin size={20} color={elementColor} />
			</ActionIcon>
		</Indicator>
	);
}
