import { useComputedColorScheme, useMantineTheme } from '@mantine/core';

type UseColorsReturn = {
	elementColor: string;
	backgroundColor: string;
};

export default function useColors(): UseColorsReturn {
	const theme = useMantineTheme();
	const computedColorScheme = useComputedColorScheme('light');
	return {
		elementColor: computedColorScheme === 'dark' ? theme.colors.gray[1] : theme.colors.gray[8],
		backgroundColor: computedColorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
	};
}
