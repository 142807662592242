import { useEffect, useRef } from 'react';

// type AnyFunction = (...args: any[]) => any;
type Callback = () => void;

// Calls the `callback` every `intervalInMS`
// Inspired by https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// Unable to use Mantine's implementation because it captures a snapshot of `callback` at instantiation, instead of a reference to the latest `callback`
export function useInterval(callback: Callback, intervalInMS: number) {
	const callbackRef = useRef<Callback>();

	// Ensure the latest callback is always stored
	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	// Execute the callback, update the interval if it changes, and cleanup the Effect
	useEffect(() => {
		function tick() {
			callbackRef.current?.();
		}
		const id = setInterval(tick, intervalInMS);
		return () => clearInterval(id);
	}, [intervalInMS]);
}
