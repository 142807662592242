import { ApolloError, BaseMutationOptions } from '@apollo/client';
import { notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import { UserFriendlyErrors, getUserFriendlyError } from '@shared';
import { isDevelopment } from './environment';

// Captures the exception in Sentry and shows the default error notification
export function handleUnexpectedError(error: ApolloError, clientOptions?: BaseMutationOptions): void;
export function handleUnexpectedError(error?: unknown): void;
export function handleUnexpectedError(error?: unknown | ApolloError): void {
	Sentry.captureException(error);
	showDefaultErrorNotification(error);
}

function showDefaultErrorNotification(error?: unknown): void {
	const title = 'Error';
	// Check if the message is user friendly, otherwise use a default. Unless we're in development, then always show the raw error
	let message: string = UserFriendlyErrors.UnexpectedError;
	if (error instanceof Error) {
		if (getUserFriendlyError(error.message) || isDevelopment()) {
			message = error.message;
		}
	}
	notifications.show({
		title,
		message,
		color: 'red',
		autoClose: false,
	});
}

export function showCustomErrorNotification({ title, message }: { title?: string; message?: string }): void {
	notifications.show({
		title: title ?? 'Error',
		message: message ?? UserFriendlyErrors.UnexpectedError,
		color: 'red',
		autoClose: false,
	});
}
