import { useQuery } from '@apollo/client';
import { Box, Group, Image, Stack, Text, Title } from '@mantine/core';
import { Info } from '@phosphor-icons/react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderStatus, graphql } from '@shared';
import Note from '../components/ui/Note';
import PageHeader from '../components/ui/PageHeader';
import ViewContainer from '../components/ui/ViewContainer';
import useSession from '../hooks/useSession';

export default function OrderConfirmation() {
	const orderNumber = useParams().orderNumber!;
	const navigate = useNavigate();
	const { user } = useSession();
	const { data } = useQuery(getOrderConfirmationQuery, {
		variables: {
			filter: {
				orderNumber,
			},
		},
		onError() {
			navigate('/');
		},
	});

	return (
		<ViewContainer>
			<PageHeader text="Order Confirmation" />
			{data?.order && (
				<Stack w={500}>
					<Box>
						<Title order={5}>Order Number</Title>
						<Text>{data.order.orderNumber}</Text>
					</Box>
					<Box>
						<Title order={5}>Total</Title>
						<Text>${data.order.total.toFixed(2)}</Text>
					</Box>
					<Box>
						<Title order={5}>Shipping Address</Title>
						<Text>{data.order.shippingAddress}</Text>
					</Box>
					{data.order.userId === user.id && data.order.status === OrderStatus.Pending && (
						<Note
							icon={<Info size={32} />}
							text="Your order is currently pending. Please check back later for your tracking information"
						/>
					)}
					<Title order={5}>Items</Title>
					{data.order.itemVariants.map((itemVariant) => (
						<Group key={itemVariant.id} align="start">
							<Image src={itemVariant.itemVariantImages[0].imageUrl} w={100} />
							<Stack gap={4}>
								<Title order={5} flex={1}>
									{itemVariant.item.name}
								</Title>
								<Text>{itemVariant.color}</Text>
								<Text>{itemVariant.size}</Text>
								<Text>${itemVariant.price.toFixed(2)}</Text>
							</Stack>
						</Group>
					))}
				</Stack>
			)}
		</ViewContainer>
	);
}

const getOrderConfirmationQuery = graphql(`
	query GetOrderConfirmation($filter: OrderFilterInput!) {
		order(filter: $filter) {
			id
			userId
			orderNumber
			status
			total
			shippingAddress
			itemVariants {
				id
				color
				size
				price
				item {
					id
					name
				}
				itemVariantImages {
					imageUrl
				}
			}
		}
	}
`);
