import { z } from 'zod';

const customErrorMap: z.ZodErrorMap = (issue, context) => {
	if (issue.code === z.ZodIssueCode.too_small) {
		const min = issue.minimum;
		if (min === 1) {
			return { message: `Required` };
		} else {
			return { message: `Must be at least ${min} characters` };
		}
	}
	if (issue.code === z.ZodIssueCode.too_big) {
		return { message: `Must be fewer than ${issue.maximum} characters` };
	}
	return { message: context.defaultError };
};

z.setErrorMap(customErrorMap);

export { z as zod };
export default z;
