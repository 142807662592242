import { ApolloCache } from '@apollo/client';
import { IQueryResolvers, IResolversParentTypes } from '@shared';

type CacheEntities = keyof IQueryResolvers<unknown, IResolversParentTypes['Query']>;

export function clearEntitiesFromCache(cache: ApolloCache<unknown>, entities: CacheEntities[]) {
	entities.forEach((entity) => {
		cache.evict({ fieldName: entity as string });
	});
	cache.gc();
}

export function clearTokenBalanceFromCache(cache: ApolloCache<unknown>) {
	// update `user` since that is the parent query which the session pulls tokens from
	clearEntitiesFromCache(cache, ['user', 'tokenBalance', 'tokenBalanceEvents']);
}
