import { Step, Steps } from 'intro.js-react';

type TutorialProps = {
	steps: Step[];
	shouldShowTutorial: boolean;
	handleExitTutorial: (value: false) => void;
};

export default function Tutorial({ steps, shouldShowTutorial, handleExitTutorial }: TutorialProps) {
	return (
		<Steps
			steps={steps}
			enabled={shouldShowTutorial}
			initialStep={0}
			onExit={() => handleExitTutorial(false)}
			options={{
				nextToDone: true,
				showBullets: false,
			}}
		/>
	);
}
