import { useQuery } from '@apollo/client';
import { Box, Group, InputLabel, SegmentedControl, SimpleGrid, Slider, Stack, Text } from '@mantine/core';
import { Step } from 'intro.js-react';
import { useMemo, useState } from 'react';
import { ArtStyle, StoryDraft, StoryImageType, StoryType, TutorialType, graphql } from '@shared';
import { ArtStyleSelector } from '../../components/ArtStyleSelector';
import NewCharacterCard from '../../components/NewCharacterCard';
import Tutorial from '../../components/Tutorial';
import RoundedImage from '../../components/ui/RoundedImage';
import useTutorials from '../../hooks/useTutorials';
import { NewStoryPageHeader } from './pageHeader';
import { StepComponentProps } from './stepComponent';

type PlanProps = StepComponentProps;

export default function Plan({ storyDraft, handleUpdateStoryDraft, activeStep, setActiveStep }: PlanProps) {
	const { shouldShowTutorial, setShouldShowTutorial, acknowledgeTutorial } = useTutorials({
		tutorialType: TutorialType.NewStoryPlan,
	});
	const { data, loading } = useQuery(getCharactersForNewStoryQuery);
	// Make a copy of the array because sorting the original array would mutate the state
	const characters = data?.characters ? [...data.characters] : [];
	const [selectedCharacters, setSelectedCharacters] = useState(storyDraft.characters);
	const [numberOfPages, setNumberOfPages] = useState(storyDraft.settings.numberOfPages);
	const [willUploadIllustrations, setWillUploadIllustrations] = useState(
		storyDraft.settings.imageType === StoryImageType.Uploaded
	);
	const [artStyle, setArtStyle] = useState<ArtStyle>(storyDraft.settings.artStyle);

	const tutorialSteps: Step[] = useMemo(
		() => [
			{
				element: '#form-step-1',
				title: 'Plan your story',
				intro: 'Set the basic details like number of pages and art style',
			},
			...(data && data?.characters.length > 0
				? [
						{
							element: '#character-0',
							title: 'Characters',
							intro: 'Select the characters you want to include in your story.',
						},
						{
							element: '#new-character-card',
							title: 'Create more characters',
							intro: 'Click here to create more characters for your story, if you need to.',
						},
					]
				: [
						{
							element: '#new-character-card',
							title: 'Your first character',
							intro: 'Click here to create for your story, if you need to.',
						},
					]),
			{
				element: '#new-story-header-next-button',
				title: 'Next',
				intro: 'When everything looks good, click Next to continue.',
			},
		],
		[data]
	);

	function handleSelectCharacter(character: StoryDraft['characters'][number]) {
		setSelectedCharacters((prev) =>
			prev.find(({ id }) => character.id === id) ? prev.filter((c) => c.id !== character.id) : [...prev, character]
		);
	}

	function handlePreviousClicked() {}
	function handleNextClicked() {
		handleUpdateStoryDraft({
			...storyDraft,
			characters: selectedCharacters,
			settings: {
				artStyle,
				numberOfPages,
				// For now, we only support comics
				storyType: StoryType.Comic,
				imageType: willUploadIllustrations ? StoryImageType.Uploaded : StoryImageType.Generated,
			},
		});
		setActiveStep(activeStep + 1);
	}

	return (
		<>
			<Tutorial
				steps={tutorialSteps}
				shouldShowTutorial={shouldShowTutorial}
				handleExitTutorial={acknowledgeTutorial}
			/>
			<NewStoryPageHeader
				activeStep={activeStep}
				isLoading={false}
				isNextButtonDisabled={loading || selectedCharacters.length === 0}
				handlePreviousClicked={handlePreviousClicked}
				handleNextClicked={handleNextClicked}
				setActiveStep={setActiveStep}
				handleShowTutorial={setShouldShowTutorial}
			/>
			<Stack my="xl">
				<Stack gap="2" align="flex-start" id="form-step-1">
					<InputLabel>Will you be uploading your own illustrations?</InputLabel>
					<SegmentedControl
						size="sm"
						value={willUploadIllustrations ? 'Yes' : 'No'}
						onChange={(value: string) => setWillUploadIllustrations(value === 'Yes')}
						data={['No', 'Yes']}
					/>
				</Stack>
				<Stack gap="2">
					<InputLabel>Pages</InputLabel>
					<Group>
						<Text size="sm" w="sm" ta="right">
							{numberOfPages}
						</Text>
						<Slider w="100" min={1} max={24} value={numberOfPages} onChange={setNumberOfPages} label={null} />
					</Group>
				</Stack>
				<ArtStyleSelector selectedArtStyle={artStyle} handleSelectArtStyle={setArtStyle} />
				<Box>
					<InputLabel>Characters</InputLabel>
					<SimpleGrid cols={{ base: 4, md: 6 }}>
						<NewCharacterCard callbackUrl={`/new-story/${storyDraft.id}`} />
						{characters.map((character, i) => (
							<RoundedImage
								id={`character-${i}`}
								key={character.id}
								label={character.name}
								textProps={{ size: 'sm' }}
								url={character.imageUrl}
								isSelected={!!selectedCharacters.find(({ id }) => character.id === id)}
								onClick={() => handleSelectCharacter(character)}
							/>
						))}
					</SimpleGrid>
				</Box>
			</Stack>
		</>
	);
}

const getCharactersForNewStoryQuery = graphql(`
	query GetCharactersForNewStory {
		characters {
			id
			name
			description
			imageUrl
			createdAt
		}
	}
`);
