import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';

const httpLink = new HttpLink({
	uri: import.meta.env.VITE_GRAPHQL_API,
	credentials: 'include',
});

export const apolloClient = new ApolloClient({
	link: from([httpLink, removeTypenameFromVariables()]),
	cache: new InMemoryCache(),
	connectToDevTools: false,
});
