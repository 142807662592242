import { useQuery } from '@apollo/client';
import { SimpleGrid } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { graphql } from '@shared';
import ItemCard from '../components/ItemCard';
import PageHeader from '../components/ui/PageHeader';
import ViewContainer from '../components/ui/ViewContainer';

export default function Shop() {
	const username = useParams().username!;
	const navigate = useNavigate();
	const { data } = useQuery(getShopQuery, {
		variables: {
			username,
		},
		onError() {
			navigate('/');
		},
	});

	return (
		<ViewContainer>
			<PageHeader text={data?.shop.id || ''} />
			<SimpleGrid cols={4}>
				{data?.shop.items.map(
					(item) =>
						'itemVariants' in item && (
							<ItemCard key={item.id} item={item} navigateToUrl={`/users/${username}/shop/${item.id}`} />
						)
				)}
			</SimpleGrid>
		</ViewContainer>
	);
}

const getShopQuery = graphql(`
	query getShop($username: String!) {
		shop(username: $username) {
			id
			items {
				id
				name
				... @defer {
					itemVariants {
						id
						price
						color
						size
						itemVariantImages {
							id
							imageUrl
						}
					}
				}
			}
		}
	}
`);
