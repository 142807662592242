import { SortDirection, SortInput } from '../types';

export const defaultSorting: SortInput[] = [
	{
		field: 'createdAt',
		// TODO: Figure out why `SortDirection.Descending` does not work
		direction: 'Descending' as SortDirection,
	},
];

export type SortSelection = 'Newest' | 'Oldest' | 'Name A-Z' | 'Name Z-A';
export function createSortInputs(sortSelection: SortSelection): SortInput[] {
	switch (sortSelection) {
		case 'Newest':
			return [{ field: 'createdAt', direction: SortDirection.Descending }];
		case 'Oldest':
			return [{ field: 'createdAt', direction: SortDirection.Ascending }];
		case 'Name A-Z':
			return [{ field: 'name', direction: SortDirection.Ascending }];
		case 'Name Z-A':
			return [{ field: 'name', direction: SortDirection.Descending }];
	}
}
