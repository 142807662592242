import { useMutation } from '@apollo/client';
import { Anchor, Button, Center, Divider, PasswordInput, Stack, Text, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { Link, useNavigate } from 'react-router-dom';
import { Endpoints, SignUpWithPasswordInput, SignUpWithPasswordInputSchema, graphql } from '@shared';
import Form from '../components/Form';
import PageHeader from '../components/ui/PageHeader';
import { SocialButton } from '../components/ui/SocialButton';
import ViewContainer from '../components/ui/ViewContainer';
import useSession from '../hooks/useSession';
import { clearEntitiesFromCache } from '../utils/cache';
import { handleUnexpectedError } from '../utils/error';
import { sendRequest } from '../utils/request';

export default function SignUp() {
	const navigate = useNavigate();
	const { refreshSession } = useSession();
	const [signUp, { loading }] = useMutation(signUpWithPasswordMutation);

	const form = useForm<SignUpWithPasswordInput>({
		validate: zodResolver(SignUpWithPasswordInputSchema),
		validateInputOnBlur: true,
		initialValues: {
			email: '',
			firstName: '',
			lastName: '',
			password: '',
		},
	});

	const handleSignUp = async () => {
		await signUp({
			variables: {
				input: form.values,
			},
			onCompleted: () => {
				navigate('/');
				notifications.show({
					title: 'Verify your email',
					message: 'Check your email to verify your account',
					color: 'teal',
				});
				// No clue why `clearEntitiesFromCache` doesn't work and we need this
				refreshSession();
			},
			onError: handleUnexpectedError,
			update: (cache) => {
				clearEntitiesFromCache(cache, ['user']);
			},
		});
	};

	async function handleSignUpWithGoogle() {
		const {
			data: { authorizationUrl },
		} = await sendRequest(Endpoints.signInWithGoogle);
		window.location.href = authorizationUrl;
	}

	return (
		<ViewContainer width="compact">
			<Form onSubmit={handleSignUp}>
				<Stack>
					<PageHeader text="Sign Up" />
					<SocialButton social="google" onClick={async () => await handleSignUpWithGoogle()}>
						Sign up with Google
					</SocialButton>
					<Divider label="or continue with email" />
					<TextInput label="Email" placeholder="stan-lee@marvel.com" {...form.getInputProps('email')} />
					<TextInput label="First Name" placeholder="Stan" {...form.getInputProps('firstName')} />
					<TextInput label="Last Name" placeholder="Lee" {...form.getInputProps('lastName')} />
					<PasswordInput label="Password" placeholder="************" {...form.getInputProps('password')} />
					<Text c="dimmed" size="xs">
						By signing up, you agree to our{' '}
						<Anchor href="https://cdn.lumistory.ai/documents/TermsOfService.pdf" target="_blank">
							Terms of Service
						</Anchor>{' '}
						and{' '}
						<Anchor href="https://cdn.lumistory.ai/documents/PrivacyPolicy.pdf" target="_blank">
							Privacy Policy
						</Anchor>
					</Text>
					<Button type="submit" disabled={!form.isValid()} loading={loading}>
						Sign up
					</Button>
				</Stack>
			</Form>
			<Divider my="lg" />
			<Center>
				<Anchor component={Link} to="/sign-in" c="dimmed" size="sm">
					Already have an account? Sign in
				</Anchor>
			</Center>
		</ViewContainer>
	);
}

const signUpWithPasswordMutation = graphql(`
	mutation SignUpWithPassword($input: SignUpWithPasswordInput!) {
		signUpWithPassword(input: $input)
	}
`);
