import { useMutation } from '@apollo/client';
import { Anchor, Button, Divider, PasswordInput, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { Link, useNavigate } from 'react-router-dom';
import { Endpoints, SignInWithPasswordInput, SignInWithPasswordInputSchema, graphql } from '@shared';
import Form from '../components/Form';
import PageHeader from '../components/ui/PageHeader';
import { SocialButton } from '../components/ui/SocialButton';
import ViewContainer from '../components/ui/ViewContainer';
import useSession from '../hooks/useSession';
import { clearEntitiesFromCache } from '../utils/cache';
import { handleUnexpectedError } from '../utils/error';
import { sendRequest } from '../utils/request';

export default function SignIn() {
	const navigate = useNavigate();
	const { refreshSession } = useSession();
	const [signIn, { loading }] = useMutation(signInWithPasswordMutation);

	const form = useForm<SignInWithPasswordInput>({
		validate: zodResolver(SignInWithPasswordInputSchema),
		validateInputOnBlur: true,
		initialValues: {
			email: '',
			password: '',
		},
	});

	async function handleSignInWithEmail() {
		await signIn({
			variables: {
				input: form.values,
			},
			onCompleted: (data) => {
				refreshSession();
				navigate(data.signInWithPassword.callbackUrl);
			},
			onError: handleUnexpectedError,
			update: (cache) => {
				clearEntitiesFromCache(cache, ['user']);
			},
		});
	}

	async function handleSignInWithGoogle() {
		const {
			data: { authorizationUrl },
		} = await sendRequest(Endpoints.signInWithGoogle);
		window.location.href = authorizationUrl;
	}

	return (
		<ViewContainer width="compact">
			<Form onSubmit={handleSignInWithEmail}>
				<Stack>
					<PageHeader text="Sign In" />
					<SocialButton onClick={async () => await handleSignInWithGoogle()} social="google">
						Sign in with Google
					</SocialButton>
					<Divider label="or continue with email" />
					<TextInput label="Email" placeholder="stan-lee@marvel.com" {...form.getInputProps('email')} />
					<PasswordInput label="Password" placeholder="************" {...form.getInputProps('password')} />
					<Button type="submit" disabled={!form.isValid()} loading={loading} mt="xs">
						Sign in
					</Button>
				</Stack>
			</Form>
			<Divider my="lg" />
			<Stack gap="xs">
				<Anchor component={Link} to="/sign-up" c="dimmed" size="sm">
					Don't have an account? Sign up
				</Anchor>
				<Anchor component={Link} to="/forgot-password" c="dimmed" size="sm">
					Forgot your password? Reset it
				</Anchor>
			</Stack>
		</ViewContainer>
	);
}

const signInWithPasswordMutation = graphql(`
	mutation SignInWithPassword($input: SignInWithPasswordInput!) {
		signInWithPassword(input: $input) {
			callbackUrl
		}
	}
`);
