import { useQuery } from '@apollo/client';
import { Button, Group, Select, SimpleGrid, Stack, Tabs } from '@mantine/core';
import { SortAscending } from '@phosphor-icons/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ResultOf, StoryDraft, graphql } from '@shared';
import StoryCard, { StoryCardProps } from '../components/StoryCard';
import Loading from '../components/ui/Loading';
import PageHeader from '../components/ui/PageHeader';
import ViewContainer from '../components/ui/ViewContainer';
import useSession from '../hooks/useSession';
import useSortedStories, { SortOption, sortOptions } from '../hooks/useSortedStories';

// TODO: Consider making this a type relating to story status (draft, published, banned, etc.)
enum TabValue {
	Drafts = 'Drafts',
	Published = 'Published',
}
type StoryDraftData = Pick<StoryCardProps['story'], 'id' | 'title' | 'coverImageUrl'> & { createdAt: string };
// TODO: Create a table to allow paging through stories and drafts
const PageSize = 50;

export default function MyStories() {
	const { user } = useSession();
	const [activeTab, setActiveTab] = useState<TabValue>(TabValue.Published);
	const [sort, setSort] = useState<SortOption>('Newest');
	const [stories, setStories] = useState<GetMyStoriesReturn>([]);
	const [storyDrafts, setStoryDrafts] = useState<StoryDraftData[]>([]);
	const { sortedStories } = useSortedStories({ stories, sort });
	const { sortedStories: sortedStoryDrafts } = useSortedStories({ stories: storyDrafts, sort });
	// Assemble all queries
	const { loading: getStoriesLoading } = useQuery(getMyStoriesQuery, {
		variables: {
			filter: {
				creatorId: user.id,
			},
			pagination: {
				offset: 0,
				limit: PageSize,
			},
		},
		onCompleted: (data) => {
			setStories(data.stories);
		},
	});
	const { loading: getStoryDraftsLoading } = useQuery(getStoryDraftsQuery, {
		variables: {
			pagination: {
				offset: 0,
				limit: PageSize,
			},
		},
		onCompleted: (data) => {
			// Map `data` (JSON) —> StoryDraft type
			const parsedStoryDrafts: StoryDraftData[] = data.storyDrafts.map(({ id, data, createdAt }) => {
				const storyDraft = data as unknown as StoryDraft;
				return {
					id,
					title: storyDraft.details.title,
					// TODO: Change when codegen has `maybeValue: 'T | undefined'`
					coverImageUrl: storyDraft.cover.selectedImageDraft?.imageUrl ?? undefined,
					createdAt,
				};
			});
			setStoryDrafts((prev) => [...prev, ...parsedStoryDrafts]);
		},
	});

	return (
		<ViewContainer width="compact">
			<Stack>
				<PageHeader
					text="My Stories"
					button={
						<Button component={Link} to="/new-story">
							New Story
						</Button>
					}
				/>
				<Tabs value={activeTab} onChange={(e) => setActiveTab(e as TabValue)}>
					<Group mb="md" justify="space-between">
						<Tabs.List>
							<Tabs.Tab value={TabValue.Published}>{TabValue.Published}</Tabs.Tab>
							<Tabs.Tab value={TabValue.Drafts}>{TabValue.Drafts}</Tabs.Tab>
						</Tabs.List>
						{/* TODO: Make reusable component for sort Select */}
						<Select
							data={sortOptions}
							value={sort}
							onChange={(value) => setSort(value as SortOption)}
							leftSection={<SortAscending />}
							ml="auto"
							size="xs"
							allowDeselect={false}
						/>
					</Group>
					<Tabs.Panel value={TabValue.Published}>
						{getStoriesLoading && <Loading />}
						<SimpleGrid cols={3} verticalSpacing="lg">
							{sortedStories.map((s) => (
								<StoryCard key={s.id} story={s} />
							))}
						</SimpleGrid>
					</Tabs.Panel>
					<Tabs.Panel value={TabValue.Drafts}>
						{getStoryDraftsLoading && <Loading />}
						<SimpleGrid cols={3} verticalSpacing="lg">
							{sortedStoryDrafts.map((s) => (
								<StoryCard key={s.id} story={s} isDraft={true} />
							))}
						</SimpleGrid>
					</Tabs.Panel>
				</Tabs>
			</Stack>
		</ViewContainer>
	);
}

type GetMyStoriesReturn = ResultOf<typeof getMyStoriesQuery>['stories'];
const getMyStoriesQuery = graphql(`
	query GetMyStories($filter: StoriesFilterInput!, $pagination: PaginationInput!) {
		stories(filter: $filter, pagination: $pagination) {
			id
			title
			coverImageUrl
			isFavorited
			createdAt
		}
	}
`);

const getStoryDraftsQuery = graphql(`
	query GetStoryDrafts($pagination: PaginationInput!) {
		storyDrafts(pagination: $pagination) {
			id
			data
			createdAt
		}
	}
`);
