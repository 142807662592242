import posthog from 'posthog-js';
import { isDevelopment } from './environment';

export const maskInSessionReplayClass = 'mask-in-session-replay';

export function initializePosthog(): void {
	if (!isDevelopment()) {
		posthog.init(import.meta.env.VITE_POSTHOG_PROJECT_ID, {
			api_host: 'https://us.i.posthog.com',
			property_denylist: ['password', 'confirmPassword', 'oldPassword', 'newPassword'],
			session_recording: {
				maskAllInputs: false,
				maskInputOptions: {
					password: true,
				},
			},
		});
	}
}
