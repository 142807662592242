import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';
import { Endpoints } from '@shared';
import { FeatureFlagsContext, defaultFeatureFlagsContext } from '../contexts/featureFlags';
import { sendRequest } from '../utils/request';

export default function FeatureFlagsProvider({ children }: PropsWithChildren) {
	const { data, isLoading, isError } = useQuery({
		queryKey: ['featureFlags'],
		queryFn: async () => await sendRequest(Endpoints.getFeatureFlags),
		retry: 0, // don't retry on error so we don't block the app from loading
	});

	if (isLoading || isError) {
		return (
			<FeatureFlagsContext.Provider
				value={{
					...defaultFeatureFlagsContext,
					isLoading,
					isError,
				}}
			>
				{children}
			</FeatureFlagsContext.Provider>
		);
	}

	return (
		<FeatureFlagsContext.Provider
			value={{
				data: data!.data,
				isLoading,
				isError,
			}}
		>
			{children}
		</FeatureFlagsContext.Provider>
	);
}
