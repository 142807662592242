import { useMutation } from '@apollo/client';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { ResultOf, graphql } from '@shared';
import { clearEntitiesFromCache } from '../utils/cache';
import { handleUnexpectedError } from '../utils/error';

type CreatePaymentMethodArgs = {
	stripe: Stripe;
	elements: StripeElements;
	onCompleted: (data: CreatePaymentMethodReturn) => void;
};

type UseCreatePaymentMethodReturn = {
	createPaymentMethod: (args: CreatePaymentMethodArgs) => Promise<void>;
	loading: boolean;
};

export default function useCreatePaymentMethod(): UseCreatePaymentMethodReturn {
	const [createPaymentMethodSetupIntent, { loading: createPaymentMethodSetupIntentLoading }] = useMutation(
		createPaymentMethodSetupIntentMutation
	);
	const [createPaymentMethodFn, { loading: createPaymentMethodFnLoading }] = useMutation(createPaymentMethodMutation);

	async function createPaymentMethod({ stripe, elements, onCompleted }: CreatePaymentMethodArgs) {
		const { error } = await elements.submit();
		if (error) {
			handleUnexpectedError(error);
			return;
		} else {
			await createPaymentMethodSetupIntent({
				onCompleted: async (data) => {
					const { setupIntent, error } = await stripe.confirmSetup({
						elements,
						clientSecret: data.createPaymentMethodSetupIntent.clientSecret,
						redirect: 'if_required',
						confirmParams: {
							// required param, never actually gets called since we only support credit card
							return_url: import.meta.env.VITE_WEB_URL,
						},
					});
					if (setupIntent) {
						await createPaymentMethodFn({
							variables: {
								input: {
									setupIntentId: setupIntent.id,
								},
							},
							onCompleted,
							update(cache) {
								clearEntitiesFromCache(cache, ['paymentMethods']);
							},
						});
					} else if (error) {
						handleUnexpectedError(error);
						return;
					}
				},
				onError: handleUnexpectedError,
			});
		}
	}

	return {
		createPaymentMethod,
		loading: createPaymentMethodSetupIntentLoading || createPaymentMethodFnLoading,
	};
}

const createPaymentMethodSetupIntentMutation = graphql(`
	mutation CreatePaymentMethodSetupIntent {
		createPaymentMethodSetupIntent {
			clientSecret
		}
	}
`);

type CreatePaymentMethodReturn = ResultOf<typeof createPaymentMethodMutation>;
const createPaymentMethodMutation = graphql(`
	mutation CreatePaymentMethod($input: CreatePaymentMethodInput!) {
		createPaymentMethod(input: $input) {
			id
		}
	}
`);
