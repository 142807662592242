import { Box, Burger, Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import useSession from '../hooks/useSession';

export default function BurgerMenu() {
	const [opened, { toggle }] = useDisclosure();
	const { isAuthenticated } = useSession();

	function BurgerLink({ to, text }: { to: string; text: string }) {
		return (
			<Menu.Item component={Link} to={to}>
				<Box>{text}</Box>
			</Menu.Item>
		);
	}

	return (
		<Menu trigger="click" opened={opened} onChange={() => toggle()} position="bottom-start">
			<Menu.Target>
				<Burger opened={opened} />
			</Menu.Target>
			<Menu.Dropdown>
				{isAuthenticated && <BurgerLink to="/my-characters" text="My Characters" />}
				{isAuthenticated && <BurgerLink to="/my-stories" text="My Stories" />}
				{isAuthenticated && <BurgerLink to="/my-shop" text="My Shop" />}
				<BurgerLink to="/pricing" text="Pricing" />
			</Menu.Dropdown>
		</Menu>
	);
}
