import { AspectRatio, Center, Stack, StackProps } from '@mantine/core';
import { PlusSquare } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import { ImageDimensions } from '@shared';
import useColors from '../hooks/useColors';

type NewCharacterCardProps = StackProps & {
	callbackUrl?: string;
};

export default function NewCharacterCard({ callbackUrl, ...props }: NewCharacterCardProps) {
	const { backgroundColor } = useColors();
	const navigate = useNavigate();

	return (
		<Stack {...props}>
			<AspectRatio
				id="new-character-card"
				ratio={ImageDimensions.character.ratio}
				bg={backgroundColor}
				style={{
					cursor: 'pointer',
					borderRadius: '4px',
				}}
				onClick={() => {
					navigate(`/new-character${callbackUrl ? `?callbackUrl=${callbackUrl}` : ''}`);
				}}
			>
				<Center>
					<PlusSquare height="50%" width="25%" />
				</Center>
			</AspectRatio>
		</Stack>
	);
}
