import { useLazyQuery } from '@apollo/client';
import { Group, Select, SimpleGrid, Stack, Tabs } from '@mantine/core';
import { Funnel, SortAscending } from '@phosphor-icons/react';
import { useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ResultOf, SortSelection, createSortInputs, graphql } from '@shared';
import NewCharacterCard from '../../components/NewCharacterCard';
import Loading from '../../components/ui/Loading';
import RoundedImage from '../../components/ui/RoundedImage';
import { handleUnexpectedError } from '../../utils/error';
import { OutletContext } from './MyCharacters';
import { TabValue } from './tabs';

// TOD: Implement Pagination. Start with a large page size for now
const PageSize = 50;
const sortSelections: SortSelection[] = ['Newest', 'Oldest', 'Name A-Z', 'Name Z-A'];

export default function Characters() {
	const { handleSetActiveTab } = useOutletContext<OutletContext>();
	const [storyIdFilter, setStoryIdFilter] = useState<string>();
	const [sort, setSort] = useState<SortSelection>('Newest');
	const [characters, setCharacters] = useState<GetMyCharactersReturn>([]);
	const stories = useMemo(() => Array.from(new Set(characters.flatMap((c) => c.stories))), [characters]);
	const filteredCharacters = useMemo(() => {
		if (!storyIdFilter) {
			return characters;
		}
		return characters.filter((c) => c.stories.some((s) => s.id === storyIdFilter));
	}, [storyIdFilter, characters]);
	const [getCharacters, { loading: getCharactersLoading }] = useLazyQuery(getMyCharactersQuery, {
		variables: {
			sorting: createSortInputs(sort),
			pagination: { offset: 0, limit: PageSize },
		},
		onCompleted: (data) => {
			setCharacters(data.characters);
		},
		onError: handleUnexpectedError,
	});

	// Handle sorting
	useEffect(() => {
		getCharacters();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sort]);

	return (
		<Stack>
			<Group justify="space-between">
				<Tabs value={TabValue.Published} onChange={(value) => handleSetActiveTab(value as TabValue)}>
					<Tabs.List>
						<Tabs.Tab value={TabValue.Published}>{TabValue.Published}</Tabs.Tab>
						<Tabs.Tab value={TabValue.Drafts}>{TabValue.Drafts}</Tabs.Tab>
					</Tabs.List>
				</Tabs>
				<Group>
					{/* Filtering */}
					<Select
						disabled={stories.length === 0}
						data={stories.map((s) => {
							return {
								value: s.id,
								label: s.title,
							};
						})}
						value={storyIdFilter}
						onChange={(value) => setStoryIdFilter(value || '')}
						leftSection={<Funnel />}
						size="xs"
						clearable
					/>
					{/* Sorting */}
					<Select
						data={sortSelections}
						disabled={getCharactersLoading}
						value={sort}
						onChange={(value) => setSort(value as SortSelection)}
						leftSection={<SortAscending />}
						size="xs"
					/>
				</Group>
			</Group>
			<SimpleGrid cols={3}>
				{getCharactersLoading ? (
					<Loading />
				) : (
					<>
						<NewCharacterCard />
						{filteredCharacters.map((c) => (
							<RoundedImage key={c.id} label={c.name} url={c.imageUrl} to={`/characters/${c.id}`} />
						))}
					</>
				)}
			</SimpleGrid>
		</Stack>
	);
}

type GetMyCharactersReturn = ResultOf<typeof getMyCharactersQuery>['characters'];
const getMyCharactersQuery = graphql(`
	query GetMyCharacters($sorting: [SortInput!], $pagination: PaginationInput) {
		characters(sorting: $sorting, pagination: $pagination) {
			id
			name
			imageUrl
			stories {
				id
				title
			}
		}
	}
`);
