import { useQuery } from '@apollo/client';
import { Divider, Group, Text } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { graphql } from '@shared';
import PageHeader from '../components/ui/PageHeader';
import RoundedImage from '../components/ui/RoundedImage';
import ViewContainer from '../components/ui/ViewContainer';

export default function Character() {
	const characterId = useParams().id!;
	const navigate = useNavigate();
	const { data } = useQuery(getCharacterQuery, {
		variables: {
			id: characterId,
		},
		onError() {
			navigate('/');
		},
	});

	return (
		<ViewContainer>
			<PageHeader text={data?.character.name || 'Character'} />
			<Group grow preventGrowOverflow={false} wrap="nowrap">
				<RoundedImage url={data?.character.imageUrl} w={400} />
				<Divider orientation="vertical" />
				<Text>{data?.character.description}</Text>
			</Group>
		</ViewContainer>
	);
}

const getCharacterQuery = graphql(`
	query GetCharacter($id: ID!) {
		character(id: $id) {
			id
			name
			description
			imageUrl
		}
	}
`);
