import { DefaultMantineColor, MantineColorsTuple, colorsTuple, createTheme, virtualColor } from '@mantine/core';

export const theme = createTheme({
	/** Put your Mantine theme override here */
	components: {
		Button: {
			defaultProps: {
				variant: 'gradient',
			},
		},
		Card: {
			defaultProps: {
				w: '100%',
				withBorder: true,
			},
		},
		InputLabel: {
			defaultProps: {
				fw: 'bold',
			},
		},
		ScrollArea: {
			defaultProps: {
				offsetScrollbars: true,
			},
		},
		Select: {
			defaultProps: {
				checkIconPosition: 'right',
			},
		},
		Textarea: {
			defaultProps: {
				autosize: true,
				minRows: 1,
			},
		},
	},
	// Must be a key of colors below
	primaryColor: 'primary',
	colors: {
		// Virtual colors must reference declared colors, and cannot use `colorsTuple` or hex directly
		// So use shadow colors for setting virtual colors
		// gray.3
		_softGrayLight: colorsTuple('#DEE2E6'),
		// gray.6
		_softGrayDark: colorsTuple('#495057'),
		softGray: virtualColor({
			name: 'softGray',
			light: '_softGrayLight',
			dark: '_softGrayDark',
		}),
		// Brand
		lumiOrange: colorsTuple('#F38C20'),
		lumiBurntOrange: colorsTuple('#E55F25'),
		lumiRobinsEgg: colorsTuple('#94D2BD'),
		lumiTeal: colorsTuple('#015F73'),
		lumiNavyBlue: colorsTuple('#013046'),
		// Primary
		primary: virtualColor({
			name: 'primary',
			light: 'lumiOrange',
			dark: 'lumiBurntOrange',
		}),
	},
	defaultGradient: { from: 'lumiNavyBlue', to: 'lumiTeal' },
	fontFamily: 'Acumin Pro',
});

// Extend color types for autocomplete
type ExtendedCustomColors =
	| 'lumiOrange'
	| 'lumiBurntOrange'
	| 'lumiRobinsEgg'
	| 'lumiTeal'
	| 'lumiNavyBlue'
	| 'softGray'
	| 'primary'
	| DefaultMantineColor;

declare module '@mantine/core' {
	export interface MantineThemeColorsOverride {
		colors: Record<ExtendedCustomColors, MantineColorsTuple>;
	}
}
