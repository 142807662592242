import { Badge, Stack, Text } from '@mantine/core';
import { CompanionMessage } from '@shared';

type ChatMessageProps = {
	message: CompanionMessage;
};

export default function ChatMessage({ message }: ChatMessageProps) {
	const { role, text } = message;
	const authorLabel = role === 'user' ? 'You' : 'Lumi';

	return (
		<Stack align={role === 'user' ? 'flex-end' : 'flex-start'}>
			<Badge variant="light" color="gray" size="sm" radius="sm">
				{authorLabel}
			</Badge>
			<Text
				size="sm"
				style={{
					marginLeft: role === 'user' ? 25 : 0,
					marginRight: role === 'user' ? 0 : 25,
					marginTop: -10,
					marginBottom: 10,
				}}
			>
				{text}
			</Text>
		</Stack>
	);
}
