import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ActionIcon, Anchor, Badge, Button, Group, Menu, Stack, Table, Text, ThemeIcon, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { CaretDown, CaretUp, DotsThree } from '@phosphor-icons/react';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SubscriptionEventType, SubscriptionStatus, UserRole, graphql, pascalCaseToSentence } from '@shared';
import NoAccessPage from '../../components/NoAccessPage';
import PaymentForm from '../../components/PaymentForm';
import Error from '../../components/ui/Error';
import Loading from '../../components/ui/Loading';
import PaymentCard from '../../components/ui/PaymentCard';
import { getPaymentMethodsQuery, getSubscriptionQuery } from '../../graphql/common';
import useCreatePaymentMethod from '../../hooks/useCreatePaymentMethod';
import useSession from '../../hooks/useSession';
import { clearEntitiesFromCache, clearTokenBalanceFromCache } from '../../utils/cache';
import { formatDateAsMonthDayAndYear, formatDateTimeAsMonthDayAndYear } from '../../utils/time';

export default function Billing() {
	const { user } = useSession();
	const [isAddingCard, setIsAddingCard] = useState(false);
	const [isSubscriptionEventsOpen, setIsSubscriptionEventsOpen] = useState(false);
	const { data: subscriptionData } = useQuery(getSubscriptionQuery);
	const { data: paymentMethodsData } = useQuery(getPaymentMethodsQuery);
	const [getSubscriptionEvents, { data: subscriptionEventsData, loading: subscriptionEventsLoading }] =
		useLazyQuery(getSubscriptionEventsQuery);
	const { createPaymentMethod, loading: createPaymentMethodLoading } = useCreatePaymentMethod();
	const [requestSubscriptionCancellation, { loading: requestSubscriptionCancellationLoading }] = useMutation(
		requestSubscriptionCancellationMutation
	);
	const [updateSubscriptionPaymentMethod, { loading: updateSubscriptionPaymentMethodLoading }] = useMutation(
		updateSubscriptionPaymentMethodMutation
	);

	useEffect(() => {
		if (isSubscriptionEventsOpen) {
			getSubscriptionEvents({
				variables: {
					subscriptionId: subscriptionData!.subscription!.id,
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubscriptionEventsOpen]);

	async function cancelSubscriptionModal() {
		return modals.openConfirmModal({
			title: 'Cancel Subscription',
			children: (
				<Text>
					Are you sure you want to cancel your subscription? You will lose access to all of your premium features at the
					end of the current period
				</Text>
			),
			labels: { confirm: 'Yes', cancel: 'No' },
			confirmProps: {
				color: 'red',
				loading: requestSubscriptionCancellationLoading,
			},
			cancelProps: {
				variant: 'default',
				disabled: requestSubscriptionCancellationLoading,
			},
			centered: true,
			onConfirm: async () => {
				await requestSubscriptionCancellation({
					variables: {
						input: {
							subscriptionId: subscriptionData!.subscription!.id,
						},
					},
					update(cache) {
						clearEntitiesFromCache(cache, ['subscription', 'subscriptionEvents']);
					},
				});
			},
		});
	}

	async function handleAddPaymentMethod(stripe: Stripe, elements: StripeElements) {
		await createPaymentMethod({
			stripe,
			elements,
			onCompleted: async (data) => {
				await updateSubscriptionPaymentMethod({
					variables: {
						input: {
							paymentMethodId: data.createPaymentMethod.id,
						},
					},
					update(cache) {
						clearEntitiesFromCache(cache, ['subscription', 'subscriptionEvents', 'paymentMethods']);
						clearTokenBalanceFromCache(cache);
					},
				});
				setIsAddingCard(false);
			},
		});
	}

	async function handleChangePaymentMethod(paymentMethodId: string) {
		await updateSubscriptionPaymentMethod({
			variables: {
				input: {
					paymentMethodId,
				},
			},
			update(cache) {
				clearEntitiesFromCache(cache, ['subscription', 'subscriptionEvents', 'paymentMethods']);
				clearTokenBalanceFromCache(cache);
			},
		});
	}

	if (user.role === UserRole.BetaTester) {
		return <NoAccessPage text="You do not currently have access to this page." />;
	}

	return (
		<Stack>
			{user.currentSubscription && !!subscriptionData?.subscription ? (
				<Stack gap="xl">
					<Stack gap="xs">
						<Group justify="space-between">
							<Title order={4}>Plan</Title>
							<Button component={Link} to="/pricing">
								Change
							</Button>
						</Group>
						<Text>{subscriptionData.subscription.subscriptionPlan.name}</Text>
						<Text>${subscriptionData.subscription.subscriptionPlan.monthlyPrice} / month</Text>
						{subscriptionData.subscription.status === SubscriptionStatus.Active && (
							<>
								{subscriptionData.subscription.nextPaymentDate ? (
									<Text>
										Next payment: {formatDateAsMonthDayAndYear(new Date(subscriptionData.subscription.nextPaymentDate))}
									</Text>
								) : (
									<Text>
										Expiring on: {formatDateAsMonthDayAndYear(new Date(subscriptionData.subscription.endDate!))}
									</Text>
								)}
							</>
						)}
						{subscriptionData.subscription.status === SubscriptionStatus.Suspended && (
							<Error
								text="Your subscription has been suspended due to a failed payment. Please update the payment method on file
							to recover your account."
							/>
						)}
					</Stack>
					<Stack gap="xs">
						<Group justify="space-between">
							<Title order={4}>Payment methods</Title>
							<Group gap="xs">
								{isAddingCard && (
									<Button variant="default" onClick={() => setIsAddingCard(false)}>
										Cancel
									</Button>
								)}
								<Button onClick={() => setIsAddingCard(true)}>{isAddingCard ? 'Save' : 'Add card'}</Button>
							</Group>
						</Group>
						<Stack>
							{paymentMethodsData?.paymentMethods.map((paymentMethod) => (
								<Group key={paymentMethod.id}>
									<Menu position="bottom-start">
										<Menu.Target>
											<ThemeIcon variant="transparent" color="dark" style={{ cursor: 'pointer' }}>
												<DotsThree height="100%" width="auto" />
											</ThemeIcon>
										</Menu.Target>
										{subscriptionData.subscription!.paymentMethod.id !== paymentMethod.id && (
											<Menu.Dropdown>
												<Menu.Item
													disabled={updateSubscriptionPaymentMethodLoading}
													onClick={async () => await handleChangePaymentMethod(paymentMethod.id)}
												>
													Make default
												</Menu.Item>
												{/* <Menu.Item color="red">Delete</Menu.Item> */}
											</Menu.Dropdown>
										)}
									</Menu>
									<PaymentCard brand={paymentMethod.brand} lastFour={paymentMethod.lastFourNumbers} />
									{subscriptionData.subscription!.paymentMethod.id === paymentMethod.id && (
										<Badge variant="default" size="sm">
											Default
										</Badge>
									)}
								</Group>
							))}
							{isAddingCard && (
								<PaymentForm
									shouldDisable={false}
									isLoading={createPaymentMethodLoading}
									handleSubmit={handleAddPaymentMethod}
								/>
							)}
						</Stack>
					</Stack>
					<Stack gap="xs">
						<Group
							justify="space-between"
							onClick={() => setIsSubscriptionEventsOpen((prev) => !prev)}
							style={{ cursor: 'pointer' }}
						>
							<Title order={4}>Payment history</Title>
							<ActionIcon variant="transparent" color="dark">
								{isSubscriptionEventsOpen ? <CaretUp /> : <CaretDown />}
							</ActionIcon>
						</Group>
						{isSubscriptionEventsOpen && (
							<Table.ScrollContainer minWidth={500}>
								<Table withRowBorders={false}>
									{subscriptionEventsLoading ? (
										<Loading size="md" />
									) : (
										<>
											<Table.Thead>
												<Table.Tr>
													<Table.Th>Date</Table.Th>
													<Table.Th>Amount</Table.Th>
													<Table.Th>Description</Table.Th>
													<Table.Th>Payment method</Table.Th>
												</Table.Tr>
											</Table.Thead>
											<Table.Tbody>
												{subscriptionEventsData?.subscriptionEvents.map((event) => (
													<Table.Tr key={event.id}>
														<Table.Td>{formatDateTimeAsMonthDayAndYear(new Date(event.createdAt))}</Table.Td>
														<Table.Td>${event.metadata?.amount?.toFixed(2)}</Table.Td>
														<Table.Td>
															<Badge
																color={event.type === SubscriptionEventType.PaymentSucceeded ? 'green' : 'red'}
																variant="light"
																size="sm"
															>
																{pascalCaseToSentence(event.type)}
															</Badge>
														</Table.Td>
														<Table.Td>
															{event.metadata?.paymentMethod && (
																<PaymentCard
																	brand={event.metadata.paymentMethod.brand}
																	lastFour={event.metadata.paymentMethod.lastFourNumbers}
																	size="sm"
																/>
															)}
														</Table.Td>
													</Table.Tr>
												))}
											</Table.Tbody>
										</>
									)}
								</Table>
							</Table.ScrollContainer>
						)}
					</Stack>
					{subscriptionData.subscription.nextPaymentDate && (
						<Button color="red" ml="auto" onClick={async () => await cancelSubscriptionModal()}>
							Cancel subscription
						</Button>
					)}
				</Stack>
			) : (
				<Text>
					You do not have an active subscription at this moment. Go to our <Anchor href="/pricing">pricing</Anchor> page
					to subscribe now!
				</Text>
			)}
		</Stack>
	);
}

const getSubscriptionEventsQuery = graphql(`
	query GetSubscriptionEvents($subscriptionId: ID!) {
		subscriptionEvents(subscriptionId: $subscriptionId) {
			id
			type
			createdAt
			metadata {
				message
				amount
				paymentMethod {
					brand
					lastFourNumbers
				}
			}
		}
	}
`);

const updateSubscriptionPaymentMethodMutation = graphql(`
	mutation UpdateSubscriptionPaymentMethod($input: UpdateSubscriptionPaymentMethodInput!) {
		updateSubscriptionPaymentMethod(input: $input) {
			id
		}
	}
`);

const requestSubscriptionCancellationMutation = graphql(`
	mutation RequestSubscriptionCancellation($input: RequestSubscriptionCancellationInput!) {
		requestSubscriptionCancellation(input: $input) {
			id
		}
	}
`);
