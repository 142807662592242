import { Endpoint, invariant, zod } from '@shared';

export type EndpointResponse<T> = Response & {
	data: T;
};
export async function sendRequest<RequestBody, ResponseBody>(
	endpoint: Endpoint<RequestBody, ResponseBody>,
	body?: RequestBody
): Promise<EndpointResponse<ResponseBody>> {
	const response = await initiateRequest(endpoint, body);
	const rawResponseData = await response.json();
	const data = endpoint.responseBodySchema.parse(rawResponseData);
	return {
		...response,
		data,
	};
}

export async function sendRequestWithoutParsing<RequestBody, ResponseBody>(
	endpoint: Endpoint<RequestBody, ResponseBody>,
	body?: RequestBody
): Promise<Response> {
	const response = await initiateRequest(endpoint, body);
	return response;
}

async function initiateRequest<RequestBody, ResponseBody>(
	endpoint: Endpoint<RequestBody, ResponseBody>,
	body?: RequestBody
): Promise<Response> {
	const url = import.meta.env.VITE_REST_API + endpoint.path;
	const response = await fetch(url, {
		method: endpoint.method,
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'include',
		body: formatRequestBody(body, endpoint.requestBodySchema),
	});

	if (!response.ok) {
		throw new Error(`Error ${response.status}: ${response.statusText}`);
	}

	return response;
}

function formatRequestBody(body: unknown, schema: zod.ZodSchema | undefined): string | undefined {
	if (!body) {
		return undefined;
	}
	invariant(schema, 'Unable to format request body. Schema not provided.');
	const parsedBody = schema.parse(body);
	const parsedBodyString = JSON.stringify(parsedBody);
	return parsedBodyString;
}
