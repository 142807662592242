import { fabric } from 'fabric';
import {
	InitialBubbleHeight,
	InitialBubbleWidth,
	InitialTailInset,
	InitialTailWidth,
	Shape,
	getProportions,
} from '@shared';
import { Defaults } from './canvas';

export function createShape(shape: Shape, options: fabric.IPathOptions = {}): fabric.Path {
	const pathString = createShapePathString(shape);
	const path = new fabric.Path(pathString, {
		originX: 'center',
		originY: 'center',
		left: 0,
		top: 0,
		fill: Defaults.TextBubble.BackgroundColor,
		stroke: Defaults.TextBubble.BorderColor,
		strokeWidth: Defaults.TextBubble.BorderWidth,
		strokeUniform: true,
		...options,
	});

	return path;
}

function createShapePathString(shape: Shape): string {
	// Smaller local variable names for ease of use
	const width = InitialBubbleWidth;
	const height = InitialBubbleHeight;
	const proportions = getProportions(shape);
	const tailWidth = InitialTailWidth;
	const tailHeight = height * (1 - proportions.bodyY);
	const tailInset = InitialTailInset;

	switch (shape) {
		case Shape.Circle: {
			const radius = width / 2;
			// Calculate control points for a perfect circle
			// SVG arc command: A rx ry x-axis-rotation large-arc-flag sweep-flag x y
			const pathCommands = [
				`M 100 100`, // Move to start point
				` m -${radius}, 0`, // Move to the left of the center to start drawing
				` a ${radius},${radius} 0 1,0 ${radius * 2},0`, // Draw the first half circle
				` a ${radius},${radius} 0 1,0 -${radius * 2},0`,
			]; // Draw the second half circle to complete the circle
			const pathString = pathCommands.join(' ');
			return pathString;
		}
		case Shape.CircleWithTail: {
			const radius = width / 2 - tailHeight / 2;
			// Circle should be centered, but offset to the left by the tail height
			const circleCenterX = width / 2;
			const circleCenterY = height / 2 - tailHeight / 2;
			// Calculate the start and end point for the tail on the circle's circumference
			// Angle in radians for the tail width (basically if the width was projected onto the circle)
			const tailWidthAngle = tailWidth / (2 * radius);
			const tailStartX = circleCenterX - radius * Math.sin(tailWidthAngle);
			const tailStartY = circleCenterY + radius * Math.cos(tailWidthAngle); // Starting Y coordinate for the tail on the circle
			const tailEndX = circleCenterX - radius * Math.sin(-tailWidthAngle); // Ending X coordinate for the tail on the circle
			const tailEndY = circleCenterY + radius * Math.cos(-tailWidthAngle); // Ending Y coordinate for the tail on the circle

			const pathCommands = [
				`M ${tailStartX} ${tailStartY}`, // Move to the starting point on the circle
				` A ${radius} ${radius} 0 1 1 ${tailEndX} ${tailEndY}`, // Draw the circle from start to end point of the tail
				// Draw the tail
				` L ${circleCenterX - (radius + tailHeight) * Math.sin(tailWidthAngle)} ${
					circleCenterY + (radius + tailHeight) * Math.cos(tailWidthAngle)
				}`, // Line to the tip of the tail
				` L ${tailStartX} ${tailStartY}`, // Line back to the start point of the tail, completing the triangle
				` Z`,
			]; // Close the path to ensure a smooth connection
			const pathString = pathCommands.join(' ');
			return pathString;
		}
		case Shape.Rectangle: {
			const pathCommands = [
				`M 0,0`, // Move to top-left corner
				`H ${width}`, // Horizontal line to top-right corner
				`V ${height}`, // Vertical line to bottom-right corner
				`H 0`, // Horizontal line to bottom-left corner
				`Z`, // Close the path
			];
			const pathString = pathCommands.join(' ');
			return pathString;
		}
		case Shape.RectangleWithTail: {
			const pathCommands = [
				`M 0,0`,
				`H ${width}`, // Top line
				`Q ${width},0 ${width},0`, // Top-right corner
				`V ${height - tailHeight}`, // Right line
				`Q ${width},${height - tailHeight} ${width},${height - tailHeight}`, // Bottom-right corner
				`H ${tailInset + tailWidth}`, // Bottom line before tail
				`L ${tailInset - tailWidth / 2},${height}`, // Tail tip
				`L ${tailInset}, ${height - tailHeight}`, // Tail base to bottom-left corner
				`Q 0,${height - tailHeight} 0,${height - tailHeight}`, // Bottom-left corner
				`V ${0}`, // Left line
				`Q 0,0 ${0},0`, // Top-left corner
				`Z`,
			];
			const pathString = pathCommands.join(' ');
			return pathString;
		}
		case Shape.RectangleRounded: {
			const cornerSize = 10;
			const cornerStart1 = { x: 0, y: cornerSize };
			const cornerMidpoint1 = { x: 0, y: 0 };
			const cornerEnd1 = { x: cornerSize, y: 0 };
			const cornerStart2 = { x: width - cornerSize, y: 0 };
			const cornerMidpoint2 = { x: width, y: 0 };
			const cornerEnd2 = { x: width, y: cornerSize };
			const cornerStart3 = { x: width, y: height - cornerSize };
			const cornerMidpoint3 = { x: width, y: height };
			const cornerEnd3 = { x: width - cornerSize, y: height };
			const cornerStart4 = { x: cornerSize, y: height };
			const cornerMidpoint4 = { x: 0, y: height };
			const cornerEnd4 = { x: 0, y: height - cornerSize };

			const pathCommands = [
				`M ${cornerStart1.x} ${cornerStart1.y}`,
				`Q ${cornerMidpoint1.x} ${cornerMidpoint1.y} ${cornerEnd1.x} ${cornerEnd1.y}`,
				`L ${cornerStart2.x} ${cornerStart2.y}`,
				`Q ${cornerMidpoint2.x} ${cornerMidpoint2.y} ${cornerEnd2.x} ${cornerEnd2.y}`,
				`L ${cornerStart3.x} ${cornerStart3.y}`,
				`Q ${cornerMidpoint3.x} ${cornerMidpoint3.y} ${cornerEnd3.x} ${cornerEnd3.y} `,
				`L ${cornerStart4.x} ${cornerStart4.y}`,
				`Q ${cornerMidpoint4.x} ${cornerMidpoint4.y} ${cornerEnd4.x} ${cornerEnd4.y}`,
				`L ${cornerStart1.x} ${cornerStart1.y}`,
				`Z`,
			];
			const pathString = pathCommands.join(' ');
			return pathString;
		}
		case Shape.RectangleRoundedWithTail: {
			// Top left, going clockwise
			//  _______
			// |a     b|
			// |       |
			// |e_____c|
			//    V d
			// a = corner1, d = tail, e = corner4
			// Only need to adjust bubbleHeight because the tail extends vertically only
			const bubbleHeight = height - tailHeight;
			// Ensure this matches the same cornerSize (and therefore corner radius) as the rounded rectangle (without a tail)
			const cornerSize = 10 * ((bubbleHeight + tailHeight / 2) / height);
			const cornerStart1 = { x: 0, y: cornerSize };
			const cornerMidpoint1 = { x: 0, y: 0 };
			const cornerEnd1 = { x: cornerSize, y: 0 };
			const cornerStart2 = { x: width - cornerSize, y: 0 };
			const cornerMidpoint2 = { x: width, y: 0 };
			const cornerEnd2 = { x: width, y: cornerSize };
			const cornerStart3 = { x: width, y: bubbleHeight - cornerSize };
			const cornerMidpoint3 = { x: width, y: bubbleHeight };
			const cornerEnd3 = { x: width - cornerSize, y: bubbleHeight };
			const tailStart = { x: cornerSize + tailInset + tailWidth, y: bubbleHeight };
			const tailMidpoint = { x: cornerSize + tailInset - tailWidth / 2, y: height };
			const tailEnd = { x: cornerSize + tailInset, y: bubbleHeight };
			const cornerStart4 = { x: cornerSize, y: bubbleHeight };
			const cornerMidpoint4 = { x: 0, y: bubbleHeight };
			const cornerEnd4 = { x: 0, y: bubbleHeight - cornerSize };
			/*
			Can be easier to understand it with real numbers
			const cornerStart1 = { x: 0, y: 10 };
			const cornerMidpoint1 = { x: 0, y: 0 };
			const cornerEnd1 = { x: 10, y: 0 };
			const cornerStart2 = { x: 90, y: 0 };
			const cornerMidpoint2 = { x: 100, y: 0 };
			const cornerEnd2 = { x: 100, y: 10 };
			const cornerStart3 = { x: 100, y: 70 };
			const cornerMidpoint3 = { x: 100, y: 80 };
			const cornerEnd3 = { x: 90, y: 80 };
			const tailStart = { x: 20, y: 80 };
			const tailMidpoint = { x: 15, y: 100 };
			const tailEnd = { x: 10, y: 80 };
			const cornerStart4 = { x: 10, y: 80 };
			const cornerMidpoint4 = { x: 0, y: 80 };
			const cornerEnd4 = { x: 0, y: 70 };
			*/

			const pathCommands = [
				`M ${cornerStart1.x} ${cornerStart1.y}`,
				`Q ${cornerMidpoint1.x} ${cornerMidpoint1.y} ${cornerEnd1.x} ${cornerEnd1.y}`,
				`L ${cornerStart2.x} ${cornerStart2.y}`,
				`Q ${cornerMidpoint2.x} ${cornerMidpoint2.y} ${cornerEnd2.x} ${cornerEnd2.y}`,
				`L ${cornerStart3.x} ${cornerStart3.y}`,
				`Q ${cornerMidpoint3.x} ${cornerMidpoint3.y} ${cornerEnd3.x} ${cornerEnd3.y}`,
				`L ${tailStart.x} ${tailStart.y} L ${tailMidpoint.x} ${tailMidpoint.y} L ${tailEnd.x} ${tailEnd.y}`,
				`L ${cornerStart4.x} ${cornerStart4.y}`,
				`Q ${cornerMidpoint4.x} ${cornerMidpoint4.y} ${cornerEnd4.x} ${cornerEnd4.y}`,
				`L ${cornerStart1.x} ${cornerStart1.y}`,
				`Z`,
			];
			const pathString = pathCommands.join(' ');
			return pathString;
		}
		case Shape.Cloud: {
			const points = 16;
			// Define the center of the cloud, inner radius, and outerRadius
			const centerX = width / 2;
			const centerY = height / 2;
			// Radius if this was a simple circle. Diameter should be 100 to match other objects
			const circleRadius = 50;
			// Amount of "puff" from the edges of the cloud, vs the inner cloud body
			const puffProportion = 0.05;
			const innerRadius = circleRadius * (1 - puffProportion);
			const outerRadius = circleRadius * (1 + puffProportion);
			const angleStep = (2 * Math.PI) / points;

			// Create an array to store the commands for the path
			const pathCommands = [];
			for (let i = 0; i < points; i++) {
				const angle = i * angleStep;
				const nextAngle = (i + 1) * angleStep;

				// Start and end points
				const tailStartX = centerX + Math.cos(angle) * innerRadius;
				const tailStartY = centerY + Math.sin(angle) * innerRadius;
				const tailEndX = centerX + Math.cos(nextAngle) * innerRadius;
				const tailEndY = centerY + Math.sin(nextAngle) * innerRadius;

				// Mid point (middle of curve)

				const pointX = centerX + Math.cos(angle + angleStep / 2) * outerRadius;
				const pointY = centerY + Math.sin(angle + angleStep / 2) * outerRadius;

				// Move to start point for the first point
				if (i === 0) {
					pathCommands.push(`M ${tailStartX} ${tailStartY}`);
				}
				pathCommands.push(`Q ${pointX} ${pointY} ${tailEndX} ${tailEndY}`);
			}
			// Close the path
			pathCommands.push('Z');

			const pathString = pathCommands.join(' ');
			return pathString;
		}
	}
}
