import { Anchor, Stack, Text } from '@mantine/core';
import PageHeader from './ui/PageHeader';
import ViewContainer from './ui/ViewContainer';

type NoAccessPageProps = {
	text: string;
};

export default function NoAccessPage({ text }: NoAccessPageProps) {
	return (
		<ViewContainer width="compact">
			<Stack>
				<PageHeader text="" />
				<Text ta="center">
					{text} If you believe this to be an error, please contact us via the{' '}
					<Anchor href="/contact-us">support</Anchor> page.
				</Text>
			</Stack>
		</ViewContainer>
	);
}
