import { useMutation } from '@apollo/client';
import { PlaidEmbeddedLink, PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import { CreateShopBankAccountInput, graphql } from '@shared';

type PlaidLaunchLinkProps = {
	token: string;
	onCompleted: (data: CreateShopBankAccountInput) => void;
};

export default function PlaidLaunchLink({ token, onCompleted }: PlaidLaunchLinkProps) {
	const [exchangePlaidPublicToken] = useMutation(exchangePlaidPublicTokenMutation);

	async function onSuccess(publicToken: string, metadata: PlaidLinkOnSuccessMetadata) {
		// TODO: This is a hack to get the main account. We should not allow Plaid to display
		// non checking accounts, which is done by adding `account_filters` when creating the
		// public token in the server. However, this functionality does not seem to work yet
		const mainAccount = metadata.accounts.find((account) => account.subtype === 'checking') || metadata.accounts[0];
		await exchangePlaidPublicToken({
			variables: {
				publicToken,
			},
			onCompleted(data) {
				onCompleted({
					bankName: metadata.institution!.name,
					bankAccountExternalProviderId: mainAccount.id,
					bankAccountExternalProviderToken: data.exchangePlaidPublicToken.accessToken,
					bankAccountExternalProviderMetadata: {
						itemId: data.exchangePlaidPublicToken.itemId,
					},
				});
			},
		});
	}

	return (
		<PlaidEmbeddedLink
			token={token}
			onSuccess={async (publicToken, metadata) => await onSuccess(publicToken, metadata)}
			style={{
				height: '250px',
			}}
		/>
	);
}

const exchangePlaidPublicTokenMutation = graphql(`
	mutation ExchangePlaidPublicToken($publicToken: String!) {
		exchangePlaidPublicToken(publicToken: $publicToken) {
			accessToken
			itemId
		}
	}
`);
