import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Button, Group, Image, InputLabel, Slider, Stack, Switch, Text, Textarea } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
	ClientImageDraft,
	CoverSchema,
	ImageKind,
	ImageStatus,
	StoryDraft,
	StoryImageType,
	UserFriendlyErrors,
	defaultPagination,
	graphql,
	zod,
} from '@shared';
import Form from '../../../components/Form';
import ImageLoadingPlaceholder from '../../../components/ui/ImageLoading';
import RoundedImage from '../../../components/ui/RoundedImage';
import Sidebar from '../../../components/ui/Sidebar';
import { fillHeight } from '../../../css/utils.module.css';
import { getImageDraftsQuery } from '../../../graphql/common';
import { useInterval } from '../../../hooks/useInterval';
import { clearEntitiesFromCache } from '../../../utils/cache';
import { handleUnexpectedError, showCustomErrorNotification } from '../../../utils/error';
import { CharactersGrid } from '../charactersGrid';

const ImageDraftUpdateFrequencyInMS = 2 * 1000;

const imageGenerationFormSchema = CoverSchema.pick({
	characterIds: true,
	genres: true,
}).extend({
	// The instructions from the user for image generation guidance
	instructions: zod.string().optional(),
	numberOfImages: zod.number().int().min(1).max(4),
	shouldUseOriginalImage: zod.boolean(),
});
type ImageGenerationForm = zod.infer<typeof imageGenerationFormSchema>;

export type CoverImageEditorProps = {
	storyDraft: StoryDraft;
	setStoryDraft: React.Dispatch<React.SetStateAction<StoryDraft>>;
};

export function CoverImageEditor({ storyDraft, setStoryDraft }: CoverImageEditorProps) {
	const { cover } = storyDraft;
	const imageGenerationForm = useForm<ImageGenerationForm>({
		validate: zodResolver(imageGenerationFormSchema),
		initialValues: {
			characterIds: storyDraft.characters.map((c) => c.id),
			genres: storyDraft.details.genres,
			instructions: '',
			numberOfImages: 2,
			shouldUseOriginalImage: false,
		},
	});
	const { selectedImageDraft: selectedImage } = cover;
	const [images, setImages] = useState<ClientImageDraft[]>([]);
	const [hasMoreImages, setHasMoreImages] = useState(true);
	const { loading: getInitialImageDraftsLoading } = useQuery(getImageDraftsQuery, {
		variables: {
			sourceDraftId: cover.id,
		},
		onCompleted: (data) => {
			if (data.imageDrafts.length > 0) {
				const imageDrafts = data.imageDrafts;
				setImages(imageDrafts);
			}
			if (data.imageDrafts.length < defaultPagination.limit) {
				setHasMoreImages(false);
			}
		},
		onError: handleUnexpectedError,
	});
	const [getMoreImages] = useLazyQuery(getImageDraftsQuery, {
		variables: {
			sourceDraftId: cover.id,
			pagination: { offset: images.length, limit: defaultPagination.limit },
		},
		onCompleted: (data) => {
			if (data.imageDrafts.length > 0) {
				const newImageDrafts = data.imageDrafts;
				setImages((prev) => [...prev, ...newImageDrafts]);
			}
			if (data.imageDrafts.length < defaultPagination.limit) {
				setHasMoreImages(false);
			}
		},
		onError: handleUnexpectedError,
	});

	async function handleGetMoreImages() {
		if (getInitialImageDraftsLoading) {
			return;
		}
		await getMoreImages();
	}

	// Select the first image once images begin generating or are loaded
	useEffect(() => {
		if (images.length > 0 && !selectedImage) {
			handleImageSelection(images[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [images, selectedImage]);

	// Keep the parent storyDraft in sync with this child's form values
	useEffect(() => {
		setStoryDraft((prev) => ({
			...prev,
			cover: {
				...prev.cover,
			},
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageGenerationForm.values]);

	useInterval(async () => {
		if (pendingImages.length) {
			await updatePendingImages();
		}
	}, ImageDraftUpdateFrequencyInMS);

	const [generateCoverImages, { loading: generateCoverImagesLoading }] = useMutation(generateCoverImagesMutation);
	const [regenerateCoverImages, { loading: regenerateCoverImagesLoading }] = useMutation(regenerateCoverImagesMutation);
	// const [upscaleImage, { loading: upscaleImageLoading }] = useMutation(upscaleImageMutation);
	const pendingImages = useMemo(() => images.filter((i) => i.status === ImageStatus.Pending), [images]);
	const [getPendingImages] = useLazyQuery(getImageDraftsQuery);

	function updatePendingImages() {
		getPendingImages({
			fetchPolicy: 'network-only',
			variables: {
				sourceDraftId: cover.id,
			},
			onCompleted(data) {
				if (data.imageDrafts.length > 0) {
					const completedImages = data.imageDrafts
						.filter((image) => image.status === ImageStatus.Completed && image.imageUrl)
						.map((image) => ({
							...image,
							imageUrl: image.imageUrl,
							status: image.status,
						}));
					setImages((prev) => [
						...prev.map((image) => {
							const updatedImage = completedImages.find((i) => i.id === image.id);
							if (updatedImage) {
								return {
									...image,
									imageUrl: updatedImage.imageUrl,
									status: updatedImage.status,
								};
							}
							return { ...image };
						}),
					]);
				}
			},
			onError: handleUnexpectedError,
		});
	}

	async function handleGenerateCoverImages(values: ImageGenerationForm) {
		const { shouldUseOriginalImage } = imageGenerationForm.values;
		const input = {
			coverDraftId: cover.id,
			storyDraftId: storyDraft.id,
			characterIds: imageGenerationForm.values.characterIds ?? [],
			genres: storyDraft.details.genres,
			artStyle: storyDraft.settings.artStyle,
			numberOfImages: values.numberOfImages,
			instructions: values.instructions,
		};
		if (shouldUseOriginalImage) {
			if (!selectedImage) {
				return showCustomErrorNotification({
					title: 'No Image To Modify',
					message: 'You must upload or generate an image first',
				});
			} else if (selectedImage.kind !== ImageKind.GeneratedImage) {
				return showCustomErrorNotification({
					message: UserFriendlyErrors.NoRegeneratingUploadedOrEditedImages,
				});
			}

			return await regenerateCoverImages({
				variables: {
					input: { ...input, originalImageDraftId: selectedImage.id },
				},
				onCompleted(data) {
					const newImages = data.regenerateCoverImages;
					setImages((prev) => [...newImages, ...prev]);
				},
				update(cache) {
					clearEntitiesFromCache(cache, ['imageDrafts']);
				},
				onError: handleUnexpectedError,
			});
		} else {
			return await generateCoverImages({
				variables: {
					input,
				},
				onCompleted(data) {
					const newImages = data.generateCoverImages;
					setImages((prev) => [...newImages, ...prev]);
				},
				update(cache) {
					clearEntitiesFromCache(cache, ['imageDrafts']);
				},
				onError: handleUnexpectedError,
			});
		}
	}

	// async function handleUpscaleClicked() {
	// 	if (!selectedImage) {
	// 		showCustomErrorNotification({
	// 			title: 'No Image Selected',
	// 			message: 'You must upload or generate an image first',
	// 		});
	// 		return;
	// 	}
	// 	// TODO: Allow upscaling uploaded images
	// 	if (selectedImage.kind === ImageKind.UploadedImage) {
	// 		showCustomErrorNotification({
	// 			title: 'Upscale Unavailable',
	// 			message: 'You may only upscale images that were generated',
	// 		});
	// 		return;
	// 	}

	// 	// Fix upscale mutation for cover image
	// 	await upscaleImage({
	// 		variables: {
	// 			input: {
	// 				imageDraftId: selectedImage.id,
	// 				// TODO change the following to match the actual fields in the UpscaleImageInput type
	// 				panelDraftId: cover.id,
	// 				// For when we implement upscaling of uploaded images
	// 				// generatedLeonardoId: selectedImage.kind !== ImageKind.UploadedImage ? selectedImage.leonardoId : undefined,
	// 				// uploadedLeonardoId: selectedImage.kind === ImageKind.UploadedImage ? selectedImage.leonardoId : undefined,
	// 			},
	// 		},
	// 		onCompleted(data) {
	// 			const newImage = data.upscaleImage;
	// 			setImages((prev) => [newImage, ...prev]);
	// 		},
	// 		update(cache) {
	// 			clearEntitiesFromCache(cache, ['imageDrafts']);
	// 		},
	// 		onError: handleUnexpectedError,
	// 	});
	// }

	function handleImageSelection(newSelectedImage: ClientImageDraft) {
		setStoryDraft((prev) => ({
			...prev,
			cover: {
				...prev.cover,
				selectedImageDraft: newSelectedImage,
				// If they're generating their story images (i.e not uploading them), then set the cover image to the generated one
				image: storyDraft.settings.imageType === StoryImageType.Generated ? newSelectedImage : prev.cover.image,
			},
		}));
	}

	return (
		<Sidebar.Container>
			<Sidebar.Side>
				<Form onSubmit={imageGenerationForm.onSubmit(handleGenerateCoverImages)}>
					<Stack gap="xs">
						<Box>
							<InputLabel size="xs">Characters</InputLabel>
							{storyDraft.characters ? (
								<CharactersGrid characters={storyDraft.characters} cols={4} />
							) : (
								<Text size="xs" fs="italic">
									No characters on the cover
								</Text>
							)}
						</Box>
						<Textarea
							label="Image instructions"
							size="xs"
							variant="unstyled"
							placeholder='e.g. "Add a sunset in the background"'
							description="Optional"
							maxRows={4}
							{...imageGenerationForm.getInputProps('instructions')}
						/>
						<Group preventGrowOverflow={false}>
							<InputLabel size="xs">Number of images</InputLabel>
							<Slider flex={1} min={1} max={4} {...imageGenerationForm.getInputProps('numberOfImages')} />
						</Group>
						<Group justify="space-between" preventGrowOverflow={false}>
							<InputLabel size="xs">Modify selected image</InputLabel>
							<Switch
								size="xs"
								disabled={!selectedImage}
								{...imageGenerationForm.getInputProps('shouldUseOriginalImage')}
							/>
						</Group>
						<Group grow>
							<Button
								type="submit"
								size="xs"
								mt="xs"
								loading={generateCoverImagesLoading || regenerateCoverImagesLoading}
							>
								Generate
							</Button>
							{/* <Button size="xs" mt="xs" loading={upscaleImageLoading} onClick={handleUpscaleClicked}>
									Upscale
								</Button> */}
						</Group>
					</Stack>
				</Form>
			</Sidebar.Side>
			<Sidebar.Main>
				<Box className={fillHeight} w="100%" mih="500">
					{selectedImage?.imageUrl && (
						<Image
							src={selectedImage!.imageUrl}
							className={fillHeight}
							style={{ objectFit: 'contain', objectPosition: 'top' }}
						/>
					)}
				</Box>
			</Sidebar.Main>
			<Sidebar.Side>
				<Box className={fillHeight}>
					{/* Generated images */}
					<InfiniteScroll
						dataLength={images.length}
						next={handleGetMoreImages}
						hasMore={hasMoreImages}
						loader={''}
						refreshFunction={handleGetMoreImages}
					>
						<Stack>
							{/* Add placeholders in while loading */}
							{(generateCoverImagesLoading || regenerateCoverImagesLoading) &&
								Array.from({ length: imageGenerationForm.values.numberOfImages }).map((_, i) => (
									<ImageLoadingPlaceholder key={`generate-${i}`} />
								))}
							{/* {upscaleImageLoading &&
												Array.from({ length: 1 }).map((_, i) => <ImageLoadingPlaceholder key={`upscale-${i}`} />)} */}
							{images.map((image) =>
								image.imageUrl ? (
									<RoundedImage
										key={image.id}
										// TODO: Change when codegen has `maybeValue: 'T | undefined'`
										url={image.imageUrl ?? undefined}
										isSelected={image.id === selectedImage?.id}
										onClick={() => handleImageSelection(image)}
									/>
								) : (
									<ImageLoadingPlaceholder key={image.id} />
								)
							)}
						</Stack>
					</InfiniteScroll>
				</Box>
			</Sidebar.Side>
		</Sidebar.Container>
	);
}

const generateCoverImagesMutation = graphql(`
	mutation generateCoverImages($input: GenerateCoverImagesInput!) {
		generateCoverImages(input: $input) {
			id
			status
			kind
			imageUrl
		}
	}
`);

const regenerateCoverImagesMutation = graphql(`
	mutation regenerateCoverImages($input: RegenerateCoverImagesInput!) {
		regenerateCoverImages(input: $input) {
			id
			status
			kind
			imageUrl
		}
	}
`);

// const upscaleImageMutation = graphql(`
// 	mutation UpscaleImage($input: UpscaleImageInput!) {
// 		upscaleImage(input: $input) {
// 			id
// 			status
// 			kind
// 			imageUrl
// 		}
// 	}
// `);
