import { zod, zodId } from '../utils';
import { ImageKind, ImageStatus } from './graphql';

export const ClientImageDraftSchema = zod.object({
	id: zodId('imagedraft'),
	kind: zod.nativeEnum(ImageKind),
	status: zod.nativeEnum(ImageStatus),
	// TODO: swap `nullish` for `optional` when codegen has `maybeValue: 'T | undefined'`
	imageUrl: zod.string().url().nullish(),
});
