import { Group, Image, MantineSize, Text } from '@mantine/core';
import { CreditCard } from '@phosphor-icons/react';

type PaymentCardProps = {
	brand: string;
	lastFour?: string;
	size?: MantineSize;
};

export default function PaymentCard({ brand, lastFour, size }: PaymentCardProps) {
	return (
		<Group gap="xs">
			{cardBrandLogos[brand] ? <Image src={cardBrandLogos[brand]} /> : <CreditCard />}
			{lastFour && <Text size={size}>**** {lastFour}</Text>}
		</Group>
	);
}

const cardBrandLogos: Record<string, string> = {
	visa: 'https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg',
	mastercard: 'https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg',
	amex: 'https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg',
	discover: 'https://js.stripe.com/v3/fingerprinted/img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg',
	diners: 'https://js.stripe.com/v3/fingerprinted/img/diners-fbcbd3360f8e3f629cdaa80e93abdb8b.svg',
	jcb: 'https://js.stripe.com/v3/fingerprinted/img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg',
	unionpay: 'https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg',
};
