import { useMutation } from '@apollo/client';
import { Anchor, Button, Center, Divider, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { Link, useNavigate } from 'react-router-dom';
import { SendForgotPasswordEmailInput, SendForgotPasswordEmailInputSchema, graphql } from '@shared';
import Form from '../components/Form';
import PageHeader from '../components/ui/PageHeader';
import ViewContainer from '../components/ui/ViewContainer';

export default function ForgotPassword() {
	const navigate = useNavigate();
	const [sendForgotPasswordEmail, { loading }] = useMutation(sendForgotPasswordEmailMutation);

	const form = useForm<SendForgotPasswordEmailInput>({
		validate: zodResolver(SendForgotPasswordEmailInputSchema),
		validateInputOnBlur: true,
		initialValues: {
			email: '',
		},
	});

	const handleForgotPassword = async () => {
		await sendForgotPasswordEmail({
			variables: {
				input: form.values,
			},
			onCompleted: () => {
				navigate('/sign-in');
				notifications.show({
					title: 'Email sent',
					message: 'Check your email for a link to reset your password',
					color: 'green',
				});
			},
		});
	};

	return (
		<ViewContainer width="compact">
			<Form onSubmit={async () => handleForgotPassword()}>
				<Stack>
					<PageHeader text="Forgot Password" />
					<TextInput label="Email" placeholder="stan-lee@marvel.com" {...form.getInputProps('email')} />
					<Button type="submit" disabled={!form.isValid()} loading={loading} mt="xs">
						Send reset email
					</Button>
				</Stack>
			</Form>
			<Divider my="lg" />
			<Center>
				<Anchor component={Link} to="/sign-up" c="dimmed" size="sm">
					Don't have an account? Sign up
				</Anchor>
			</Center>
		</ViewContainer>
	);
}

const sendForgotPasswordEmailMutation = graphql(`
	mutation SendForgotPasswordEmail($input: SendForgotPasswordEmailInput!) {
		sendForgotPasswordEmail(input: $input)
	}
`);
