import { useEffect, useState } from 'react';
import { Story } from '@shared';

/**
 * TODO: Move server side
 */

export type SortOption = 'Newest' | 'Oldest' | 'Title A-Z' | 'Title Z-A';
export const sortOptions: SortOption[] = ['Newest', 'Oldest', 'Title A-Z', 'Title Z-A'];

// TODO: Enable`createdAt` to parse as `Date` instead of string
// https://github.com/apollographql/apollo-feature-requests/issues/368
type SortableStory = Pick<Story, 'id' | 'title'> & { createdAt: string };

type UseSortedStoriesProps<T extends SortableStory> = {
	stories: T[];
	sort?: SortOption;
};

export default function useSortedStories<T extends SortableStory>({ stories, sort }: UseSortedStoriesProps<T>) {
	const [sortedStories, setSortedStories] = useState(stories);

	useEffect(() => {
		const storiesCopy = [...stories];
		switch (sort) {
			case 'Newest':
				setSortedStories(storiesCopy.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
				break;
			case 'Oldest':
				setSortedStories(storiesCopy.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()));
				break;
			case 'Title A-Z':
				setSortedStories(storiesCopy.sort((a, b) => a.title.localeCompare(b.title)));
				break;
			case 'Title Z-A':
				setSortedStories(storiesCopy.sort((a, b) => b.title.localeCompare(a.title)));
				break;
			default:
				setSortedStories(storiesCopy);
		}
	}, [sort, stories]);

	return {
		sortedStories,
	};
}
