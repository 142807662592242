import { useMutation, useQuery } from '@apollo/client';
import { ActionIcon, Anchor, Button, Center, Group, Stack, Table, Text, Title, Tooltip } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { Check } from '@phosphor-icons/react';
import { useState } from 'react';
import {
	CreateShopBankAccountInput,
	CreateShopBankAccountInputSchema,
	MinimumWithdrawalAmount,
	graphql,
} from '@shared';
import PlaidLaunchLink from '../../components/PlaidLaunchLink';
import useSession from '../../hooks/useSession';
import { clearEntitiesFromCache } from '../../utils/cache';
import { formatDateTimeAsMonthDayAndYear } from '../../utils/time';

export default function Dashboard() {
	const { user } = useSession();
	const [plaidLinkToken, setPlaidLinkToken] = useState<string>();
	const { data, loading: getMyShopDashboardLoading } = useQuery(getMyShopDashboardQuery, {
		variables: {
			username: user.username,
		},
	});
	const [createPlaidLinkToken, { loading: createPlaidLinkTokenLoading }] = useMutation(createPlaidLinkTokenMutation);
	const [createShopBankAccount, { loading: createShopBankAccountLoading }] = useMutation(createShopBankAccountMutation);
	const [withdrawShopBalance, { loading: withdrawShopBalanceLoading }] = useMutation(withdrawShopBalanceMutation);

	const createShopBankAccountForm = useForm<CreateShopBankAccountInput>({
		validate: zodResolver(CreateShopBankAccountInputSchema),
		validateInputOnBlur: true,
		initialValues: {
			bankName: '',
			bankAccountExternalProviderId: '',
			bankAccountExternalProviderToken: '',
			bankAccountExternalProviderMetadata: {},
		},
	});

	async function handleClickAddBankAccount() {
		await createPlaidLinkToken({
			onCompleted: (data) => {
				setPlaidLinkToken(data.createPlaidLinkToken);
			},
		});
	}

	async function handleCreateShopBankAccount() {
		await createShopBankAccount({
			variables: {
				input: createShopBankAccountForm.values,
			},
			onCompleted: () => {
				createShopBankAccountForm.reset();
			},
			update: (cache) => {
				clearEntitiesFromCache(cache, ['shop']);
			},
		});
	}

	async function handleWithdrawShopBalance() {
		await withdrawShopBalance({
			onCompleted: () => {
				notifications.show({
					title: 'Success',
					message: 'Your balance has been withdrawn',
					color: 'teal',
				});
			},
			update: (cache) => {
				clearEntitiesFromCache(cache, ['shop']);
			},
		});
	}

	return (
		<Stack>
			<Group justify="space-between">
				<Group gap="xs">
					<Title order={4}>Balance:</Title>
					<Text>${data?.shop.balance.toFixed(2)}</Text>
				</Group>
				<Group>
					{data?.shop &&
						'bankAccount' in data.shop &&
						!data.shop.bankAccount &&
						(!plaidLinkToken ? (
							<Button loading={createPlaidLinkTokenLoading} onClick={async () => await handleClickAddBankAccount()}>
								Add bank account
							</Button>
						) : (
							<Button
								onClick={async () => await handleCreateShopBankAccount()}
								loading={createShopBankAccountLoading}
								disabled={!createShopBankAccountForm.isValid()}
							>
								Link bank account
							</Button>
						))}
					<Tooltip
						label={`Balance must be at least $${MinimumWithdrawalAmount} to withdraw, and you must have a bank account linked`}
					>
						<Button
							loading={withdrawShopBalanceLoading}
							disabled={
								(data?.shop.balance || 0) < MinimumWithdrawalAmount ||
								getMyShopDashboardLoading ||
								// shop has no bank account
								(data?.shop && 'bankAccount' in data.shop && !data.shop.bankAccount)
							}
							onClick={async () => await handleWithdrawShopBalance()}
						>
							Withdraw
						</Button>
					</Tooltip>
				</Group>
			</Group>
			{/* if the user has not linked a bank account yet */}
			{plaidLinkToken && !createShopBankAccountForm.isValid() && (
				<PlaidLaunchLink token={plaidLinkToken} onCompleted={(data) => createShopBankAccountForm.setValues(data)} />
			)}
			{createShopBankAccountForm.isValid() && (
				<Center>
					<Stack>
						<Center>
							<ActionIcon color="green" variant="transparent">
								<Check size={32} />
							</ActionIcon>
						</Center>
						<Text>Bank account linked</Text>
					</Stack>
				</Center>
			)}
			<Title order={4}>Recent Orders</Title>
			<Table>
				<Table.Thead>
					<Table.Tr>
						<Table.Th>Order Number</Table.Th>
						<Table.Th>Total</Table.Th>
						<Table.Th>Date</Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>
					{data?.shop &&
						'orders' in data.shop &&
						data.shop.orders.map((order) => (
							<Table.Tr key={order.id}>
								<Table.Td>
									<Anchor size="sm" href={`/order-confirmation/${order.orderNumber}`}>
										{order.orderNumber}
									</Anchor>
								</Table.Td>
								<Table.Td>${order.total.toFixed(2)}</Table.Td>
								<Table.Td>{formatDateTimeAsMonthDayAndYear(new Date(order.createdAt))}</Table.Td>
							</Table.Tr>
						))}
				</Table.Tbody>
			</Table>
		</Stack>
	);
}

const getMyShopDashboardQuery = graphql(`
	query GetMyShopDashboard($username: String!) {
		shop(username: $username) {
			id
			balance
			... @defer {
				orders {
					id
					orderNumber
					total
					createdAt
				}
				bankAccount {
					id
				}
			}
		}
	}
`);

const createPlaidLinkTokenMutation = graphql(`
	mutation CreatePlaidLinkToken {
		createPlaidLinkToken
	}
`);

const createShopBankAccountMutation = graphql(`
	mutation CreateShopBankAccount($input: CreateShopBankAccountInput!) {
		createShopBankAccount(input: $input) {
			id
		}
	}
`);

const withdrawShopBalanceMutation = graphql(`
	mutation WithdrawShopBalance {
		withdrawShopBalance
	}
`);
