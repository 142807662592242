import { useMutation, useQuery } from '@apollo/client';
import {
	ActionIcon,
	Button,
	Divider,
	Flex,
	Group,
	Image,
	Radio,
	Select,
	Stack,
	Text,
	TextInput,
	Title,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { Trash } from '@phosphor-icons/react';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	Country,
	CreateUserAddressInput,
	CreateUserAddressInputSchema,
	State,
	getAddressString,
	graphql,
} from '@shared';
import Form from '../components/Form';
import PaymentForm from '../components/PaymentForm';
import PageHeader from '../components/ui/PageHeader';
import PaymentCard from '../components/ui/PaymentCard';
import ViewContainer from '../components/ui/ViewContainer';
import { getPaymentMethodsQuery } from '../graphql/common';
import useCreatePaymentMethod from '../hooks/useCreatePaymentMethod';
import useIsMobileView from '../hooks/useIsMobileView';
import { clearEntitiesFromCache } from '../utils/cache';

export default function Checkout() {
	const isMobileView = useIsMobileView();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const buyNowItemVariantId = searchParams.get('buyNowItemVariantId');
	const [isCreatingNewAddress, setIsCreatingNewAddress] = useState(false);
	const [isCreatingNewPaymentMethod, setIsCreatingNewPaymentMethod] = useState(false);
	const [selectedAddressId, setSelectedAddressId] = useState<string>();
	const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string>();
	const [externalProviderId, setExternalProviderId] = useState<number>();
	const [shippingCost, setShippingCost] = useState<number>();
	const [taxCost, setTaxCost] = useState<number>();
	const { data: buyNowItemData } = useQuery(getBuyNowItemVariantForCheckoutQuery, {
		variables: {
			id: buyNowItemVariantId!,
		},
		skip: !buyNowItemVariantId,
	});
	const { data: cartItemVariantsData, loading: getCartItemVariantsLoading } = useQuery(getCartItemVariantsQuery, {
		skip: !!buyNowItemVariantId,
	});
	const { data: userAddressesData } = useQuery(getUserAddressesQuery, {
		onCompleted(data) {
			if (data.userAddresses.length === 0) {
				setIsCreatingNewAddress(true);
			}
		},
	});
	const { data: paymentMethodData } = useQuery(getPaymentMethodsQuery, {
		onCompleted(data) {
			if (data.paymentMethods.length === 0) {
				setIsCreatingNewPaymentMethod(true);
			}
		},
	});
	const [createUserAddress, { loading: createUserAddressLoading }] = useMutation(createUserAddressMutation);
	const { createPaymentMethod, loading: createPaymentMethodLoading } = useCreatePaymentMethod();
	const [removeCartItemVariant, { loading: removeCartItemVariantLoading }] = useMutation(removeCartItemVariantMutation);
	const [createOrderDraft, { loading: createOrderDraftLoading }] = useMutation(createOrderDraftMutation);
	const [buyItems, { loading: buyItemsLoading }] = useMutation(buyItemsMutation);

	const itemVariantIds = useMemo(
		() =>
			buyNowItemVariantId
				? [buyNowItemVariantId]
				: cartItemVariantsData?.cartItemVariants.map((cartItemVariant) => cartItemVariant.itemVariant!.id) || [],
		[buyNowItemVariantId, cartItemVariantsData]
	);

	const subtotal = useMemo(
		() =>
			`$${
				buyNowItemData
					? buyNowItemData.itemVariant.price.toFixed(2)
					: cartItemVariantsData?.cartItemVariants
							.reduce((sum, current) => sum + current.itemVariant!.price, 0)
							.toFixed(2)
			}`,
		[buyNowItemData, cartItemVariantsData]
	);

	const total = useMemo(
		() =>
			shippingCost && taxCost
				? `$${(
						(buyNowItemData?.itemVariant.price ||
							cartItemVariantsData!.cartItemVariants.reduce((sum, current) => sum + current.itemVariant!.price, 0)) +
						shippingCost +
						taxCost
					).toFixed(2)}`
				: 'TBD',
		[buyNowItemData, cartItemVariantsData, shippingCost, taxCost]
	);

	const itemVariantsBeingBought = useMemo(() => {
		if (buyNowItemData?.itemVariant) {
			return [buyNowItemData.itemVariant];
		}
		if (cartItemVariantsData?.cartItemVariants) {
			return cartItemVariantsData.cartItemVariants.map((cartItemVariant) => cartItemVariant.itemVariant!);
		}
		return [];
	}, [buyNowItemData, cartItemVariantsData]);

	// as the address is changed, recalculate the price for shipping and tax
	useEffect(() => {
		if (selectedAddressId && (buyNowItemData || !!cartItemVariantsData?.cartItemVariants.length)) {
			handleCreateOrderDraft();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAddressId]);

	const addressForm = useForm<CreateUserAddressInput>({
		validate: zodResolver(CreateUserAddressInputSchema),
		validateInputOnBlur: true,
		initialValues: {
			addressLine1: '',
			addressLine2: '',
			city: '',
			state: '',
			zip: '',
			country: Country.US,
		},
	});

	async function handleCreateUserAddress() {
		await createUserAddress({
			variables: {
				input: addressForm.values,
			},
			async onCompleted(data) {
				setSelectedAddressId(data.createUserAddress.id);
				setIsCreatingNewAddress(false);
			},
			update(cache) {
				clearEntitiesFromCache(cache, ['userAddresses']);
			},
		});
	}

	async function handleAddPaymentMethod(stripe: Stripe, elements: StripeElements) {
		await createPaymentMethod({
			stripe,
			elements,
			onCompleted(data) {
				setSelectedPaymentMethodId(data.createPaymentMethod.id);
				setIsCreatingNewPaymentMethod(false);
			},
		});
	}

	async function handleRemoveCartItemVariant(cartItemVariantId: string) {
		await removeCartItemVariant({
			variables: {
				input: {
					id: cartItemVariantId,
				},
			},
			async onCompleted() {
				await handleCreateOrderDraft();
			},
			update(cache) {
				clearEntitiesFromCache(cache, ['cartItemVariants']);
			},
		});
	}

	async function handleCreateOrderDraft() {
		if (selectedAddressId) {
			await createOrderDraft({
				variables: {
					input: {
						itemVariantIds,
						userAddressId: selectedAddressId,
					},
				},
				onCompleted(data) {
					setExternalProviderId(data.createOrderDraft.externalProviderId);
					setShippingCost(data.createOrderDraft.shippingCost);
					setTaxCost(data.createOrderDraft.taxCost);
				},
			});
		}
	}

	async function handleBuyItems() {
		await buyItems({
			variables: {
				input: {
					externalProviderId: externalProviderId!,
					itemVariantIds,
					userAddressId: selectedAddressId!,
					paymentMethodId: selectedPaymentMethodId!,
				},
			},
			onCompleted(data) {
				navigate(`/order-confirmation/${data.buyItems.orderNumber}`);
			},
			update(cache) {
				clearEntitiesFromCache(cache, ['cartItemVariants']);
			},
		});
	}

	if (!getCartItemVariantsLoading && cartItemVariantsData?.cartItemVariants.length === 0) {
		return (
			<ViewContainer>
				<PageHeader text="Checkout" />
				<Text>
					Your cart is currently empty. Please visit some creator shops and add items to your cart to checkout.
					{/* TODO: Maybe have cards to our most popular creator shops here */}
				</Text>
			</ViewContainer>
		);
	}

	return (
		<ViewContainer>
			<PageHeader text="Checkout" />
			<Flex direction={isMobileView ? 'column' : 'row'} gap="xl">
				<Stack w={{ base: '100%', sm: 500 }}>
					<Form onSubmit={async () => await handleCreateUserAddress()}>
						<Stack>
							<Group justify="space-between">
								<Title order={3}>Shipping Address</Title>
								<Button
									onClick={() => {
										setIsCreatingNewAddress(true);
										setSelectedAddressId(undefined);
									}}
								>
									Add new
								</Button>
							</Group>
							{isCreatingNewAddress ? (
								<>
									<TextInput label="Address Line 1" {...addressForm.getInputProps('addressLine1')} />
									<TextInput
										label="Apartment / Suite"
										description="Optional"
										{...addressForm.getInputProps('addressLine2')}
									/>
									<TextInput label="City" {...addressForm.getInputProps('city')} />
									<Select label="State" data={Object.values(State)} {...addressForm.getInputProps('state')} />
									<TextInput label="Zip" {...addressForm.getInputProps('zip')} />
									<Group justify="space-between" grow mt="xs">
										<Button
											onClick={() => {
												setIsCreatingNewAddress(false);
												addressForm.reset();
											}}
											variant="default"
										>
											Cancel
										</Button>
										<Button type="submit" disabled={!addressForm.isValid()} loading={createUserAddressLoading}>
											Save
										</Button>
									</Group>
								</>
							) : (
								<Radio.Group value={selectedAddressId} onChange={setSelectedAddressId}>
									<Stack>
										{userAddressesData?.userAddresses.map((address) => (
											<Radio key={address.id} label={getAddressString(address)} value={address.id} />
										))}
									</Stack>
								</Radio.Group>
							)}
						</Stack>
					</Form>
					<Stack>
						<Group justify="space-between" align="start">
							<Title order={3}>Payment</Title>
							<Button onClick={() => setIsCreatingNewPaymentMethod(true)}>Add new</Button>
						</Group>
						{isCreatingNewPaymentMethod ? (
							<PaymentForm
								shouldDisable={false}
								isLoading={createPaymentMethodLoading}
								handleSubmit={handleAddPaymentMethod}
							/>
						) : (
							<Radio.Group value={selectedPaymentMethodId} onChange={setSelectedPaymentMethodId}>
								<Stack>
									{paymentMethodData?.paymentMethods.map((paymentMethod) => (
										<Radio
											key={paymentMethod.id}
											label={
												<PaymentCard
													key={paymentMethod.id}
													brand={paymentMethod.brand}
													lastFour={paymentMethod.lastFourNumbers}
												/>
											}
											value={paymentMethod.id}
										/>
									))}
								</Stack>
							</Radio.Group>
						)}
					</Stack>
				</Stack>
				<Stack flex={1}>
					<Title order={3}>{buyNowItemVariantId ? 'Buy now' : 'In your cart'}</Title>
					<Stack gap="xs">
						<Group justify="space-between">
							<Text>Subtotal</Text>
							<Text>{subtotal}</Text>
						</Group>
						<Group justify="space-between">
							<Text>Shipping</Text>
							<Text>{shippingCost ? `$${shippingCost.toFixed(2)}` : 'TBD'}</Text>
						</Group>
						<Group justify="space-between">
							<Text>Tax</Text>
							<Text>{taxCost ? `$${taxCost.toFixed(2)}` : 'TBD'}</Text>
						</Group>
						<Divider />
						<Group justify="space-between">
							<Text>Total</Text>
							<Text>{total}</Text>
						</Group>
					</Stack>
					{itemVariantsBeingBought.map((cartItemVariant) => (
						<Group key={cartItemVariant.id} align="start">
							<Image src={cartItemVariant.itemVariantImages?.[0].imageUrl} w="25%" />
							<Stack gap={4}>
								<Group justify="space-between" align="start">
									<Title order={4} flex={1}>
										{cartItemVariant.item?.name}
									</Title>
									{buyNowItemData && (
										<ActionIcon
											variant="transparent"
											color="dark"
											onClick={async () => await handleRemoveCartItemVariant(cartItemVariant.id)}
										>
											<Trash />
										</ActionIcon>
									)}
								</Group>
								<Text size="xs">{cartItemVariant.color}</Text>
								<Text size="xs">{cartItemVariant.size}</Text>
								<Text size="xs">${cartItemVariant.price.toFixed(2)}</Text>
							</Stack>
						</Group>
					))}
					<Button
						disabled={
							!selectedAddressId ||
							!selectedPaymentMethodId ||
							(!buyNowItemData && !cartItemVariantsData?.cartItemVariants.length)
						}
						loading={removeCartItemVariantLoading || createOrderDraftLoading || buyItemsLoading}
						onClick={async () => await handleBuyItems()}
						mt="xs"
					>
						Buy
					</Button>
				</Stack>
			</Flex>
		</ViewContainer>
	);
}

const getBuyNowItemVariantForCheckoutQuery = graphql(`
	query GetBuyNowItemVariantForCheckout($id: ID!) {
		itemVariant(id: $id) {
			id
			color
			size
			price
			itemVariantImages {
				imageUrl
			}
			item {
				id
				name
			}
		}
	}
`);

const getCartItemVariantsQuery = graphql(`
	query GetCartItemVariants {
		cartItemVariants {
			id
			itemVariant {
				id
				color
				size
				price
				itemVariantImages {
					imageUrl
				}
				item {
					id
					name
				}
			}
		}
	}
`);

const getUserAddressesQuery = graphql(`
	query GetUserAddresses {
		userAddresses {
			id
			addressLine1
			addressLine2
			city
			state
			zip
			country
		}
	}
`);

const createUserAddressMutation = graphql(`
	mutation CreateUserAddress($input: CreateUserAddressInput!) {
		createUserAddress(input: $input) {
			id
		}
	}
`);

const createOrderDraftMutation = graphql(`
	mutation CreateOrderDraft($input: CreateOrderDraftInput!) {
		createOrderDraft(input: $input) {
			externalProviderId
			shippingCost
			taxCost
		}
	}
`);

const removeCartItemVariantMutation = graphql(`
	mutation RemoveCartItemVariant($input: RemoveCartInputItemVariantInput!) {
		removeCartItemVariant(input: $input)
	}
`);

const buyItemsMutation = graphql(`
	mutation BuyItems($input: BuyItemsInput!) {
		buyItems(input: $input) {
			id
			orderNumber
		}
	}
`);
