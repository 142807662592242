import { useQuery } from '@apollo/client';
import { PropsWithChildren, useEffect, useState } from 'react';
import { graphql } from '@shared';
import { AcknowledgedTutorialsContext, defaultAcknowledgedTutorialsContext } from '../contexts/acknowledgedTutorials';
import useSession from '../hooks/useSession';

export default function AcknowledgedTutorialsProvider({ children }: PropsWithChildren) {
	const { isAuthenticated } = useSession();
	const [isLoading, setIsLoading] = useState(true);
	const { data, loading, error } = useQuery(getAcknowledgedTutorialsQuery, {
		skip: !isAuthenticated,
	});

	// since we have to wait for the dom to hydrate, assume the tutorials have been acknowledged
	// while we wait, and then update them with the proper value later
	useEffect(() => {
		const timer = setTimeout(() => setIsLoading(loading || false), 1000);
		return () => clearTimeout(timer);
	}, [loading]);

	if (!data || loading || error) {
		return (
			<AcknowledgedTutorialsContext.Provider
				value={{
					...defaultAcknowledgedTutorialsContext,
					isLoading,
				}}
			>
				{children}
			</AcknowledgedTutorialsContext.Provider>
		);
	}

	return (
		<AcknowledgedTutorialsContext.Provider
			value={{
				isLoading,
				acknowledgedTutorials: data!.userAcknowledgedTutorials.map(({ tutorialType }) => tutorialType),
			}}
		>
			{children}
		</AcknowledgedTutorialsContext.Provider>
	);
}

const getAcknowledgedTutorialsQuery = graphql(`
	query GetAcknowledgedTutorials {
		userAcknowledgedTutorials {
			tutorialType
		}
	}
`);
