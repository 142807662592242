import { Alert, AlertProps } from '@mantine/core';
import { Warning } from '@phosphor-icons/react';

/**
 * A generic component to be used when displaying a "error" alert to the user
 *
 * e.g.
 * - "Your account has been terminated due to a violation of our terms of service"
 * - "Your payment method has expired. Please update your payment method to continue using the service"
 */

type ErrorProps = AlertProps & {
	text: string;
};

export default function Error({ text, ...props }: ErrorProps) {
	return (
		<Alert variant="light" color="red" title="Error" icon={<Warning size={32} />} {...props}>
			{text}
		</Alert>
	);
}
