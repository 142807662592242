import { Box, BoxProps, Group, Text } from '@mantine/core';
import { formatDistance } from 'date-fns';
import { GetCommentsForStoryReturn } from '../pages/Story';
import ProfilePicture from './ui/ProfilePicture';

type Comment = BoxProps & {
	comment: Omit<GetCommentsForStoryReturn[number], 'children'>;
};

export default function Comment({ comment, ...props }: Comment) {
	return (
		<Box {...props}>
			<Group>
				<ProfilePicture user={comment.user!} />
				<Box>
					<Text fz="sm" c="dimmed">
						{comment.user.displayName}
					</Text>
					<Text fz="xs" c="dimmed">
						{formatDistance(comment.createdAt, new Date(), { addSuffix: true })}
					</Text>
				</Box>
			</Group>
			{/* 
				Why 54? Official Mantine docs have that as the padding needed to match the text after an Avatar
				https://ui.mantine.dev/category/comments/ 
			*/}
			<Text pl={54} mt={5}>
				{comment.text}
			</Text>
		</Box>
	);
}
