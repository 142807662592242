import { useMutation } from '@apollo/client';
import { Badge, Box, Group, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateStoryInput, Folder, PageImage, StoryImageType, graphql } from '@shared';
import PageCard from '../../components/PageCard';
import ProfilePicture from '../../components/ui/ProfilePicture';
import useImageUploader from '../../hooks/useImageUploader';
import useSession from '../../hooks/useSession';
import { clearEntitiesFromCache } from '../../utils/cache';
import { handleUnexpectedError } from '../../utils/error';
import { NewStoryPageHeader } from './pageHeader';
import { StepComponentProps } from './stepComponent';

type PreviewProps = StepComponentProps;

export default function Preview({ storyDraft, activeStep, setActiveStep }: PreviewProps) {
	const { user } = useSession();
	const storyDraftId = useParams().id!;
	const navigate = useNavigate();
	const [createStory] = useMutation(createStoryMutation);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { uploadImage } = useImageUploader();
	const theme = useMantineTheme();

	function handlePreviousClicked() {
		setActiveStep(activeStep - 1);
	}
	async function handleNextClicked() {
		setIsSubmitting(true);
		try {
			const input = await createInputForStory();
			await createStory({
				variables: {
					input,
				},
				onCompleted: (data) => {
					navigate(`/stories/${data.createStory.id}`);
				},
				update: (cache) => {
					clearEntitiesFromCache(cache, ['stories', 'story', 'storyDraft', 'storyDrafts']);
				},
			});
		} catch (error) {
			handleUnexpectedError(error);
		} finally {
			setIsSubmitting(false);
		}
	}

	async function createInputForStory(): Promise<CreateStoryInput> {
		const coverImageUrl = storyDraft.cover.image!.imageUrl!;
		let pageImages: PageImage[] = [];
		if (storyDraft.settings.imageType === StoryImageType.Uploaded) {
			pageImages = storyDraft.pages.map((p) => ({ index: p.index, url: p.image!.imageUrl! }));
		} else {
			// Cannot do Promise.all because, oddly, AWS (or Apollo's cache) will return the same image URL for every request
			for (const p of storyDraft.pages) {
				const url = await uploadImage(p.canvasImageData!, Folder.PageImages);
				pageImages.push({
					index: p.index,
					url,
				});
			}
		}

		return {
			storyDraftId,
			coverImageUrl,
			pageImages,
		};
	}

	return (
		<>
			<NewStoryPageHeader
				activeStep={activeStep}
				isLoading={isSubmitting}
				isNextButtonDisabled={false}
				handlePreviousClicked={handlePreviousClicked}
				handleNextClicked={handleNextClicked}
				setActiveStep={setActiveStep}
			/>
			<Box mx="auto" w={theme.breakpoints.xs}>
				<Stack gap="md" mb="xl">
					<Title order={1}>{storyDraft.details.title}</Title>
					<Group gap="xs">
						<ProfilePicture user={user} />
						<Text c="dimmed" flex={1} lineClamp={1}>
							{user.displayName}
						</Text>
					</Group>
					<Group gap="xs">
						{storyDraft.details.genres.map((g) => (
							<Badge key={g} variant="default">
								{g}
							</Badge>
						))}
					</Group>
					<Text>{storyDraft.details.summary}</Text>
				</Stack>
				<PageCard key="cover" imageUrl={storyDraft.cover.image!.imageUrl!} />
				<Stack gap="0">
					{storyDraft.pages.map((p, i) => (
						<PageCard
							key={i}
							imageUrl={
								storyDraft.settings.imageType === StoryImageType.Uploaded ? p.image!.imageUrl! : p.canvasImageData!
							}
						/>
					))}
				</Stack>
			</Box>
		</>
	);
}

const createStoryMutation = graphql(`
	mutation CreateStory($input: CreateStoryInput!) {
		createStory(input: $input) {
			id
		}
	}
`);
