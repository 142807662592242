import { useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { TutorialType, graphql } from '@shared';
import { AcknowledgedTutorialsContext } from '../contexts/acknowledgedTutorials';
import { clearEntitiesFromCache } from '../utils/cache';

type UseTutorialsProps = {
	tutorialType: TutorialType;
};

type UseTutorialsReturn = {
	isTutorialAcknowledged: boolean;
	shouldShowTutorial: boolean;
	setShouldShowTutorial: Dispatch<SetStateAction<boolean>>;
	acknowledgeTutorial: () => void;
};

export default function useTutorials({ tutorialType }: UseTutorialsProps): UseTutorialsReturn {
	const { acknowledgedTutorials, isLoading } = useContext(AcknowledgedTutorialsContext);
	const [shouldShowTutorial, setShouldShowTutorial] = useState(false);
	const isTutorialAcknowledged = acknowledgedTutorials.includes(tutorialType);
	const [acknowledgeTutorial] = useMutation(acknowledgeTutorialMutation);

	useEffect(() => {
		if (!isLoading) {
			setShouldShowTutorial(!isTutorialAcknowledged);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	async function acknowledgeTutorialFn() {
		setShouldShowTutorial(false);
		// don't save the acknowledgement again if it's already been acknowledged at some point
		if (!isTutorialAcknowledged) {
			await acknowledgeTutorial({
				variables: {
					tutorialType,
				},
				update: (cache) => {
					clearEntitiesFromCache(cache, ['userAcknowledgedTutorials']);
				},
			});
		}
	}

	return {
		isTutorialAcknowledged,
		shouldShowTutorial,
		setShouldShowTutorial,
		acknowledgeTutorial: acknowledgeTutorialFn,
	};
}

const acknowledgeTutorialMutation = graphql(`
	mutation AcknowledgeTutorial($tutorialType: TutorialType!) {
		acknowledgeTutorial(tutorialType: $tutorialType) {
			id
		}
	}
`);
