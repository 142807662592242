import { Anchor, Group } from '@mantine/core';
import useColors from '../hooks/useColors';
import ViewContainer from './ui/ViewContainer';

export function Footer() {
	const { elementColor } = useColors();

	return (
		<ViewContainer>
			<Group justify="space-between" h={40}>
				{/* <Group>
					<Button component="a" href="https://twitter.com/lumistoryai" variant="transparent" color="dark" px={0}>
						<TwitterLogo size={24} />
					</Button>
					<Button component="a" href="https://www.instagram.com/lumistoryai" variant="transparent" color="dark" px={0}>
						<InstagramLogo size={24} />
					</Button>
					<Button component="a" href="https://www.tiktok.com/@lumistoryai" variant="transparent" color="dark" px={0}>
						<TiktokLogo size={24} />
					</Button>
				</Group> */}
				<Group ml="auto">
					<Anchor c={elementColor} size="sm" href="https://cdn.lumistory.ai/documents/TermsOfService.pdf">
						Terms of Service
					</Anchor>
					<Anchor c={elementColor} size="sm" href="https://cdn.lumistory.ai/documents/PrivacyPolicy.pdf">
						Privacy Policy
					</Anchor>
					<Anchor c={elementColor} size="sm" href="https://lumistory.ai/careers">
						Careers
					</Anchor>
				</Group>
			</Group>
		</ViewContainer>
	);
}
