import { Box, Card, CardProps, ElementProps, List, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { SubscriptionPeriod } from '@shared';
import { GetSubscriptionPlansReturn } from '../graphql/common';

type SubscriptionPlanPriceCardProps = ElementProps<'div'> &
	CardProps & {
		plan: GetSubscriptionPlansReturn[number];
		selectedPeriod: SubscriptionPeriod;
		isSelected: boolean;
		button: React.ReactNode;
	};

export default function SubscriptionPlanPriceCard({
	plan,
	selectedPeriod,
	isSelected,
	button,
	...props
}: SubscriptionPlanPriceCardProps) {
	const theme = useMantineTheme();

	return (
		<Card
			key={plan.id}
			style={{
				border: isSelected ? `1px solid ${theme.colors.lumiBurntOrange[6]}` : undefined,
			}}
			{...props}
		>
			<Stack justify="space-evenly" h="100%">
				<Box h="100%">
					<Stack>
						<Box>
							<Title order={4} ta="center">
								{plan.name}
							</Title>
							<Title order={1} ta="center">
								{selectedPeriod === SubscriptionPeriod.Monthly ? `$${plan.monthlyPrice}` : `$${plan.yearlyPrice}`}
							</Title>
							{selectedPeriod === SubscriptionPeriod.Yearly && (
								<Title order={5} ta="center" c="red">
									Save ${plan.monthlyPrice * 12 - plan.yearlyPrice} a year!
								</Title>
							)}
						</Box>
						<Text size="sm" mih={{ xs: 90 }}>
							{plan.description}
						</Text>
						<List mr="lg">
							{plan.bullets.map((bullet) => (
								<List.Item key={bullet}>
									<Text size="xs">{bullet}</Text>
								</List.Item>
							))}
						</List>
					</Stack>
				</Box>
				{button}
			</Stack>
		</Card>
	);
}
