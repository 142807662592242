import { useMutation, useQuery } from '@apollo/client';
import {
	Button,
	Checkbox,
	ColorSwatch,
	FileButton,
	Group,
	Image,
	InputLabel,
	NumberInput,
	SimpleGrid,
	Stack,
	Text,
	TextInput,
	Textarea,
	useMantineTheme,
} from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateItemInput, CreateItemInputSchema, Folder, ResultOf, graphql } from '@shared';
import useImageUploader from '../../hooks/useImageUploader';
import useIsMobileView from '../../hooks/useIsMobileView';
import { clearEntitiesFromCache } from '../../utils/cache';
import { isDevelopment } from '../../utils/environment';
import { sleep } from '../../utils/sleep';

type DisplayColor = {
	variantIds: number[];
	color: string;
	colorCode: string;
};

type MockupImageUrl = {
	variantId: number;
	imageUrl: string;
};

export default function CreateItem() {
	const navigate = useNavigate();
	const theme = useMantineTheme();
	const isMobileView = useIsMobileView();
	const [selectedItem, setSelectedItem] = useState<GetTemplateItemsReturn[number]>();
	const [selectedVariant, setSelectedVariant] = useState<GetTemplateItemsReturn[number]['variants'][number]>();
	const [selectedColors, setSelectedColors] = useState<DisplayColor[]>([]);
	const [mockupImageUrls, setMockupImageUrls] = useState<MockupImageUrl[]>([]);
	const [pendingImage, setPendingImage] = useState<File>();
	const [doMockupsMatchSelections, setDoMockupsMatchSelections] = useState(false);
	const [isMockupGeneratorCoolingDown, setIsMockupGeneratorCoolingDown] = useState(false);
	const { uploadImage } = useImageUploader();
	const { data } = useQuery(getTemplateItemsQuery);
	const [generateItemMockup, { loading: generateItemMockupLoading }] = useMutation(generateItemMockupsMutation);
	const [createItem, { loading: createItemLoading }] = useMutation(createItemMutation);

	// the available colors of the selected item
	const colors: DisplayColor[] = useMemo(() => {
		const record: Record<string, DisplayColor> = {};
		selectedItem?.variants.forEach((variant) => {
			record[variant.color] = {
				variantIds: record[variant.color] ? [...record[variant.color].variantIds, variant.id] : [variant.id],
				color: variant.color,
				colorCode: variant.colorCode,
			};
		});
		return Object.entries(record).map(([color, { variantIds, colorCode }]) => ({ variantIds, color, colorCode }));
	}, [selectedItem]);

	// the available sizes of the selected item
	const sizes: string[] = useMemo(() => {
		const record: Record<string, boolean> = {};
		for (const variant of selectedItem?.variants || []) {
			record[variant.size] = true;
		}
		const sortList = ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];
		return Object.keys(record).sort((a, b) => sortList.indexOf(a) - sortList.indexOf(b));
	}, [selectedItem]);

	const form = useForm<CreateItemInput>({
		validate: zodResolver(CreateItemInputSchema),
		validateInputOnBlur: true,
		initialValues: {
			externalProviderId: 0,
			name: '',
			description: '',
			price: 0,
			placements: [],
			itemVariants: [],
		},
	});

	// Set the viewing image to the image of the variant that was last clicked
	useEffect(() => {
		if (selectedItem && selectedColors.length > 0) {
			setSelectedVariant(
				selectedItem.variants.find((variant) => variant.color === selectedColors[selectedColors.length - 1].color)
			);
		}
	}, [selectedItem, selectedColors]);

	// If a different image is uploaded, invalidate the mockup image
	useEffect(() => {
		setMockupImageUrls([]);
	}, [selectedItem, pendingImage]);

	// When an item is selected, or deselected, update the form
	useEffect(() => {
		if (selectedItem) {
			form.setValues({
				externalProviderId: selectedItem.id,
				name: selectedItem.name,
				description: '',
				price: selectedItem.minimumPrice * 1.25,
				itemVariants: [],
				placements: [],
			});
		} else {
			form.reset();
		}
		setSelectedColors([]);
		setSelectedVariant(undefined);
		setMockupImageUrls([]);
		setDoMockupsMatchSelections(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItem]);

	// as colors or mockups get updated, keep the form in sync
	useEffect(() => {
		form.setValues({
			itemVariants:
				selectedItem?.variants
					.filter((variant) => selectedColors.some((color) => color.color === variant.color))
					.map((variant) => ({
						size: variant.size,
						color: variant.color,
						imageUrls: (() =>
							mockupImageUrls
								.filter((image) =>
									selectedColors.find((c) => c.color === variant.color)!.variantIds.includes(image.variantId)
								)
								.map((image) => image.imageUrl))(),
					})) || [],
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedColors, mockupImageUrls]);

	// an time the colors are changes, invalidate the mockups to prevent the user from creating an item with mismatched mockups
	useEffect(() => setDoMockupsMatchSelections(false), [selectedColors]);

	async function handleGenerateMockup() {
		if (!selectedItem || !selectedVariant || !pendingImage) {
			return;
		}
		const imageUrl = await uploadImage(pendingImage, Folder.ShopItemGraphics);
		form.setValues({
			placements: [
				{
					placement: selectedItem.placement,
					technique: selectedItem.technique,
					layers: [{ imageUrl }],
				},
			],
		});
		await generateItemMockup({
			variables: {
				input: {
					productId: selectedItem.id,
					variantIds: selectedColors.map((color) => color.variantIds[0]),
					imageUrl,
				},
			},
			onCompleted: (data) => {
				setMockupImageUrls(data.generateItemMockups);
				form.setValues({
					itemVariants: form.values.itemVariants.map((variant) => {
						if (variant.color === selectedVariant.color) {
							return {
								...variant,
								imageUrls: [
									data.generateItemMockups.find((mockup) => mockup.variantId === selectedVariant.id)!.imageUrl,
								],
							};
						}
						return variant;
					}),
				});
				setDoMockupsMatchSelections(true);
			},
		});
		setIsMockupGeneratorCoolingDown(true);
		// sleep for a minute to prevent spamming the mockup generator, since the printful api rate limits us to 10 / minute
		await sleep(isDevelopment() ? 0 : 30000);
		setIsMockupGeneratorCoolingDown(false);
	}

	async function handleCreateItem() {
		await createItem({
			variables: {
				input: form.values,
			},
			onCompleted() {
				navigate('/my-shop/items');
			},
			update: (cache) => {
				clearEntitiesFromCache(cache, ['items']);
			},
		});
	}

	return (
		<Stack>
			<SimpleGrid cols={9}>
				{data?.templateItems.map((item) => (
					<Image
						key={item.id}
						src={item.imageUrl}
						alt={item.name}
						onClick={() => setSelectedItem(item)}
						style={{
							border: item.id === selectedItem?.id ? `1px solid ${theme.colors.lumiBurntOrange[6]}` : undefined,
							cursor: 'pointer',
						}}
					/>
				))}
			</SimpleGrid>
			{selectedItem ? (
				<Group align="start">
					<Stack>
						<Image
							src={selectedVariant?.imageUrl || selectedItem.imageUrl}
							alt={selectedItem.name}
							mah={500}
							maw={300}
						/>
						{mockupImageUrls.map((image) => (
							<Image key={image.imageUrl} src={image.imageUrl} mah={500} maw={300} />
						))}
					</Stack>
					<Stack flex={!isMobileView ? 1 : undefined}>
						<TextInput label="Name" size="xs" {...form.getInputProps('name')} />
						<Textarea label="Description" size="xs" {...form.getInputProps('description')} />
						<Stack gap="xs">
							<InputLabel size="xs">Available Colors</InputLabel>
							<SimpleGrid cols={2}>
								{colors.map((color) => (
									<Checkbox
										key={color.color}
										label={
											<Group gap="xs">
												<ColorSwatch color={color.colorCode} size={16} />
												<Text size="sm">{color.color}</Text>
											</Group>
										}
										checked={selectedColors.includes(color)}
										onChange={(event) => {
											setSelectedColors((prev) => {
												if (event.target.checked) {
													return [...prev, color];
												}
												return prev.filter((c) => c !== color);
											});
										}}
									/>
								))}
							</SimpleGrid>
						</Stack>
						<Stack gap="xs">
							<InputLabel size="xs">Available Sizes</InputLabel>
							<Group gap="xs">
								{sizes.map((size) => (
									<Text key={size} size="xs">
										{size}
									</Text>
								))}
							</Group>
						</Stack>
						<Stack gap="xs">
							<InputLabel size="xs">Product Specs</InputLabel>
							<Text
								size="xs"
								style={{
									whiteSpace: 'pre-wrap',
								}}
							>
								{selectedItem.specs}
							</Text>
						</Stack>
						<NumberInput
							label="Selling price"
							description={`The minimum price you can sell this item for is $${selectedItem.minimumPrice}`}
							size="xs"
							{...form.getInputProps('price')}
							min={selectedItem.minimumPrice}
						/>
						<Group align="baseline" mt="xs" ml="auto">
							<Button
								disabled={!selectedVariant || !pendingImage || isMockupGeneratorCoolingDown}
								loading={generateItemMockupLoading}
								onClick={async () => await handleGenerateMockup()}
								size="xs"
							>
								Generate mockup
							</Button>
							<FileButton onChange={(file) => setPendingImage(file || undefined)} accept="image/png,image/jpg">
								{(props) => (
									<Button size="xs" {...props}>
										Upload image
									</Button>
								)}
							</FileButton>
							<Button
								disabled={!form.isValid() || mockupImageUrls.length === 0 || !doMockupsMatchSelections}
								loading={createItemLoading}
								onClick={async () => await handleCreateItem()}
								size="xs"
							>
								Create
							</Button>
						</Group>
						<Group>{pendingImage && <Image src={URL.createObjectURL(pendingImage)} w="50%" />}</Group>
					</Stack>
				</Group>
			) : (
				<Text>Select an item to get started creating your merchandise</Text>
			)}
		</Stack>
	);
}

type GetTemplateItemsReturn = ResultOf<typeof getTemplateItemsQuery>['templateItems'];
const getTemplateItemsQuery = graphql(`
	query GetTemplateItems {
		templateItems {
			id
			name
			specs
			imageUrl
			type
			minimumPrice
			placement
			technique
			variants {
				id
				color
				colorCode
				size
				imageUrl
			}
		}
	}
`);

const generateItemMockupsMutation = graphql(`
	mutation GenerateItemMockupsInput($input: GenerateItemMockupsInput!) {
		generateItemMockups(input: $input) {
			variantId
			imageUrl
		}
	}
`);

const createItemMutation = graphql(`
	mutation CreateItem($input: CreateItemInput!) {
		createItem(input: $input) {
			id
		}
	}
`);
