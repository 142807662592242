import { fabric } from 'fabric';
import {
	Font,
	GeneratedDialogue,
	ImageDimensions,
	InitialBorderRadius,
	InitialBubbleHeight,
	InitialBubbleWidth,
	Panel,
	Shape,
	TextAlignment,
	calculateShapeScaleFactors,
	getProportions,
} from '@shared';
import { createShape } from './shape';

export const Defaults = {
	TextBubble: {
		BackgroundColor: '#FFF',
		BorderColor: '#000',
		TextColor: '#000',
		TextSize: 24,
		TextAlignment: TextAlignment.Center,
		BorderWidth: 2,
		Width: ImageDimensions.story.width * 0.85,
	},
	CaptionFont: Font.Arial,
	DialogueFont: Font.Arial,
	// TODO: Will implement this when combining Dialogue + Caption objects
	// const CaptionShape = Shape.Rectangle;
	DialogueShape: Shape.RectangleWithTail,
	MaxCanvasWidth: ImageDimensions.story.width,
	MaxCanvasHeight: ImageDimensions.story.height,
};

export type CanvasData = {
	backgroundImage: fabric.Image;
	objects: (CaptionObject | DialogueObject)[];
};

export type CanvasObject = fabric.Group & BubbleProps;
export type CaptionObject = CanvasObject;
export type DialogueObject = CanvasObject;

type BubbleProps = {
	index: number;
	shape: Shape;
	name: 'Caption' | 'Dialogue';
};
const BubblePropKeys: (keyof BubbleProps)[] = ['index', 'shape', 'name'];

export function isCaptionObject(object: fabric.Object): object is CaptionObject {
	return object.name === 'Caption';
}

export function isDialogueObject(object: fabric.Object): object is DialogueObject {
	return object.name === 'Dialogue';
}

const CaptionObject = fabric.util.createClass(fabric.Group, {
	initialize: function (bubble: fabric.Path, textBox: fabric.Textbox, options: fabric.IGroupOptions, index: number) {
		this.callSuper('initialize', [bubble, textBox], options);
		this.set('index', index);
		// Must use name, type is a reserved word for Fabric objects
		this.set('name', 'Caption');
	},

	toObject: function () {
		return fabric.util.object.extend(this.callSuper('toObject'), {
			index: this.get('index'),
			name: this.get('name'),
		});
	},
});

const DialogueObject = fabric.util.createClass(fabric.Group, {
	initialize: function (
		bubble: fabric.Path,
		textBox: fabric.Textbox,
		options: fabric.IGroupOptions,
		index: number,
		shape: Shape
	) {
		this.callSuper('initialize', [bubble, textBox], options);
		this.set('index', index);
		// Must use name, type is a reserved word for Fabric objects
		this.set('name', 'Dialogue');
		this.set('shape', shape);
	},

	toObject: function () {
		return fabric.util.object.extend(this.callSuper('toObject'), {
			index: this.get('index'),
			name: this.get('name'),
			shape: this.get('shape'),
		});
	},

	// Has to be a function, as setting it as a property loses key pieces of context, such as the type (in this case 'textbox')
	textBox: function () {
		return this.getObjects('textbox')[0];
	},
});

// Helper function to get objects in our types
function getObjects(canvas: fabric.Canvas): CanvasObject[] {
	return canvas.getObjects() as CanvasObject[];
}

// Helper function to get JSON in our types, as well as preserve custom properties
export function getCanvasData(canvas: fabric.Canvas): CanvasData {
	const customProperties = [...BubblePropKeys, 'width', 'height', 'scaleX', 'scaleY'];
	return canvas.toJSON(customProperties) as unknown as CanvasData;
}

// TODO: Remove this function and go to class-based `textBox` property.
// This is a limitation with Fabric.js, likely resolved by upgrading to V6 beta
export function getTextBox(object: CanvasObject): fabric.Textbox {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const objectArray = object.objects ?? object._objects;
	return objectArray[1] as fabric.Textbox;
}

// TODO: Remove this function and go to class-based `textBox` property.
// This is a limitation with Fabric.js, likely resolved by upgrading to V6 beta
function getBubble(object: CanvasObject): fabric.Path {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const objectArray = object.objects ?? object._objects;
	return objectArray[0] as fabric.Path;
}

export function updateObjectText(
	canvas: fabric.Canvas,
	name: CanvasObject['name'],
	index: number,
	values: fabric.ITextOptions
) {
	const object = getObject(canvas, name, index);
	if (!object) {
		throw new Error(`Unable to find canvas object ${name}, ${index}`);
	}
	getTextBox(object).setOptions({
		...values,
	});
	// Ensures the entire object gets updated with the TextBox
	object.addWithUpdate();
	canvas.renderAll();
}

export function addEmptyCaptionObject(canvas: fabric.Canvas, index: number): CaptionObject {
	const newObject = createCaptionObject({ text: '', index });
	canvas.add(newObject);
	canvas.renderAll();
	return newObject;
}

export function addDialogueObject(
	canvas: fabric.Canvas,
	characterName: string,
	text: string,
	index: number
): DialogueObject {
	const newObject = createDialogueObject({ characterName, text, index });
	canvas.add(newObject);
	canvas.renderAll();
	return newObject;
}

export function addEmptyDialogueObject(canvas: fabric.Canvas, index: number): DialogueObject {
	const newObject = createDialogueObject({ characterName: '', text: '', index });
	canvas.add(newObject);
	canvas.renderAll();
	return newObject;
}

export function removeObject(canvas: fabric.Canvas, name: CanvasObject['name'], index: number) {
	const objects = getObjects(canvas);
	objects.forEach((object: CanvasObject) => {
		if (object.name === name && object.index === index) {
			canvas.remove(object);
		} else if (object.name === name && object.index > index) {
			// Remap indices above the deleted object index to fill the gap
			object.setOptions({ index: object.index - 1 });
		}
	});
	canvas.renderAll();
}

export function getObject(
	canvas: fabric.Canvas,
	name: CanvasObject['name'],
	index: number
): DialogueObject | CaptionObject {
	const objects = getObjects(canvas);
	switch (name) {
		case 'Dialogue':
			return objects.find((o) => isDialogueObject(o) && o.index === index)!;
		case 'Caption':
			return objects.find((o) => isCaptionObject(o))!;
	}
}

export function findObject(
	canvas: fabric.Canvas,
	name: CanvasObject['name'],
	index: number
): DialogueObject | CaptionObject | undefined {
	const objects = getObjects(canvas);
	switch (name) {
		case 'Dialogue':
			return objects.find((o) => isDialogueObject(o) && o.index === index);
		case 'Caption':
			return objects.find((o) => isCaptionObject(o));
	}
}

// Given `canvasData`, uses the background image's real dimensions to calculate the max canvas size which preserves the story aspect ratio
function maximizeImageDimensionsForImageDimensions(
	canvasData: CanvasData,
	aspectRatio: number
): { width: number; height: number } {
	const originalImageWidth = canvasData.backgroundImage.width!;
	const originalImageHeight = canvasData.backgroundImage.height!;
	// Is the original image wider than the aspect ratio?
	const isWider = originalImageWidth / originalImageHeight > aspectRatio;
	// If wider, max out height, then calculate the width from the height * aspectRatio
	// If narrower/equal, max out width, then calculate height from width / aspectRatio
	const width = isWider ? originalImageHeight * aspectRatio : originalImageWidth;
	const height = isWider ? originalImageHeight : originalImageWidth / aspectRatio;
	return {
		width,
		height,
	};
}

export async function createImageData(canvasData: CanvasData, index: number): Promise<string> {
	const tempCanvas = new fabric.Canvas(`page-canvas-temp-${index}`);
	const canvasDimensions = maximizeImageDimensionsForImageDimensions(canvasData, ImageDimensions.story.ratio);
	return new Promise((resolve) => {
		// Load the scene's canvasData
		tempCanvas.loadFromJSON(canvasData, function () {
			adjustCanvasSize(tempCanvas, canvasDimensions.width, canvasDimensions.height);
			const imageDataUrl = tempCanvas.toDataURL({
				format: 'png',
			});
			tempCanvas.dispose();
			resolve(imageDataUrl);
		});
	});
}

// Used to create the initial canvas data when the scenes are first generated
// If a creator is manually uploading their scenes, no `imageURL` will be provided
export async function initializeCanvasData(panel: Panel): Promise<CanvasData> {
	// Dummy parentWidth since this is initializing pre-component render
	const parentWidth = Defaults.MaxCanvasWidth;
	const parentHeight = Defaults.MaxCanvasHeight;
	const canvas = new fabric.Canvas(`page-canvas-temp-${panel.pageIndex}`, {
		width: parentWidth,
		height: parentHeight,
	});

	const initialCaption = panel.caption ? createCaptionObject({ text: panel.caption, index: 0 }) : undefined;
	const initialDialogues = panel.dialogues.map(createDialogueObject);
	if (panel.selectedImageDraft?.imageUrl) {
		await setBackgroundImage(canvas, panel.selectedImageDraft.imageUrl);
	}
	initialDialogues.forEach((d) => canvas.add(d));
	if (initialCaption) {
		canvas.add(initialCaption);
		// TODO: Bottom center the caption. Doesn't work because of current dummy height != actual height later
		// So the top and left are incorrect later
		initialCaption.set('top', parentHeight - initialCaption.height!);
		initialCaption.set('left', parentWidth / 2 - initialCaption.width! / 2);
	}

	adjustCanvasSize(canvas, parentWidth, parentHeight);
	const canvasData = getCanvasData(canvas);
	canvas.dispose();
	return canvasData;
}

export async function loadCanvas(canvasData: CanvasData, document: Document, index: number): Promise<fabric.Canvas> {
	const parentWidth = Math.min(document.getElementById('page-canvas-parent')!.clientWidth, Defaults.MaxCanvasWidth);
	// TODO: Allow this to preserve aspect ratio. For now, always a square
	const parentHeight = 1.5 * parentWidth;
	const canvas = new fabric.Canvas(`page-canvas-${index}`);
	return new Promise((resolve) => {
		canvas.loadFromJSON(
			canvasData,
			() => {
				adjustCanvasSize(canvas, parentWidth, parentHeight);
				// Recalculate coordinates for all objects
				canvas.forEachObject((object) => {
					object.setCoords();
				});
				resolve(canvas);
			},
			(_jsonObject: unknown, object: fabric.Object) => customReviver(_jsonObject, object, canvas)
		);
	});
}

// Function for further parsing objects in `loadFromJSON`. Called after each fabric object is created.
function customReviver(_jsonObject: unknown, object: fabric.Object, canvas: fabric.Canvas): void {
	if (isCaptionObject(object) || isDialogueObject(object)) {
		const group = object as CanvasObject;
		const textBox = getTextBox(group);

		group.on('modified', function () {
			let isDirty = false;
			if (group.flipX && !textBox.flipX) {
				textBox.flipX = true;
				isDirty = true;
			} else if (!group.flipX && textBox.flipX) {
				textBox.flipX = false;
				isDirty = true;
			}

			if (group.flipY && !textBox.flipY) {
				textBox.flipY = true;
				isDirty = true;
			} else if (!group.flipY && textBox.flipY) {
				textBox.flipY = false;
				isDirty = true;
			}

			if (isDirty) {
				canvas.renderAll();
			}
		});
	}
}

// Loads the image and scales it to cover the entire canvas
export async function setBackgroundImage(canvas: fabric.Canvas, url: string): Promise<void> {
	return new Promise((resolve) => {
		fabric.util.loadImage(
			url,
			(imageElement) => {
				const image = new fabric.Image(imageElement, {
					crossOrigin: 'anonymous',
				});
				// Calculate the scaling factor to fit the image within the canvas
				// Calculate scaling factors for both width and height to fit the image within the canvas
				const scaleFactorWidth = canvas.getWidth() / image.width!;
				const scaleFactorHeight = canvas.getHeight() / image.height!;
				// Choose the larger scaling factor to ensure the image covers the entire canvas
				const scaleFactor = Math.max(scaleFactorWidth, scaleFactorHeight);
				image.set({
					scaleX: scaleFactor,
					scaleY: scaleFactor,
					// Center the image within the canvas
					left: (canvas.getWidth() - image.width! * scaleFactor) / 2,
					top: (canvas.getHeight() - image.height! * scaleFactor) / 2,
				});

				canvas.setBackgroundImage(image, () => canvas.renderAll(), {
					scaleX: scaleFactor,
					scaleY: scaleFactor,
					crossOrigin: 'anonymous',
				});
				resolve();
			},
			{},
			'anonymous'
		);
	});
}

function adjustCanvasSize(canvas: fabric.Canvas, newWidth: number, newHeight: number): void {
	const oldWidth = canvas.getWidth();
	const oldHeight = canvas.getHeight();

	const scaleXRatio = newWidth / oldWidth;
	const scaleYRatio = newHeight / oldHeight;

	canvas.setWidth(newWidth);
	canvas.setHeight(newHeight);

	// Scale and reposition the background image
	const { backgroundImage } = canvas;
	if (backgroundImage && backgroundImage instanceof fabric.Image) {
		backgroundImage.scaleX! *= scaleXRatio;
		backgroundImage.scaleY! *= scaleYRatio;
		backgroundImage.left! *= scaleXRatio;
		backgroundImage.top! *= scaleYRatio;
		backgroundImage.setCoords();
	}

	// Scale and reposition all objects
	canvas.forEachObject((object) => {
		object.scaleX! *= scaleXRatio;
		object.scaleY! *= scaleYRatio;
		object.left! *= scaleXRatio;
		object.top! *= scaleYRatio;
		object.setCoords();
	});

	canvas.renderAll();
}

function createCaptionObject({ text, index }: { text: string; index: number }): CaptionObject {
	// TODO: Use shape like Dialogue
	const padding = 10 + InitialBorderRadius;
	// Total width of the group
	const width = Defaults.TextBubble.Width;
	const textBoxWidth = width - padding * 2;

	const textBox = new fabric.Textbox(text, {
		originX: 'center',
		originY: 'center',
		left: textBoxWidth / 2 + padding,
		// Set the top after the height is computed during this Fabric's Textbox instantiation
		width: textBoxWidth,
		fill: Defaults.TextBubble.TextColor,
		fontSize: Defaults.TextBubble.TextSize,
		fontFamily: Defaults.DialogueFont,
		textAlign: Defaults.TextBubble.TextAlignment,
	});
	// Total height of the group
	const height = textBox.height! + padding * 2;
	// Above fabric.TextBox(...) sets the height automatically. Now that it is known, center the text
	textBox.set('top', height / 2);

	const bubble = new fabric.Path(
		[
			`M 0,0`, // Move to top-left corner
			`H ${width}`, // Horizontal line to top-right corner
			`V ${height}`, // Vertical line to bottom-right corner
			`H 0`, // Horizontal line to bottom-left corner
			`Z`, // Close the path
		].join(' '),
		{
			left: 0,
			top: 0,
			fill: Defaults.TextBubble.BackgroundColor,
			stroke: Defaults.TextBubble.BorderColor,
			strokeWidth: Defaults.TextBubble.BorderWidth,
			strokeUniform: true,
		}
	);

	// The group is used to keep the bubble and text together
	return new CaptionObject(
		bubble,
		textBox,
		{
			left: 0,
			top: 0,
			hasControls: true,
		},
		index
	);
}

function createDialogueObject(dialogue: GeneratedDialogue): DialogueObject {
	const bubbleShape = Defaults.DialogueShape;
	const bubbleProportions = getProportions(bubbleShape);

	// Step 1. Create the bubble and textBox
	const bubble = createShape(bubbleShape);
	const textBoxWidth = Defaults.TextBubble.Width * (1 - bubbleProportions.bodyPadding);
	const textBox = new fabric.Textbox(dialogue.text, {
		originX: 'center',
		originY: 'center',
		// Set the top after the height is computed during this Fabric's Textbox instantiation
		width: textBoxWidth,
		fill: Defaults.TextBubble.TextColor,
		fontSize: Defaults.TextBubble.TextSize,
		fontFamily: Defaults.DialogueFont,
		textAlign: Defaults.TextBubble.TextAlignment,
	});

	// Step 2. Scale the bubble to the size of the textBox
	// The textBox height fluctuates based on the text, so use the max height between that and the bubble default (we don't want it shrinking much below this)
	const maxGroupHeight = Math.max(textBox.height!, bubble.height!);
	const scaleX = (textBox.width! / bubble.width!) * (1 + bubbleProportions.bodyPadding);
	const scaleY = (maxGroupHeight / bubble.height!) * (1 + bubbleProportions.bodyPadding + bubbleProportions.bodyY);
	bubble.set({
		scaleX,
		scaleY,
	});

	// Step 3. Adjust the textBox so that it is centered in the bubble's body, not the the total height of the body + tail
	const scaledBubbleHeight = scaleY * bubble.height!;
	const tailProportionY = 1 - bubbleProportions.bodyY;
	textBox.set({
		top: -(scaledBubbleHeight * tailProportionY) / 2,
	});

	// The group is used to keep the bubble and text together
	return new DialogueObject(
		bubble,
		textBox,
		{
			left: 0,
			top: 0,
			hasControls: true,
		},
		dialogue.index,
		Defaults.DialogueShape
	);
}

export function updateShape(canvas: fabric.Canvas, index: number, shape: Shape) {
	// TODO: Combine DialogueObject and CaptionObject to prevent needing the cast
	const dialogueObject = getObject(canvas, 'Dialogue', index) as DialogueObject;
	// Little bit easier to reason about as a group
	// Step 1. Setup: access the old bubble and create the new one
	const group = dialogueObject;
	const oldBubble = getBubble(dialogueObject);
	const newBubble = createShape(shape);

	// Step 2. Shape Scale Factors: calculate the amount of scaling needed from the old bubble's shape to the new bubble's shape
	// E.g. if the old bubble had a tail and the new bubble doesn't, the new bubble's total size should only be within the old bubble's body size (i.e. non-tail portion)
	const shapeScaleFactors = calculateShapeScaleFactors(shape, group.shape);
	const shapeScaleFactorWidth = shapeScaleFactors.bodyPadding;
	const bodyScaleFactorHeight = shapeScaleFactors.bodyPadding * shapeScaleFactors.bodyY;
	// Step 3. Fabric Scale Factors: calculate the amount that Fabric scaled the bubble and the group, which is changed on when either the group or canvas is resized
	const bubbleScaleX = group.scaleX! * oldBubble.scaleX!;
	const bubbleScaleY = group.scaleY! * oldBubble.scaleY!;
	const scaleX = bubbleScaleX * shapeScaleFactorWidth;
	const scaleY = bubbleScaleY * bodyScaleFactorHeight;
	// Step 4. X, Y Offset: calculate the new bubble's left/top position from the width and height
	const newShapeWidth = scaleX * InitialBubbleWidth;
	const newShapeHeight = scaleY * InitialBubbleHeight;
	const left = group.left! + (newShapeWidth * (1 - shapeScaleFactors.bodyPadding)) / 2;
	const top = group.top! + (newShapeHeight * (1 - shapeScaleFactors.bodyPadding)) / 2;

	newBubble.set({
		originX: 'left',
		originY: 'top',
		left,
		top,
		scaleX,
		scaleY,
	});

	dialogueObject.removeWithUpdate(oldBubble);
	dialogueObject.addWithUpdate(newBubble);
	dialogueObject.set('shape', shape);
	// Move it as the first object in the group, so it's the background
	newBubble.moveTo(0);
	// Ensure the group's bounding box is recalculated
	dialogueObject.setCoords();
	canvas.renderAll();
}
