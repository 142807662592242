import { Center, Loader, LoaderProps } from '@mantine/core';

type LoadingProps = LoaderProps;

export default function Loading({ ...loaderProps }: LoadingProps) {
	return (
		<Center>
			<Loader size="sm" {...loaderProps} />
		</Center>
	);
}
