import { zod, zodId } from '../utils';
import { ClientImageDraftSchema } from './clientImageDraft';
import { ArtStyle, StoryType } from './enums';
import { StoryImageType } from './enums/storyImageType';

const CharacterSchema = zod.object({
	id: zodId('character'),
	name: zod.string().min(1),
	description: zod.string(),
	imageUrl: zod.string().url(),
});

const IdeaSchema = zod.string();

const ScriptSchema = zod.string();

const DialogueSchema = zod.object({
	index: zod.number().min(0),
	// This could be a minor character not saved in the database
	characterId: zodId('character').optional(),
	characterName: zod.string().min(1),
	text: zod.string().min(1),
});

export type Cover = zod.infer<typeof CoverSchema>;
export const CoverSchema = zod.object({
	id: zodId('coverdraft'),
	// Genre of the story
	genres: zod.array(zod.string()).optional(),
	// Characters in the cover
	characterIds: zod.array(zodId('character')).optional(),
	// The image that was last selected when generating images
	selectedImageDraft: ClientImageDraftSchema.optional(),
	// The final image to use as the cover
	// When not uploading, this mirrors `selectedImageDraft`
	// When uploading, this is only set when an image is uploaded
	image: ClientImageDraftSchema.optional(),
});

export type Panel = zod.infer<typeof PanelSchema>;
export const PanelSchema = zod.object({
	id: zodId('paneldraft'),
	pageIndex: zod.number().int().min(0),
	index: zod.number().int().min(0),
	// What is happening in the scene
	description: zod.string().min(1),
	setting: zod.string().min(1),
	caption: zod.string().optional(),
	dialogues: zod.array(DialogueSchema),
	// Characters in the scene
	characterIds: zod.array(zodId('character')).optional(),
	// The image that was last selected when generating images or uploading
	selectedImageDraft: ClientImageDraftSchema.optional(),
	// TODO: Move this to PageSchema
	// Keeps track of the latest state of the canvas (i.e. dialogue, captions, and even which image is set as the background). Any updates to the canvas will get saved here
	canvasData: zod.any().optional(),
});

export const PageSchema = zod.object({
	index: zod.number().int().min(0),
	// Represents the image to use for the page when a creator is uploading their own illustrations
	// This will be set in Pages, before Preview
	image: ClientImageDraftSchema.optional(),
	// When a creator is not uploading their own illustrations, this will hold the image data from the canvas, set during Pages —> Preview transition
	// Note, this is image data, not a remote URL: http://fabricjs.com/docs/fabric.Canvas.html#toDataURL
	canvasImageData: zod.string().optional(),
});

export const DetailsSchema = zod.object({
	title: zod.string().min(1),
	summary: zod.string().min(1),
	genres: zod.array(zod.string()),
});

export const SettingsSchema = zod.object({
	storyType: zod.nativeEnum(StoryType),
	imageType: zod.nativeEnum(StoryImageType),
	artStyle: zod.nativeEnum(ArtStyle),
	numberOfPages: zod.number().int().min(1).max(24),
});

export type StoryDraft = zod.infer<typeof StoryDraftSchema>;
export const StoryDraftSchema = zod.object({
	id: zodId('storydraft'),
	characters: zod.array(CharacterSchema),
	// TODO: Reconcile uploaded story and if it should be stored here, or just passed in as companion context
	idea: IdeaSchema,
	script: ScriptSchema,
	panels: zod.array(PanelSchema),
	pages: zod.array(PageSchema),
	cover: CoverSchema,
	details: DetailsSchema,
	settings: SettingsSchema,
});
