import { Box } from '@mantine/core';
import { PropsWithChildren } from 'react';

type AppContainerProps = PropsWithChildren;

export function AppContainer({ children }: AppContainerProps) {
	return (
		<Box
			style={{
				height: '100dvh',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{children}
		</Box>
	);
}
