/* eslint-disable */
// @ts-nocheck
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { ImageKind } from 'packages/shared/src';
import { ShopBalanceEventType } from 'packages/shared/src';
import { SubscriptionEventType } from 'packages/shared/src';
import { TokenBalanceTransactionEventType } from 'packages/shared/src';
import { TutorialType } from 'packages/shared/src';
import { UserRole } from 'packages/shared/src';
import { BaseObject } from '@shared';
import { ServerContext } from '../../types/serverContext';

type Maybe<T> = T | null;
type InputMaybe<T> = T | undefined;
type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
type Scalars = {
	ID: { input: string; output: string };
	String: { input: string; output: string };
	Boolean: { input: boolean; output: boolean };
	Int: { input: number; output: number };
	Float: { input: number; output: number };
	DateTime: { input: Date; output: Date };
	JSON: { input: unknown; output: unknown };
	LiteralEditedImage: { input: ImageKind.EditedImage; output: ImageKind.EditedImage };
	LiteralGeneratedImage: { input: ImageKind.GeneratedImage; output: ImageKind.GeneratedImage };
	ShopBalanceEventType: { input: ShopBalanceEventType; output: ShopBalanceEventType };
	SubscriptionEventType: { input: SubscriptionEventType; output: SubscriptionEventType };
	TokenBalanceTransactionEventType: {
		input: TokenBalanceTransactionEventType;
		output: TokenBalanceTransactionEventType;
	};
	TutorialType: { input: TutorialType; output: TutorialType };
	UserRole: { input: UserRole; output: UserRole };
};

type IAddCartInputItemVariant = {
	itemVariantId: Scalars['ID']['input'];
};

type IAuthResponse = {
	callbackUrl: Scalars['String']['output'];
};

type IAuthUser = {
	addresses: Array<IUserAddress>;
	bio?: Maybe<Scalars['String']['output']>;
	createdAt: Scalars['DateTime']['output'];
	currentSubscription?: Maybe<IUserSubscription>;
	displayName: Scalars['String']['output'];
	email: Scalars['String']['output'];
	externalPaymentProcessorId?: Maybe<Scalars['ID']['output']>;
	favoriteStories: Array<IStory>;
	firstName: Scalars['String']['output'];
	followers: Array<IUser>;
	followersCount?: Maybe<Scalars['Int']['output']>;
	following: Array<IUser>;
	followingCount?: Maybe<Scalars['Int']['output']>;
	id: Scalars['ID']['output'];
	isBlocklisted: Scalars['Boolean']['output'];
	isEmailVerified: Scalars['Boolean']['output'];
	isFollower?: Maybe<Scalars['Boolean']['output']>;
	lastName: Scalars['String']['output'];
	orders: Array<IOrder>;
	passwordHash?: Maybe<Scalars['String']['output']>;
	profilePictureImageUrl?: Maybe<Scalars['String']['output']>;
	role: Scalars['UserRole']['output'];
	shop?: Maybe<IShop>;
	stories: Array<IStory>;
	subscriptions: Array<IUserSubscription>;
	tokenBalance: ITokenBalance;
	tokenBalanceEvents: Array<ITokenBalanceEvent>;
	updatedAt: Scalars['DateTime']['output'];
	username: Scalars['String']['output'];
};

type IBuyItemsInput = {
	externalProviderId: Scalars['Int']['input'];
	itemVariantIds: Array<Scalars['ID']['input']>;
	paymentMethodId: Scalars['ID']['input'];
	userAddressId: Scalars['ID']['input'];
};

type IBuyTokensInput = {
	tokens: Scalars['Int']['input'];
};

type ICartItemVariant = {
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	itemVariant: IItemVariant;
	itemVariantId: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
};

type IChangeSubscriptionPlanInput = {
	period: Scalars['String']['input'];
	subscriptionPlanId: Scalars['String']['input'];
};

type ICharacter = {
	createdAt: Scalars['DateTime']['output'];
	creator: IUser;
	creatorId: Scalars['ID']['output'];
	description: Scalars['String']['output'];
	id: Scalars['ID']['output'];
	imageUrl: Scalars['String']['output'];
	name: Scalars['String']['output'];
	stories: Array<IStory>;
	updatedAt: Scalars['DateTime']['output'];
};

type ICharacterDraft = {
	artStyle?: Maybe<Scalars['String']['output']>;
	createdAt: Scalars['DateTime']['output'];
	creator?: Maybe<IUser>;
	creatorId: Scalars['ID']['output'];
	description?: Maybe<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	name?: Maybe<Scalars['String']['output']>;
	selectedImageDraft?: Maybe<IClientImageDraft>;
	selectedImageDraftId?: Maybe<Scalars['ID']['output']>;
	updatedAt: Scalars['DateTime']['output'];
};

type ICharactersFilterInput = {
	characterIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	creatorId?: InputMaybe<Scalars['ID']['input']>;
};

type IClientImage = {
	id: Scalars['ID']['output'];
	imageUrl?: Maybe<Scalars['String']['output']>;
	kind: Scalars['String']['output'];
	status: Scalars['String']['output'];
};

type IClientImageDraft = {
	id: Scalars['ID']['output'];
	imageUrl?: Maybe<Scalars['String']['output']>;
	kind: IImageKind;
	status: IImageStatus;
};

type ICommentOnStoryInput = {
	parentStoryCommentId?: InputMaybe<Scalars['ID']['input']>;
	storyId: Scalars['ID']['input'];
	text: Scalars['String']['input'];
};

enum IContactUsMessageType {
	Bug = 'Bug',
	FeatureRequest = 'FeatureRequest',
	Feedback = 'Feedback',
}

type ICreateCharacterInput = {
	artStyle: Scalars['String']['input'];
	characterDraftId: Scalars['ID']['input'];
	description: Scalars['String']['input'];
	imageUrl: Scalars['String']['input'];
	name: Scalars['String']['input'];
	parentCharacterId?: InputMaybe<Scalars['ID']['input']>;
};

type ICreateDialogueInput = {
	characterId?: InputMaybe<Scalars['ID']['input']>;
	characterName: Scalars['String']['input'];
	index: Scalars['Int']['input'];
	text: Scalars['String']['input'];
};

type ICreateImageInput = {
	imageUrl: Scalars['String']['input'];
	isSelected: Scalars['Boolean']['input'];
	kind: Scalars['String']['input'];
	leonardoId?: InputMaybe<Scalars['String']['input']>;
	prompt?: InputMaybe<Scalars['String']['input']>;
	seed?: InputMaybe<Scalars['Int']['input']>;
	userRating?: InputMaybe<Scalars['Int']['input']>;
};

type ICreateItemInput = {
	description: Scalars['String']['input'];
	externalProviderId: Scalars['Int']['input'];
	itemVariants: Array<ICreateItemVariantInput>;
	name: Scalars['String']['input'];
	placements: Array<ICreateItemPlacementInput>;
	price: Scalars['Float']['input'];
};

type ICreateItemPlacementInput = {
	layers: Array<ICreateItemPlacementLayerInput>;
	placement: Scalars['String']['input'];
	technique: Scalars['String']['input'];
};

type ICreateItemPlacementLayerInput = {
	imageUrl: Scalars['String']['input'];
};

type ICreateItemVariantInput = {
	color: Scalars['String']['input'];
	imageUrls: Array<Scalars['String']['input']>;
	size: Scalars['String']['input'];
};

type ICreateOrderDraftInput = {
	itemVariantIds: Array<Scalars['ID']['input']>;
	userAddressId: Scalars['ID']['input'];
};

type ICreatePanelDraftInput = {
	storyDraftId: Scalars['ID']['input'];
};

type ICreatePaymentMethodInput = {
	setupIntentId: Scalars['String']['input'];
};

type ICreateShopBankAccountInput = {
	bankAccountExternalProviderId: Scalars['String']['input'];
	bankAccountExternalProviderMetadata: Scalars['JSON']['input'];
	bankAccountExternalProviderToken: Scalars['String']['input'];
	bankName: Scalars['String']['input'];
};

type ICreateStoryDraftInput = {
	data: Scalars['JSON']['input'];
	lastActiveFormStep: Scalars['Int']['input'];
};

type ICreateStoryInput = {
	coverImageUrl: Scalars['String']['input'];
	pageImages: Array<IPageImage>;
	storyDraftId: Scalars['ID']['input'];
};

type ICreateStoryViewInput = {
	storyId: Scalars['ID']['input'];
};

type ICreateSubscriptionInput = {
	paymentMethodId: Scalars['String']['input'];
	period: Scalars['String']['input'];
	subscriptionPlanId: Scalars['String']['input'];
};

type ICreateUpcomingStoryInput = {
	coverImageUrl: Scalars['String']['input'];
	creatorId: Scalars['ID']['input'];
	storyDraftId?: InputMaybe<Scalars['ID']['input']>;
	storyId?: InputMaybe<Scalars['ID']['input']>;
	title: Scalars['String']['input'];
};

type ICreateUploadedImageDraftInput = {
	sourceDraftId: Scalars['String']['input'];
	uploadedImageUrl: Scalars['String']['input'];
};

type ICreateUserAddressInput = {
	addressLine1: Scalars['String']['input'];
	addressLine2?: InputMaybe<Scalars['String']['input']>;
	city: Scalars['String']['input'];
	country: Scalars['String']['input'];
	state: Scalars['String']['input'];
	zip: Scalars['String']['input'];
};

type IDialogue = {
	character?: Maybe<ICharacter>;
	characterId?: Maybe<Scalars['ID']['output']>;
	characterName: Scalars['String']['output'];
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	index: Scalars['Int']['output'];
	text: Scalars['String']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type IEmailVerificationToken = {
	createdAt: Scalars['DateTime']['output'];
	expiresAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	token: Scalars['String']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
};

type IFavoriteStory = {
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	story: IStory;
	storyId: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IUser;
	userId: Scalars['ID']['output'];
};

type IFavoriteStoryInput = {
	isFavorited: Scalars['Boolean']['input'];
	storyId: Scalars['ID']['input'];
};

type IFileUploadUrlsInput = {
	folder: Scalars['String']['input'];
};

type IFileUploadUrlsResponse = {
	cloudfrontUrl: Scalars['String']['output'];
	uploadUrl: Scalars['String']['output'];
};

type IFollowUserInput = {
	isFollowing: Scalars['Boolean']['input'];
	userId: Scalars['ID']['input'];
};

type IGenerateCharacterHeadshotImagesInput = {
	artStyle: Scalars['String']['input'];
	characterDraftId: Scalars['ID']['input'];
	characterId: Scalars['ID']['input'];
	description: Scalars['String']['input'];
	name: Scalars['String']['input'];
	numberOfImages: Scalars['Int']['input'];
	originalImageDraftId: Scalars['ID']['input'];
};

type IGenerateCharacterImagesInput = {
	artStyle: Scalars['String']['input'];
	characterDraftId: Scalars['ID']['input'];
	description: Scalars['String']['input'];
	name: Scalars['String']['input'];
	numberOfImages: Scalars['Int']['input'];
};

type IGenerateCoverImagesInput = {
	artStyle: Scalars['String']['input'];
	characterIds: Array<Scalars['ID']['input']>;
	coverDraftId: Scalars['ID']['input'];
	genres: Array<Scalars['String']['input']>;
	instructions?: InputMaybe<Scalars['String']['input']>;
	numberOfImages: Scalars['Int']['input'];
	storyDraftId: Scalars['ID']['input'];
	summary?: InputMaybe<Scalars['String']['input']>;
	title?: InputMaybe<Scalars['String']['input']>;
};

type IGenerateItemMockupsInput = {
	imageUrl: Scalars['String']['input'];
	productId: Scalars['Int']['input'];
	variantIds: Array<Scalars['Int']['input']>;
};

type IGenerateSceneImagesInput = {
	artStyle: Scalars['String']['input'];
	caption?: InputMaybe<Scalars['String']['input']>;
	characterIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	description: Scalars['String']['input'];
	dialogues?: InputMaybe<Array<ICreateDialogueInput>>;
	instructions?: InputMaybe<Scalars['String']['input']>;
	numberOfImages: Scalars['Int']['input'];
	panelDraftId: Scalars['ID']['input'];
	setting: Scalars['String']['input'];
	storyDraftId: Scalars['ID']['input'];
};

type IGeneratedDialogue = {
	characterId?: Maybe<Scalars['ID']['output']>;
	characterName: Scalars['String']['output'];
	index: Scalars['Int']['output'];
	text: Scalars['String']['output'];
};

type IGeneratedIdea = {
	genres: Array<Scalars['String']['output']>;
	numberOfScenes: Scalars['Int']['output'];
	plot: Scalars['String']['output'];
	setting: Scalars['String']['output'];
	title: Scalars['String']['output'];
};

type IGeneratedItemMockup = {
	imageUrl: Scalars['String']['output'];
	variantId: Scalars['Int']['output'];
};

type IGeneratedScene = {
	caption?: Maybe<Scalars['String']['output']>;
	characterIds?: Maybe<Array<Scalars['ID']['output']>>;
	description: Scalars['String']['output'];
	dialogues?: Maybe<Array<IGeneratedDialogue>>;
	index: Scalars['Int']['output'];
	setting: Scalars['String']['output'];
};

enum IImageKind {
	EditedImage = 'EditedImage',
	GeneratedImage = 'GeneratedImage',
	UploadedImage = 'UploadedImage',
}

enum IImageStatus {
	Completed = 'Completed',
	Failed = 'Failed',
	Pending = 'Pending',
}

type IItem = {
	createdAt: Scalars['DateTime']['output'];
	description: Scalars['String']['output'];
	externalProviderId: Scalars['Int']['output'];
	id: Scalars['ID']['output'];
	itemVariants: Array<IItemVariant>;
	name: Scalars['String']['output'];
	placements: Array<IItemPlacement>;
	shop: IShop;
	shopId: Scalars['ID']['output'];
	specs: Scalars['String']['output'];
	tags: Array<Scalars['String']['output']>;
	type: Scalars['String']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type IItemPlacement = {
	layers: Array<IItemPlacementLayer>;
	placement: Scalars['String']['output'];
	technique: Scalars['String']['output'];
};

type IItemPlacementLayer = {
	imageUrl: Scalars['String']['output'];
};

type IItemVariant = {
	color: Scalars['String']['output'];
	createdAt: Scalars['DateTime']['output'];
	externalProviderId: Scalars['Int']['output'];
	id: Scalars['ID']['output'];
	item: IItem;
	itemId: Scalars['ID']['output'];
	itemVariantImages: Array<IItemVariantImage>;
	minimumPrice: Scalars['Float']['output'];
	price: Scalars['Float']['output'];
	size: Scalars['String']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type IItemVariantImage = {
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	imageUrl: Scalars['String']['output'];
	index: Scalars['Int']['output'];
	itemVariant: IItemVariant;
	itemVariantId: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type IMutation = {
	acknowledgeTutorial: IUserAcknowledgedTutorial;
	addCartItemVariant: ICartItemVariant;
	buyItems: IOrder;
	buyTokens: Scalars['Boolean']['output'];
	changeSubscriptionPlan: IUserSubscription;
	commentOnStory: IStoryComment;
	createCharacter: ICharacter;
	createCharacterDraft: ICharacterDraft;
	createItem: IItem;
	createOrderDraft: IOrderDraft;
	createPanelDraft: IPanelDraft;
	createPaymentMethod: IPaymentMethod;
	createPaymentMethodSetupIntent: IPaymentMethodSetupIntent;
	createPlaidLinkToken: Scalars['String']['output'];
	createShop: IShop;
	createShopBankAccount: IShopBankAccount;
	createStory: IStory;
	createStoryDraft: IStoryDraftData;
	createStoryView: IStoryView;
	createSubscription: IUserSubscription;
	createUpcomingStory: IUpcomingStory;
	createUploadedImageDraft: IClientImageDraft;
	createUserAddress: IUserAddress;
	deleteTokenAutoPay: Scalars['Boolean']['output'];
	exchangePlaidPublicToken: IPlaidExchangedToken;
	favoriteStory: IFavoriteStory;
	followUser: IUserFollower;
	generateCharacterHeadshotImages: Array<IClientImageDraft>;
	generateCharacterImages: Array<IClientImageDraft>;
	generateCoverImages: Array<IClientImageDraft>;
	generateItemMockups: Array<IGeneratedItemMockup>;
	generateSceneImages: Array<IClientImageDraft>;
	regenerateCoverImages: Array<IClientImageDraft>;
	regenerateSceneImages: Array<IClientImageDraft>;
	removeCartItemVariant: Scalars['Boolean']['output'];
	requestSubscriptionCancellation: IUserSubscription;
	resetPassword: Scalars['Boolean']['output'];
	sendContactUsEmail: Scalars['Boolean']['output'];
	sendForgotPasswordEmail: Scalars['Boolean']['output'];
	signInWithPassword: IAuthResponse;
	signOut: Scalars['Boolean']['output'];
	signUpWithPassword: Scalars['Boolean']['output'];
	updateCharacterDraft: Scalars['Boolean']['output'];
	updatePassword: Scalars['Boolean']['output'];
	updateStoryDraft: IStoryDraftData;
	updateSubscriptionPaymentMethod: IUserSubscription;
	updateTokenAutoPay: ITokenAutoPay;
	updateUpcomingStory: IUpcomingStory;
	updateUser: IAuthUser;
	uploadStory: IGeneratedIdea;
	upscaleImage: IClientImageDraft;
	verifyEmail: IAuthResponse;
	withdrawShopBalance: Scalars['Boolean']['output'];
};

type IMutationAcknowledgeTutorialArgs = {
	tutorialType: Scalars['TutorialType']['input'];
};

type IMutationAddCartItemVariantArgs = {
	input: IAddCartInputItemVariant;
};

type IMutationBuyItemsArgs = {
	input: IBuyItemsInput;
};

type IMutationBuyTokensArgs = {
	input: IBuyTokensInput;
};

type IMutationChangeSubscriptionPlanArgs = {
	input: IChangeSubscriptionPlanInput;
};

type IMutationCommentOnStoryArgs = {
	input: ICommentOnStoryInput;
};

type IMutationCreateCharacterArgs = {
	input: ICreateCharacterInput;
};

type IMutationCreateItemArgs = {
	input: ICreateItemInput;
};

type IMutationCreateOrderDraftArgs = {
	input: ICreateOrderDraftInput;
};

type IMutationCreatePanelDraftArgs = {
	input: ICreatePanelDraftInput;
};

type IMutationCreatePaymentMethodArgs = {
	input: ICreatePaymentMethodInput;
};

type IMutationCreateShopBankAccountArgs = {
	input: ICreateShopBankAccountInput;
};

type IMutationCreateStoryArgs = {
	input: ICreateStoryInput;
};

type IMutationCreateStoryDraftArgs = {
	input: ICreateStoryDraftInput;
};

type IMutationCreateStoryViewArgs = {
	input: ICreateStoryViewInput;
};

type IMutationCreateSubscriptionArgs = {
	input: ICreateSubscriptionInput;
};

type IMutationCreateUpcomingStoryArgs = {
	input: ICreateUpcomingStoryInput;
};

type IMutationCreateUploadedImageDraftArgs = {
	input: ICreateUploadedImageDraftInput;
};

type IMutationCreateUserAddressArgs = {
	input: ICreateUserAddressInput;
};

type IMutationExchangePlaidPublicTokenArgs = {
	publicToken: Scalars['String']['input'];
};

type IMutationFavoriteStoryArgs = {
	input: IFavoriteStoryInput;
};

type IMutationFollowUserArgs = {
	input: IFollowUserInput;
};

type IMutationGenerateCharacterHeadshotImagesArgs = {
	input: IGenerateCharacterHeadshotImagesInput;
};

type IMutationGenerateCharacterImagesArgs = {
	input: IGenerateCharacterImagesInput;
};

type IMutationGenerateCoverImagesArgs = {
	input: IGenerateCoverImagesInput;
};

type IMutationGenerateItemMockupsArgs = {
	input: IGenerateItemMockupsInput;
};

type IMutationGenerateSceneImagesArgs = {
	input: IGenerateSceneImagesInput;
};

type IMutationRegenerateCoverImagesArgs = {
	input: IRegenerateCoverImagesInput;
};

type IMutationRegenerateSceneImagesArgs = {
	input: IRegenerateSceneImagesInput;
};

type IMutationRemoveCartItemVariantArgs = {
	input: IRemoveCartInputItemVariantInput;
};

type IMutationRequestSubscriptionCancellationArgs = {
	input: IRequestSubscriptionCancellationInput;
};

type IMutationResetPasswordArgs = {
	input: IResetPasswordInput;
};

type IMutationSendContactUsEmailArgs = {
	input: ISendContactUsEmailInput;
};

type IMutationSendForgotPasswordEmailArgs = {
	input: ISendForgotPasswordEmailInput;
};

type IMutationSignInWithPasswordArgs = {
	input: ISignInWithPasswordInput;
};

type IMutationSignUpWithPasswordArgs = {
	input: ISignUpWithPasswordInput;
};

type IMutationUpdateCharacterDraftArgs = {
	input: IUpdateCharacterDraftInput;
};

type IMutationUpdatePasswordArgs = {
	input: IUpdatePasswordInput;
};

type IMutationUpdateStoryDraftArgs = {
	input: IUpdateStoryDraftInput;
};

type IMutationUpdateSubscriptionPaymentMethodArgs = {
	input: IUpdateSubscriptionPaymentMethodInput;
};

type IMutationUpdateTokenAutoPayArgs = {
	input: IUpdateTokenAutoPayInput;
};

type IMutationUpdateUpcomingStoryArgs = {
	input: IUpdateUpcomingStoryInput;
};

type IMutationUpdateUserArgs = {
	input: IUpdateUserInput;
};

type IMutationUploadStoryArgs = {
	input: IUploadStoryInput;
};

type IMutationUpscaleImageArgs = {
	input: IUpscaleImageInput;
};

type IMutationVerifyEmailArgs = {
	input: IVerifyEmailInput;
};

type IOrder = {
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	itemVariants: Array<IItemVariant>;
	orderNumber: Scalars['String']['output'];
	shippingAddress: Scalars['String']['output'];
	status: Scalars['String']['output'];
	total: Scalars['Float']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
};

type IOrderDraft = {
	externalProviderId: Scalars['Int']['output'];
	shippingCost: Scalars['Float']['output'];
	taxCost: Scalars['Float']['output'];
};

type IOrderFilterInput = {
	id?: InputMaybe<Scalars['ID']['input']>;
	orderNumber?: InputMaybe<Scalars['String']['input']>;
};

type IOrderItemVariant = {
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	itemVariant: IItemVariant;
	itemVariantId: Scalars['ID']['output'];
	order: IOrder;
	orderId: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type IOrdersFilterInput = {
	self?: InputMaybe<Scalars['Boolean']['input']>;
	shopId?: InputMaybe<Scalars['ID']['input']>;
};

type IPage = {
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	imageUrl: Scalars['String']['output'];
	index: Scalars['Int']['output'];
	story: IStory;
	storyId?: Maybe<Scalars['ID']['output']>;
	updatedAt: Scalars['DateTime']['output'];
};

type IPageImage = {
	index: Scalars['Int']['input'];
	url: Scalars['String']['input'];
};

type IPagesFilterInput = {
	storyId: Scalars['ID']['input'];
};

type IPaginationInput = {
	limit: Scalars['Int']['input'];
	offset: Scalars['Int']['input'];
};

type IPanelDraft = {
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	storyDraftId: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type IPaymentMethod = {
	brand: Scalars['String']['output'];
	createdAt: Scalars['DateTime']['output'];
	expirationMonth: Scalars['Int']['output'];
	expirationYear: Scalars['Int']['output'];
	externalPaymentProcessorId: Scalars['ID']['output'];
	id: Scalars['ID']['output'];
	lastFourNumbers: Scalars['String']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
};

type IPaymentMethodSetupIntent = {
	clientSecret: Scalars['String']['output'];
};

type IPlaidExchangedToken = {
	accessToken: Scalars['String']['output'];
	itemId: Scalars['String']['output'];
};

type IProvideCharacterImageFeedbackInput = {
	imageUrl: Scalars['String']['input'];
	originalUserInput: IGenerateCharacterImagesInput;
	prompt: Scalars['String']['input'];
	rating: Scalars['Int']['input'];
	seed: Scalars['Int']['input'];
	text?: InputMaybe<Scalars['String']['input']>;
};

type IQuery = {
	authUser: IAuthUser;
	cartItemVariants: Array<ICartItemVariant>;
	character: ICharacter;
	characterDraft: ICharacterDraft;
	characterDrafts: Array<ICharacterDraft>;
	characters: Array<ICharacter>;
	fileUploadUrls: IFileUploadUrlsResponse;
	imageDrafts: Array<IClientImageDraft>;
	item: IItem;
	itemVariant: IItemVariant;
	items: Array<IItem>;
	order: IOrder;
	orders: Array<IOrder>;
	pages: Array<IPage>;
	paymentMethods: Array<IPaymentMethod>;
	shop: IShop;
	stories: Array<IStory>;
	story: IStory;
	storyCharacters: Array<IStoryCharacter>;
	storyComments: Array<IStoryComment>;
	storyDraft: IStoryDraftData;
	storyDrafts: Array<IStoryDraftData>;
	storyViews: Scalars['Int']['output'];
	subscription?: Maybe<IUserSubscription>;
	subscriptionEvents: Array<ISubscriptionEvent>;
	subscriptionPlans: Array<ISubscriptionPlan>;
	templateItems: Array<ITemplateItem>;
	tokenAutoPay?: Maybe<ITokenAutoPay>;
	tokenBalance: ITokenBalance;
	tokenBalanceEvents: Array<ITokenBalanceEvent>;
	upcomingStories: Array<IUpcomingStory>;
	user: IUser;
	userAcknowledgedTutorials: Array<IUserAcknowledgedTutorial>;
	userAddresses: Array<IUserAddress>;
};

type IQueryCharacterArgs = {
	id: Scalars['ID']['input'];
};

type IQueryCharacterDraftArgs = {
	id: Scalars['ID']['input'];
};

type IQueryCharacterDraftsArgs = {
	pagination?: InputMaybe<IPaginationInput>;
	sorting?: InputMaybe<Array<ISortInput>>;
};

type IQueryCharactersArgs = {
	pagination?: InputMaybe<IPaginationInput>;
	sorting?: InputMaybe<Array<ISortInput>>;
};

type IQueryFileUploadUrlsArgs = {
	input: IFileUploadUrlsInput;
};

type IQueryImageDraftsArgs = {
	pagination?: InputMaybe<IPaginationInput>;
	sourceDraftId: Scalars['ID']['input'];
};

type IQueryItemArgs = {
	id: Scalars['ID']['input'];
};

type IQueryItemVariantArgs = {
	id: Scalars['ID']['input'];
};

type IQueryItemsArgs = {
	shopId: Scalars['ID']['input'];
};

type IQueryOrderArgs = {
	filter: IOrderFilterInput;
};

type IQueryOrdersArgs = {
	filter: IOrdersFilterInput;
};

type IQueryPagesArgs = {
	pagination?: InputMaybe<IPaginationInput>;
	storyId: Scalars['ID']['input'];
};

type IQueryShopArgs = {
	username: Scalars['String']['input'];
};

type IQueryStoriesArgs = {
	filter?: InputMaybe<IStoriesFilterInput>;
	pagination?: InputMaybe<IPaginationInput>;
};

type IQueryStoryArgs = {
	id: Scalars['ID']['input'];
};

type IQueryStoryCharactersArgs = {
	storyId: Scalars['ID']['input'];
};

type IQueryStoryCommentsArgs = {
	storyId: Scalars['ID']['input'];
};

type IQueryStoryDraftArgs = {
	id: Scalars['ID']['input'];
};

type IQueryStoryDraftsArgs = {
	pagination?: InputMaybe<IPaginationInput>;
};

type IQueryStoryViewsArgs = {
	storyId: Scalars['ID']['input'];
	unique?: InputMaybe<Scalars['Boolean']['input']>;
};

type IQuerySubscriptionEventsArgs = {
	subscriptionId: Scalars['ID']['input'];
};

type IQueryUpcomingStoriesArgs = {
	ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

type IQueryUserArgs = {
	filter: IUserFilterInput;
};

type IRegenerateCoverImagesInput = {
	artStyle: Scalars['String']['input'];
	characterIds: Array<Scalars['ID']['input']>;
	coverDraftId: Scalars['ID']['input'];
	genres: Array<Scalars['String']['input']>;
	instructions?: InputMaybe<Scalars['String']['input']>;
	numberOfImages: Scalars['Int']['input'];
	originalImageDraftId: Scalars['ID']['input'];
	storyDraftId: Scalars['ID']['input'];
	summary?: InputMaybe<Scalars['String']['input']>;
	title?: InputMaybe<Scalars['String']['input']>;
};

type IRegenerateSceneImagesInput = {
	artStyle: Scalars['String']['input'];
	caption?: InputMaybe<Scalars['String']['input']>;
	characterIds?: InputMaybe<Array<Scalars['ID']['input']>>;
	description: Scalars['String']['input'];
	dialogues?: InputMaybe<Array<ICreateDialogueInput>>;
	instructions?: InputMaybe<Scalars['String']['input']>;
	numberOfImages: Scalars['Int']['input'];
	originalImageDraftId: Scalars['ID']['input'];
	panelDraftId: Scalars['ID']['input'];
	setting: Scalars['String']['input'];
	shouldUseOriginalImage: Scalars['Boolean']['input'];
	storyDraftId: Scalars['ID']['input'];
};

type IRemoveCartInputItemVariantInput = {
	id: Scalars['ID']['input'];
};

type IRequestSubscriptionCancellationInput = {
	subscriptionId: Scalars['ID']['input'];
};

type IResetPasswordInput = {
	password: Scalars['String']['input'];
	token: Scalars['String']['input'];
};

type IResetPasswordToken = {
	createdAt: Scalars['DateTime']['output'];
	expiresAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	tokenHash: Scalars['String']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
};

type ISendContactUsEmailInput = {
	message: Scalars['String']['input'];
	type: IContactUsMessageType;
};

type ISendForgotPasswordEmailInput = {
	email: Scalars['String']['input'];
};

type IShop = {
	balance: Scalars['Float']['output'];
	balanceEvents: Array<IShopBalanceEvent>;
	bankAccount?: Maybe<IShopBankAccount>;
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	items: Array<IItem>;
	orders: Array<IOrder>;
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
};

type IShopBalanceEvent = {
	amount: Scalars['Float']['output'];
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	metadata?: Maybe<Scalars['JSON']['output']>;
	shop: IShop;
	shopId: Scalars['ID']['output'];
	type: Scalars['ShopBalanceEventType']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type IShopBankAccount = {
	bankName: Scalars['String']['output'];
	createdAt: Scalars['DateTime']['output'];
	externalProviderId: Scalars['String']['output'];
	externalProviderMetadata: Scalars['JSON']['output'];
	externalProviderToken: Scalars['String']['output'];
	id: Scalars['ID']['output'];
	shop: IShop;
	shopId: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type ISignInWithPasswordInput = {
	email: Scalars['String']['input'];
	password: Scalars['String']['input'];
};

type ISignUpWithPasswordInput = {
	email: Scalars['String']['input'];
	firstName: Scalars['String']['input'];
	lastName: Scalars['String']['input'];
	password: Scalars['String']['input'];
	profilePictureImageUrl?: InputMaybe<Scalars['String']['input']>;
};

enum ISortDirection {
	Ascending = 'Ascending',
	Descending = 'Descending',
}

type ISortInput = {
	direction: ISortDirection;
	field: Scalars['String']['input'];
};

type IStoriesFilterInput = {
	creatorId?: InputMaybe<Scalars['ID']['input']>;
	storyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

type IStory = {
	artStyle: Scalars['String']['output'];
	coverImageUrl: Scalars['String']['output'];
	createdAt: Scalars['DateTime']['output'];
	creator: IUser;
	creatorId: Scalars['ID']['output'];
	genres: Array<Scalars['String']['output']>;
	id: Scalars['ID']['output'];
	isFavorited?: Maybe<Scalars['Boolean']['output']>;
	pages: Array<IPage>;
	storyCharacters: Array<IStoryCharacter>;
	storyDraft: IStoryDraftData;
	storyDraftId: Scalars['ID']['output'];
	summary: Scalars['String']['output'];
	title: Scalars['String']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type IStoryCharacter = {
	character: ICharacter;
	characterDevelopment?: Maybe<Scalars['String']['output']>;
	characterId: Scalars['ID']['output'];
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	story: IStory;
	storyId: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type IStoryCharacterInput = {
	characterDevelopment?: InputMaybe<Scalars['String']['input']>;
	characterId: Scalars['ID']['input'];
};

type IStoryComment = {
	children: Array<IStoryComment>;
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	parent?: Maybe<IStoryComment>;
	parentStoryCommentId?: Maybe<Scalars['ID']['output']>;
	story: IStory;
	storyId: Scalars['ID']['output'];
	text: Scalars['String']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IUser;
	userId: Scalars['ID']['output'];
};

type IStoryDraftData = {
	createdAt: Scalars['DateTime']['output'];
	data: Scalars['JSON']['output'];
	id: Scalars['ID']['output'];
	lastActiveFormStep: Scalars['Int']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type IStoryView = {
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	story: IStory;
	storyId: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user?: Maybe<IUser>;
	userId?: Maybe<Scalars['ID']['output']>;
};

type ISubscriptionEvent = {
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	metadata?: Maybe<ISubscriptionEventMetadata>;
	subscription: IUserSubscription;
	subscriptionId: Scalars['ID']['output'];
	type: Scalars['SubscriptionEventType']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type ISubscriptionEventMetadata = {
	amount?: Maybe<Scalars['Float']['output']>;
	message?: Maybe<Scalars['String']['output']>;
	paymentMethod?: Maybe<ISubscriptionEventPaymentMethod>;
};

type ISubscriptionEventPaymentMethod = {
	brand: Scalars['String']['output'];
	lastFourNumbers: Scalars['String']['output'];
};

type ISubscriptionPlan = {
	bullets: Array<Scalars['String']['output']>;
	description: Scalars['String']['output'];
	id: Scalars['ID']['output'];
	monthlyPrice: Scalars['Float']['output'];
	monthlyTokenRefillAmount: Scalars['Int']['output'];
	name: Scalars['String']['output'];
	subscriptions: Array<IUserSubscription>;
	yearlyPrice: Scalars['Float']['output'];
};

type ITemplateItem = {
	id: Scalars['Int']['output'];
	imageUrl: Scalars['String']['output'];
	minimumPrice: Scalars['Float']['output'];
	mockupStyleId: Scalars['Int']['output'];
	name: Scalars['String']['output'];
	placement: Scalars['String']['output'];
	specs: Scalars['String']['output'];
	technique: Scalars['String']['output'];
	type: Scalars['String']['output'];
	variants: Array<ITemplateItemVariant>;
};

type ITemplateItemVariant = {
	color: Scalars['String']['output'];
	colorCode: Scalars['String']['output'];
	id: Scalars['Int']['output'];
	imageUrl: Scalars['String']['output'];
	minimumPrice?: Maybe<Scalars['Float']['output']>;
	size: Scalars['String']['output'];
};

type ITokenAutoPay = {
	amount: Scalars['Int']['output'];
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
};

type ITokenBalance = {
	amount: Scalars['Int']['output'];
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
};

type ITokenBalanceEvent = {
	amount: Scalars['Int']['output'];
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	type: Scalars['TokenBalanceTransactionEventType']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
};

type IUpcomingStory = {
	coverImageUrl: Scalars['String']['output'];
	createdAt: Scalars['DateTime']['output'];
	creator: IUser;
	creatorId: Scalars['ID']['output'];
	id: Scalars['ID']['output'];
	story?: Maybe<IStory>;
	storyDraft?: Maybe<IStoryDraftData>;
	storyDraftId?: Maybe<Scalars['ID']['output']>;
	storyId?: Maybe<Scalars['ID']['output']>;
	title: Scalars['String']['output'];
	updatedAt: Scalars['DateTime']['output'];
};

type IUpdateCharacterDraftInput = {
	artStyle: Scalars['String']['input'];
	characterDraftId: Scalars['ID']['input'];
	description?: InputMaybe<Scalars['String']['input']>;
	name?: InputMaybe<Scalars['String']['input']>;
	parentCharacterId?: InputMaybe<Scalars['ID']['input']>;
	selectedImageDraftId?: InputMaybe<Scalars['ID']['input']>;
};

type IUpdatePasswordInput = {
	newPassword: Scalars['String']['input'];
	oldPassword: Scalars['String']['input'];
};

type IUpdateStoryDraftInput = {
	data?: InputMaybe<Scalars['JSON']['input']>;
	id: Scalars['ID']['input'];
	lastActiveFormStep?: InputMaybe<Scalars['Int']['input']>;
};

type IUpdateSubscriptionPaymentMethodInput = {
	paymentMethodId: Scalars['ID']['input'];
};

type IUpdateTokenAutoPayInput = {
	amount: Scalars['Int']['input'];
};

type IUpdateUpcomingStoryInput = {
	coverImageUrl?: InputMaybe<Scalars['String']['input']>;
	id: Scalars['ID']['input'];
	storyDraftId?: InputMaybe<Scalars['ID']['input']>;
	storyId?: InputMaybe<Scalars['ID']['input']>;
	title?: InputMaybe<Scalars['String']['input']>;
};

type IUpdateUserInput = {
	bio?: InputMaybe<Scalars['String']['input']>;
	displayName?: InputMaybe<Scalars['String']['input']>;
	firstName?: InputMaybe<Scalars['String']['input']>;
	lastName?: InputMaybe<Scalars['String']['input']>;
	profilePictureImageUrl?: InputMaybe<Scalars['String']['input']>;
	username?: InputMaybe<Scalars['String']['input']>;
};

type IUploadStoryInput = {
	text: Scalars['String']['input'];
};

type IUpscaleImageInput = {
	imageDraftId: Scalars['ID']['input'];
	panelDraftId: Scalars['ID']['input'];
};

type IUser = {
	bio?: Maybe<Scalars['String']['output']>;
	createdAt: Scalars['DateTime']['output'];
	displayName: Scalars['String']['output'];
	favoriteStories: Array<IStory>;
	followers: Array<IUser>;
	followersCount?: Maybe<Scalars['Int']['output']>;
	following: Array<IUser>;
	followingCount?: Maybe<Scalars['Int']['output']>;
	id: Scalars['ID']['output'];
	isFollower?: Maybe<Scalars['Boolean']['output']>;
	profilePictureImageUrl?: Maybe<Scalars['String']['output']>;
	shop?: Maybe<IShop>;
	stories: Array<IStory>;
	updatedAt: Scalars['DateTime']['output'];
	username: Scalars['String']['output'];
};

type IUserAcknowledgedTutorial = {
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	tutorialType: Scalars['TutorialType']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
};

type IUserAddress = {
	addressLine1: Scalars['String']['output'];
	addressLine2?: Maybe<Scalars['String']['output']>;
	city: Scalars['String']['output'];
	country: Scalars['String']['output'];
	createdAt: Scalars['DateTime']['output'];
	id: Scalars['ID']['output'];
	state: Scalars['String']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
	zip: Scalars['String']['output'];
};

type IUserFilterInput = {
	id?: InputMaybe<Scalars['ID']['input']>;
	username?: InputMaybe<Scalars['String']['input']>;
};

type IUserFollower = {
	createdAt: Scalars['DateTime']['output'];
	follower: IUser;
	followerId: Scalars['ID']['output'];
	id: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IUser;
	userId: Scalars['ID']['output'];
};

type IUserSubscription = {
	createdAt: Scalars['DateTime']['output'];
	endDate?: Maybe<Scalars['DateTime']['output']>;
	id: Scalars['ID']['output'];
	nextPaymentDate?: Maybe<Scalars['DateTime']['output']>;
	paymentMethod: IPaymentMethod;
	paymentMethodId: Scalars['ID']['output'];
	period: Scalars['String']['output'];
	startDate: Scalars['DateTime']['output'];
	status: Scalars['String']['output'];
	subscriptionEvents: Array<ISubscriptionEvent>;
	subscriptionPlan: ISubscriptionPlan;
	subscriptionPlanId: Scalars['ID']['output'];
	updatedAt: Scalars['DateTime']['output'];
	user: IAuthUser;
	userId: Scalars['ID']['output'];
};

type IVerifyEmailInput = {
	token: Scalars['String']['input'];
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
	resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
	| ResolverFn<TResult, TParent, TContext, TArgs>
	| ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
	subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
	resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
	subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
	resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
	| SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
	| SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
	| ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
	| SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
	parent: TParent,
	context: TContext,
	info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
	obj: T,
	context: TContext,
	info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
	next: NextResolverFn<TResult>,
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type IResolversTypes = {
	AddCartInputItemVariant: ResolverTypeWrapper<BaseObject<IAddCartInputItemVariant>>;
	AuthResponse: ResolverTypeWrapper<BaseObject<IAuthResponse>>;
	AuthUser: ResolverTypeWrapper<BaseObject<IAuthUser>>;
	Boolean: ResolverTypeWrapper<BaseObject<Scalars['Boolean']['output']>>;
	BuyItemsInput: ResolverTypeWrapper<BaseObject<IBuyItemsInput>>;
	BuyTokensInput: ResolverTypeWrapper<BaseObject<IBuyTokensInput>>;
	CartItemVariant: ResolverTypeWrapper<BaseObject<ICartItemVariant>>;
	ChangeSubscriptionPlanInput: ResolverTypeWrapper<BaseObject<IChangeSubscriptionPlanInput>>;
	Character: ResolverTypeWrapper<BaseObject<ICharacter>>;
	CharacterDraft: ResolverTypeWrapper<BaseObject<ICharacterDraft>>;
	CharactersFilterInput: ResolverTypeWrapper<BaseObject<ICharactersFilterInput>>;
	ClientImage: ResolverTypeWrapper<BaseObject<IClientImage>>;
	ClientImageDraft: ResolverTypeWrapper<BaseObject<IClientImageDraft>>;
	CommentOnStoryInput: ResolverTypeWrapper<BaseObject<ICommentOnStoryInput>>;
	ContactUsMessageType: ResolverTypeWrapper<BaseObject<IContactUsMessageType>>;
	CreateCharacterInput: ResolverTypeWrapper<BaseObject<ICreateCharacterInput>>;
	CreateDialogueInput: ResolverTypeWrapper<BaseObject<ICreateDialogueInput>>;
	CreateImageInput: ResolverTypeWrapper<BaseObject<ICreateImageInput>>;
	CreateItemInput: ResolverTypeWrapper<BaseObject<ICreateItemInput>>;
	CreateItemPlacementInput: ResolverTypeWrapper<BaseObject<ICreateItemPlacementInput>>;
	CreateItemPlacementLayerInput: ResolverTypeWrapper<BaseObject<ICreateItemPlacementLayerInput>>;
	CreateItemVariantInput: ResolverTypeWrapper<BaseObject<ICreateItemVariantInput>>;
	CreateOrderDraftInput: ResolverTypeWrapper<BaseObject<ICreateOrderDraftInput>>;
	CreatePanelDraftInput: ResolverTypeWrapper<BaseObject<ICreatePanelDraftInput>>;
	CreatePaymentMethodInput: ResolverTypeWrapper<BaseObject<ICreatePaymentMethodInput>>;
	CreateShopBankAccountInput: ResolverTypeWrapper<BaseObject<ICreateShopBankAccountInput>>;
	CreateStoryDraftInput: ResolverTypeWrapper<BaseObject<ICreateStoryDraftInput>>;
	CreateStoryInput: ResolverTypeWrapper<BaseObject<ICreateStoryInput>>;
	CreateStoryViewInput: ResolverTypeWrapper<BaseObject<ICreateStoryViewInput>>;
	CreateSubscriptionInput: ResolverTypeWrapper<BaseObject<ICreateSubscriptionInput>>;
	CreateUpcomingStoryInput: ResolverTypeWrapper<BaseObject<ICreateUpcomingStoryInput>>;
	CreateUploadedImageDraftInput: ResolverTypeWrapper<BaseObject<ICreateUploadedImageDraftInput>>;
	CreateUserAddressInput: ResolverTypeWrapper<BaseObject<ICreateUserAddressInput>>;
	DateTime: ResolverTypeWrapper<BaseObject<Scalars['DateTime']['output']>>;
	Dialogue: ResolverTypeWrapper<BaseObject<IDialogue>>;
	EmailVerificationToken: ResolverTypeWrapper<BaseObject<IEmailVerificationToken>>;
	FavoriteStory: ResolverTypeWrapper<BaseObject<IFavoriteStory>>;
	FavoriteStoryInput: ResolverTypeWrapper<BaseObject<IFavoriteStoryInput>>;
	FileUploadUrlsInput: ResolverTypeWrapper<BaseObject<IFileUploadUrlsInput>>;
	FileUploadUrlsResponse: ResolverTypeWrapper<BaseObject<IFileUploadUrlsResponse>>;
	Float: ResolverTypeWrapper<BaseObject<Scalars['Float']['output']>>;
	FollowUserInput: ResolverTypeWrapper<BaseObject<IFollowUserInput>>;
	GenerateCharacterHeadshotImagesInput: ResolverTypeWrapper<BaseObject<IGenerateCharacterHeadshotImagesInput>>;
	GenerateCharacterImagesInput: ResolverTypeWrapper<BaseObject<IGenerateCharacterImagesInput>>;
	GenerateCoverImagesInput: ResolverTypeWrapper<BaseObject<IGenerateCoverImagesInput>>;
	GenerateItemMockupsInput: ResolverTypeWrapper<BaseObject<IGenerateItemMockupsInput>>;
	GenerateSceneImagesInput: ResolverTypeWrapper<BaseObject<IGenerateSceneImagesInput>>;
	GeneratedDialogue: ResolverTypeWrapper<BaseObject<IGeneratedDialogue>>;
	GeneratedIdea: ResolverTypeWrapper<BaseObject<IGeneratedIdea>>;
	GeneratedItemMockup: ResolverTypeWrapper<BaseObject<IGeneratedItemMockup>>;
	GeneratedScene: ResolverTypeWrapper<BaseObject<IGeneratedScene>>;
	ID: ResolverTypeWrapper<BaseObject<Scalars['ID']['output']>>;
	ImageKind: ResolverTypeWrapper<BaseObject<IImageKind>>;
	ImageStatus: ResolverTypeWrapper<BaseObject<IImageStatus>>;
	Int: ResolverTypeWrapper<BaseObject<Scalars['Int']['output']>>;
	Item: ResolverTypeWrapper<BaseObject<IItem>>;
	ItemPlacement: ResolverTypeWrapper<BaseObject<IItemPlacement>>;
	ItemPlacementLayer: ResolverTypeWrapper<BaseObject<IItemPlacementLayer>>;
	ItemVariant: ResolverTypeWrapper<BaseObject<IItemVariant>>;
	ItemVariantImage: ResolverTypeWrapper<BaseObject<IItemVariantImage>>;
	JSON: ResolverTypeWrapper<BaseObject<Scalars['JSON']['output']>>;
	LiteralEditedImage: ResolverTypeWrapper<BaseObject<Scalars['LiteralEditedImage']['output']>>;
	LiteralGeneratedImage: ResolverTypeWrapper<BaseObject<Scalars['LiteralGeneratedImage']['output']>>;
	Mutation: ResolverTypeWrapper<{}>;
	Order: ResolverTypeWrapper<BaseObject<IOrder>>;
	OrderDraft: ResolverTypeWrapper<BaseObject<IOrderDraft>>;
	OrderFilterInput: ResolverTypeWrapper<BaseObject<IOrderFilterInput>>;
	OrderItemVariant: ResolverTypeWrapper<BaseObject<IOrderItemVariant>>;
	OrdersFilterInput: ResolverTypeWrapper<BaseObject<IOrdersFilterInput>>;
	Page: ResolverTypeWrapper<BaseObject<IPage>>;
	PageImage: ResolverTypeWrapper<BaseObject<IPageImage>>;
	PagesFilterInput: ResolverTypeWrapper<BaseObject<IPagesFilterInput>>;
	PaginationInput: ResolverTypeWrapper<BaseObject<IPaginationInput>>;
	PanelDraft: ResolverTypeWrapper<BaseObject<IPanelDraft>>;
	PaymentMethod: ResolverTypeWrapper<BaseObject<IPaymentMethod>>;
	PaymentMethodSetupIntent: ResolverTypeWrapper<BaseObject<IPaymentMethodSetupIntent>>;
	PlaidExchangedToken: ResolverTypeWrapper<BaseObject<IPlaidExchangedToken>>;
	ProvideCharacterImageFeedbackInput: ResolverTypeWrapper<BaseObject<IProvideCharacterImageFeedbackInput>>;
	Query: ResolverTypeWrapper<{}>;
	RegenerateCoverImagesInput: ResolverTypeWrapper<BaseObject<IRegenerateCoverImagesInput>>;
	RegenerateSceneImagesInput: ResolverTypeWrapper<BaseObject<IRegenerateSceneImagesInput>>;
	RemoveCartInputItemVariantInput: ResolverTypeWrapper<BaseObject<IRemoveCartInputItemVariantInput>>;
	RequestSubscriptionCancellationInput: ResolverTypeWrapper<BaseObject<IRequestSubscriptionCancellationInput>>;
	ResetPasswordInput: ResolverTypeWrapper<BaseObject<IResetPasswordInput>>;
	ResetPasswordToken: ResolverTypeWrapper<BaseObject<IResetPasswordToken>>;
	SendContactUsEmailInput: ResolverTypeWrapper<BaseObject<ISendContactUsEmailInput>>;
	SendForgotPasswordEmailInput: ResolverTypeWrapper<BaseObject<ISendForgotPasswordEmailInput>>;
	Shop: ResolverTypeWrapper<BaseObject<IShop>>;
	ShopBalanceEvent: ResolverTypeWrapper<BaseObject<IShopBalanceEvent>>;
	ShopBalanceEventType: ResolverTypeWrapper<BaseObject<Scalars['ShopBalanceEventType']['output']>>;
	ShopBankAccount: ResolverTypeWrapper<BaseObject<IShopBankAccount>>;
	SignInWithPasswordInput: ResolverTypeWrapper<BaseObject<ISignInWithPasswordInput>>;
	SignUpWithPasswordInput: ResolverTypeWrapper<BaseObject<ISignUpWithPasswordInput>>;
	SortDirection: ResolverTypeWrapper<BaseObject<ISortDirection>>;
	SortInput: ResolverTypeWrapper<BaseObject<ISortInput>>;
	StoriesFilterInput: ResolverTypeWrapper<BaseObject<IStoriesFilterInput>>;
	Story: ResolverTypeWrapper<BaseObject<IStory>>;
	StoryCharacter: ResolverTypeWrapper<BaseObject<IStoryCharacter>>;
	StoryCharacterInput: ResolverTypeWrapper<BaseObject<IStoryCharacterInput>>;
	StoryComment: ResolverTypeWrapper<BaseObject<IStoryComment>>;
	StoryDraftData: ResolverTypeWrapper<BaseObject<IStoryDraftData>>;
	StoryView: ResolverTypeWrapper<BaseObject<IStoryView>>;
	String: ResolverTypeWrapper<BaseObject<Scalars['String']['output']>>;
	SubscriptionEvent: ResolverTypeWrapper<BaseObject<ISubscriptionEvent>>;
	SubscriptionEventMetadata: ResolverTypeWrapper<BaseObject<ISubscriptionEventMetadata>>;
	SubscriptionEventPaymentMethod: ResolverTypeWrapper<BaseObject<ISubscriptionEventPaymentMethod>>;
	SubscriptionEventType: ResolverTypeWrapper<BaseObject<Scalars['SubscriptionEventType']['output']>>;
	SubscriptionPlan: ResolverTypeWrapper<BaseObject<ISubscriptionPlan>>;
	TemplateItem: ResolverTypeWrapper<BaseObject<ITemplateItem>>;
	TemplateItemVariant: ResolverTypeWrapper<BaseObject<ITemplateItemVariant>>;
	TokenAutoPay: ResolverTypeWrapper<BaseObject<ITokenAutoPay>>;
	TokenBalance: ResolverTypeWrapper<BaseObject<ITokenBalance>>;
	TokenBalanceEvent: ResolverTypeWrapper<BaseObject<ITokenBalanceEvent>>;
	TokenBalanceTransactionEventType: ResolverTypeWrapper<
		BaseObject<Scalars['TokenBalanceTransactionEventType']['output']>
	>;
	TutorialType: ResolverTypeWrapper<BaseObject<Scalars['TutorialType']['output']>>;
	UpcomingStory: ResolverTypeWrapper<BaseObject<IUpcomingStory>>;
	UpdateCharacterDraftInput: ResolverTypeWrapper<BaseObject<IUpdateCharacterDraftInput>>;
	UpdatePasswordInput: ResolverTypeWrapper<BaseObject<IUpdatePasswordInput>>;
	UpdateStoryDraftInput: ResolverTypeWrapper<BaseObject<IUpdateStoryDraftInput>>;
	UpdateSubscriptionPaymentMethodInput: ResolverTypeWrapper<BaseObject<IUpdateSubscriptionPaymentMethodInput>>;
	UpdateTokenAutoPayInput: ResolverTypeWrapper<BaseObject<IUpdateTokenAutoPayInput>>;
	UpdateUpcomingStoryInput: ResolverTypeWrapper<BaseObject<IUpdateUpcomingStoryInput>>;
	UpdateUserInput: ResolverTypeWrapper<BaseObject<IUpdateUserInput>>;
	UploadStoryInput: ResolverTypeWrapper<BaseObject<IUploadStoryInput>>;
	UpscaleImageInput: ResolverTypeWrapper<BaseObject<IUpscaleImageInput>>;
	User: ResolverTypeWrapper<BaseObject<IUser>>;
	UserAcknowledgedTutorial: ResolverTypeWrapper<BaseObject<IUserAcknowledgedTutorial>>;
	UserAddress: ResolverTypeWrapper<BaseObject<IUserAddress>>;
	UserFilterInput: ResolverTypeWrapper<BaseObject<IUserFilterInput>>;
	UserFollower: ResolverTypeWrapper<BaseObject<IUserFollower>>;
	UserRole: ResolverTypeWrapper<BaseObject<Scalars['UserRole']['output']>>;
	UserSubscription: ResolverTypeWrapper<BaseObject<IUserSubscription>>;
	VerifyEmailInput: ResolverTypeWrapper<BaseObject<IVerifyEmailInput>>;
};

/** Mapping between all available schema types and the resolvers parents */
export type IResolversParentTypes = {
	AddCartInputItemVariant: BaseObject<IAddCartInputItemVariant>;
	AuthResponse: BaseObject<IAuthResponse>;
	AuthUser: BaseObject<IAuthUser>;
	Boolean: BaseObject<Scalars['Boolean']['output']>;
	BuyItemsInput: BaseObject<IBuyItemsInput>;
	BuyTokensInput: BaseObject<IBuyTokensInput>;
	CartItemVariant: BaseObject<ICartItemVariant>;
	ChangeSubscriptionPlanInput: BaseObject<IChangeSubscriptionPlanInput>;
	Character: BaseObject<ICharacter>;
	CharacterDraft: BaseObject<ICharacterDraft>;
	CharactersFilterInput: BaseObject<ICharactersFilterInput>;
	ClientImage: BaseObject<IClientImage>;
	ClientImageDraft: BaseObject<IClientImageDraft>;
	CommentOnStoryInput: BaseObject<ICommentOnStoryInput>;
	CreateCharacterInput: BaseObject<ICreateCharacterInput>;
	CreateDialogueInput: BaseObject<ICreateDialogueInput>;
	CreateImageInput: BaseObject<ICreateImageInput>;
	CreateItemInput: BaseObject<ICreateItemInput>;
	CreateItemPlacementInput: BaseObject<ICreateItemPlacementInput>;
	CreateItemPlacementLayerInput: BaseObject<ICreateItemPlacementLayerInput>;
	CreateItemVariantInput: BaseObject<ICreateItemVariantInput>;
	CreateOrderDraftInput: BaseObject<ICreateOrderDraftInput>;
	CreatePanelDraftInput: BaseObject<ICreatePanelDraftInput>;
	CreatePaymentMethodInput: BaseObject<ICreatePaymentMethodInput>;
	CreateShopBankAccountInput: BaseObject<ICreateShopBankAccountInput>;
	CreateStoryDraftInput: BaseObject<ICreateStoryDraftInput>;
	CreateStoryInput: BaseObject<ICreateStoryInput>;
	CreateStoryViewInput: BaseObject<ICreateStoryViewInput>;
	CreateSubscriptionInput: BaseObject<ICreateSubscriptionInput>;
	CreateUpcomingStoryInput: BaseObject<ICreateUpcomingStoryInput>;
	CreateUploadedImageDraftInput: BaseObject<ICreateUploadedImageDraftInput>;
	CreateUserAddressInput: BaseObject<ICreateUserAddressInput>;
	DateTime: BaseObject<Scalars['DateTime']['output']>;
	Dialogue: BaseObject<IDialogue>;
	EmailVerificationToken: BaseObject<IEmailVerificationToken>;
	FavoriteStory: BaseObject<IFavoriteStory>;
	FavoriteStoryInput: BaseObject<IFavoriteStoryInput>;
	FileUploadUrlsInput: BaseObject<IFileUploadUrlsInput>;
	FileUploadUrlsResponse: BaseObject<IFileUploadUrlsResponse>;
	Float: BaseObject<Scalars['Float']['output']>;
	FollowUserInput: BaseObject<IFollowUserInput>;
	GenerateCharacterHeadshotImagesInput: BaseObject<IGenerateCharacterHeadshotImagesInput>;
	GenerateCharacterImagesInput: BaseObject<IGenerateCharacterImagesInput>;
	GenerateCoverImagesInput: BaseObject<IGenerateCoverImagesInput>;
	GenerateItemMockupsInput: BaseObject<IGenerateItemMockupsInput>;
	GenerateSceneImagesInput: BaseObject<IGenerateSceneImagesInput>;
	GeneratedDialogue: BaseObject<IGeneratedDialogue>;
	GeneratedIdea: BaseObject<IGeneratedIdea>;
	GeneratedItemMockup: BaseObject<IGeneratedItemMockup>;
	GeneratedScene: BaseObject<IGeneratedScene>;
	ID: BaseObject<Scalars['ID']['output']>;
	Int: BaseObject<Scalars['Int']['output']>;
	Item: BaseObject<IItem>;
	ItemPlacement: BaseObject<IItemPlacement>;
	ItemPlacementLayer: BaseObject<IItemPlacementLayer>;
	ItemVariant: BaseObject<IItemVariant>;
	ItemVariantImage: BaseObject<IItemVariantImage>;
	JSON: BaseObject<Scalars['JSON']['output']>;
	LiteralEditedImage: BaseObject<Scalars['LiteralEditedImage']['output']>;
	LiteralGeneratedImage: BaseObject<Scalars['LiteralGeneratedImage']['output']>;
	Mutation: {};
	Order: BaseObject<IOrder>;
	OrderDraft: BaseObject<IOrderDraft>;
	OrderFilterInput: BaseObject<IOrderFilterInput>;
	OrderItemVariant: BaseObject<IOrderItemVariant>;
	OrdersFilterInput: BaseObject<IOrdersFilterInput>;
	Page: BaseObject<IPage>;
	PageImage: BaseObject<IPageImage>;
	PagesFilterInput: BaseObject<IPagesFilterInput>;
	PaginationInput: BaseObject<IPaginationInput>;
	PanelDraft: BaseObject<IPanelDraft>;
	PaymentMethod: BaseObject<IPaymentMethod>;
	PaymentMethodSetupIntent: BaseObject<IPaymentMethodSetupIntent>;
	PlaidExchangedToken: BaseObject<IPlaidExchangedToken>;
	ProvideCharacterImageFeedbackInput: BaseObject<IProvideCharacterImageFeedbackInput>;
	Query: {};
	RegenerateCoverImagesInput: BaseObject<IRegenerateCoverImagesInput>;
	RegenerateSceneImagesInput: BaseObject<IRegenerateSceneImagesInput>;
	RemoveCartInputItemVariantInput: BaseObject<IRemoveCartInputItemVariantInput>;
	RequestSubscriptionCancellationInput: BaseObject<IRequestSubscriptionCancellationInput>;
	ResetPasswordInput: BaseObject<IResetPasswordInput>;
	ResetPasswordToken: BaseObject<IResetPasswordToken>;
	SendContactUsEmailInput: BaseObject<ISendContactUsEmailInput>;
	SendForgotPasswordEmailInput: BaseObject<ISendForgotPasswordEmailInput>;
	Shop: BaseObject<IShop>;
	ShopBalanceEvent: BaseObject<IShopBalanceEvent>;
	ShopBalanceEventType: BaseObject<Scalars['ShopBalanceEventType']['output']>;
	ShopBankAccount: BaseObject<IShopBankAccount>;
	SignInWithPasswordInput: BaseObject<ISignInWithPasswordInput>;
	SignUpWithPasswordInput: BaseObject<ISignUpWithPasswordInput>;
	SortInput: BaseObject<ISortInput>;
	StoriesFilterInput: BaseObject<IStoriesFilterInput>;
	Story: BaseObject<IStory>;
	StoryCharacter: BaseObject<IStoryCharacter>;
	StoryCharacterInput: BaseObject<IStoryCharacterInput>;
	StoryComment: BaseObject<IStoryComment>;
	StoryDraftData: BaseObject<IStoryDraftData>;
	StoryView: BaseObject<IStoryView>;
	String: BaseObject<Scalars['String']['output']>;
	SubscriptionEvent: BaseObject<ISubscriptionEvent>;
	SubscriptionEventMetadata: BaseObject<ISubscriptionEventMetadata>;
	SubscriptionEventPaymentMethod: BaseObject<ISubscriptionEventPaymentMethod>;
	SubscriptionEventType: BaseObject<Scalars['SubscriptionEventType']['output']>;
	SubscriptionPlan: BaseObject<ISubscriptionPlan>;
	TemplateItem: BaseObject<ITemplateItem>;
	TemplateItemVariant: BaseObject<ITemplateItemVariant>;
	TokenAutoPay: BaseObject<ITokenAutoPay>;
	TokenBalance: BaseObject<ITokenBalance>;
	TokenBalanceEvent: BaseObject<ITokenBalanceEvent>;
	TokenBalanceTransactionEventType: BaseObject<Scalars['TokenBalanceTransactionEventType']['output']>;
	TutorialType: BaseObject<Scalars['TutorialType']['output']>;
	UpcomingStory: BaseObject<IUpcomingStory>;
	UpdateCharacterDraftInput: BaseObject<IUpdateCharacterDraftInput>;
	UpdatePasswordInput: BaseObject<IUpdatePasswordInput>;
	UpdateStoryDraftInput: BaseObject<IUpdateStoryDraftInput>;
	UpdateSubscriptionPaymentMethodInput: BaseObject<IUpdateSubscriptionPaymentMethodInput>;
	UpdateTokenAutoPayInput: BaseObject<IUpdateTokenAutoPayInput>;
	UpdateUpcomingStoryInput: BaseObject<IUpdateUpcomingStoryInput>;
	UpdateUserInput: BaseObject<IUpdateUserInput>;
	UploadStoryInput: BaseObject<IUploadStoryInput>;
	UpscaleImageInput: BaseObject<IUpscaleImageInput>;
	User: BaseObject<IUser>;
	UserAcknowledgedTutorial: BaseObject<IUserAcknowledgedTutorial>;
	UserAddress: BaseObject<IUserAddress>;
	UserFilterInput: BaseObject<IUserFilterInput>;
	UserFollower: BaseObject<IUserFollower>;
	UserRole: BaseObject<Scalars['UserRole']['output']>;
	UserSubscription: BaseObject<IUserSubscription>;
	VerifyEmailInput: BaseObject<IVerifyEmailInput>;
};

export type IConstraintDirectiveArgs = {
	contains?: Maybe<Scalars['String']['input']>;
	endsWith?: Maybe<Scalars['String']['input']>;
	exclusiveMax?: Maybe<Scalars['Float']['input']>;
	exclusiveMin?: Maybe<Scalars['Float']['input']>;
	format?: Maybe<Scalars['String']['input']>;
	max?: Maybe<Scalars['Float']['input']>;
	maxLength?: Maybe<Scalars['Int']['input']>;
	min?: Maybe<Scalars['Float']['input']>;
	minLength?: Maybe<Scalars['Int']['input']>;
	multipleOf?: Maybe<Scalars['Float']['input']>;
	notContains?: Maybe<Scalars['String']['input']>;
	pattern?: Maybe<Scalars['String']['input']>;
	startsWith?: Maybe<Scalars['String']['input']>;
	uniqueTypeName?: Maybe<Scalars['String']['input']>;
	zodId?: Maybe<Scalars['String']['input']>;
};

export type IConstraintDirectiveResolver<
	Result,
	Parent,
	ContextType = ServerContext,
	Args = IConstraintDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ISubresolveDirectiveArgs = {};

export type ISubresolveDirectiveResolver<
	Result,
	Parent,
	ContextType = ServerContext,
	Args = ISubresolveDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type IAuthResponseResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['AuthResponse'] = IResolversParentTypes['AuthResponse'],
> = {
	callbackUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IAuthUserResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['AuthUser'] = IResolversParentTypes['AuthUser'],
> = {
	addresses?: Resolver<Array<IResolversTypes['UserAddress']>, ParentType, ContextType>;
	bio?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	currentSubscription?: Resolver<Maybe<IResolversTypes['UserSubscription']>, ParentType, ContextType>;
	displayName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	email?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	externalPaymentProcessorId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
	favoriteStories?: Resolver<Array<IResolversTypes['Story']>, ParentType, ContextType>;
	firstName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	followers?: Resolver<Array<IResolversTypes['User']>, ParentType, ContextType>;
	followersCount?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
	following?: Resolver<Array<IResolversTypes['User']>, ParentType, ContextType>;
	followingCount?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	isBlocklisted?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
	isEmailVerified?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
	isFollower?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
	lastName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	orders?: Resolver<Array<IResolversTypes['Order']>, ParentType, ContextType>;
	passwordHash?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	profilePictureImageUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	role?: Resolver<IResolversTypes['UserRole'], ParentType, ContextType>;
	shop?: Resolver<Maybe<IResolversTypes['Shop']>, ParentType, ContextType>;
	stories?: Resolver<Array<IResolversTypes['Story']>, ParentType, ContextType>;
	subscriptions?: Resolver<Array<IResolversTypes['UserSubscription']>, ParentType, ContextType>;
	tokenBalance?: Resolver<IResolversTypes['TokenBalance'], ParentType, ContextType>;
	tokenBalanceEvents?: Resolver<Array<IResolversTypes['TokenBalanceEvent']>, ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	username?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICartItemVariantResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['CartItemVariant'] = IResolversParentTypes['CartItemVariant'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	itemVariant?: Resolver<IResolversTypes['ItemVariant'], ParentType, ContextType>;
	itemVariantId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICharacterResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['Character'] = IResolversParentTypes['Character'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	creator?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
	creatorId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	description?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	imageUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	stories?: Resolver<Array<IResolversTypes['Story']>, ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ICharacterDraftResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['CharacterDraft'] = IResolversParentTypes['CharacterDraft'],
> = {
	artStyle?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	creator?: Resolver<Maybe<IResolversTypes['User']>, ParentType, ContextType>;
	creatorId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	description?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	name?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	selectedImageDraft?: Resolver<Maybe<IResolversTypes['ClientImageDraft']>, ParentType, ContextType>;
	selectedImageDraftId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IClientImageResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['ClientImage'] = IResolversParentTypes['ClientImage'],
> = {
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	imageUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	kind?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	status?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IClientImageDraftResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['ClientImageDraft'] = IResolversParentTypes['ClientImageDraft'],
> = {
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	imageUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	kind?: Resolver<IResolversTypes['ImageKind'], ParentType, ContextType>;
	status?: Resolver<IResolversTypes['ImageStatus'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IDateTimeScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['DateTime'], any> {
	name: 'DateTime';
}

export type IDialogueResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['Dialogue'] = IResolversParentTypes['Dialogue'],
> = {
	character?: Resolver<Maybe<IResolversTypes['Character']>, ParentType, ContextType>;
	characterId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
	characterName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	index?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	text?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IEmailVerificationTokenResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['EmailVerificationToken'] = IResolversParentTypes['EmailVerificationToken'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	expiresAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	token?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IFavoriteStoryResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['FavoriteStory'] = IResolversParentTypes['FavoriteStory'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	story?: Resolver<IResolversTypes['Story'], ParentType, ContextType>;
	storyId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IFileUploadUrlsResponseResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['FileUploadUrlsResponse'] = IResolversParentTypes['FileUploadUrlsResponse'],
> = {
	cloudfrontUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	uploadUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IGeneratedDialogueResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['GeneratedDialogue'] = IResolversParentTypes['GeneratedDialogue'],
> = {
	characterId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
	characterName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	index?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	text?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IGeneratedIdeaResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['GeneratedIdea'] = IResolversParentTypes['GeneratedIdea'],
> = {
	genres?: Resolver<Array<IResolversTypes['String']>, ParentType, ContextType>;
	numberOfScenes?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	plot?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	setting?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	title?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IGeneratedItemMockupResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['GeneratedItemMockup'] = IResolversParentTypes['GeneratedItemMockup'],
> = {
	imageUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	variantId?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IGeneratedSceneResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['GeneratedScene'] = IResolversParentTypes['GeneratedScene'],
> = {
	caption?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	characterIds?: Resolver<Maybe<Array<IResolversTypes['ID']>>, ParentType, ContextType>;
	description?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	dialogues?: Resolver<Maybe<Array<IResolversTypes['GeneratedDialogue']>>, ParentType, ContextType>;
	index?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	setting?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IItemResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['Item'] = IResolversParentTypes['Item'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	description?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	externalProviderId?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	itemVariants?: Resolver<Array<IResolversTypes['ItemVariant']>, ParentType, ContextType>;
	name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	placements?: Resolver<Array<IResolversTypes['ItemPlacement']>, ParentType, ContextType>;
	shop?: Resolver<IResolversTypes['Shop'], ParentType, ContextType>;
	shopId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	specs?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	tags?: Resolver<Array<IResolversTypes['String']>, ParentType, ContextType>;
	type?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IItemPlacementResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['ItemPlacement'] = IResolversParentTypes['ItemPlacement'],
> = {
	layers?: Resolver<Array<IResolversTypes['ItemPlacementLayer']>, ParentType, ContextType>;
	placement?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	technique?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IItemPlacementLayerResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['ItemPlacementLayer'] = IResolversParentTypes['ItemPlacementLayer'],
> = {
	imageUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IItemVariantResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['ItemVariant'] = IResolversParentTypes['ItemVariant'],
> = {
	color?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	externalProviderId?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	item?: Resolver<IResolversTypes['Item'], ParentType, ContextType>;
	itemId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	itemVariantImages?: Resolver<Array<IResolversTypes['ItemVariantImage']>, ParentType, ContextType>;
	minimumPrice?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
	price?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
	size?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IItemVariantImageResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['ItemVariantImage'] = IResolversParentTypes['ItemVariantImage'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	imageUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	index?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	itemVariant?: Resolver<IResolversTypes['ItemVariant'], ParentType, ContextType>;
	itemVariantId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IJsonScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['JSON'], any> {
	name: 'JSON';
}

export interface ILiteralEditedImageScalarConfig
	extends GraphQLScalarTypeConfig<IResolversTypes['LiteralEditedImage'], any> {
	name: 'LiteralEditedImage';
}

export interface ILiteralGeneratedImageScalarConfig
	extends GraphQLScalarTypeConfig<IResolversTypes['LiteralGeneratedImage'], any> {
	name: 'LiteralGeneratedImage';
}

export type IMutationResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['Mutation'] = IResolversParentTypes['Mutation'],
> = {
	acknowledgeTutorial?: Resolver<
		IResolversTypes['UserAcknowledgedTutorial'],
		ParentType,
		ContextType,
		RequireFields<IMutationAcknowledgeTutorialArgs, 'tutorialType'>
	>;
	addCartItemVariant?: Resolver<
		IResolversTypes['CartItemVariant'],
		ParentType,
		ContextType,
		RequireFields<IMutationAddCartItemVariantArgs, 'input'>
	>;
	buyItems?: Resolver<IResolversTypes['Order'], ParentType, ContextType, RequireFields<IMutationBuyItemsArgs, 'input'>>;
	buyTokens?: Resolver<
		IResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<IMutationBuyTokensArgs, 'input'>
	>;
	changeSubscriptionPlan?: Resolver<
		IResolversTypes['UserSubscription'],
		ParentType,
		ContextType,
		RequireFields<IMutationChangeSubscriptionPlanArgs, 'input'>
	>;
	commentOnStory?: Resolver<
		IResolversTypes['StoryComment'],
		ParentType,
		ContextType,
		RequireFields<IMutationCommentOnStoryArgs, 'input'>
	>;
	createCharacter?: Resolver<
		IResolversTypes['Character'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreateCharacterArgs, 'input'>
	>;
	createCharacterDraft?: Resolver<IResolversTypes['CharacterDraft'], ParentType, ContextType>;
	createItem?: Resolver<
		IResolversTypes['Item'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreateItemArgs, 'input'>
	>;
	createOrderDraft?: Resolver<
		IResolversTypes['OrderDraft'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreateOrderDraftArgs, 'input'>
	>;
	createPanelDraft?: Resolver<
		IResolversTypes['PanelDraft'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreatePanelDraftArgs, 'input'>
	>;
	createPaymentMethod?: Resolver<
		IResolversTypes['PaymentMethod'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreatePaymentMethodArgs, 'input'>
	>;
	createPaymentMethodSetupIntent?: Resolver<IResolversTypes['PaymentMethodSetupIntent'], ParentType, ContextType>;
	createPlaidLinkToken?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	createShop?: Resolver<IResolversTypes['Shop'], ParentType, ContextType>;
	createShopBankAccount?: Resolver<
		IResolversTypes['ShopBankAccount'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreateShopBankAccountArgs, 'input'>
	>;
	createStory?: Resolver<
		IResolversTypes['Story'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreateStoryArgs, 'input'>
	>;
	createStoryDraft?: Resolver<
		IResolversTypes['StoryDraftData'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreateStoryDraftArgs, 'input'>
	>;
	createStoryView?: Resolver<
		IResolversTypes['StoryView'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreateStoryViewArgs, 'input'>
	>;
	createSubscription?: Resolver<
		IResolversTypes['UserSubscription'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreateSubscriptionArgs, 'input'>
	>;
	createUpcomingStory?: Resolver<
		IResolversTypes['UpcomingStory'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreateUpcomingStoryArgs, 'input'>
	>;
	createUploadedImageDraft?: Resolver<
		IResolversTypes['ClientImageDraft'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreateUploadedImageDraftArgs, 'input'>
	>;
	createUserAddress?: Resolver<
		IResolversTypes['UserAddress'],
		ParentType,
		ContextType,
		RequireFields<IMutationCreateUserAddressArgs, 'input'>
	>;
	deleteTokenAutoPay?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
	exchangePlaidPublicToken?: Resolver<
		IResolversTypes['PlaidExchangedToken'],
		ParentType,
		ContextType,
		RequireFields<IMutationExchangePlaidPublicTokenArgs, 'publicToken'>
	>;
	favoriteStory?: Resolver<
		IResolversTypes['FavoriteStory'],
		ParentType,
		ContextType,
		RequireFields<IMutationFavoriteStoryArgs, 'input'>
	>;
	followUser?: Resolver<
		IResolversTypes['UserFollower'],
		ParentType,
		ContextType,
		RequireFields<IMutationFollowUserArgs, 'input'>
	>;
	generateCharacterHeadshotImages?: Resolver<
		Array<IResolversTypes['ClientImageDraft']>,
		ParentType,
		ContextType,
		RequireFields<IMutationGenerateCharacterHeadshotImagesArgs, 'input'>
	>;
	generateCharacterImages?: Resolver<
		Array<IResolversTypes['ClientImageDraft']>,
		ParentType,
		ContextType,
		RequireFields<IMutationGenerateCharacterImagesArgs, 'input'>
	>;
	generateCoverImages?: Resolver<
		Array<IResolversTypes['ClientImageDraft']>,
		ParentType,
		ContextType,
		RequireFields<IMutationGenerateCoverImagesArgs, 'input'>
	>;
	generateItemMockups?: Resolver<
		Array<IResolversTypes['GeneratedItemMockup']>,
		ParentType,
		ContextType,
		RequireFields<IMutationGenerateItemMockupsArgs, 'input'>
	>;
	generateSceneImages?: Resolver<
		Array<IResolversTypes['ClientImageDraft']>,
		ParentType,
		ContextType,
		RequireFields<IMutationGenerateSceneImagesArgs, 'input'>
	>;
	regenerateCoverImages?: Resolver<
		Array<IResolversTypes['ClientImageDraft']>,
		ParentType,
		ContextType,
		RequireFields<IMutationRegenerateCoverImagesArgs, 'input'>
	>;
	regenerateSceneImages?: Resolver<
		Array<IResolversTypes['ClientImageDraft']>,
		ParentType,
		ContextType,
		RequireFields<IMutationRegenerateSceneImagesArgs, 'input'>
	>;
	removeCartItemVariant?: Resolver<
		IResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<IMutationRemoveCartItemVariantArgs, 'input'>
	>;
	requestSubscriptionCancellation?: Resolver<
		IResolversTypes['UserSubscription'],
		ParentType,
		ContextType,
		RequireFields<IMutationRequestSubscriptionCancellationArgs, 'input'>
	>;
	resetPassword?: Resolver<
		IResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<IMutationResetPasswordArgs, 'input'>
	>;
	sendContactUsEmail?: Resolver<
		IResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<IMutationSendContactUsEmailArgs, 'input'>
	>;
	sendForgotPasswordEmail?: Resolver<
		IResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<IMutationSendForgotPasswordEmailArgs, 'input'>
	>;
	signInWithPassword?: Resolver<
		IResolversTypes['AuthResponse'],
		ParentType,
		ContextType,
		RequireFields<IMutationSignInWithPasswordArgs, 'input'>
	>;
	signOut?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
	signUpWithPassword?: Resolver<
		IResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<IMutationSignUpWithPasswordArgs, 'input'>
	>;
	updateCharacterDraft?: Resolver<
		IResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<IMutationUpdateCharacterDraftArgs, 'input'>
	>;
	updatePassword?: Resolver<
		IResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<IMutationUpdatePasswordArgs, 'input'>
	>;
	updateStoryDraft?: Resolver<
		IResolversTypes['StoryDraftData'],
		ParentType,
		ContextType,
		RequireFields<IMutationUpdateStoryDraftArgs, 'input'>
	>;
	updateSubscriptionPaymentMethod?: Resolver<
		IResolversTypes['UserSubscription'],
		ParentType,
		ContextType,
		RequireFields<IMutationUpdateSubscriptionPaymentMethodArgs, 'input'>
	>;
	updateTokenAutoPay?: Resolver<
		IResolversTypes['TokenAutoPay'],
		ParentType,
		ContextType,
		RequireFields<IMutationUpdateTokenAutoPayArgs, 'input'>
	>;
	updateUpcomingStory?: Resolver<
		IResolversTypes['UpcomingStory'],
		ParentType,
		ContextType,
		RequireFields<IMutationUpdateUpcomingStoryArgs, 'input'>
	>;
	updateUser?: Resolver<
		IResolversTypes['AuthUser'],
		ParentType,
		ContextType,
		RequireFields<IMutationUpdateUserArgs, 'input'>
	>;
	uploadStory?: Resolver<
		IResolversTypes['GeneratedIdea'],
		ParentType,
		ContextType,
		RequireFields<IMutationUploadStoryArgs, 'input'>
	>;
	upscaleImage?: Resolver<
		IResolversTypes['ClientImageDraft'],
		ParentType,
		ContextType,
		RequireFields<IMutationUpscaleImageArgs, 'input'>
	>;
	verifyEmail?: Resolver<
		IResolversTypes['AuthResponse'],
		ParentType,
		ContextType,
		RequireFields<IMutationVerifyEmailArgs, 'input'>
	>;
	withdrawShopBalance?: Resolver<IResolversTypes['Boolean'], ParentType, ContextType>;
};

export type IOrderResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['Order'] = IResolversParentTypes['Order'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	itemVariants?: Resolver<Array<IResolversTypes['ItemVariant']>, ParentType, ContextType>;
	orderNumber?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	shippingAddress?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	status?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	total?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IOrderDraftResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['OrderDraft'] = IResolversParentTypes['OrderDraft'],
> = {
	externalProviderId?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	shippingCost?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
	taxCost?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IOrderItemVariantResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['OrderItemVariant'] = IResolversParentTypes['OrderItemVariant'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	itemVariant?: Resolver<IResolversTypes['ItemVariant'], ParentType, ContextType>;
	itemVariantId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	order?: Resolver<IResolversTypes['Order'], ParentType, ContextType>;
	orderId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IPageResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['Page'] = IResolversParentTypes['Page'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	imageUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	index?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	story?: Resolver<IResolversTypes['Story'], ParentType, ContextType>;
	storyId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IPanelDraftResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['PanelDraft'] = IResolversParentTypes['PanelDraft'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	storyDraftId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IPaymentMethodResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['PaymentMethod'] = IResolversParentTypes['PaymentMethod'],
> = {
	brand?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	expirationMonth?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	expirationYear?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	externalPaymentProcessorId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	lastFourNumbers?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IPaymentMethodSetupIntentResolvers<
	ContextType = ServerContext,
	ParentType extends
		IResolversParentTypes['PaymentMethodSetupIntent'] = IResolversParentTypes['PaymentMethodSetupIntent'],
> = {
	clientSecret?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IPlaidExchangedTokenResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['PlaidExchangedToken'] = IResolversParentTypes['PlaidExchangedToken'],
> = {
	accessToken?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	itemId?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IQueryResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['Query'] = IResolversParentTypes['Query'],
> = {
	authUser?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	cartItemVariants?: Resolver<Array<IResolversTypes['CartItemVariant']>, ParentType, ContextType>;
	character?: Resolver<IResolversTypes['Character'], ParentType, ContextType, RequireFields<IQueryCharacterArgs, 'id'>>;
	characterDraft?: Resolver<
		IResolversTypes['CharacterDraft'],
		ParentType,
		ContextType,
		RequireFields<IQueryCharacterDraftArgs, 'id'>
	>;
	characterDrafts?: Resolver<
		Array<IResolversTypes['CharacterDraft']>,
		ParentType,
		ContextType,
		Partial<IQueryCharacterDraftsArgs>
	>;
	characters?: Resolver<Array<IResolversTypes['Character']>, ParentType, ContextType, Partial<IQueryCharactersArgs>>;
	fileUploadUrls?: Resolver<
		IResolversTypes['FileUploadUrlsResponse'],
		ParentType,
		ContextType,
		RequireFields<IQueryFileUploadUrlsArgs, 'input'>
	>;
	imageDrafts?: Resolver<
		Array<IResolversTypes['ClientImageDraft']>,
		ParentType,
		ContextType,
		RequireFields<IQueryImageDraftsArgs, 'sourceDraftId'>
	>;
	item?: Resolver<IResolversTypes['Item'], ParentType, ContextType, RequireFields<IQueryItemArgs, 'id'>>;
	itemVariant?: Resolver<
		IResolversTypes['ItemVariant'],
		ParentType,
		ContextType,
		RequireFields<IQueryItemVariantArgs, 'id'>
	>;
	items?: Resolver<Array<IResolversTypes['Item']>, ParentType, ContextType, RequireFields<IQueryItemsArgs, 'shopId'>>;
	order?: Resolver<IResolversTypes['Order'], ParentType, ContextType, RequireFields<IQueryOrderArgs, 'filter'>>;
	orders?: Resolver<
		Array<IResolversTypes['Order']>,
		ParentType,
		ContextType,
		RequireFields<IQueryOrdersArgs, 'filter'>
	>;
	pages?: Resolver<Array<IResolversTypes['Page']>, ParentType, ContextType, RequireFields<IQueryPagesArgs, 'storyId'>>;
	paymentMethods?: Resolver<Array<IResolversTypes['PaymentMethod']>, ParentType, ContextType>;
	shop?: Resolver<IResolversTypes['Shop'], ParentType, ContextType, RequireFields<IQueryShopArgs, 'username'>>;
	stories?: Resolver<Array<IResolversTypes['Story']>, ParentType, ContextType, Partial<IQueryStoriesArgs>>;
	story?: Resolver<IResolversTypes['Story'], ParentType, ContextType, RequireFields<IQueryStoryArgs, 'id'>>;
	storyCharacters?: Resolver<
		Array<IResolversTypes['StoryCharacter']>,
		ParentType,
		ContextType,
		RequireFields<IQueryStoryCharactersArgs, 'storyId'>
	>;
	storyComments?: Resolver<
		Array<IResolversTypes['StoryComment']>,
		ParentType,
		ContextType,
		RequireFields<IQueryStoryCommentsArgs, 'storyId'>
	>;
	storyDraft?: Resolver<
		IResolversTypes['StoryDraftData'],
		ParentType,
		ContextType,
		RequireFields<IQueryStoryDraftArgs, 'id'>
	>;
	storyDrafts?: Resolver<
		Array<IResolversTypes['StoryDraftData']>,
		ParentType,
		ContextType,
		Partial<IQueryStoryDraftsArgs>
	>;
	storyViews?: Resolver<
		IResolversTypes['Int'],
		ParentType,
		ContextType,
		RequireFields<IQueryStoryViewsArgs, 'storyId'>
	>;
	subscription?: Resolver<Maybe<IResolversTypes['UserSubscription']>, ParentType, ContextType>;
	subscriptionEvents?: Resolver<
		Array<IResolversTypes['SubscriptionEvent']>,
		ParentType,
		ContextType,
		RequireFields<IQuerySubscriptionEventsArgs, 'subscriptionId'>
	>;
	subscriptionPlans?: Resolver<Array<IResolversTypes['SubscriptionPlan']>, ParentType, ContextType>;
	templateItems?: Resolver<Array<IResolversTypes['TemplateItem']>, ParentType, ContextType>;
	tokenAutoPay?: Resolver<Maybe<IResolversTypes['TokenAutoPay']>, ParentType, ContextType>;
	tokenBalance?: Resolver<IResolversTypes['TokenBalance'], ParentType, ContextType>;
	tokenBalanceEvents?: Resolver<Array<IResolversTypes['TokenBalanceEvent']>, ParentType, ContextType>;
	upcomingStories?: Resolver<
		Array<IResolversTypes['UpcomingStory']>,
		ParentType,
		ContextType,
		Partial<IQueryUpcomingStoriesArgs>
	>;
	user?: Resolver<IResolversTypes['User'], ParentType, ContextType, RequireFields<IQueryUserArgs, 'filter'>>;
	userAcknowledgedTutorials?: Resolver<Array<IResolversTypes['UserAcknowledgedTutorial']>, ParentType, ContextType>;
	userAddresses?: Resolver<Array<IResolversTypes['UserAddress']>, ParentType, ContextType>;
};

export type IResetPasswordTokenResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['ResetPasswordToken'] = IResolversParentTypes['ResetPasswordToken'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	expiresAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	tokenHash?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IShopResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['Shop'] = IResolversParentTypes['Shop'],
> = {
	balance?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
	balanceEvents?: Resolver<Array<IResolversTypes['ShopBalanceEvent']>, ParentType, ContextType>;
	bankAccount?: Resolver<Maybe<IResolversTypes['ShopBankAccount']>, ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	items?: Resolver<Array<IResolversTypes['Item']>, ParentType, ContextType>;
	orders?: Resolver<Array<IResolversTypes['Order']>, ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IShopBalanceEventResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['ShopBalanceEvent'] = IResolversParentTypes['ShopBalanceEvent'],
> = {
	amount?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	metadata?: Resolver<Maybe<IResolversTypes['JSON']>, ParentType, ContextType>;
	shop?: Resolver<IResolversTypes['Shop'], ParentType, ContextType>;
	shopId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	type?: Resolver<IResolversTypes['ShopBalanceEventType'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IShopBalanceEventTypeScalarConfig
	extends GraphQLScalarTypeConfig<IResolversTypes['ShopBalanceEventType'], any> {
	name: 'ShopBalanceEventType';
}

export type IShopBankAccountResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['ShopBankAccount'] = IResolversParentTypes['ShopBankAccount'],
> = {
	bankName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	externalProviderId?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	externalProviderMetadata?: Resolver<IResolversTypes['JSON'], ParentType, ContextType>;
	externalProviderToken?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	shop?: Resolver<IResolversTypes['Shop'], ParentType, ContextType>;
	shopId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IStoryResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['Story'] = IResolversParentTypes['Story'],
> = {
	artStyle?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	coverImageUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	creator?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
	creatorId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	genres?: Resolver<Array<IResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	isFavorited?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
	pages?: Resolver<Array<IResolversTypes['Page']>, ParentType, ContextType>;
	storyCharacters?: Resolver<Array<IResolversTypes['StoryCharacter']>, ParentType, ContextType>;
	storyDraft?: Resolver<IResolversTypes['StoryDraftData'], ParentType, ContextType>;
	storyDraftId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	summary?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	title?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IStoryCharacterResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['StoryCharacter'] = IResolversParentTypes['StoryCharacter'],
> = {
	character?: Resolver<IResolversTypes['Character'], ParentType, ContextType>;
	characterDevelopment?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	characterId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	story?: Resolver<IResolversTypes['Story'], ParentType, ContextType>;
	storyId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IStoryCommentResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['StoryComment'] = IResolversParentTypes['StoryComment'],
> = {
	children?: Resolver<Array<IResolversTypes['StoryComment']>, ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	parent?: Resolver<Maybe<IResolversTypes['StoryComment']>, ParentType, ContextType>;
	parentStoryCommentId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
	story?: Resolver<IResolversTypes['Story'], ParentType, ContextType>;
	storyId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	text?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IStoryDraftDataResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['StoryDraftData'] = IResolversParentTypes['StoryDraftData'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	data?: Resolver<IResolversTypes['JSON'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	lastActiveFormStep?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IStoryViewResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['StoryView'] = IResolversParentTypes['StoryView'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	story?: Resolver<IResolversTypes['Story'], ParentType, ContextType>;
	storyId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<Maybe<IResolversTypes['User']>, ParentType, ContextType>;
	userId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISubscriptionEventResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['SubscriptionEvent'] = IResolversParentTypes['SubscriptionEvent'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	metadata?: Resolver<Maybe<IResolversTypes['SubscriptionEventMetadata']>, ParentType, ContextType>;
	subscription?: Resolver<IResolversTypes['UserSubscription'], ParentType, ContextType>;
	subscriptionId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	type?: Resolver<IResolversTypes['SubscriptionEventType'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISubscriptionEventMetadataResolvers<
	ContextType = ServerContext,
	ParentType extends
		IResolversParentTypes['SubscriptionEventMetadata'] = IResolversParentTypes['SubscriptionEventMetadata'],
> = {
	amount?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
	message?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	paymentMethod?: Resolver<Maybe<IResolversTypes['SubscriptionEventPaymentMethod']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ISubscriptionEventPaymentMethodResolvers<
	ContextType = ServerContext,
	ParentType extends
		IResolversParentTypes['SubscriptionEventPaymentMethod'] = IResolversParentTypes['SubscriptionEventPaymentMethod'],
> = {
	brand?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	lastFourNumbers?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ISubscriptionEventTypeScalarConfig
	extends GraphQLScalarTypeConfig<IResolversTypes['SubscriptionEventType'], any> {
	name: 'SubscriptionEventType';
}

export type ISubscriptionPlanResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['SubscriptionPlan'] = IResolversParentTypes['SubscriptionPlan'],
> = {
	bullets?: Resolver<Array<IResolversTypes['String']>, ParentType, ContextType>;
	description?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	monthlyPrice?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
	monthlyTokenRefillAmount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	subscriptions?: Resolver<Array<IResolversTypes['UserSubscription']>, ParentType, ContextType>;
	yearlyPrice?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ITemplateItemResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['TemplateItem'] = IResolversParentTypes['TemplateItem'],
> = {
	id?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	imageUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	minimumPrice?: Resolver<IResolversTypes['Float'], ParentType, ContextType>;
	mockupStyleId?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	name?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	placement?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	specs?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	technique?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	type?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	variants?: Resolver<Array<IResolversTypes['TemplateItemVariant']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ITemplateItemVariantResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['TemplateItemVariant'] = IResolversParentTypes['TemplateItemVariant'],
> = {
	color?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	colorCode?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	imageUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	minimumPrice?: Resolver<Maybe<IResolversTypes['Float']>, ParentType, ContextType>;
	size?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ITokenAutoPayResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['TokenAutoPay'] = IResolversParentTypes['TokenAutoPay'],
> = {
	amount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ITokenBalanceResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['TokenBalance'] = IResolversParentTypes['TokenBalance'],
> = {
	amount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ITokenBalanceEventResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['TokenBalanceEvent'] = IResolversParentTypes['TokenBalanceEvent'],
> = {
	amount?: Resolver<IResolversTypes['Int'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	type?: Resolver<IResolversTypes['TokenBalanceTransactionEventType'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ITokenBalanceTransactionEventTypeScalarConfig
	extends GraphQLScalarTypeConfig<IResolversTypes['TokenBalanceTransactionEventType'], any> {
	name: 'TokenBalanceTransactionEventType';
}

export interface ITutorialTypeScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['TutorialType'], any> {
	name: 'TutorialType';
}

export type IUpcomingStoryResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['UpcomingStory'] = IResolversParentTypes['UpcomingStory'],
> = {
	coverImageUrl?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	creator?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
	creatorId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	story?: Resolver<Maybe<IResolversTypes['Story']>, ParentType, ContextType>;
	storyDraft?: Resolver<Maybe<IResolversTypes['StoryDraftData']>, ParentType, ContextType>;
	storyDraftId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
	storyId?: Resolver<Maybe<IResolversTypes['ID']>, ParentType, ContextType>;
	title?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUserResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['User'] = IResolversParentTypes['User'],
> = {
	bio?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	displayName?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	favoriteStories?: Resolver<Array<IResolversTypes['Story']>, ParentType, ContextType>;
	followers?: Resolver<Array<IResolversTypes['User']>, ParentType, ContextType>;
	followersCount?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
	following?: Resolver<Array<IResolversTypes['User']>, ParentType, ContextType>;
	followingCount?: Resolver<Maybe<IResolversTypes['Int']>, ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	isFollower?: Resolver<Maybe<IResolversTypes['Boolean']>, ParentType, ContextType>;
	profilePictureImageUrl?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	shop?: Resolver<Maybe<IResolversTypes['Shop']>, ParentType, ContextType>;
	stories?: Resolver<Array<IResolversTypes['Story']>, ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	username?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUserAcknowledgedTutorialResolvers<
	ContextType = ServerContext,
	ParentType extends
		IResolversParentTypes['UserAcknowledgedTutorial'] = IResolversParentTypes['UserAcknowledgedTutorial'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	tutorialType?: Resolver<IResolversTypes['TutorialType'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUserAddressResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['UserAddress'] = IResolversParentTypes['UserAddress'],
> = {
	addressLine1?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	addressLine2?: Resolver<Maybe<IResolversTypes['String']>, ParentType, ContextType>;
	city?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	country?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	state?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	zip?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IUserFollowerResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['UserFollower'] = IResolversParentTypes['UserFollower'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	follower?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
	followerId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['User'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IUserRoleScalarConfig extends GraphQLScalarTypeConfig<IResolversTypes['UserRole'], any> {
	name: 'UserRole';
}

export type IUserSubscriptionResolvers<
	ContextType = ServerContext,
	ParentType extends IResolversParentTypes['UserSubscription'] = IResolversParentTypes['UserSubscription'],
> = {
	createdAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	endDate?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
	id?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	nextPaymentDate?: Resolver<Maybe<IResolversTypes['DateTime']>, ParentType, ContextType>;
	paymentMethod?: Resolver<IResolversTypes['PaymentMethod'], ParentType, ContextType>;
	paymentMethodId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	period?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	startDate?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	status?: Resolver<IResolversTypes['String'], ParentType, ContextType>;
	subscriptionEvents?: Resolver<Array<IResolversTypes['SubscriptionEvent']>, ParentType, ContextType>;
	subscriptionPlan?: Resolver<IResolversTypes['SubscriptionPlan'], ParentType, ContextType>;
	subscriptionPlanId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	updatedAt?: Resolver<IResolversTypes['DateTime'], ParentType, ContextType>;
	user?: Resolver<IResolversTypes['AuthUser'], ParentType, ContextType>;
	userId?: Resolver<IResolversTypes['ID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IResolvers<ContextType = ServerContext> = {
	AuthResponse?: IAuthResponseResolvers<ContextType>;
	AuthUser?: IAuthUserResolvers<ContextType>;
	CartItemVariant?: ICartItemVariantResolvers<ContextType>;
	Character?: ICharacterResolvers<ContextType>;
	CharacterDraft?: ICharacterDraftResolvers<ContextType>;
	ClientImage?: IClientImageResolvers<ContextType>;
	ClientImageDraft?: IClientImageDraftResolvers<ContextType>;
	DateTime?: GraphQLScalarType;
	Dialogue?: IDialogueResolvers<ContextType>;
	EmailVerificationToken?: IEmailVerificationTokenResolvers<ContextType>;
	FavoriteStory?: IFavoriteStoryResolvers<ContextType>;
	FileUploadUrlsResponse?: IFileUploadUrlsResponseResolvers<ContextType>;
	GeneratedDialogue?: IGeneratedDialogueResolvers<ContextType>;
	GeneratedIdea?: IGeneratedIdeaResolvers<ContextType>;
	GeneratedItemMockup?: IGeneratedItemMockupResolvers<ContextType>;
	GeneratedScene?: IGeneratedSceneResolvers<ContextType>;
	Item?: IItemResolvers<ContextType>;
	ItemPlacement?: IItemPlacementResolvers<ContextType>;
	ItemPlacementLayer?: IItemPlacementLayerResolvers<ContextType>;
	ItemVariant?: IItemVariantResolvers<ContextType>;
	ItemVariantImage?: IItemVariantImageResolvers<ContextType>;
	JSON?: GraphQLScalarType;
	LiteralEditedImage?: GraphQLScalarType;
	LiteralGeneratedImage?: GraphQLScalarType;
	Mutation?: IMutationResolvers<ContextType>;
	Order?: IOrderResolvers<ContextType>;
	OrderDraft?: IOrderDraftResolvers<ContextType>;
	OrderItemVariant?: IOrderItemVariantResolvers<ContextType>;
	Page?: IPageResolvers<ContextType>;
	PanelDraft?: IPanelDraftResolvers<ContextType>;
	PaymentMethod?: IPaymentMethodResolvers<ContextType>;
	PaymentMethodSetupIntent?: IPaymentMethodSetupIntentResolvers<ContextType>;
	PlaidExchangedToken?: IPlaidExchangedTokenResolvers<ContextType>;
	Query?: IQueryResolvers<ContextType>;
	ResetPasswordToken?: IResetPasswordTokenResolvers<ContextType>;
	Shop?: IShopResolvers<ContextType>;
	ShopBalanceEvent?: IShopBalanceEventResolvers<ContextType>;
	ShopBalanceEventType?: GraphQLScalarType;
	ShopBankAccount?: IShopBankAccountResolvers<ContextType>;
	Story?: IStoryResolvers<ContextType>;
	StoryCharacter?: IStoryCharacterResolvers<ContextType>;
	StoryComment?: IStoryCommentResolvers<ContextType>;
	StoryDraftData?: IStoryDraftDataResolvers<ContextType>;
	StoryView?: IStoryViewResolvers<ContextType>;
	SubscriptionEvent?: ISubscriptionEventResolvers<ContextType>;
	SubscriptionEventMetadata?: ISubscriptionEventMetadataResolvers<ContextType>;
	SubscriptionEventPaymentMethod?: ISubscriptionEventPaymentMethodResolvers<ContextType>;
	SubscriptionEventType?: GraphQLScalarType;
	SubscriptionPlan?: ISubscriptionPlanResolvers<ContextType>;
	TemplateItem?: ITemplateItemResolvers<ContextType>;
	TemplateItemVariant?: ITemplateItemVariantResolvers<ContextType>;
	TokenAutoPay?: ITokenAutoPayResolvers<ContextType>;
	TokenBalance?: ITokenBalanceResolvers<ContextType>;
	TokenBalanceEvent?: ITokenBalanceEventResolvers<ContextType>;
	TokenBalanceTransactionEventType?: GraphQLScalarType;
	TutorialType?: GraphQLScalarType;
	UpcomingStory?: IUpcomingStoryResolvers<ContextType>;
	User?: IUserResolvers<ContextType>;
	UserAcknowledgedTutorial?: IUserAcknowledgedTutorialResolvers<ContextType>;
	UserAddress?: IUserAddressResolvers<ContextType>;
	UserFollower?: IUserFollowerResolvers<ContextType>;
	UserRole?: GraphQLScalarType;
	UserSubscription?: IUserSubscriptionResolvers<ContextType>;
};

export type IDirectiveResolvers<ContextType = ServerContext> = {
	constraint?: IConstraintDirectiveResolver<any, any, ContextType>;
	subresolve?: ISubresolveDirectiveResolver<any, any, ContextType>;
};

export type AddCartInputItemVariant = IAddCartInputItemVariant;

export type AuthResponse = IAuthResponse;

export type AuthUser = Omit<
	IAuthUser,
	| 'stories'
	| 'favoriteStories'
	| 'followers'
	| 'followersCount'
	| 'following'
	| 'followingCount'
	| 'isFollower'
	| 'subscriptions'
	| 'currentSubscription'
	| 'tokenBalance'
	| 'tokenBalanceEvents'
	| 'addresses'
	| 'shop'
	| 'orders'
>;

export type BuyItemsInput = IBuyItemsInput;

export type BuyTokensInput = IBuyTokensInput;

export type CartItemVariant = Omit<ICartItemVariant, 'user' | 'itemVariant'>;

export type ChangeSubscriptionPlanInput = IChangeSubscriptionPlanInput;

export type Character = Omit<ICharacter, 'creator' | 'stories'>;

export type CharacterDraft = Omit<ICharacterDraft, 'selectedImageDraft' | 'creator'>;

export type CharactersFilterInput = ICharactersFilterInput;

export type ClientImage = IClientImage;

export type ClientImageDraft = IClientImageDraft;

export type CommentOnStoryInput = ICommentOnStoryInput;

export { IContactUsMessageType as ContactUsMessageType };

export type CreateCharacterInput = ICreateCharacterInput;

export type CreateDialogueInput = ICreateDialogueInput;

export type CreateImageInput = ICreateImageInput;

export type CreateItemInput = ICreateItemInput;

export type CreateItemPlacementInput = ICreateItemPlacementInput;

export type CreateItemPlacementLayerInput = ICreateItemPlacementLayerInput;

export type CreateItemVariantInput = ICreateItemVariantInput;

export type CreateOrderDraftInput = ICreateOrderDraftInput;

export type CreatePanelDraftInput = ICreatePanelDraftInput;

export type CreatePaymentMethodInput = ICreatePaymentMethodInput;

export type CreateShopBankAccountInput = ICreateShopBankAccountInput;

export type CreateStoryDraftInput = ICreateStoryDraftInput;

export type CreateStoryInput = ICreateStoryInput;

export type CreateStoryViewInput = ICreateStoryViewInput;

export type CreateSubscriptionInput = ICreateSubscriptionInput;

export type CreateUpcomingStoryInput = ICreateUpcomingStoryInput;

export type CreateUploadedImageDraftInput = ICreateUploadedImageDraftInput;

export type CreateUserAddressInput = ICreateUserAddressInput;

export type Dialogue = Omit<IDialogue, 'character'>;

export type EmailVerificationToken = Omit<IEmailVerificationToken, 'user'>;

export type FavoriteStory = Omit<IFavoriteStory, 'user' | 'story'>;

export type FavoriteStoryInput = IFavoriteStoryInput;

export type FileUploadUrlsInput = IFileUploadUrlsInput;

export type FileUploadUrlsResponse = IFileUploadUrlsResponse;

export type FollowUserInput = IFollowUserInput;

export type GenerateCharacterHeadshotImagesInput = IGenerateCharacterHeadshotImagesInput;

export type GenerateCharacterImagesInput = IGenerateCharacterImagesInput;

export type GenerateCoverImagesInput = IGenerateCoverImagesInput;

export type GenerateItemMockupsInput = IGenerateItemMockupsInput;

export type GenerateSceneImagesInput = IGenerateSceneImagesInput;

export type GeneratedDialogue = IGeneratedDialogue;

export type GeneratedIdea = IGeneratedIdea;

export type GeneratedItemMockup = IGeneratedItemMockup;

export type GeneratedScene = IGeneratedScene;

export { IImageKind as ImageKind };

export { IImageStatus as ImageStatus };

export type Item = Omit<IItem, 'itemVariants' | 'shop'>;

export type ItemPlacement = IItemPlacement;

export type ItemPlacementLayer = IItemPlacementLayer;

export type ItemVariant = Omit<IItemVariant, 'item' | 'itemVariantImages'>;

export type ItemVariantImage = Omit<IItemVariantImage, 'itemVariant'>;

export type Mutation = IMutation;

export type Order = Omit<IOrder, 'user' | 'itemVariants'>;

export type OrderDraft = IOrderDraft;

export type OrderFilterInput = IOrderFilterInput;

export type OrderItemVariant = Omit<IOrderItemVariant, 'itemVariant' | 'order'>;

export type OrdersFilterInput = IOrdersFilterInput;

export type Page = Omit<IPage, 'story'>;

export type PageImage = IPageImage;

export type PagesFilterInput = IPagesFilterInput;

export type PaginationInput = IPaginationInput;

export type PanelDraft = IPanelDraft;

export type PaymentMethod = Omit<IPaymentMethod, 'user'>;

export type PaymentMethodSetupIntent = IPaymentMethodSetupIntent;

export type PlaidExchangedToken = IPlaidExchangedToken;

export type ProvideCharacterImageFeedbackInput = IProvideCharacterImageFeedbackInput;

export type Query = IQuery;

export type RegenerateCoverImagesInput = IRegenerateCoverImagesInput;

export type RegenerateSceneImagesInput = IRegenerateSceneImagesInput;

export type RemoveCartInputItemVariantInput = IRemoveCartInputItemVariantInput;

export type RequestSubscriptionCancellationInput = IRequestSubscriptionCancellationInput;

export type ResetPasswordInput = IResetPasswordInput;

export type ResetPasswordToken = Omit<IResetPasswordToken, 'user'>;

export type SendContactUsEmailInput = ISendContactUsEmailInput;

export type SendForgotPasswordEmailInput = ISendForgotPasswordEmailInput;

export type Shop = Omit<IShop, 'user' | 'items' | 'orders' | 'bankAccount' | 'balanceEvents'>;

export type ShopBalanceEvent = Omit<IShopBalanceEvent, 'shop'>;

export type ShopBankAccount = Omit<IShopBankAccount, 'shop'>;

export type SignInWithPasswordInput = ISignInWithPasswordInput;

export type SignUpWithPasswordInput = ISignUpWithPasswordInput;

export { ISortDirection as SortDirection };

export type SortInput = ISortInput;

export type StoriesFilterInput = IStoriesFilterInput;

export type Story = Omit<IStory, 'creator' | 'storyDraft' | 'storyCharacters' | 'pages' | 'isFavorited'>;

export type StoryCharacter = Omit<IStoryCharacter, 'story' | 'character'>;

export type StoryCharacterInput = IStoryCharacterInput;

export type StoryComment = Omit<IStoryComment, 'story' | 'parent' | 'children' | 'user'>;

export type StoryDraftData = IStoryDraftData;

export type StoryView = Omit<IStoryView, 'story' | 'user'>;

export type SubscriptionEvent = Omit<ISubscriptionEvent, 'subscription'>;

export type SubscriptionEventMetadata = ISubscriptionEventMetadata;

export type SubscriptionEventPaymentMethod = ISubscriptionEventPaymentMethod;

export type SubscriptionPlan = Omit<ISubscriptionPlan, 'subscriptions'>;

export type TemplateItem = ITemplateItem;

export type TemplateItemVariant = ITemplateItemVariant;

export type TokenAutoPay = Omit<ITokenAutoPay, 'user'>;

export type TokenBalance = Omit<ITokenBalance, 'user'>;

export type TokenBalanceEvent = Omit<ITokenBalanceEvent, 'user'>;

export type UpcomingStory = Omit<IUpcomingStory, 'creator' | 'storyDraft' | 'story'>;

export type UpdateCharacterDraftInput = IUpdateCharacterDraftInput;

export type UpdatePasswordInput = IUpdatePasswordInput;

export type UpdateStoryDraftInput = IUpdateStoryDraftInput;

export type UpdateSubscriptionPaymentMethodInput = IUpdateSubscriptionPaymentMethodInput;

export type UpdateTokenAutoPayInput = IUpdateTokenAutoPayInput;

export type UpdateUpcomingStoryInput = IUpdateUpcomingStoryInput;

export type UpdateUserInput = IUpdateUserInput;

export type UploadStoryInput = IUploadStoryInput;

export type UpscaleImageInput = IUpscaleImageInput;

export type User = Omit<
	IUser,
	| 'stories'
	| 'favoriteStories'
	| 'followers'
	| 'followersCount'
	| 'following'
	| 'followingCount'
	| 'isFollower'
	| 'shop'
>;

export type UserAcknowledgedTutorial = Omit<IUserAcknowledgedTutorial, 'user'>;

export type UserAddress = Omit<IUserAddress, 'user'>;

export type UserFilterInput = IUserFilterInput;

export type UserFollower = Omit<IUserFollower, 'user' | 'follower'>;

export type UserSubscription = Omit<
	IUserSubscription,
	'user' | 'subscriptionPlan' | 'paymentMethod' | 'subscriptionEvents'
>;

export type VerifyEmailInput = IVerifyEmailInput;
