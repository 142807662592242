import { MantineSize } from '@mantine/core';
import { BoxProps, Flex, FlexProps } from '@mantine/core';
import { ReactNode } from 'react';

type ContainerProps = FlexProps;
function Container({ children, ...flexProps }: ContainerProps) {
	return (
		// `style=...` is used to pass `fillHeight` styles to child components, if embedded in a `fillHeight` container
		// `flex: 1` ensures the container grows to the full height
		// `overflow: auto` ensures the container scrolls if the height is larger than the container
		<Flex gap="md" {...flexProps} style={{ flex: 1, overflow: 'auto' }}>
			{children}
		</Flex>
	);
}

const widths: Record<MantineSize, string> = {
	xs: '50px',
	sm: '100px',
	md: '200px',
	lg: '300px',
	xl: '400px',
};
const defaultWidth = widths.md;
type SideProps = FlexProps & {
	children: ReactNode;
};
function Side({ children, w, ...boxProps }: SideProps) {
	let width = w ?? defaultWidth;
	if (typeof w === 'string' && w in widths) {
		width = widths[w as MantineSize];
	}
	return (
		<Flex
			w={width}
			style={{
				// Prevents `Side` from shrinking when the screen width gets smaller, instead shrinking `Main`
				flexShrink: 0,
			}}
			{...boxProps}
		>
			{children}
		</Flex>
	);
}

type MainProps = BoxProps & {
	children: ReactNode;
};
function Main({ children, ...boxProps }: MainProps) {
	return (
		<Flex
			style={{
				flexGrow: 1,
				flexShrink: 1,
			}}
			{...boxProps}
		>
			{children}
		</Flex>
	);
}

/*
<Sidebar.Container>
  <Sidebar.Side>
    ...
  </Sidebar.Side>
  <Sidebar.Main>
    ...
  </Sidebar.Main>
</Sidebar.Container>
*/
const Sidebar = {
	Container,
	Side,
	Main,
};
export default Sidebar;
