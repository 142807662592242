import { useMutation, useQuery } from '@apollo/client';
import { Button, Center, ColorSwatch, Group, Image, InputLabel, Stack, Text, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { graphql } from '@shared';
import PageHeader from '../components/ui/PageHeader';
import ViewContainer from '../components/ui/ViewContainer';
import { clearEntitiesFromCache } from '../utils/cache';

export default function Item() {
	const navigate = useNavigate();
	const id = useParams().id!;
	const [imageUrl, setImageUrl] = useState<string>();
	const [selectedColor, setSelectedColor] = useState<string>();
	const [selectedSize, setSelectedSize] = useState<string>();
	const [selectedVariantId, setSelectedVariantId] = useState<string>();
	const { data } = useQuery(getItemQuery, {
		variables: {
			id,
		},
		onCompleted: (data) => {
			setImageUrl(data.item.itemVariants[0].itemVariantImages[0].imageUrl);
			setSelectedColor(data.item.itemVariants[0].color);
			setSelectedSize(data.item.itemVariants[0].size);
		},
		onError() {
			navigate('/');
		},
	});
	const [addCartItemVariant, { loading: addCartItemVariantLoading }] = useMutation(addCartItemVariantMutation);

	// the available colors of the selected item
	const colors: string[] = useMemo(() => {
		const record: Record<string, boolean> = {};
		for (const variant of data?.item.itemVariants || []) {
			record[variant.color!] = true;
		}
		return Object.keys(record);
	}, [data]);

	// the available sizes of the selected item
	const sizes: string[] = useMemo(() => {
		const record: Record<string, boolean> = {};
		for (const variant of data?.item.itemVariants.filter((itemVariant) => itemVariant.color === selectedColor) || []) {
			record[variant.size!] = true;
		}
		const sortList = ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];
		return Object.keys(record).sort((a, b) => {
			return sortList.indexOf(a) - sortList.indexOf(b);
		});
	}, [data, selectedColor]);

	useEffect(() => {
		setImageUrl(
			data?.item.itemVariants.find((variant) => variant.color === selectedColor)?.itemVariantImages[0].imageUrl
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedColor]);

	useEffect(() => {
		if (selectedColor && selectedSize) {
			setSelectedVariantId(
				data!.item!.itemVariants!.find((variant) => variant.color === selectedColor && variant.size === selectedSize)!
					.id
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedColor, selectedSize]);

	async function handleAddCartItemVariant() {
		if (!selectedVariantId) {
			return;
		}
		await addCartItemVariant({
			variables: {
				input: {
					itemVariantId: selectedVariantId,
				},
			},
			onCompleted: () => {
				notifications.show({
					title: 'Item added',
					message: 'Item has been added to your cart',
					color: 'teal',
				});
			},
			update: (cache) => {
				clearEntitiesFromCache(cache, ['cartItemVariants']);
			},
		});
	}

	return (
		<ViewContainer>
			<PageHeader text="" /> {/* Need this to get padding for some reason */}
			<Group align="start">
				<Image src={imageUrl} w="33%" />
				<Stack flex={1}>
					<Group justify="space-between">
						<Title order={1}>{data?.item.name}</Title>
						<Title order={1}>${data?.item.itemVariants[0].price.toFixed(2)}</Title>
					</Group>
					<Stack gap="xs">
						<InputLabel>Colors</InputLabel>
						<Group>
							{colors.map((color) => (
								<ColorSwatch
									key={color}
									color={color}
									style={{
										cursor: 'pointer',
										border: selectedColor === color ? '4px solid var(--mantine-color-blue-6)' : 'none',
									}}
									onClick={() => setSelectedColor(color)}
								/>
							))}
						</Group>
					</Stack>
					<Stack gap="xs">
						<InputLabel>Sizes</InputLabel>
						<Group>
							{sizes.map((size) => (
								<Center
									key={size}
									bg="softGray"
									style={{
										cursor: 'pointer',
										border: selectedSize === size ? '4px solid var(--mantine-color-blue-6)' : 'none',
									}}
									onClick={() => setSelectedSize(size)}
									w={50}
									h={50}
								>
									<Text size="xs" ta="center">
										{size}
									</Text>
								</Center>
							))}
						</Group>
					</Stack>
					<Group justify="space-between" grow>
						<Button loading={addCartItemVariantLoading} onClick={async () => await handleAddCartItemVariant()}>
							Add to cart
						</Button>
						<Button component={Link} to={`/checkout?buyNowItemVariantId=${selectedVariantId}`}>
							Buy now
						</Button>
					</Group>
					<Stack gap="xs">
						<InputLabel>Description</InputLabel>
						<Text
							size="xs"
							style={{
								whiteSpace: 'pre-wrap',
							}}
						>
							{data?.item.description}
						</Text>
					</Stack>
					<Stack gap="xs">
						<InputLabel>Product Specs</InputLabel>
						<Text
							size="xs"
							style={{
								whiteSpace: 'pre-wrap',
							}}
						>
							{data?.item?.specs}
						</Text>
					</Stack>
				</Stack>
			</Group>
		</ViewContainer>
	);
}

const getItemQuery = graphql(`
	query GetItem($id: ID!) {
		item(id: $id) {
			id
			externalProviderId
			name
			description
			specs
			itemVariants {
				id
				externalProviderId
				price
				color
				size
				itemVariantImages {
					id
					imageUrl
				}
			}
		}
	}
`);

const addCartItemVariantMutation = graphql(`
	mutation AddCartItemVariant($input: AddCartInputItemVariant!) {
		addCartItemVariant(input: $input) {
			id
		}
	}
`);
