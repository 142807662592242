import { ActionIcon, useMantineColorScheme } from '@mantine/core';
import { Moon, Sun } from '@phosphor-icons/react';

export default function ThemeToggler() {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();

	return (
		<ActionIcon variant="transparent" color={colorScheme === 'light' ? 'yellow' : 'blue'} onClick={toggleColorScheme}>
			{colorScheme === 'light' ? <Sun /> : <Moon />}
		</ActionIcon>
	);
}
