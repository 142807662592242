import { Button, ButtonProps, FileButton } from '@mantine/core';
import { UploadSimple } from '@phosphor-icons/react';

type UploadButtonProps = ButtonProps & {
	acceptedFileTypes: string;
	text: string;
	isLoading: boolean;
	shouldShowIcon?: boolean;
	onLoaded: (file: File) => void;
};

export default function UploadButton({
	acceptedFileTypes,
	text,
	isLoading,
	onLoaded,
	shouldShowIcon = true,
	...buttonProps
}: UploadButtonProps) {
	return (
		<FileButton onChange={(file) => onLoaded(file!)} accept={acceptedFileTypes}>
			{(props) => (
				<Button
					size="xs"
					leftSection={shouldShowIcon ? <UploadSimple /> : undefined}
					loading={isLoading}
					{...props}
					{...buttonProps}
				>
					{text}
				</Button>
			)}
		</FileButton>
	);
}
