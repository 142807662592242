import { SimpleGrid } from '@mantine/core';
import { StoryDraft } from '@shared';
import RoundedImage from '../../components/ui/RoundedImage';

type CharacterGridProps = {
	characters: StoryDraft['characters'];
	cols?: number;
};

export function CharactersGrid({ characters, cols = 2 }: CharacterGridProps) {
	return (
		<SimpleGrid py="sm" cols={cols} spacing="xs" verticalSpacing="xs">
			{characters.map((c) => (
				<RoundedImage
					key={c.id}
					url={c.imageUrl}
					label={c.name}
					textProps={{
						size: 'xs',
						truncate: true,
						mt: 4,
					}}
				/>
			))}
		</SimpleGrid>
	);
}
