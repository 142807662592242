export enum Font {
	'Arial' = 'Arial',
	'Arial Black' = 'Arial Black',
	'Comic Sans MS' = 'Comic Sans MS',
	'Courier New' = 'Courier New',
	'Georgia' = 'Georgia',
	'Impact' = 'Impact',
	'Lucida Console' = 'Lucida Console',
	'Lucida Sans Unicode' = 'Lucida Sans Unicode',
	'Palatino Linotype' = 'Palatino Linotype',
	'Tahoma' = 'Tahoma',
	'Times New Roman' = 'Times New Roman',
	'Trebuchet MS' = 'Trebuchet MS',
	'Verdana' = 'Verdana',
}

export enum TextSize {
	'Size_8' = 8,
	'Size_10' = 10,
	'Size_12' = 12,
	'Size_14' = 14,
	'Size_16' = 16,
	'Size_18' = 18,
	'Size_20' = 20,
	'Size_24' = 24,
}
// Numeric enums map both key and value in `Object.values(...)`, so we need to filter out the keys
export const textSizes = Object.values(TextSize).filter((value) => typeof value === 'number');

// Values represent Fabric-specific casing
export enum TextAlignment {
	Left = 'left',
	Center = 'center',
	Right = 'right',
}

export enum TextStyle {
	'Italic' = 'italic',
	'Normal' = 'normal',
	'Underline' = 'underline',
}

export enum TextWeight {
	'Bold' = 'bold',
	'Normal' = 'normal',
}
