import { useQuery } from '@apollo/client';
import { ActionIcon, Indicator } from '@mantine/core';
import { ShoppingCart } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { formatLargeNumberToBeReadable, graphql } from '@shared';
import useColors from '../hooks/useColors';

export default function Cart() {
	const { elementColor } = useColors();
	const { data } = useQuery(getCartItemVariantsForCount);

	return (
		<Indicator size={16} label={formatLargeNumberToBeReadable(data?.cartItemVariants.length || 0)}>
			<ActionIcon component={Link} to="/checkout" variant="transparent">
				<ShoppingCart size={20} color={elementColor} />
			</ActionIcon>
		</Indicator>
	);
}

const getCartItemVariantsForCount = graphql(`
	query GetCartItemVariantsForCount {
		cartItemVariants {
			id
		}
	}
`);
