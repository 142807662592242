export enum UserFriendlyErrors {
	InvalidSignInCredentials = 'Incorrect email or password. Please try again or reset your password',
	EmailAlreadyInUse = 'This email is already registered. Please sign in or reset your password if you forgot it',
	NeedToVerifyEmail = "This email hasn't been verified. Please check your inbox for a verification link",
	WrongOldPassword = 'This old password is incorrect. Please try again',
	InvalidAuthLink = 'This link has expired or is invalid. Please request a new link and try again',
	// Intentionally ambiguous to prevent a user from knowing they're blocked
	Blocklisted = 'An unexpected error occurred.',
	UnexpectedError = 'An unexpected error occurred. Please try again, or contact support if this issue continues',
	InsufficientTokens = 'You do not have enough tokens to complete this action. Please purchase more tokens to continue',
	PaymentFailed = 'Payment failed. Please try again or contact support if this issue continues',
	NoRegeneratingUploadedOrEditedImages = 'You cannot regenerate images that have been uploaded or upscaled',
	ContentModerationLeonardoError = 'We are unable to generate your image(s) due to content moderation (e.g. child characters with low camera angles). Please revise your prompt and try again.',
}

export function getUserFriendlyError(message: string): string {
	const key = Object.keys(UserFriendlyErrors).find(
		(key) => UserFriendlyErrors[key as keyof typeof UserFriendlyErrors] == message
	);
	return key ? UserFriendlyErrors[key as keyof typeof UserFriendlyErrors] : UserFriendlyErrors.UnexpectedError;
}
