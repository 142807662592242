import { createContext } from 'react';
import { UserRole } from '@shared';
import { GetUserContextReturn } from '../graphql/common';

export type SessionContext = {
	isAuthenticated: boolean;
	isLoading: boolean;
	user: GetUserContextReturn;
	refreshSession: () => void;
};

export const defaultSessionContext: SessionContext = {
	isAuthenticated: false,
	isLoading: true,
	user: {
		id: '',
		email: '',
		username: '',
		firstName: '',
		lastName: '',
		displayName: '',
		profilePictureImageUrl: '',
		bio: '',
		isBlocklisted: false,
		role: UserRole.Member,
		currentSubscription: {
			id: '',
			subscriptionPlanId: '',
		},
		tokenBalance: {
			amount: 0,
		},
		shop: {
			id: '',
		},
	},
	refreshSession: () => {},
};

export const SessionContext = createContext<SessionContext>(defaultSessionContext);
