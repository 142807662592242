import { RichTextEditor, RichTextEditorProps } from '@mantine/tiptap';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';
import showdown from 'showdown';
import TurndownService from 'turndown';

const showdownConverter = new showdown.Converter();
function markdownToHtml(markdown: string): string {
	return showdownConverter.makeHtml(markdown);
}

const turndownConverter = new TurndownService().addRule('underline', {
	filter: ['u'],
	replacement: (content) => `<u>${content}</u>`,
});
function htmlToMarkdown(html: string): string {
	return turndownConverter.turndown(html);
}

type TextEditorProps = Omit<RichTextEditorProps, 'editor' | 'children'> & {
	id?: string;
	text: string;
	handleChange: (text: string) => void;
	placeholder?: string;
	shouldShowToolbar?: boolean;
};

export default function TextEditor({
	id,
	text,
	handleChange,
	placeholder,
	shouldShowToolbar = true,
	...richTextEditorProps
}: TextEditorProps) {
	const editor = useEditor({
		onUpdate({ editor }) {
			handleChange(htmlToMarkdown(editor.getHTML()));
		},
		content: markdownToHtml(text),
		extensions: [StarterKit, Placeholder.configure({ placeholder }), Underline],
		editorProps: {
			// Remove default padding on the innermost content div
			attributes: {
				class: 'tiptap ProseMirror',
				style: 'padding: 0;',
			},
		},
	});

	useEffect(() => {
		if (editor) {
			// Save cursor position
			const { from, to } = editor.state.selection;
			// Update content
			editor?.commands.setContent(markdownToHtml(text));
			// Restore cursor position
			const newFrom = Math.min(from, editor.state.doc.content.size);
			const newTo = Math.min(to, editor.state.doc.content.size);
			editor.commands.setTextSelection({ from: newFrom, to: newTo });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [text]);

	return (
		<RichTextEditor
			editor={editor}
			id={id}
			{...richTextEditorProps}
			style={{ border: 0, fontSize: 14, ...richTextEditorProps.style }}
		>
			{shouldShowToolbar && (
				<RichTextEditor.Toolbar px="0" mb="md">
					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Bold />
						<RichTextEditor.Italic />
						<RichTextEditor.Underline />
					</RichTextEditor.ControlsGroup>
					{/* <RichTextEditor.ControlsGroup>
							<RichTextEditor.Undo />
							<RichTextEditor.Redo />
						</RichTextEditor.ControlsGroup> */}
				</RichTextEditor.Toolbar>
			)}
			<RichTextEditor.Content />
		</RichTextEditor>
	);
}
