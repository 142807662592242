import { ActionIcon } from '@mantine/core';
import { ArrowLeft, ArrowRight } from '@phosphor-icons/react';

type ControlIconProps = {
	direction: 'previous' | 'next';
};

export default function ControlIcon({ direction }: ControlIconProps) {
	return (
		<ActionIcon component="div" variant="white" color="dark" radius="xl">
			{direction === 'previous' && <ArrowLeft weight="bold" />}
			{direction === 'next' && <ArrowRight weight="bold" />}
		</ActionIcon>
	);
}
