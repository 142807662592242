import { NavLink } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';

type SidebarLinkProps = {
	to: string;
	label: string;
};

export default function SidebarLink({ to, label }: SidebarLinkProps) {
	const { pathname } = useLocation();

	return (
		<NavLink
			component={Link}
			to={to}
			label={label}
			active={pathname.includes(to)}
			style={{
				borderRadius: '4px',
			}}
		/>
	);
}
