import { Box, BoxProps, Button, Stack, useComputedColorScheme, useMantineTheme } from '@mantine/core';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElements, loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import { stripeAppearance } from '../utils/ui';
import Form from './Form';
import StripePaymentCollectionForm from './StripePaymentCollectionForm';

type PaymentFormProps = BoxProps & {
	shouldDisable: boolean;
	isLoading: boolean;
	handleSubmit: (stripe: Stripe, elements: StripeElements) => void;
};

export default function PaymentForm({ shouldDisable, isLoading, handleSubmit, ...others }: PaymentFormProps) {
	const theme = useMantineTheme();
	const colorScheme = useComputedColorScheme('dark');
	const [stripe, setStripe] = useState<Stripe>();
	const [elements, setElements] = useState<StripeElements>();
	const [isFormValid, setIsFormValid] = useState(false);

	return (
		<Box {...others}>
			<Elements
				stripe={loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)}
				options={{
					mode: 'setup',
					currency: 'usd',
					loader: 'never',
					appearance: stripeAppearance(theme, colorScheme),
					fonts: [
						{
							family: 'Acumin Pro',
							src: '../fonts/acuminPro.woff',
						},
					],
				}}
			>
				<Form onSubmit={() => handleSubmit(stripe!, elements!)}>
					<Stack>
						<StripePaymentCollectionForm
							handleSetStripe={setStripe}
							handleSetElements={setElements}
							handleSetIsFormValid={setIsFormValid}
						/>
						<Button
							type="submit"
							loading={isLoading}
							disabled={!stripe || !isFormValid || shouldDisable}
							fullWidth
							mt="xs"
						>
							Submit
						</Button>
					</Stack>
				</Form>
			</Elements>
		</Box>
	);
}
