import { useMutation } from '@apollo/client';
import { Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { graphql } from '@shared';
import PageHeader from '../components/ui/PageHeader';
import ViewContainer from '../components/ui/ViewContainer';
import useSession from '../hooks/useSession';
import { showCustomErrorNotification } from '../utils/error';

export default function VerifyEmail() {
	const navigate = useNavigate();
	const { refreshSession } = useSession();
	const [searchParams] = useSearchParams();
	const [token] = useState(searchParams.get('token') || '');
	const [verifyEmail, { loading, error }] = useMutation(verifyEmailMutation);

	// Run once on page load, to verify the email
	useEffect(() => {
		verifyEmail({
			variables: {
				input: {
					token,
				},
			},
			onCompleted: (data) => {
				refreshSession();
				navigate(data.verifyEmail.callbackUrl);
			},
			onError: async (error) => {
				showCustomErrorNotification({
					message: error.message,
				});
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function displayText() {
		if (loading) {
			return 'We are verifying your email...';
		}
		if (error) {
			return error.message;
		}
	}

	return (
		<ViewContainer width="compact">
			<Stack>
				<PageHeader text="Verify Email" />
				<Text>{displayText()}</Text>
			</Stack>
		</ViewContainer>
	);
}

const verifyEmailMutation = graphql(`
	mutation VerifyEmail($input: VerifyEmailInput!) {
		verifyEmail(input: $input) {
			callbackUrl
		}
	}
`);
