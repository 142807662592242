import { typeid } from 'typeid-js';
import { DatabaseIdPrefixes, EventIdPrefixes, FileIdPrefixes, ObservabilityIdPrefixes } from '../types';
import { zod } from './zod';

type IdPrefixes = DatabaseIdPrefixes | FileIdPrefixes | ObservabilityIdPrefixes | EventIdPrefixes;

export function zodId(prefix: IdPrefixes) {
	return (
		zod
			.string()
			.min(1)
			// TODO: figure out what the exact regex of type-id is
			.regex(new RegExp(`^${prefix}_[a-z0-9]+$`, 'i'), { message: 'Not a valid ID' })
	);
}

export function generateDatabaseId<T extends DatabaseIdPrefixes>(prefix: T): string {
	return typeid(prefix).toString();
}

export function generateFileId<T extends FileIdPrefixes>(prefix: T): string {
	return typeid(prefix).toString();
}

export function generateObservabilityId<T extends ObservabilityIdPrefixes>(prefix: T): string {
	return typeid(prefix).toString();
}

export function generateEventId<T extends EventIdPrefixes>(prefix: T): string {
	return typeid(prefix).toString();
}
