import zod from '../utils/zod';
import { ImageKindSchema } from './graphql';

export type RegenerationArgs = {
	// Input from the user to guide the generation
	instructions: string;
	// Final, transformed prompt that was used to create the original image generation
	originalPrompt: string;
	// Use original image as a reference to regenerate the image
	shouldUseOriginalImage?: boolean;
	// Original image generation ID to use as a reference to regenerate the image
	originalImageId?: string;
};

export const ExternalProviderImagesSchema = zod.object({
	externalProviderImageId: zod.string().nullable(),
	kind: ImageKindSchema,
});
export type ExternalProviderImages = zod.infer<typeof ExternalProviderImagesSchema>;
