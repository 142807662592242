export enum SubscriptionEventType {
	Created = 'Created',
	PaymentSucceeded = 'PaymentSucceeded',
	PaymentFailed = 'PaymentFailed',
	Upgraded = 'Upgraded',
	Downgraded = 'Downgraded',
	CancellationRequested = 'CancellationRequested',
	Cancelled = 'Cancelled',
	Suspended = 'Suspended',
	Resumed = 'Resumed',
	Terminated = 'Terminated',
	PaymentMethodChanged = 'PaymentMethodChanged',
}
