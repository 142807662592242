/* eslint-disable */
import z from '../../../utils/zod';
import {
	AddCartInputItemVariant,
	BuyItemsInput,
	BuyTokensInput,
	ChangeSubscriptionPlanInput,
	CharactersFilterInput,
	CommentOnStoryInput,
	ContactUsMessageType,
	CreateCharacterInput,
	CreateDialogueInput,
	CreateImageInput,
	CreateItemInput,
	CreateItemPlacementInput,
	CreateItemPlacementLayerInput,
	CreateItemVariantInput,
	CreateOrderDraftInput,
	CreatePanelDraftInput,
	CreatePaymentMethodInput,
	CreateShopBankAccountInput,
	CreateStoryDraftInput,
	CreateStoryInput,
	CreateStoryViewInput,
	CreateSubscriptionInput,
	CreateUpcomingStoryInput,
	CreateUploadedImageDraftInput,
	CreateUserAddressInput,
	FavoriteStoryInput,
	FileUploadUrlsInput,
	FollowUserInput,
	GenerateCharacterHeadshotImagesInput,
	GenerateCharacterImagesInput,
	GenerateCoverImagesInput,
	GenerateItemMockupsInput,
	GenerateSceneImagesInput,
	ImageKind,
	ImageStatus,
	OrderFilterInput,
	OrdersFilterInput,
	PageImage,
	PagesFilterInput,
	PaginationInput,
	ProvideCharacterImageFeedbackInput,
	RegenerateCoverImagesInput,
	RegenerateSceneImagesInput,
	RemoveCartInputItemVariantInput,
	RequestSubscriptionCancellationInput,
	ResetPasswordInput,
	SendContactUsEmailInput,
	SendForgotPasswordEmailInput,
	SignInWithPasswordInput,
	SignUpWithPasswordInput,
	SortDirection,
	SortInput,
	StoriesFilterInput,
	StoryCharacterInput,
	UpdateCharacterDraftInput,
	UpdatePasswordInput,
	UpdateStoryDraftInput,
	UpdateSubscriptionPaymentMethodInput,
	UpdateTokenAutoPayInput,
	UpdateUpcomingStoryInput,
	UpdateUserInput,
	UploadStoryInput,
	UpscaleImageInput,
	UserFilterInput,
	VerifyEmailInput,
} from './types';

type Properties<T> = Required<{
	[K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny => v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export const SortDirectionSchema = z.nativeEnum(SortDirection);

export const ImageStatusSchema = z.nativeEnum(ImageStatus);

export const ImageKindSchema = z.nativeEnum(ImageKind);

export const ContactUsMessageTypeSchema = z.nativeEnum(ContactUsMessageType);

export const VerifyEmailInputSchema: z.ZodObject<Properties<VerifyEmailInput>> = z.object({
	token: z.string().min(1),
});

export const UserFilterInputSchema: z.ZodObject<Properties<UserFilterInput>> = z.object({
	id: z.string().optional(),
	username: z.string().optional(),
});

export const UpscaleImageInputSchema: z.ZodObject<Properties<UpscaleImageInput>> = z.object({
	imageDraftId: z.string().regex(/^imagedraft_[a-z0-9]+$/, 'i'),
	panelDraftId: z.string().regex(/^paneldraft_[a-z0-9]+$/, 'i'),
});

export const UploadStoryInputSchema: z.ZodObject<Properties<UploadStoryInput>> = z.object({
	text: z.string().min(1),
});

export const UpdateUserInputSchema: z.ZodObject<Properties<UpdateUserInput>> = z.object({
	bio: z.string().optional(),
	displayName: z.string().min(1).max(50).optional(),
	firstName: z.string().min(1).max(50).optional(),
	lastName: z.string().min(1).max(50).optional(),
	profilePictureImageUrl: z.string().url().optional(),
	username: z.string().min(5).max(50).optional(),
});

export const UpdateUpcomingStoryInputSchema: z.ZodObject<Properties<UpdateUpcomingStoryInput>> = z.object({
	coverImageUrl: z.string().url().optional(),
	id: z.string().regex(/^upcomingstory_[a-z0-9]+$/, 'i'),
	storyDraftId: z.string().optional(),
	storyId: z.string().optional(),
	title: z.string().min(1).optional(),
});

export const UpdateTokenAutoPayInputSchema: z.ZodObject<Properties<UpdateTokenAutoPayInput>> = z.object({
	amount: z.number().min(1),
});

export const UpdateSubscriptionPaymentMethodInputSchema: z.ZodObject<Properties<UpdateSubscriptionPaymentMethodInput>> =
	z.object({
		paymentMethodId: z.string().regex(/^paymentmethod_[a-z0-9]+$/, 'i'),
	});

export const UpdatePasswordInputSchema: z.ZodObject<Properties<UpdatePasswordInput>> = z.object({
	newPassword: z.string().min(8),
	oldPassword: z.string().min(8),
});

export const UpdateCharacterDraftInputSchema: z.ZodObject<Properties<UpdateCharacterDraftInput>> = z.object({
	artStyle: z.string().min(1),
	characterDraftId: z.string().regex(/^characterdraft_[a-z0-9]+$/, 'i'),
	description: z.string().min(1).optional(),
	name: z.string().min(1).max(50).optional(),
	parentCharacterId: z
		.string()
		.regex(/^character_[a-z0-9]+$/, 'i')
		.optional(),
	selectedImageDraftId: z
		.string()
		.regex(/^imagedraft_[a-z0-9]+$/, 'i')
		.optional(),
});

export const StoryCharacterInputSchema: z.ZodObject<Properties<StoryCharacterInput>> = z.object({
	characterDevelopment: z.string().optional(),
	characterId: z.string().regex(/^character_[a-z0-9]+$/, 'i'),
});

export const StoriesFilterInputSchema: z.ZodObject<Properties<StoriesFilterInput>> = z.object({
	creatorId: z.string().optional(),
	storyIds: z.array(z.string()).optional(),
});

export const SortInputSchema: z.ZodObject<Properties<SortInput>> = z.object({
	direction: SortDirectionSchema,
	field: z.string(),
});

export const SignUpWithPasswordInputSchema: z.ZodObject<Properties<SignUpWithPasswordInput>> = z.object({
	email: z.string().email().min(1),
	firstName: z.string().min(1).max(50),
	lastName: z.string().min(1).max(50),
	password: z.string().min(8),
	profilePictureImageUrl: z.string().url().optional(),
});

export const SignInWithPasswordInputSchema: z.ZodObject<Properties<SignInWithPasswordInput>> = z.object({
	email: z.string().email().min(1),
	password: z.string().min(8),
});

export const SendForgotPasswordEmailInputSchema: z.ZodObject<Properties<SendForgotPasswordEmailInput>> = z.object({
	email: z.string().email().min(1),
});

export const SendContactUsEmailInputSchema: z.ZodObject<Properties<SendContactUsEmailInput>> = z.object({
	message: z.string().min(1),
	type: ContactUsMessageTypeSchema,
});

export const ResetPasswordInputSchema: z.ZodObject<Properties<ResetPasswordInput>> = z.object({
	password: z.string().min(8),
	token: z.string().min(1),
});

export const RequestSubscriptionCancellationInputSchema: z.ZodObject<Properties<RequestSubscriptionCancellationInput>> =
	z.object({
		subscriptionId: z.string().regex(/^subscription_[a-z0-9]+$/, 'i'),
	});

export const RemoveCartInputItemVariantInputSchema: z.ZodObject<Properties<RemoveCartInputItemVariantInput>> = z.object(
	{
		id: z.string().regex(/^cartitemvariant_[a-z0-9]+$/, 'i'),
	}
);

export const RegenerateSceneImagesInputSchema: z.ZodObject<Properties<RegenerateSceneImagesInput>> = z.object({
	artStyle: z.string().min(1),
	caption: z.string().optional(),
	characterIds: z.array(z.string()).optional(),
	description: z.string().min(1),
	dialogues: z.array(z.lazy(() => CreateDialogueInputSchema)).optional(),
	instructions: z.string().optional(),
	numberOfImages: z.number().min(1).max(4),
	originalImageDraftId: z.string().regex(/^imagedraft_[a-z0-9]+$/, 'i'),
	panelDraftId: z.string().regex(/^paneldraft_[a-z0-9]+$/, 'i'),
	setting: z.string().min(1),
	shouldUseOriginalImage: z.boolean(),
	storyDraftId: z.string().regex(/^storydraft_[a-z0-9]+$/, 'i'),
});

export const RegenerateCoverImagesInputSchema: z.ZodObject<Properties<RegenerateCoverImagesInput>> = z.object({
	artStyle: z.string().min(1),
	characterIds: z.array(z.string().regex(/^character_[a-z0-9]+$/, 'i')),
	coverDraftId: z.string().regex(/^coverdraft_[a-z0-9]+$/, 'i'),
	genres: z.array(z.string()),
	instructions: z.string().optional(),
	numberOfImages: z.number().min(1).max(4),
	originalImageDraftId: z.string().regex(/^imagedraft_[a-z0-9]+$/, 'i'),
	storyDraftId: z.string().regex(/^storydraft_[a-z0-9]+$/, 'i'),
	summary: z.string().optional(),
	title: z.string().optional(),
});

export const ProvideCharacterImageFeedbackInputSchema: z.ZodObject<Properties<ProvideCharacterImageFeedbackInput>> =
	z.object({
		imageUrl: z.string().url(),
		originalUserInput: z.lazy(() => GenerateCharacterImagesInputSchema),
		prompt: z.string(),
		rating: z.number().min(1).max(5),
		seed: z.number(),
		text: z.string().optional(),
	});

export const PaginationInputSchema: z.ZodObject<Properties<PaginationInput>> = z.object({
	limit: z.number(),
	offset: z.number(),
});

export const PagesFilterInputSchema: z.ZodObject<Properties<PagesFilterInput>> = z.object({
	storyId: z.string(),
});

export const PageImageSchema: z.ZodObject<Properties<PageImage>> = z.object({
	index: z.number().min(0),
	url: z.string().url(),
});

export const OrdersFilterInputSchema: z.ZodObject<Properties<OrdersFilterInput>> = z.object({
	self: z.boolean().optional(),
	shopId: z.string().optional(),
});

export const OrderFilterInputSchema: z.ZodObject<Properties<OrderFilterInput>> = z.object({
	id: z.string().optional(),
	orderNumber: z.string().optional(),
});

export const UpdateStoryDraftInputSchema: z.ZodObject<Properties<UpdateStoryDraftInput>> = z.object({
	data: definedNonNullAnySchema.optional(),
	id: z.string().regex(/^storydraft_[a-z0-9]+$/, 'i'),
	lastActiveFormStep: z.number().min(0).optional(),
});

export const GenerateSceneImagesInputSchema: z.ZodObject<Properties<GenerateSceneImagesInput>> = z.object({
	artStyle: z.string().min(1),
	caption: z.string().optional(),
	characterIds: z.array(z.string()).optional(),
	description: z.string().min(1),
	dialogues: z.array(z.lazy(() => CreateDialogueInputSchema)).optional(),
	instructions: z.string().optional(),
	numberOfImages: z.number().min(1).max(4),
	panelDraftId: z.string().regex(/^paneldraft_[a-z0-9]+$/, 'i'),
	setting: z.string().min(1),
	storyDraftId: z.string().regex(/^storydraft_[a-z0-9]+$/, 'i'),
});

export const GenerateItemMockupsInputSchema: z.ZodObject<Properties<GenerateItemMockupsInput>> = z.object({
	imageUrl: z.string(),
	productId: z.number(),
	variantIds: z.array(z.number()),
});

export const GenerateCoverImagesInputSchema: z.ZodObject<Properties<GenerateCoverImagesInput>> = z.object({
	artStyle: z.string().min(1),
	characterIds: z.array(z.string().regex(/^character_[a-z0-9]+$/, 'i')),
	coverDraftId: z.string().regex(/^coverdraft_[a-z0-9]+$/, 'i'),
	genres: z.array(z.string()),
	instructions: z.string().optional(),
	numberOfImages: z.number().min(1).max(4),
	storyDraftId: z.string().regex(/^storydraft_[a-z0-9]+$/, 'i'),
	summary: z.string().optional(),
	title: z.string().optional(),
});

export const GenerateCharacterImagesInputSchema: z.ZodObject<Properties<GenerateCharacterImagesInput>> = z.object({
	artStyle: z.string().min(1),
	characterDraftId: z.string().regex(/^characterdraft_[a-z0-9]+$/, 'i'),
	description: z.string().min(1),
	name: z.string().min(1).max(50),
	numberOfImages: z.number().min(1).max(4),
});

export const GenerateCharacterHeadshotImagesInputSchema: z.ZodObject<Properties<GenerateCharacterHeadshotImagesInput>> =
	z.object({
		artStyle: z.string().min(1),
		characterDraftId: z.string().regex(/^characterdraft_[a-z0-9]+$/, 'i'),
		characterId: z.string().regex(/^character_[a-z0-9]+$/, 'i'),
		description: z.string().min(1),
		name: z.string().min(1).max(50),
		numberOfImages: z.number().min(1).max(4),
		originalImageDraftId: z.string().regex(/^imagedraft_[a-z0-9]+$/, 'i'),
	});

export const FollowUserInputSchema: z.ZodObject<Properties<FollowUserInput>> = z.object({
	isFollowing: z.boolean(),
	userId: z.string().regex(/^user_[a-z0-9]+$/, 'i'),
});

export const FileUploadUrlsInputSchema: z.ZodObject<Properties<FileUploadUrlsInput>> = z.object({
	folder: z.string().min(1),
});

export const FavoriteStoryInputSchema: z.ZodObject<Properties<FavoriteStoryInput>> = z.object({
	isFavorited: z.boolean(),
	storyId: z.string().regex(/^story_[a-z0-9]+$/, 'i'),
});

export const CreateUserAddressInputSchema: z.ZodObject<Properties<CreateUserAddressInput>> = z.object({
	addressLine1: z.string().min(1),
	addressLine2: z.string().optional(),
	city: z.string().min(1),
	country: z.string().min(1),
	state: z.string().min(1),
	zip: z.string().min(1),
});

export const CreateUploadedImageDraftInputSchema: z.ZodObject<Properties<CreateUploadedImageDraftInput>> = z.object({
	sourceDraftId: z.string(),
	uploadedImageUrl: z.string().url(),
});

export const CreateUpcomingStoryInputSchema: z.ZodObject<Properties<CreateUpcomingStoryInput>> = z.object({
	coverImageUrl: z.string().url(),
	creatorId: z.string().regex(/^user_[a-z0-9]+$/, 'i'),
	storyDraftId: z.string().optional(),
	storyId: z.string().optional(),
	title: z.string().min(1),
});

export const CreateSubscriptionInputSchema: z.ZodObject<Properties<CreateSubscriptionInput>> = z.object({
	paymentMethodId: z.string().regex(/^paymentmethod_[a-z0-9]+$/, 'i'),
	period: z.string().min(1),
	subscriptionPlanId: z.string().regex(/^subscriptionplan_[a-z0-9]+$/, 'i'),
});

export const CreateStoryViewInputSchema: z.ZodObject<Properties<CreateStoryViewInput>> = z.object({
	storyId: z.string().regex(/^story_[a-z0-9]+$/, 'i'),
});

export const CreateStoryInputSchema: z.ZodObject<Properties<CreateStoryInput>> = z.object({
	coverImageUrl: z.string().url(),
	pageImages: z.array(PageImageSchema),
	storyDraftId: z.string().regex(/^storydraft_[a-z0-9]+$/, 'i'),
});

export const CreateStoryDraftInputSchema: z.ZodObject<Properties<CreateStoryDraftInput>> = z.object({
	data: definedNonNullAnySchema,
	lastActiveFormStep: z.number().min(0),
});

export const CreateShopBankAccountInputSchema: z.ZodObject<Properties<CreateShopBankAccountInput>> = z.object({
	bankAccountExternalProviderId: z.string().min(1),
	bankAccountExternalProviderMetadata: definedNonNullAnySchema,
	bankAccountExternalProviderToken: z.string().min(1),
	bankName: z.string().min(1),
});

export const CreatePaymentMethodInputSchema: z.ZodObject<Properties<CreatePaymentMethodInput>> = z.object({
	setupIntentId: z.string().regex(/^seti_[a-z0-9]+$/, 'i'),
});

export const CreatePanelDraftInputSchema: z.ZodObject<Properties<CreatePanelDraftInput>> = z.object({
	storyDraftId: z.string().regex(/^storydraft_[a-z0-9]+$/, 'i'),
});

export const CreateOrderDraftInputSchema: z.ZodObject<Properties<CreateOrderDraftInput>> = z.object({
	itemVariantIds: z.array(z.string().regex(/^itemvariant_[a-z0-9]+$/, 'i')),
	userAddressId: z.string().regex(/^useraddress_[a-z0-9]+$/, 'i'),
});

export const CreateItemVariantInputSchema: z.ZodObject<Properties<CreateItemVariantInput>> = z.object({
	color: z.string(),
	imageUrls: z.array(z.string().min(1)),
	size: z.string(),
});

export const CreateItemPlacementLayerInputSchema: z.ZodObject<Properties<CreateItemPlacementLayerInput>> = z.object({
	imageUrl: z.string(),
});

export const CreateItemPlacementInputSchema: z.ZodObject<Properties<CreateItemPlacementInput>> = z.object({
	layers: z.array(z.lazy(() => CreateItemPlacementLayerInputSchema)),
	placement: z.string(),
	technique: z.string(),
});

export const CreateItemInputSchema: z.ZodObject<Properties<CreateItemInput>> = z.object({
	description: z.string().min(1),
	externalProviderId: z.number(),
	itemVariants: z.array(z.lazy(() => CreateItemVariantInputSchema)),
	name: z.string().min(1),
	placements: z.array(z.lazy(() => CreateItemPlacementInputSchema)),
	price: z.number(),
});

export const CreateImageInputSchema: z.ZodObject<Properties<CreateImageInput>> = z.object({
	imageUrl: z.string().url(),
	isSelected: z.boolean(),
	kind: z.string().min(1),
	leonardoId: z.string().optional(),
	prompt: z.string().optional(),
	seed: z.number().optional(),
	userRating: z.number().optional(),
});

export const CreateDialogueInputSchema: z.ZodObject<Properties<CreateDialogueInput>> = z.object({
	characterId: z
		.string()
		.regex(/^character_[a-z0-9]+$/, 'i')
		.optional(),
	characterName: z.string().min(1),
	index: z.number().min(0),
	text: z.string().min(1),
});

export const CreateCharacterInputSchema: z.ZodObject<Properties<CreateCharacterInput>> = z.object({
	artStyle: z.string().min(1),
	characterDraftId: z.string().regex(/^characterdraft_[a-z0-9]+$/, 'i'),
	description: z.string().min(1),
	imageUrl: z.string().url(),
	name: z.string().min(1).max(50),
	parentCharacterId: z
		.string()
		.regex(/^character_[a-z0-9]+$/, 'i')
		.optional(),
});

export const CommentOnStoryInputSchema: z.ZodObject<Properties<CommentOnStoryInput>> = z.object({
	parentStoryCommentId: z
		.string()
		.regex(/^storycomment_[a-z0-9]+$/, 'i')
		.optional(),
	storyId: z.string().regex(/^story_[a-z0-9]+$/, 'i'),
	text: z.string().min(1),
});

export const CharactersFilterInputSchema: z.ZodObject<Properties<CharactersFilterInput>> = z.object({
	characterIds: z.array(z.string()).optional(),
	creatorId: z.string().optional(),
});

export const ChangeSubscriptionPlanInputSchema: z.ZodObject<Properties<ChangeSubscriptionPlanInput>> = z.object({
	period: z.string().min(1),
	subscriptionPlanId: z.string().regex(/^subscriptionplan_[a-z0-9]+$/, 'i'),
});

export const BuyTokensInputSchema: z.ZodObject<Properties<BuyTokensInput>> = z.object({
	tokens: z.number().min(1),
});

export const BuyItemsInputSchema: z.ZodObject<Properties<BuyItemsInput>> = z.object({
	externalProviderId: z.number(),
	itemVariantIds: z.array(z.string().regex(/^itemvariant_[a-z0-9]+$/, 'i')),
	paymentMethodId: z.string().regex(/^paymentmethod_[a-z0-9]+$/, 'i'),
	userAddressId: z.string().regex(/^useraddress_[a-z0-9]+$/, 'i'),
});

export const AddCartInputItemVariantSchema: z.ZodObject<Properties<AddCartInputItemVariant>> = z.object({
	itemVariantId: z.string().regex(/^itemvariant_[a-z0-9]+$/, 'i'),
});
