import { Button, ButtonProps } from '@mantine/core';
import GoogleIcon from '../../assets/GoogleIcon';

type SocialButtonProps = ButtonProps & {
	social: 'google';
	onClick?: () => void;
};

export function SocialButton({ social, onClick, ...props }: SocialButtonProps) {
	return (
		<Button
			leftSection={social === 'google' ? <GoogleIcon /> : undefined}
			variant="default"
			radius="xl"
			onClick={onClick}
			{...props}
		/>
	);
}
