import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ActionIcon, Badge, Box, Button, Card, Group, Stack, Text, Textarea, Title } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { ArrowBendDownLeft } from '@phosphor-icons/react';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { CommentOnStoryInput, CommentOnStoryInputSchema, ResultOf, graphql } from '@shared';
import Comment from '../components/Comment';
import PageCard from '../components/PageCard';
import FavoriteButton from '../components/ui/FavoriteButton';
import Loading from '../components/ui/Loading';
import PageHeader from '../components/ui/PageHeader';
import ProfilePicture from '../components/ui/ProfilePicture';
import ViewContainer from '../components/ui/ViewContainer';
import useFavoriteStory from '../hooks/useFavoriteStory';
import useSession from '../hooks/useSession';
import { clearEntitiesFromCache } from '../utils/cache';

const BATCH = 25;

export default function Story() {
	const storyId = useParams().id!;
	const navigate = useNavigate();
	const { isAuthenticated, user } = useSession();
	const [pages, setPages] = useState<GetPagesForStoryReturn>([]);
	const [hasMore, setHasMore] = useState(true);
	const [replyingCommentIndex, setReplyingCommentIndex] = useState<number>();
	const [getComments, { data: commentsData }] = useLazyQuery(getCommentsForStoryQuery);
	const [createStoryView] = useMutation(createStoryViewMutation);
	const [commentOnStory, { loading: commentOnStoryLoading }] = useMutation(commentOnStoryMutation);
	const { favoriteStory } = useFavoriteStory();
	const [getMorePages] = useLazyQuery(getPagesForStoryQuery, {
		onCompleted: (data) => {
			if (data.pages.length > 0) {
				setPages((prevPages) => [...prevPages, ...data.pages]);
			}
			if (data.pages.length < BATCH) {
				setHasMore(false);
			}
		},
	});
	const { data: storyData, loading: getStoryLoading } = useQuery(getStoryQuery, {
		variables: {
			id: storyId,
		},
		async onCompleted(data) {
			await Promise.all([
				handleGetMorePages(),
				getComments({
					variables: {
						storyId: data.story.id,
					},
				}),
				createStoryView({
					variables: {
						input: {
							storyId: data.story.id,
						},
					},
				}),
			]);
		},
		onError() {
			navigate('/');
		},
	});

	const form = useForm<CommentOnStoryInput>({
		validate: zodResolver(CommentOnStoryInputSchema),
		initialValues: {
			storyId,
			text: '',
		},
	});

	const replyForm = useForm<CommentOnStoryInput>({
		validate: zodResolver(CommentOnStoryInputSchema),
		initialValues: {
			storyId,
			text: '',
		},
	});

	const handleFavoriteStory = async (storyId: string, isFavorited: boolean) =>
		await favoriteStory(storyId, isFavorited);

	const handleGetMorePages = async () => {
		await getMorePages({
			variables: {
				storyId,
				pagination: {
					offset: pages.length,
					limit: BATCH,
				},
			},
		});
	};

	const handleCommentOnStory = async (parentStoryCommentId?: string) => {
		await commentOnStory({
			variables: {
				input: {
					storyId,
					parentStoryCommentId,
					text: parentStoryCommentId ? replyForm.values.text : form.values.text,
				},
			},
			onCompleted: () => {
				if (parentStoryCommentId) {
					replyForm.reset();
				} else {
					form.reset();
				}
			},
			update: (cache) => {
				clearEntitiesFromCache(cache, ['storyComments']);
			},
		});
	};

	return (
		<ViewContainer width="compact">
			{getStoryLoading && <Loading />}
			{!getStoryLoading && storyData?.story && (
				<Stack>
					<Box>
						<Stack gap="xs">
							<Group justify="space-between">
								<PageHeader text={storyData.story.title} />
								{isAuthenticated && (
									<Box onClick={() => handleFavoriteStory(storyData.story!.id, !!storyData.story!.isFavorited)}>
										<FavoriteButton isFavorited={!!storyData.story.isFavorited} />
									</Box>
								)}
							</Group>
							<Group gap="xs">
								<ProfilePicture user={storyData.story.creator!} />
								<Text c="dimmed" lineClamp={1}>
									{storyData.story.creator!.displayName}
								</Text>
							</Group>
							<Text>{storyData.story.summary}</Text>
							<Group gap="xs">
								{storyData.story.genres.map((g) => (
									<Badge key={g} variant="default">
										{g}
									</Badge>
								))}
							</Group>
						</Stack>
					</Box>
					<InfiniteScroll
						// + 1 for the cover image
						dataLength={pages.length + 1}
						next={handleGetMorePages}
						hasMore={hasMore}
						loader={''}
						refreshFunction={handleGetMorePages}
					>
						<PageCard key="cover" imageUrl={storyData.story.coverImageUrl} />
						{pages!.map((p, i) => (
							<PageCard key={i} imageUrl={p.imageUrl} />
						))}
					</InfiniteScroll>
					<Stack>
						<Title order={2}>Comments</Title>
						{commentsData?.storyComments
							.filter((comment) => !comment.parentStoryCommentId)
							.map((comment, i) => (
								<Card key={comment.id}>
									<Stack gap="xs">
										<Comment comment={comment} />
										<Stack ml="10%" gap="xs">
											{comment.children.map((child) => (
												<Comment key={child.id} comment={child} my="xs" />
											))}
										</Stack>
										{isAuthenticated && (
											<>
												{replyingCommentIndex === i ? (
													<Group align="start" ml="10%">
														<ProfilePicture user={user} />
														<Stack flex={1}>
															<Textarea mr={0} {...replyForm.getInputProps('text')} />
															<Group ml="auto">
																<Button
																	disabled={commentOnStoryLoading}
																	onClick={async () => {
																		setReplyingCommentIndex(undefined);
																		replyForm.reset();
																	}}
																	variant="default"
																>
																	Cancel
																</Button>
																<Button
																	loading={commentOnStoryLoading}
																	disabled={!replyForm.isValid()}
																	onClick={async () => {
																		await handleCommentOnStory(comment.id);
																		setReplyingCommentIndex(undefined);
																	}}
																>
																	Submit
																</Button>
															</Group>
														</Stack>
													</Group>
												) : (
													<ActionIcon
														variant="transparent"
														ml="auto"
														onClick={() => {
															setReplyingCommentIndex(i);
															replyForm.reset();
														}}
													>
														<ArrowBendDownLeft color="black" />
													</ActionIcon>
												)}
											</>
										)}
									</Stack>
								</Card>
							))}
						{isAuthenticated && (
							<Card withBorder={false}>
								<Group align="start">
									<ProfilePicture user={user} />
									<Stack flex={1}>
										<Textarea mr={0} {...form.getInputProps('text')} />
										<Button
											loading={commentOnStoryLoading}
											disabled={!form.isValid()}
											onClick={async () => await handleCommentOnStory()}
											ml="auto"
										>
											Submit
										</Button>
									</Stack>
								</Group>
							</Card>
						)}
					</Stack>
				</Stack>
			)}
		</ViewContainer>
	);
}

const getStoryQuery = graphql(`
	query GetStory($id: ID!) {
		story(id: $id) {
			id
			title
			summary
			genres
			coverImageUrl
			isFavorited
			creator {
				id
				username
				displayName
				profilePictureImageUrl
			}
		}
	}
`);

type GetPagesForStoryReturn = ResultOf<typeof getPagesForStoryQuery>['pages'];
const getPagesForStoryQuery = graphql(`
	query GetPagesForStory($storyId: ID!, $pagination: PaginationInput) {
		pages(storyId: $storyId, pagination: $pagination) {
			id
			imageUrl
		}
	}
`);

export type GetCommentsForStoryReturn = ResultOf<typeof getCommentsForStoryQuery>['storyComments'];
const getCommentsForStoryQuery = graphql(`
	query GetCommentsForStory($storyId: ID!) {
		storyComments(storyId: $storyId) {
			id
			parentStoryCommentId
			text
			createdAt
			user {
				username
				displayName
				profilePictureImageUrl
			}
			children {
				id
				parentStoryCommentId
				text
				createdAt
				user {
					username
					displayName
					profilePictureImageUrl
				}
			}
		}
	}
`);

const commentOnStoryMutation = graphql(`
	mutation CommentOnStory($input: CommentOnStoryInput!) {
		commentOnStory(input: $input) {
			id
			text
			createdAt
			user {
				displayName
				profilePictureImageUrl
			}
		}
	}
`);

const createStoryViewMutation = graphql(`
	mutation CreateStoryView($input: CreateStoryViewInput!) {
		createStoryView(input: $input) {
			id
		}
	}
`);
