import { UserSubscription } from './graphql';

export enum SubscriptionStatus {
	Active = 'Active',
	// The subscription has ended after a user requested to cancel it, and will not renew
	Cancelled = 'Cancelled',
	// The subscription was suspended after payment failed
	Suspended = 'Suspended',
}

export enum SubscriptionPeriod {
	Daily = 'Daily', // DEV ONLY
	Monthly = 'Monthly',
	Yearly = 'Yearly',
}

export type Subscription = UserSubscription;
