import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, Button, Group, Select, SimpleGrid, Stack, Tabs, Text, Title } from '@mantine/core';
import { SortAscending } from '@phosphor-icons/react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { graphql } from '@shared';
import ItemCard from '../components/ItemCard';
import StoryCard from '../components/StoryCard';
import Loading from '../components/ui/Loading';
import ProfilePicture from '../components/ui/ProfilePicture';
import ViewContainer from '../components/ui/ViewContainer';
import { GetStoriesReturn, getStoriesQuery } from '../graphql/common';
import useFavoriteStory from '../hooks/useFavoriteStory';
import useSession from '../hooks/useSession';
import useSortedStories, { SortOption, sortOptions } from '../hooks/useSortedStories';
import { clearEntitiesFromCache } from '../utils/cache';

enum TabValue {
	Stories = 'Stories',
	Characters = 'Characters',
	Shop = 'Shop',
}

export default function User() {
	const username = useParams().username!;
	const navigate = useNavigate();
	const { isAuthenticated, user } = useSession();
	const [activeTab, setActiveTab] = useState<TabValue>(TabValue.Stories);
	const [stories, setStories] = useState<GetStoriesReturn>([]);
	const [sort, setSort] = useState<SortOption>('Newest');
	const { sortedStories } = useSortedStories({ stories, sort });
	const [getStories] = useLazyQuery(getStoriesQuery);
	const [followUser] = useMutation(followUserMutation);
	const { favoriteStory } = useFavoriteStory();
	const { data } = useQuery(getUserProfileQuery, {
		variables: {
			filter: {
				username,
			},
		},
		async onCompleted(data) {
			await getStories({
				variables: {
					filter: {
						creatorId: data.user.id,
					},
				},
				onCompleted(data) {
					setStories(data.stories);
				},
			});
		},
		onError() {
			navigate('/');
		},
	});

	async function handleFollowUser() {
		if (!isAuthenticated) {
			navigate('/sign-up');
		}
		await followUser({
			variables: {
				input: {
					userId: data!.user!.id,
					isFollowing: !!data!.user!.isFollower,
				},
			},
			update: (cache) => {
				clearEntitiesFromCache(cache, ['user']);
			},
		});
	}

	async function handleFavoriteStory(storyId: string, isFavorited: boolean) {
		await favoriteStory(storyId, isFavorited, (data) =>
			setStories((prev) =>
				prev.map((s) =>
					s.id === data.favoriteStory.story.id ? { ...s, isFavorited: data.favoriteStory.story.isFavorited } : s
				)
			)
		);
	}

	return (
		<ViewContainer width="compact">
			{data?.user && (
				<Stack>
					<Group justify="space-between">
						<Group>
							<ProfilePicture user={data!.user} size="xl" />
							<Box>
								<Title order={3}>{data.user.displayName}</Title>
								<Group gap="xs">
									<Text fz="sm" c="dimmed">
										<b>{stories.length || 0}</b> stories
									</Text>
									<Text fz="sm" c="dimmed">
										<b>{data.user.followersCount}</b> followers
									</Text>
									<Text fz="sm" c="dimmed">
										<b>{data.user.followingCount}</b> following
									</Text>
								</Group>
							</Box>
						</Group>
						{user.id !== data.user.id && (
							<Button
								variant={data.user.isFollower === true ? 'default' : undefined}
								onClick={async () => handleFollowUser()}
							>
								{data.user.isFollower ? 'Following' : 'Follow'}
							</Button>
						)}
					</Group>
					<Tabs value={activeTab} onChange={(e) => setActiveTab(e as TabValue)}>
						<Tabs.List>
							{Object.keys(TabValue).map((tab) => (
								<Tabs.Tab key={tab} value={tab}>
									{tab}
								</Tabs.Tab>
							))}
						</Tabs.List>
						<Tabs.Panel value={TabValue.Stories}>
							{sortedStories.length > 0 && (
								<Stack pt="xs">
									<Select
										data={sortOptions}
										value={sort}
										onChange={(value) => setSort(value as SortOption)}
										leftSection={<SortAscending />}
										size="xs"
										ml="auto"
										allowDeselect={false}
									/>
									<SimpleGrid cols={3}>
										{sortedStories.map((story) => (
											<StoryCard
												key={story.id}
												story={story}
												creator={story.creator}
												favoriting={{
													isFavorited: !!story.isFavorited,
													favoriteStory: handleFavoriteStory,
												}}
											/>
										))}
									</SimpleGrid>
								</Stack>
							)}
						</Tabs.Panel>
						<Tabs.Panel value={TabValue.Shop}>
							<Stack pt="xs">
								{!('shop' in data.user) && <Loading />}
								{'shop' in data.user && data.user.shop === null && <Text>No items in shop</Text>}
								{'shop' in data.user && !!data.user.shop?.items.length && (
									<SimpleGrid cols={3}>
										{data.user.shop.items.map((item) => (
											<ItemCard key={item.id} item={item} navigateToUrl={`/users/${username}/shop/${item.id}`} />
										))}
									</SimpleGrid>
								)}
							</Stack>
						</Tabs.Panel>
					</Tabs>
				</Stack>
			)}
		</ViewContainer>
	);
}

const getUserProfileQuery = graphql(`
	query GetUserProfile($filter: UserFilterInput!) {
		user(filter: $filter) {
			id
			username
			displayName
			profilePictureImageUrl
			isFollower
			followersCount
			followingCount
			... @defer {
				shop {
					id
					items {
						id
						name
						itemVariants {
							id
							price
							itemVariantImages {
								id
								imageUrl
							}
						}
					}
				}
			}
		}
	}
`);

const followUserMutation = graphql(`
	mutation FollowUser($input: FollowUserInput!) {
		followUser(input: $input) {
			id
		}
	}
`);
