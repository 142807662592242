import { Allow, parse } from 'partial-json';
import { useEffect, useState } from 'react';
import { Endpoint } from '@shared';
import { sendRequestWithoutParsing } from '../utils/request';

type UseStreamProps<RequestBody, ResponseBody> = {
	endpoint: Endpoint<RequestBody, ResponseBody>;
	handleStream?: (data: ResponseBody) => void;
	handleCompleteStream?: (data: ResponseBody) => void;
};

export type UseStreamReturn<RequestBody, ResponseBody> = {
	activeStreamData?: ResponseBody;
	isActive: boolean;
	start: (body: RequestBody) => Promise<void>;
	stop: () => void;
};

export function useStream<RequestBody, ResponseBody>({
	endpoint,
	handleStream,
	handleCompleteStream,
}: UseStreamProps<RequestBody, ResponseBody>): UseStreamReturn<RequestBody, ResponseBody> {
	const [reader, setReader] = useState<ReadableStreamDefaultReader<Uint8Array>>();
	const [isActive, setIsActive] = useState(false);
	const [activeStreamData, setActiveStreamData] = useState<ResponseBody>();
	const [activeBuffer, setActiveBuffer] = useState('');

	async function start(body: RequestBody) {
		if (isActive) {
			return;
		}
		setIsActive(true);
		const response = await sendRequestWithoutParsing(endpoint, body);
		if (!response.body) {
			setIsActive(false);
			throw new Error('Response body is not readable');
		}
		setReader(response.body.getReader());
	}

	function stop() {
		setReader(undefined);
		setActiveStreamData(undefined);
		setIsActive(false);
		setActiveBuffer('');
	}

	// Cleanup on unmount
	useEffect(() => {
		return stop;
	}, []);

	useEffect(() => {
		async function continueStream() {
			if (!reader) {
				return;
			}
			const { done, data, buffer } = (await getNextStreamData(reader, endpoint, activeBuffer).next())
				.value as StreamData<ResponseBody>;
			if (done) {
				if (handleCompleteStream && !activeStreamData) {
					throw new Error("Unable to complete stream: last streamed data doesn't exist");
				}
				handleCompleteStream?.(activeStreamData!);
				setReader(undefined);
				setActiveStreamData(undefined);
				setIsActive(false);
				setActiveBuffer('');
				return;
			}
			if (data) {
				handleStream?.(data);
				setActiveStreamData(data);
			}
			if (buffer) {
				setActiveBuffer(buffer);
			}
		}
		if (reader) {
			continueStream();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reader, activeBuffer]);

	return { activeStreamData, isActive, start, stop };
}

type StreamData<T> = { done: boolean; data?: T; buffer?: string };

async function* getNextStreamData<RequestBody, ResponseBody>(
	reader: ReadableStreamDefaultReader<Uint8Array>,
	endpoint: Endpoint<RequestBody, ResponseBody>,
	buffer: string
): AsyncGenerator<StreamData<ResponseBody>> {
	const decoder = new TextDecoder('utf-8');
	const { value, done } = await reader.read();
	if (done) {
		try {
			const data = endpoint.responseBodySchema.parse(parse(buffer, Allow.ALL));
			yield { done, data, buffer };
		} catch (error) {
			yield { done, data: undefined };
		}
	}

	const chunk = decoder.decode(value, { stream: true });
	buffer += chunk;

	try {
		let data = parse(buffer, Allow.ALL);
		data = endpoint.responseBodySchema.parse(data);
		yield { done, data, buffer };
	} catch (error) {
		yield { done, data: undefined, buffer };
	}
}
