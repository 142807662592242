import { useMutation, useQuery } from '@apollo/client';
import { Button, Flex, Stack } from '@mantine/core';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserRole, graphql } from '@shared';
import NoAccessPage from '../../components/NoAccessPage';
import PageHeader from '../../components/ui/PageHeader';
import SidebarLink from '../../components/ui/SidebarLink';
import ViewContainer from '../../components/ui/ViewContainer';
import useIsMobileView from '../../hooks/useIsMobileView';
import useSession from '../../hooks/useSession';
import { clearEntitiesFromCache } from '../../utils/cache';

export default function MyShop() {
	const location = useLocation();
	const navigate = useNavigate();
	const isMobileView = useIsMobileView();
	const { user } = useSession();
	const {
		data: myShopData,
		loading: myShopDataLoading,
		// TODO: look into why this is needed and clearEntitiesFromCache won't work for ship
		refetch: refetchGetMyShopQuery,
	} = useQuery(getMyShopQuery, {
		variables: {
			username: user.username,
		},
	});
	const [createShop, { loading: createShopLoading }] = useMutation(createShopMutation);

	// Redirect to items by default if shop exists
	useEffect(() => {
		if (!myShopDataLoading && myShopData?.shop && location.pathname === '/my-shop') {
			navigate('/my-shop/items');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myShopDataLoading, myShopData, navigate]);

	async function handleCreateShop() {
		await createShop({
			onCompleted: async () => {
				await refetchGetMyShopQuery();
			},
			update: (cache) => {
				clearEntitiesFromCache(cache, ['shop', 'user']);
			},
		});
	}

	if (user.role === UserRole.BetaTester) {
		return <NoAccessPage text="You do not currently have access to this feature." />;
	}

	return (
		<ViewContainer>
			<Stack>
				<PageHeader
					text="My Shop"
					button={
						!myShopDataLoading && !myShopData?.shop ? (
							<Button loading={createShopLoading} onClick={async () => await handleCreateShop()}>
								Create Shop
							</Button>
						) : undefined
					}
				/>
				{myShopData?.shop && (
					<Flex direction={isMobileView ? 'column' : 'row'} gap="xl">
						<Stack w={150} gap={0}>
							<SidebarLink label="Items" to="/my-shop/items" />
							<SidebarLink label="Dashboard" to="/my-shop/dashboard" />
							{/* <SidebarLink label="Management" href="/my-shop/management" /> */}
						</Stack>
						<Stack flex={1}>
							<Outlet />
						</Stack>
					</Flex>
				)}
			</Stack>
		</ViewContainer>
	);
}

const getMyShopQuery = graphql(`
	query GetMyShop($username: String!) {
		shop(username: $username) {
			id
		}
	}
`);

const createShopMutation = graphql(`
	mutation CreateShop {
		createShop {
			id
		}
	}
`);
