import { initGraphQLTada } from 'gql.tada';
import {
	ContactUsMessageType,
	ImageKind,
	ImageStatus,
	ShopBalanceEventType,
	SortDirection,
	SubscriptionEventType,
	TokenBalanceTransactionEventType,
	TutorialType,
	UserRole,
} from '../types';
import { introspection } from '../types/graphql/generated/graphql-env';

export const graphql = initGraphQLTada<{
	introspection: introspection;
	scalars: {
		ID: string;
		DateTime: string;
		JSON: unknown;
		ImageKind: ImageKind;
		ImageStatus: ImageStatus;
		LiteralGeneratedImage: ImageKind.GeneratedImage;
		LiteralEditedImage: ImageKind.EditedImage;
		SortDirection: SortDirection;
		SubscriptionEventType: SubscriptionEventType;
		TokenBalanceTransactionEventType: TokenBalanceTransactionEventType;
		ShopBalanceEventType: ShopBalanceEventType;
		ContactUsMessageType: ContactUsMessageType;
		UserRole: UserRole;
		TutorialType: TutorialType;
	};
}>();

export type { FragmentOf, ResultOf, VariablesOf } from 'gql.tada';
