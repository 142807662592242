import { StoryDraftSchema, featureFlagsSchema } from '../types';
import { CompanionHistorySchema } from '../types/companion';
import { zodId } from '../utils';
import { zod } from '../utils/zod';

export type RequestMethod = 'GET' | 'POST' | 'PUT';
export type Endpoint<RequestBody = void, ResponseBody = void> = {
	path: string;
	method: RequestMethod;
	requestBodySchema: zod.ZodSchema<RequestBody>;
	responseBodySchema: zod.ZodSchema<ResponseBody>;
};
// Utility types for extracting the Endpoint `RequestBody` and `ResponseBody` types from their respective schemas
export type RequestBody<T extends Endpoint<unknown, unknown>> =
	T extends Endpoint<infer RequestBody, unknown> ? RequestBody : never;
export type ResponseBody<T extends Endpoint<unknown, unknown>> =
	T extends Endpoint<unknown, infer ResponseBody> ? ResponseBody : never;

export type StreamResponse = zod.infer<typeof StreamResponseSchema>;
export const StreamResponseSchema = zod.string();

export const Endpoints = {
	sendMessageToCharacterCompanion: createEndpoint(
		'new-character/companion',
		'POST',
		zod.object({
			message: zod.string().min(1),
			// Can be an empty string
			name: zod.string().optional(),
			description: zod.string().optional(),
			characterDraftId: zodId('characterdraft'),
			history: CompanionHistorySchema,
		}),
		zod.object({
			message: zod.string(),
			name: zod.string().optional(),
			description: zod.string().optional(),
		})
	),
	sendMessageToIdeaCompanion: createEndpoint(
		'new-story/idea/companion',
		'POST',
		zod.object({
			message: zod.string().min(1),
			// Can be an empty string
			idea: StoryDraftSchema.shape.idea.optional(),
			characterIds: zod.array(zodId('character')),
			storyDraftId: zodId('storydraft'),
			history: CompanionHistorySchema,
		}),
		zod.object({
			message: zod.string(),
			idea: zod.string().optional(),
		})
	),
	sendMessageToScriptCompanion: createEndpoint(
		'new-story/script/companion',
		'POST',
		zod.object({
			message: zod.string().min(1),
			script: zod.string(),
			characterIds: zod.array(zodId('character')),
			storyDraftId: zodId('storydraft'),
			history: CompanionHistorySchema,
			settings: StoryDraftSchema.shape.settings,
		}),
		zod.object({
			message: zod.string(),
			script: zod.string().optional(),
		})
	),
	sendMessageToPanelsCompanion: createEndpoint(
		'new-story/panels/companion',
		'POST',
		zod.object({
			message: zod.string().min(1),
			panels: StoryDraftSchema.shape.pages,
			characterIds: zod.array(zodId('character')),
			storyDraftId: zodId('storydraft'),
			history: CompanionHistorySchema,
		}),
		zod.object({
			message: zod.string(),
			panels: StoryDraftSchema.shape.pages.optional(),
		})
	),
	generateScript: createEndpoint(
		'new-story/script/generation',
		'POST',
		zod.object({
			idea: StoryDraftSchema.shape.idea,
			settings: StoryDraftSchema.shape.settings,
			characterIds: zod.array(zodId('character')),
			storyDraftId: zodId('storydraft'),
		}),
		zod.object({
			script: StoryDraftSchema.shape.script,
		})
	),
	generatePanels: createEndpoint(
		'new-story/panels/generation',
		'POST',
		zod.object({
			script: StoryDraftSchema.shape.script,
			settings: StoryDraftSchema.shape.settings,
			characterIds: zod.array(zodId('character')),
			storyDraftId: zodId('storydraft'),
		}),
		zod.object({
			details: StoryDraftSchema.shape.details,
			pages: StoryDraftSchema.shape.pages,
			panels: StoryDraftSchema.shape.panels,
		})
	),
	// auth
	signInWithGoogle: createEndpoint(
		'auth/sign-in/google',
		'GET',
		zod.object({}),
		zod.object({ authorizationUrl: zod.string() })
	),
	validateGoogleSignInCallback: createEndpoint('auth/sign-in/google/callback', 'GET', zod.object({}), zod.object({})),
	// feature flags
	getFeatureFlags: createEndpoint('feature-flags', 'GET', zod.object({}), featureFlagsSchema),
	// waitlist
	addUserToWaitlist: createEndpoint(
		'waitlisted-user',
		'POST',
		zod.object({ email: zod.string().email(), socialMediaLinks: zod.string().array().optional() }),
		zod.object({})
	),
};

function createEndpoint<RequestBody = void, ResponseBody = void>(
	path: string,
	method: RequestMethod,
	requestBodySchema: zod.ZodSchema<RequestBody>,
	responseBodySchema: zod.ZodSchema<ResponseBody>
): Endpoint<RequestBody, ResponseBody> {
	const RestAPIPrefix = '/rest/v1/';
	return {
		path: RestAPIPrefix + path,
		method,
		requestBodySchema,
		responseBodySchema,
	};
}
