import { FetchResult, useMutation } from '@apollo/client';
import { ResultOf, graphql } from '@shared';
import { clearEntitiesFromCache } from '../utils/cache';

type UseFavoriteStoryReturn = {
	favoriteStory: (
		storyId: string,
		isFavorited: boolean,
		onCompleted?: (data: FavoriteStoryMutationReturn) => void
	) => Promise<FetchResult<FavoriteStoryMutationReturn>>;
};

export default function useFavoriteStory(): UseFavoriteStoryReturn {
	const [favoriteStoryMutationFn] = useMutation(favoriteStoryMutation);

	const favoriteStory = async (
		storyId: string,
		isFavorited: boolean,
		onCompleted?: (data: FavoriteStoryMutationReturn) => void
	) =>
		await favoriteStoryMutationFn({
			variables: {
				input: {
					storyId,
					isFavorited,
				},
			},
			update: (cache) => clearEntitiesFromCache(cache, ['story', 'stories']),
			onCompleted,
		});

	return {
		favoriteStory,
	};
}

type FavoriteStoryMutationReturn = ResultOf<typeof favoriteStoryMutation>;
const favoriteStoryMutation = graphql(`
	mutation FavoriteStory($input: FavoriteStoryInput!) {
		favoriteStory(input: $input) {
			id
			story {
				id
				isFavorited
			}
		}
	}
`);
