import { Button, Center, Group, Stack, Text, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import useSession from '../hooks/useSession';
import { GetMyCharactersReturn } from '../pages/Home';
import RoundedImage from './ui/RoundedImage';

type HeroProps = {
	characters?: GetMyCharactersReturn;
};

export default function Hero({ characters }: HeroProps) {
	const { isAuthenticated } = useSession();

	if (!isAuthenticated) {
		return <UnauthenticatedHero />;
	} else if (!characters || characters.length === 0) {
		return <NoCharactersHero />;
	} else {
		return <CharactersHero characters={characters} />;
	}
}

function UnauthenticatedHero() {
	return (
		<Stack ta="center" py={{ base: '40', sm: '80' }}>
			<Title fw="bolder">
				Bring stories to life <br /> with Lumi
			</Title>
			<Text>
				The complete platform for comic creators,
				<br />
				from idea to publishing
			</Text>
			<Button component={Link} to="/sign-up" m="auto">
				Create your story
			</Button>
		</Stack>
	);
}

function NoCharactersHero() {
	return (
		<Stack ta="center" py={{ base: '40', sm: '80' }}>
			<Title fw="bolder">
				Bring stories to life <br /> with Lumi
			</Title>
			<Text>
				The complete platform for comic creators,
				<br />
				from idea to publishing
			</Text>
			<Button component={Link} to="/new-character" m="auto">
				Create your first character
			</Button>
		</Stack>
	);
}

type CharactersHeroProps = {
	characters: GetMyCharactersReturn;
};

function CharactersHero({ characters }: CharactersHeroProps) {
	return (
		<Stack ta="center" gap="lg" py={{ base: '40', sm: '80' }}>
			<Stack gap="sm">
				<Title fw="bolder">
					Bring stories to life <br /> with Lumi
				</Title>
				<Text>
					The complete platform for comic creators,
					<br />
					from idea to publishing
				</Text>
			</Stack>
			<Center>
				<Group wrap="nowrap">
					{characters.map((c) => (
						<RoundedImage maw={'200'} key={c.id} url={c.imageUrl} to={`/characters/${c.id}`} />
					))}
				</Group>
			</Center>
			<Button component={Link} to="/new-story" m="auto">
				Create your story
			</Button>
		</Stack>
	);
}
