export function formatDateAsMonthDayAndYear(date: Date): string {
	return date.toLocaleString(undefined, {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
		timeZone: 'utc',
	});
}

export function formatDateTimeAsMonthDayAndYear(date: Date): string {
	return date.toLocaleString(undefined, {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	});
}

export function formatSecondsAsMinutesString(seconds: number): string {
	const minutes = Math.ceil(seconds / 60);
	if (minutes <= 1) {
		return `1 minute`;
	}
	return `${minutes} minutes`;
}
